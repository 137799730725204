import React from "react";
import TikTokAuthor from "./TikTokAuthor/TikTokAuthor";

const AuthorList = (props) => {
  console.log(props)
  return (
    <div className="App-main">
      {this.props.items.map((author) => (
        <TikTokAuthor key={author.id} data={author}/>
      ))}
    </div>
  )
};

export default AuthorList;
