
// import './Tracking.scss';
import tagData from '../../tagsearch.js';
import Search from "../../components/Search/Search.js";
import VideoList from "../../components/VideoList";
import React from "react";
import AuthorList from "../../components/AuthorList";

class Tracking extends React.Component {
  constructor() {
    super();

    this.state = {
      items: [],
    };
  }

  // for init, get the list of creators being tracked from the server
  componentDidMount() {
    fetch(`${process.env.REACT_APP_GOFAMER_TIKTOK_API}/creators`)
      .then(res => res.json())
      .then((data) => {
        console.log(data);
        this.setState({ items: data })
      })
      .catch(console.log)
  }

  render() {
    return (
      <div className="App">
        <h1>Currently tracking</h1>
        <main>
          <p className="App-error" style={{display: this.state.showError ? 'block' : 'none'}}>
            Error: {this.state.errorMessage}
          </p>
          {this.state.items.length > 0 ? (
              <AuthorList items={this.state.items} />
            ) : (
              <p>No creators being tracked</p>
            )
          }
          <pre style={{color: 'white'}}>{JSON.stringify(this.state.items, null, 2)}</pre>
        </main>

      </div>
    );
  }
}

export default Tracking;
