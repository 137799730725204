import './Discovery.scss';
import tagData from '../../tagsearch.js';
import Search from "../../components/Search/Search.js";
import VideoList from "../../components/VideoList";
import React from "react";

class Discovery extends React.Component {
  constructor() {
    super();

    this.state = {
      items: tagData,
      tag: 'indiemusician',
      showError: false,
      errorMessage: ''
    };
  }

  searchCallback = (query, result) => {
    if (result.status === 'error') {
      this.setState({
        showError: true,
        errorMessage: result.message
      });
    }
    this.setState({items: result, tag: query});
  }

  render() {
    return (
      <div className="App">
        <Search callback={this.searchCallback} searchTerm={this.state.tag}></Search>

        <h1>Top video results for <code>#{this.state.tag}</code></h1>
        <main>
          <p className="App-error" style={{display: this.state.showError ? 'block' : 'none'}}>
            Error: {this.state.errorMessage}
          </p>
          <VideoList items={this.state.items.itemList ? this.state.items : {itemList: []}}></VideoList>
          <button className="Button" onClick={this.loadMore()}>Load more</button>
          <pre style={{color: 'white'}}>{JSON.stringify(this.state.items, null, 2)}</pre>
        </main>

      </div>
    );
  }

  // TODO: implement
  loadMore() {
    return undefined;
  }
}

export default Discovery;
