import './Loading.scss';

const Loading = () => {
  return (
    <div className="Loading">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="15 15 80 80"
        preserveAspectRatio="xMidYMid"
        transform="rotate(40)">
        <g transform="rotate(0 50 50)">
          <rect x="13.5" y="34.5" rx="10.22" ry="4.5" width="73" height="9" fill="#ffffff">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8s" repeatCount="indefinite"></animate>
          </rect>
        </g><g transform="rotate(72 50 50)">
          <rect x="13.5" y="34.5" rx="10.22" ry="4.5" width="73" height="9" fill="#ffffff">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6s" repeatCount="indefinite"></animate>
          </rect>
        </g><g transform="rotate(144 50 50)">
          <rect x="13.5" y="34.5" rx="10.22" ry="4.5" width="73" height="9" fill="#ffffff">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4s" repeatCount="indefinite"></animate>
          </rect>
        </g><g transform="rotate(216 50 50)">
          <rect x="13.5" y="34.5" rx="10.22" ry="4.5" width="73" height="9" fill="#ffffff">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.2s" repeatCount="indefinite"></animate>
          </rect>
        </g><g transform="rotate(288 50 50)">
          <rect x="13.5" y="34.5" rx="10.22" ry="4.5" width="73" height="9" fill="#ffffff">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
          </rect>
        </g>
        </svg>
    </div>
  );
};

export default Loading;
