import './TikTokVideo.scss';
import Stat  from "./Stat/Stat";
import Tracker from "./Tracker/Tracker";

const TikTokVideo = ({data}) => {
  const author = data.author;
  const authorStats = data.authorStats;
  const authorName = author.nickname;
  const authorId = author.uniqueId;

  function limitString (string, limit) {
    if (limit === undefined) {
      limit = 50;
    }

    if (string.length > limit) {
      return string.substring(0, limit) + '...';
    } else {
      return string;
    }
  }

  return (
    <div className="TikTokVideo">
      <a href={`https://www.tiktok.com/@${authorId}/video/${data.id}`} target="_blank" className="TikTokVideo-video">
        <img className="TikTokVideo-videoImage" src={data.video.cover} alt={data.desc} />
      </a>
      <p>{limitString(data.desc, 150)}</p>
      <div className="TikTokVideo-author">
        <img className="TikTokVideo-avatar" src={author.avatarThumb} alt={authorName} />
        <h2 className="TikTokVideo-authorName"><a href={'https://www.tiktok.com/@'+authorId} target="_blank">{authorName}</a></h2>
      </div>
      <div className="TikTokVideo-authorStats">
        <Stat type="heart" count={authorStats.heartCount} />
        <Stat type="Followers" count={authorStats.followerCount} />
      </div>
      <Tracker
        authorName={authorName}
        authorId={authorId}
        heartCount={authorStats.heartCount}
        followerCount={authorStats.followerCount}
        followingCount={authorStats.followingCount}
        diggCount={authorStats.diggCount}
        friendCount={authorStats.friendCount}
        videoCount={authorStats.videoCount}
      ></Tracker>

    </div>
  );
};

export default TikTokVideo;
