import './Stat.scss';

const Stat = ({type, count}) => {

  function bigNum(x) {
    if (x < 1000) {
      return x;
    } else if (x < 1000000) {
      return (x/1000).toFixed(1) + 'K';
    } else if (x < 1000000000) {
      return (x/1000000).toFixed(1) + 'M';
    } else if (x < 1000000000000) {
      return (x/1000000000).toFixed(1) + 'B';
    } else if (x < 1000000000000000) {
      return (x/1000000000000).toFixed(1) + 'T';
    } else if (x < 1000000000000000000) {
      return (x/1000000000000000).toFixed(1) + 'Q';
    } else if (x < 1000000000000000000000) {
      return (x/1000000000000000000).toFixed(1) + 'E';
    }
  }

  function icon (type) {
    if (type === 'heart') {
      return (
        <svg className="Stat-icon">
          <g>
            <path
              d="M7.5 2.25c3 0 4.5 2 4.5 2s1.5-2 4.5-2c3.5 0 6 2.75 6 6.25 0 4-3.269 7.566-6.25 10.25C14.41 20.407 13 21.5 12 21.5s-2.45-1.101-4.25-2.75C4.82 16.066 1.5 12.5 1.5 8.5c0-3.5 2.5-6.25 6-6.25Z">
            </path>
            <path
              fill="black"
              fillOpacity=".03"
              d="M2.402 12.2c1.187 2.497 3.357 4.727 5.348 6.55C9.55 20.399 11 21.5 12 21.5s2.41-1.093 4.25-2.75c2.98-2.684 6.25-6.25 6.25-10.25 0-.087-.002-.174-.005-.26C20.567 13.661 13.68 18.5 11.75 18.5c-1.437 0-6.14-2.687-9.348-6.3Z">
            </path>
          </g>
        </svg>
      );
    } else if (type === 'video') {
      return (
        <svg className="Stat-icon" height="1em" viewBox="0 0 576 512">
          <path
            d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2V384c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1V320 192 174.9l14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z"/>
        </svg>
      );
    } else if (type === 'Friends') {
      return (
        <svg className="Stat-icon" height="1em" viewBox="0 0 640 512">
          <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32v-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2h61.4C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z"/>
        </svg>
      );
    } else {
      return (
        <span className="Stat-icon Stat-icon--text">
          {type}
        </span>
      );
    }
  }

  return (
    <div className="Stat">
      {icon(type)}
      <span className="Stat-value">
        {bigNum(count)}
      </span>
    </div>
  );
};

export default Stat;
