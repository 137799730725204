import {Route, Routes} from 'react-router-dom';
import Discovery from './pages/Discovery/Discovery.js';
import Tracking from './pages/Tracking/Tracking.js';

import logo from './gofamer-logo.png';

function Router() {
  return (
    <div>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="Gofamer"/>
      </header>
      <Routes>
        <Route exact path="/" element={<Discovery/>} />
        <Route path="/discovery" element={<Discovery/>} />
        <Route path="/tracking" element={<Tracking/>} />
      </Routes>
    </div>
  );
}

export default Router;
