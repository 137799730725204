import TikTokVideo from "./TikTokVideo";
import React from "react";

class VideoList extends React.Component {
  render() {
    return (
      <div className="App-main">
        {this.props.items.itemList.map((video) => (
          <TikTokVideo key={video.id} data={video}/>
        ))}
      </div>
    )
  };
};

export default VideoList;
