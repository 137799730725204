// import '../TikTokVideo.scss';
import Stat  from "../Stat/Stat";
import Tracker from "../Tracker/Tracker";

const TikTokAuthor = ({data}) => {
  const authorName = data.authorName ?? '';
  const authorId = data.authorId ?? '';
  const authorStats = data.creatorStats[0] ?? {};

  console.log('author', data);

  return (
    <div className="TikTokVideo">
      <div className="TikTokVideo-author">
        {/*<img className="TikTokVideo-avatar" src={author.avatarThumb} alt={authorName} />*/}
        <h2 className="TikTokVideo-authorName"><a href={'https://www.tiktok.com/@'+authorId} target="_blank">{authorName}</a></h2>
      </div>
      <div className="TikTokVideo-authorStats">
        <Stat type="Followers" count={authorStats.followerCount} />
        <Stat type="Following" count={authorStats.followingCount} />
        <Stat type="Friends" count={authorStats.friendCount} />
        <Stat type="video" count={authorStats.videoCount} />
        <Stat type="heart" count={authorStats.heartCount} />
      </div>
      <div className="TikTokVideo-authorStats">
        <span>Last updated:<br/>
          {authorStats.createdAt}
        </span>
      </div>
    </div>
  );
};

export default TikTokAuthor;
