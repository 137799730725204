let data = {
  "cursor": "30",
  "extra": {
    "fatal_item_ids": [],
    "logid": "20231114095954E1219E7683CB910E9B2D",
    "now": 1699955996000
  },
  "hasMore": true,
  "itemList": [
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "7224218912691438638",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Discovering Artists",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAA79sDcMCbl7QatFptF3JKobDAz67Uc0yws24YRf6FRLl8aWSAgSzrw6mu-bd0maiw",
        "secret": false,
        "signature": "\ud83d\udce7: discoveringartists.irl@gmail.com\n\nFollow our Spotify playlist!\ud83d\udc47",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "discoveringartists.irl",
        "verified": false
      },
      "authorStats": {
        "diggCount": 86,
        "followerCount": 515300,
        "followingCount": 13,
        "friendCount": 0,
        "heart": 15800000,
        "heartCount": 15800000,
        "videoCount": 35
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055695396906",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "doyoumakemusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055791882286",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "discoveringartistsirl"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "325757",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "4504990",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "newmusicfriday"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "136517",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musiccurator"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "14319",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "alexg"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "17331",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indie"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "496813",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "singersongwriter"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "50864",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musicproducer"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "35857",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indieartist"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "211116",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "area"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "Do you make music?"
        },
        {
          "desc": ""
        },
        {
          "desc": "#doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #alexg #indie #singersongwriter #musicproducer #indieartist #indiemusician #area",
          "textExtra": [
            {
              "awemeId": "",
              "end": 15,
              "hashtagId": "7252811055695396906",
              "hashtagName": "doyoumakemusic",
              "isCommerce": false,
              "secUid": "",
              "start": 0,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 38,
              "hashtagId": "7252811055791882286",
              "hashtagName": "discoveringartistsirl",
              "isCommerce": false,
              "secUid": "",
              "start": 16,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 56,
              "hashtagId": "325757",
              "hashtagName": "undergroundmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 39,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 72,
              "hashtagId": "4504990",
              "hashtagName": "newmusicfriday",
              "isCommerce": false,
              "secUid": "",
              "start": 57,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 86,
              "hashtagId": "136517",
              "hashtagName": "musiccurator",
              "isCommerce": false,
              "secUid": "",
              "start": 73,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 93,
              "hashtagId": "14319",
              "hashtagName": "alexg",
              "isCommerce": false,
              "secUid": "",
              "start": 87,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 100,
              "hashtagId": "17331",
              "hashtagName": "indie",
              "isCommerce": false,
              "secUid": "",
              "start": 94,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 118,
              "hashtagId": "496813",
              "hashtagName": "singersongwriter",
              "isCommerce": false,
              "secUid": "",
              "start": 101,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 133,
              "hashtagId": "50864",
              "hashtagName": "musicproducer",
              "isCommerce": false,
              "secUid": "",
              "start": 119,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 146,
              "hashtagId": "35857",
              "hashtagName": "indieartist",
              "isCommerce": false,
              "secUid": "",
              "start": 134,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 161,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 147,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 167,
              "hashtagId": "211116",
              "hashtagName": "area",
              "isCommerce": false,
              "secUid": "",
              "start": 162,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1689712188,
      "desc": "Do you make music? #doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #alexg #indie #singersongwriter #musicproducer #indieartist #indiemusician #area",
      "digged": false,
      "diversificationId": 10046,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7257258547753553198",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "",
        "authorName": "Discovering Artists",
        "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "coverMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "coverThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "duration": 34,
        "id": "7257258550241217326",
        "original": true,
        "playUrl": "https://v16m.tiktokcdn.com/bf6c1776b63e9fa1c0423ea6fe5495ec/6553999d/video/tos/maliva/tos-maliva-v-27dcd7c799-us/oskUybv26gEJn0foQtKDC9BS9DteQSC0kdYQTy/?a=1233&ch=0&cr=0&dr=0&er=2&cd=0%7C0%7C0%7C0&br=250&bt=125&bti=NDU3ZjAwOg%3D%3D&ft=d2A~l-Inz7TPgL-Ziyq8Z&mime_type=audio_mpeg&qs=6&rc=PDloaDQ3aWdoNDxpOGk3NkBpamh1OTg6ZmlpbDMzZzU8NEBhNGJjNS5eNTIxNWAwYC8xYSNwbmYxcjRfYG9gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00088000&cc=3&download=true",
        "title": "original sound"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 263200,
        "commentCount": 7096,
        "diggCount": 2600000,
        "playCount": 14000000,
        "shareCount": 8712
      },
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 34,
          "hashtagId": "7252811055695396906",
          "hashtagName": "doyoumakemusic",
          "isCommerce": false,
          "secUid": "",
          "start": 19,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 57,
          "hashtagId": "7252811055791882286",
          "hashtagName": "discoveringartistsirl",
          "isCommerce": false,
          "secUid": "",
          "start": 35,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 75,
          "hashtagId": "325757",
          "hashtagName": "undergroundmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 58,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 91,
          "hashtagId": "4504990",
          "hashtagName": "newmusicfriday",
          "isCommerce": false,
          "secUid": "",
          "start": 76,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 105,
          "hashtagId": "136517",
          "hashtagName": "musiccurator",
          "isCommerce": false,
          "secUid": "",
          "start": 92,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 112,
          "hashtagId": "14319",
          "hashtagName": "alexg",
          "isCommerce": false,
          "secUid": "",
          "start": 106,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 119,
          "hashtagId": "17331",
          "hashtagName": "indie",
          "isCommerce": false,
          "secUid": "",
          "start": 113,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 137,
          "hashtagId": "496813",
          "hashtagName": "singersongwriter",
          "isCommerce": false,
          "secUid": "",
          "start": 120,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 152,
          "hashtagId": "50864",
          "hashtagName": "musicproducer",
          "isCommerce": false,
          "secUid": "",
          "start": 138,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 165,
          "hashtagId": "35857",
          "hashtagName": "indieartist",
          "isCommerce": false,
          "secUid": "",
          "start": 153,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 180,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 166,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 186,
          "hashtagId": "211116",
          "hashtagName": "area",
          "isCommerce": false,
          "secUid": "",
          "start": 181,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 2485488,
        "bitrateInfo": [
          {
            "Bitrate": 2485488,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 10784846,
              "FileCs": "c:0-29467-a01a",
              "FileHash": "f7da5dbdc01fd7d86fd26229489b49fc",
              "Uri": "v12044gd0000cirfbo3c77u287fgb0g0",
              "UrlKey": "v12044gd0000cirfbo3c77u287fgb0g0_h264_540p_2485488",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/owSIpU9QwArGBQCgIfNd3yzA8KWDghCFkS8wQ2/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4854&bt=2427&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=PGRnZTU3OzU3Ojg7Z2c0ZkBpMzNqOjU6ZnJpbDMzZzczNEA0Li9fMmAzXy8xYmA1NV80YSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=baee57ec5c6c5cd2b8e23c81b0a5734a&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/owSIpU9QwArGBQCgIfNd3yzA8KWDghCFkS8wQ2/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4854&bt=2427&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=PGRnZTU3OzU3Ojg7Z2c0ZkBpMzNqOjU6ZnJpbDMzZzczNEA0Li9fMmAzXy8xYmA1NV80YSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=baee57ec5c6c5cd2b8e23c81b0a5734a&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 1671963,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 7254857,
              "FileCs": "c:0-30150-4c37",
              "FileHash": "27d13623a9b212ce9b727e9c57252adb",
              "Uri": "v12044gd0000cirfbo3c77u287fgb0g0",
              "UrlKey": "v12044gd0000cirfbo3c77u287fgb0g0_bytevc1_1080p_1671963",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQ7FIgfN6dpw6gv2BQzAUrWCIQyw9AyCG3h8NR/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3264&bt=1632&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=OTU4OztpOzs4Njs3NTo7OEBpMzNqOjU6ZnJpbDMzZzczNEA2NmE1YWEzXjUxMV4uYjFjYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=88fc49cdecc39f2aba7bb67d9c893a1d&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQ7FIgfN6dpw6gv2BQzAUrWCIQyw9AyCG3h8NR/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3264&bt=1632&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=OTU4OztpOzs4Njs3NTo7OEBpMzNqOjU6ZnJpbDMzZzczNEA2NmE1YWEzXjUxMV4uYjFjYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=88fc49cdecc39f2aba7bb67d9c893a1d&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 1128619,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 4897223,
              "FileCs": "c:0-29467-d96b",
              "FileHash": "5b7c4d7159872a7a30c53c3d62fb7ad7",
              "Uri": "v12044gd0000cirfbo3c77u287fgb0g0",
              "UrlKey": "v12044gd0000cirfbo3c77u287fgb0g0_h264_540p_1128619",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/osvE7tg1geLAABSV4kEgRxgJXDQfbDBnot7JJ7/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2204&bt=1102&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=NjM7ODhnaGhoOzVnM2c3OEBpMzNqOjU6ZnJpbDMzZzczNEBgLjYyYmM0XjYxYjM1NjIxYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=b941b5e10660cd7485b5850d78ec5e67&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/osvE7tg1geLAABSV4kEgRxgJXDQfbDBnot7JJ7/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2204&bt=1102&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=NjM7ODhnaGhoOzVnM2c3OEBpMzNqOjU6ZnJpbDMzZzczNEBgLjYyYmM0XjYxYjM1NjIxYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=b941b5e10660cd7485b5850d78ec5e67&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 1007064,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 4369779,
              "FileCs": "c:0-30153-c2d5",
              "FileHash": "011f4f4ce846e08c8df18ecd6266437e",
              "Uri": "v12044gd0000cirfbo3c77u287fgb0g0",
              "UrlKey": "v12044gd0000cirfbo3c77u287fgb0g0_bytevc1_720p_1007064",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQ3FIgfVKdpwogg2BQzAUrWCIQyw9AuCE3h8Nu/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1966&bt=983&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=N2k6ZjpoNmg3NWVnNWk1aEBpMzNqOjU6ZnJpbDMzZzczNEAyLzQtMmJgNi8xLTViLjAvYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=47d3a7e4729361a8aa39656f4d65aeee&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQ3FIgfVKdpwogg2BQzAUrWCIQyw9AuCE3h8Nu/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1966&bt=983&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=N2k6ZjpoNmg3NWVnNWk1aEBpMzNqOjU6ZnJpbDMzZzczNEAyLzQtMmJgNi8xLTViLjAvYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=47d3a7e4729361a8aa39656f4d65aeee&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 737987,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 3202220,
              "FileCs": "c:0-29467-685a",
              "FileHash": "bd84f0e46eb9e147ab5318eb6145c43b",
              "Uri": "v12044gd0000cirfbo3c77u287fgb0g0",
              "UrlKey": "v12044gd0000cirfbo3c77u287fgb0g0_h264_540p_737987",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oEigBDbDwgtkkgASgoBXRLAWG7Qke4EGnEev92/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1440&bt=720&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=NDxoNWRkZjU5aWhoODU5N0BpMzNqOjU6ZnJpbDMzZzczNEA0NTNhLWEtNS4xNjQvNl9hYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=69f5ae86724cd74c9f131006f2b0d0b8&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oEigBDbDwgtkkgASgoBXRLAWG7Qke4EGnEev92/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1440&bt=720&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=NDxoNWRkZjU5aWhoODU5N0BpMzNqOjU6ZnJpbDMzZzczNEA0NTNhLWEtNS4xNjQvNl9hYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=69f5ae86724cd74c9f131006f2b0d0b8&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          },
          {
            "Bitrate": 711723,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 3088259,
              "FileCs": "c:0-30153-e493",
              "FileHash": "5f424e048c6e44512b398b78a16694da",
              "Uri": "v12044gd0000cirfbo3c77u287fgb0g0",
              "UrlKey": "v12044gd0000cirfbo3c77u287fgb0g0_bytevc1_540p_711723",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oMbRQEXgAEgjeF72BQB9or49gge0DkAvtLnS4D/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1390&bt=695&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=aDQ3aThkZzo3Mzw3aDdlNUBpMzNqOjU6ZnJpbDMzZzczNEAyLTMuLzU0XzUxLi00YDRiYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=0be17ec6f8b91a1a15d533b765e6cfde&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oMbRQEXgAEgjeF72BQB9or49gge0DkAvtLnS4D/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1390&bt=695&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=aDQ3aThkZzo3Mzw3aDdlNUBpMzNqOjU6ZnJpbDMzZzczNEAyLTMuLzU0XzUxLi00YDRiYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=0be17ec6f8b91a1a15d533b765e6cfde&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/821ef44b7582455398cae778191625c2_1689712189?x-expires=1700125200&x-signature=aQ1%2FOoQuH%2FGfth1O4UfY%2FLYz9CY%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/osHN3frVAlFIzzCgUg9BwQ58iahCWyXpwQA2Id/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=3710&bt=1855&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aDg8ZWg0NmVkOzk2O2k7ZEBpMzNqOjU6ZnJpbDMzZzczNEBjYGNgNjFiNmIxLmIyLmA0YSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=f3c390747d5d33dcc0ab20acae0df530&tk=tt_chain_token",
        "duration": 34,
        "dynamicCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/910c4f66ad0545749d344de5f3408dfb_1689712190?x-expires=1700125200&x-signature=FRaMGKuxenQD%2B%2Flh3luRKn%2FiuvQ%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7257258547753553198",
        "originCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/9834814b35004505b21d2380a4d63b3a_1689712189?x-expires=1700125200&x-signature=yjr4OxFFL1N0fy35MLCm4adM0lw%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/owSIpU9QwArGBQCgIfNd3yzA8KWDghCFkS8wQ2/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4854&bt=2427&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=PGRnZTU3OzU3Ojg7Z2c0ZkBpMzNqOjU6ZnJpbDMzZzczNEA0Li9fMmAzXy8xYmA1NV80YSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=baee57ec5c6c5cd2b8e23c81b0a5734a&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/821ef44b7582455398cae778191625c2_1689712189~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=WVSwJOt%2BUleFIzq1CKo3U%2FyBO28%3D",
        "shareCover": [
          "",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/9834814b35004505b21d2380a4d63b3a_1689712189~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=tfkHgx9qJ99gcZp58sAJ08bRtKw%3D",
          "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/9834814b35004505b21d2380a4d63b3a_1689712189~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=FPqDKi6Eb433FwMerwNp4iR8VZs%3D"
        ],
        "size": 10784846,
        "subtitleInfos": [
          {
            "Format": "webvtt",
            "LanguageCodeName": "jpn-JP",
            "LanguageID": "3",
            "Size": 1220,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/98244b1b204c1099b66397d334817bef/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/af3d7886746342f7ace169206f214618/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4854&bt=2427&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzNqOjU6ZnJpbDMzZzczNEBpMzNqOjU6ZnJpbDMzZzczNEBkX2M1cjQwX29gLS1kMS9zYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": -1211454349
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "por-PT",
            "LanguageID": "8",
            "Size": 1050,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/91f86c5d1d3516cdd97bb6422d9043f7/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/94d0143a39a44a1ea9579b3342853a51/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4854&bt=2427&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzNqOjU6ZnJpbDMzZzczNEBpMzNqOjU6ZnJpbDMzZzczNEBkX2M1cjQwX29gLS1kMS9zYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": -1542137548
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "fra-FR",
            "LanguageID": "7",
            "Size": 1119,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/c9421ec04d165d43659d2bd9dae2ea5a/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/90da7cb40a0e499cb77e7d7494fc7ef1/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4854&bt=2427&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzNqOjU6ZnJpbDMzZzczNEBpMzNqOjU6ZnJpbDMzZzczNEBkX2M1cjQwX29gLS1kMS9zYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": -1124094127
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "eng-US",
            "LanguageID": "2",
            "Size": 775,
            "Source": "ASR",
            "Url": "https://v16-webapp.tiktok.com/c54b8a0c1e6742b40df88e6927348556/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/0646ee5ae3bc4640a0412966607ae84e/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4854&bt=2427&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzNqOjU6ZnJpbDMzZzczNEBpMzNqOjU6ZnJpbDMzZzczNEBkX2M1cjQwX29gLS1kMS9zYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "1",
            "VideoSubtitleID": -161354575
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "rus-RU",
            "LanguageID": "6",
            "Size": 1339,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/6af00603f8604b8d5ca1cffaf34b91fb/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/54fabeb405154b81812edc57cc1e844c/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4854&bt=2427&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzNqOjU6ZnJpbDMzZzczNEBpMzNqOjU6ZnJpbDMzZzczNEBkX2M1cjQwX29gLS1kMS9zYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": -929652095
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ara-SA",
            "LanguageID": "34",
            "Size": 994,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/cccefa82f41a66fb8c1f9772ee0c6f10/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/51a42a1e58214645bfdd1d6a5f58dccb/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4854&bt=2427&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzNqOjU6ZnJpbDMzZzczNEBpMzNqOjU6ZnJpbDMzZzczNEBkX2M1cjQwX29gLS1kMS9zYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": 2100494861
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "deu-DE",
            "LanguageID": "20",
            "Size": 1132,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/20f161bf394064fea62aec0370cda3d3/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/5d48b57cd69b44399cee02dea16819d5/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4854&bt=2427&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzNqOjU6ZnJpbDMzZzczNEBpMzNqOjU6ZnJpbDMzZzczNEBkX2M1cjQwX29gLS1kMS9zYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": -1482541492
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ind-ID",
            "LanguageID": "23",
            "Size": 1018,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/56717cba839966ef6c9efd4058adf754/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/405ba5d2cf7e4b868572f32c4d291083/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4854&bt=2427&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzNqOjU6ZnJpbDMzZzczNEBpMzNqOjU6ZnJpbDMzZzczNEBkX2M1cjQwX29gLS1kMS9zYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": -1058743789
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "spa-ES",
            "LanguageID": "9",
            "Size": 1056,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/4231de4d7863281a5b70142b1d7ca8f2/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/bbb60136e2554d96919dc1cd03d94016/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4854&bt=2427&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzNqOjU6ZnJpbDMzZzczNEBpMzNqOjU6ZnJpbDMzZzczNEBkX2M1cjQwX29gLS1kMS9zYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": -251195758
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "vie-VN",
            "LanguageID": "10",
            "Size": 1230,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/168e5b6e0cb0ab1b5d037b2324345c3c/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/10f234de0b5d4cd7b51e0793c653adc7/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4854&bt=2427&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzNqOjU6ZnJpbDMzZzczNEBpMzNqOjU6ZnJpbDMzZzczNEBkX2M1cjQwX29gLS1kMS9zYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": -646272342
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "cmn-Hans-CN",
            "LanguageID": "1",
            "Size": 1016,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/0eb66ec0c7d14ef50649d783b4366b11/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/7ff85e5000584f7b97b0a9ab87b8d84c/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4854&bt=2427&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzNqOjU6ZnJpbDMzZzczNEBpMzNqOjU6ZnJpbDMzZzczNEBkX2M1cjQwX29gLS1kMS9zYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": 1333302312
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "kor-KR",
            "LanguageID": "4",
            "Size": 1176,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/f2ca8086591f5ffcb9b2558082f1203a/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/bbf6b1d8085144ee94785505bf1d3dea/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4854&bt=2427&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzNqOjU6ZnJpbDMzZzczNEBpMzNqOjU6ZnJpbDMzZzczNEBkX2M1cjQwX29gLS1kMS9zYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": 489380475
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ita-IT",
            "LanguageID": "26",
            "Size": 1048,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/0f7607985b6e3ba4d85ac1c1ee86400f/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/7a5a876bf1a44f828ecdde298441a08b/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4854&bt=2427&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzNqOjU6ZnJpbDMzZzczNEBpMzNqOjU6ZnJpbDMzZzczNEBkX2M1cjQwX29gLS1kMS9zYSNkX2M1cjQwX29gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": -1974299799
          }
        ],
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -11.1,
          "Peak": 1
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/821ef44b7582455398cae778191625c2_1689712189~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=uDpcW6ma3IXpArEKTuVU3KjmnBo%3D",
          "480": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/821ef44b7582455398cae778191625c2_1689712189~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=847zDLFppbIAQd9nEI47mv4r1dU%3D",
          "720": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/821ef44b7582455398cae778191625c2_1689712189~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=b7qT27DVtFf8%2BCTOD7izON4SjJM%3D",
          "960": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/821ef44b7582455398cae778191625c2_1689712189~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=L9Dresv5%2BWbTeXbesbh6lPXnTjA%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "7224218912691438638",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Discovering Artists",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAA79sDcMCbl7QatFptF3JKobDAz67Uc0yws24YRf6FRLl8aWSAgSzrw6mu-bd0maiw",
        "secret": false,
        "signature": "\ud83d\udce7: discoveringartists.irl@gmail.com\n\nFollow our Spotify playlist!\ud83d\udc47",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "discoveringartists.irl",
        "verified": false
      },
      "authorStats": {
        "diggCount": 86,
        "followerCount": 515300,
        "followingCount": 13,
        "friendCount": 0,
        "heart": 15800000,
        "heartCount": 15800000,
        "videoCount": 35
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055695396906",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "doyoumakemusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055791882286",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "discoveringartistsirl"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "325757",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "4504990",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "newmusicfriday"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "136517",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musiccurator"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "67840002",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "sweettrip"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "17331",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indie"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "496813",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "singersongwriter"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "50864",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musicproducer"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "35857",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indieartist"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "Do you make music?"
        },
        {
          "desc": ""
        },
        {
          "desc": "#doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #sweettrip #indie #singersongwriter #musicproducer #indieartist #indiemusician ",
          "textExtra": [
            {
              "awemeId": "",
              "end": 15,
              "hashtagId": "7252811055695396906",
              "hashtagName": "doyoumakemusic",
              "isCommerce": false,
              "secUid": "",
              "start": 0,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 38,
              "hashtagId": "7252811055791882286",
              "hashtagName": "discoveringartistsirl",
              "isCommerce": false,
              "secUid": "",
              "start": 16,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 56,
              "hashtagId": "325757",
              "hashtagName": "undergroundmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 39,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 72,
              "hashtagId": "4504990",
              "hashtagName": "newmusicfriday",
              "isCommerce": false,
              "secUid": "",
              "start": 57,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 86,
              "hashtagId": "136517",
              "hashtagName": "musiccurator",
              "isCommerce": false,
              "secUid": "",
              "start": 73,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 97,
              "hashtagId": "67840002",
              "hashtagName": "sweettrip",
              "isCommerce": false,
              "secUid": "",
              "start": 87,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 104,
              "hashtagId": "17331",
              "hashtagName": "indie",
              "isCommerce": false,
              "secUid": "",
              "start": 98,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 122,
              "hashtagId": "496813",
              "hashtagName": "singersongwriter",
              "isCommerce": false,
              "secUid": "",
              "start": 105,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 137,
              "hashtagId": "50864",
              "hashtagName": "musicproducer",
              "isCommerce": false,
              "secUid": "",
              "start": 123,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 150,
              "hashtagId": "35857",
              "hashtagName": "indieartist",
              "isCommerce": false,
              "secUid": "",
              "start": 138,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 165,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 151,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1688772382,
      "desc": "Do you make music? #doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #sweettrip #indie #singersongwriter #musicproducer #indieartist #indiemusician ",
      "digged": false,
      "diversificationId": 10046,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7253222095553940779",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "",
        "authorName": "Discovering Artists",
        "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "coverMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "coverThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "duration": 33,
        "id": "7253222103124708142",
        "original": true,
        "playUrl": "https://v16m.tiktokcdn.com/2798dd49443fef2450452b5097313974/6553999c/video/tos/maliva/tos-maliva-v-27dcd7c799-us/ow0g8XQIQDmmS9UDJnkUonyBqoJYS0fbgCweiJ/?a=1233&ch=0&cr=0&dr=0&er=2&cd=0%7C0%7C0%7C0&br=250&bt=125&bti=NDU3ZjAwOg%3D%3D&ft=d2A~l-Inz7TPgL-Ziyq8Z&mime_type=audio_mpeg&qs=6&rc=Njs5N2ZkZ2hkaWc2PDZlaUBpM21nNmU6Zjs8bDMzZzU8NEAwLjU0MjAxNi8xXzM1L2IvYSMvaC9wcjQwcmhgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00088000&cc=3&download=true",
        "title": "original sound"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 208300,
        "commentCount": 7342,
        "diggCount": 2300000,
        "playCount": 12900000,
        "shareCount": 7394
      },
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 34,
          "hashtagId": "7252811055695396906",
          "hashtagName": "doyoumakemusic",
          "isCommerce": false,
          "secUid": "",
          "start": 19,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 57,
          "hashtagId": "7252811055791882286",
          "hashtagName": "discoveringartistsirl",
          "isCommerce": false,
          "secUid": "",
          "start": 35,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 75,
          "hashtagId": "325757",
          "hashtagName": "undergroundmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 58,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 91,
          "hashtagId": "4504990",
          "hashtagName": "newmusicfriday",
          "isCommerce": false,
          "secUid": "",
          "start": 76,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 105,
          "hashtagId": "136517",
          "hashtagName": "musiccurator",
          "isCommerce": false,
          "secUid": "",
          "start": 92,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 116,
          "hashtagId": "67840002",
          "hashtagName": "sweettrip",
          "isCommerce": false,
          "secUid": "",
          "start": 106,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 123,
          "hashtagId": "17331",
          "hashtagName": "indie",
          "isCommerce": false,
          "secUid": "",
          "start": 117,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 141,
          "hashtagId": "496813",
          "hashtagName": "singersongwriter",
          "isCommerce": false,
          "secUid": "",
          "start": 124,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 156,
          "hashtagId": "50864",
          "hashtagName": "musicproducer",
          "isCommerce": false,
          "secUid": "",
          "start": 142,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 169,
          "hashtagId": "35857",
          "hashtagName": "indieartist",
          "isCommerce": false,
          "secUid": "",
          "start": 157,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 184,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 170,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 2491039,
        "bitrateInfo": [
          {
            "Bitrate": 2491039,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 10439634,
              "FileCs": "c:0-28228-3c28",
              "FileHash": "ef3c7e224e9b94eb58b0c9dc74b293c4",
              "Uri": "v12044gd0000cik9sf3c77u2uqt0sht0",
              "UrlKey": "v12044gd0000cik9sf3c77u2uqt0sht0_h264_540p_2491039",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oM9rITBkDg9UuKR6JhtyNQRAoeKzqfC4JSOnoE/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4864&bt=2432&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=MzgzZzQzNjhkNDg8ODY3ZkBpM2szdDU6Zmk8bDMzZzczNEAxLS00NWFgNjIxYTAvNF40YSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128829&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=18eb3bd14c6e01f2d509961d94979e5b&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oM9rITBkDg9UuKR6JhtyNQRAoeKzqfC4JSOnoE/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4864&bt=2432&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=MzgzZzQzNjhkNDg8ODY3ZkBpM2szdDU6Zmk8bDMzZzczNEAxLS00NWFgNjIxYTAvNF40YSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128829&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=18eb3bd14c6e01f2d509961d94979e5b&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 2204806,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 9195971,
              "FileCs": "c:0-29021-2516",
              "FileHash": "0a7bd0bfffcc3f3da258f554d1e1f3cf",
              "Uri": "v12044gd0000cik9sf3c77u2uqt0sht0",
              "UrlKey": "v12044gd0000cik9sf3c77u2uqt0sht0_bytevc1_1080p_2204806",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oMxooJs9zUSI6oC4AOfQSvReRBNVkIDIhagrEq/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4306&bt=2153&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=MzNmPDU8Zzs4OGU1NTxnPEBpM2szdDU6Zmk8bDMzZzczNEBfYC0yLjZeNS0xMl8xLl4yYSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128829&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=b411937056e1d0152bb748a70307d99b&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oMxooJs9zUSI6oC4AOfQSvReRBNVkIDIhagrEq/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4306&bt=2153&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=MzNmPDU8Zzs4OGU1NTxnPEBpM2szdDU6Zmk8bDMzZzczNEBfYC0yLjZeNS0xMl8xLl4yYSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128829&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=b411937056e1d0152bb748a70307d99b&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 1256604,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 5266273,
              "FileCs": "c:0-28228-090f",
              "FileHash": "a62f0c19b9c6fd2ad9471aa176e8db57",
              "Uri": "v12044gd0000cik9sf3c77u2uqt0sht0",
              "UrlKey": "v12044gd0000cik9sf3c77u2uqt0sht0_h264_540p_1256604",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/okooeRgqNAIxM4aPQzRkrzB6nhDSfEBO9JFUEC/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2454&bt=1227&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=aTdmNTxnZTtkZ2lmZjw1aEBpM2szdDU6Zmk8bDMzZzczNEBiYDUzNGJfXi8xMzItYF4xYSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128829&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=de4482f4be2b56b04ed37932f866cbd8&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/okooeRgqNAIxM4aPQzRkrzB6nhDSfEBO9JFUEC/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2454&bt=1227&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=aTdmNTxnZTtkZ2lmZjw1aEBpM2szdDU6Zmk8bDMzZzczNEBiYDUzNGJfXi8xMzItYF4xYSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128829&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=de4482f4be2b56b04ed37932f866cbd8&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 1157611,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 4828251,
              "FileCs": "c:0-29063-8e60",
              "FileHash": "113171d74a8fa23e80025f4003dacf7a",
              "Uri": "v12044gd0000cik9sf3c77u2uqt0sht0",
              "UrlKey": "v12044gd0000cik9sf3c77u2uqt0sht0_bytevc1_720p_1157611",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oMKREERhRSrNO4v6CgQJrUmqIA9koCzTHePDfB/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2260&bt=1130&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=aGVkaDM2NjQ6Ojk1NGg1PEBpM2szdDU6Zmk8bDMzZzczNEBgMy8tYDRiNWExYjUzYWE0YSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128829&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=fb2e2a0f46cae8439b0af5bf8687173f&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oMKREERhRSrNO4v6CgQJrUmqIA9koCzTHePDfB/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2260&bt=1130&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=aGVkaDM2NjQ6Ojk1NGg1PEBpM2szdDU6Zmk8bDMzZzczNEBgMy8tYDRiNWExYjUzYWE0YSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128829&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=fb2e2a0f46cae8439b0af5bf8687173f&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 904117,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 3770960,
              "FileCs": "c:0-29026-1f5d",
              "FileHash": "b1d3b6d85f4c90dca7d08666a35306d9",
              "Uri": "v12044gd0000cik9sf3c77u2uqt0sht0",
              "UrlKey": "v12044gd0000cik9sf3c77u2uqt0sht0_bytevc1_540p_904117",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oga4IS69ezAAorDImJfCDRkQKEOgRB6qUoNroh/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1764&bt=882&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=NmU1NmllODU5NTxkNzw2O0BpM2szdDU6Zmk8bDMzZzczNEAuY142LzJgNS0xNWFeNDUzYSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128829&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=a5d8c2b6730d26116d0770069b690e92&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oga4IS69ezAAorDImJfCDRkQKEOgRB6qUoNroh/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1764&bt=882&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=NmU1NmllODU5NTxkNzw2O0BpM2szdDU6Zmk8bDMzZzczNEAuY142LzJgNS0xNWFeNDUzYSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128829&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=a5d8c2b6730d26116d0770069b690e92&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 834198,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 3496020,
              "FileCs": "c:0-28228-f754",
              "FileHash": "ffaac2d58b7f0ff1b20fbffd977c8715",
              "Uri": "v12044gd0000cik9sf3c77u2uqt0sht0",
              "UrlKey": "v12044gd0000cik9sf3c77u2uqt0sht0_h264_540p_834198",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oIaIEVP6hfA9mqQgURgzSeoOJRnD4CkobDrNBz/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1628&bt=814&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=NTo2NmY2O2VlNjQ6Mzw1M0BpM2szdDU6Zmk8bDMzZzczNEAxXjNhMjRhX2AxXzVhYl4vYSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128829&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c1a61dab2b73d4057e22d9fa72046d7b&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oIaIEVP6hfA9mqQgURgzSeoOJRnD4CkobDrNBz/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1628&bt=814&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=NTo2NmY2O2VlNjQ6Mzw1M0BpM2szdDU6Zmk8bDMzZzczNEAxXjNhMjRhX2AxXzVhYl4vYSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128829&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c1a61dab2b73d4057e22d9fa72046d7b&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/c3703d3fdd4544c59547248b2327d0a9_1688772383?x-expires=1700125200&x-signature=FeQy1Ypb5CNtPOQzDvP7Vf164kY%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/okjo874DFXRQgomQ8BekAneng0SgUDd0bJqEcF/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=4908&bt=2454&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=PDRkOzk0OzVlN2c6PDNpM0BpM2szdDU6Zmk8bDMzZzczNEAuMi1eYDNjNmAxMGNeLjBjYSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128829&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=5070c6a5fab46873809cb8cb4548ceb0&tk=tt_chain_token",
        "duration": 33,
        "dynamicCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/442b0824395e49b3b021d9b73fe7dbbe_1688772386?x-expires=1700125200&x-signature=LsLHCCves7QDfgx4Eqk5EdMEej4%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7253222095553940779",
        "originCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/67c36f6c555942fea6982d35a45b72d6_1688772384?x-expires=1700125200&x-signature=IjZ7zMkCmlFMYMm2d5W3AEZ1Rcs%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oM9rITBkDg9UuKR6JhtyNQRAoeKzqfC4JSOnoE/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4864&bt=2432&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=MzgzZzQzNjhkNDg8ODY3ZkBpM2szdDU6Zmk8bDMzZzczNEAxLS00NWFgNjIxYTAvNF40YSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128829&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=18eb3bd14c6e01f2d509961d94979e5b&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/c3703d3fdd4544c59547248b2327d0a9_1688772383~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=UptiKeb8rwbDvXONGXvxr62xOn4%3D",
        "shareCover": [
          "",
          "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/67c36f6c555942fea6982d35a45b72d6_1688772384~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=P59w0QgrFyF0oT8e4NDrxmQf9k0%3D",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/67c36f6c555942fea6982d35a45b72d6_1688772384~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=avgV6Z7G52hmG36ESExe4lrh6qs%3D"
        ],
        "size": 10439634,
        "subtitleInfos": [
          {
            "Format": "webvtt",
            "LanguageCodeName": "spa-ES",
            "LanguageID": "9",
            "Size": 904,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/f44d3ad659737bae7c6ff4d87beb6c22/6555e83d/video/tos/maliva/tos-maliva-v-0068c799-us/fe4fed32c3a14fd6aebada7ce1942f0b/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14890&bt=7445&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2szdDU6Zmk8bDMzZzczNEBpM2szdDU6Zmk8bDMzZzczNEBxcHFycjQwcGhgLS1kMS9zYSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128829,
            "Version": "4",
            "VideoSubtitleID": 1036176275
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ara-SA",
            "LanguageID": "34",
            "Size": 894,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/b0c1063bb3840da3c32403ce06c98537/6555e83d/video/tos/maliva/tos-maliva-v-0068c799-us/45e59171240f45debe95bd0a9cc34383/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14890&bt=7445&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2szdDU6Zmk8bDMzZzczNEBpM2szdDU6Zmk8bDMzZzczNEBxcHFycjQwcGhgLS1kMS9zYSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128829,
            "Version": "4",
            "VideoSubtitleID": 474236390
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ind-ID",
            "LanguageID": "23",
            "Size": 889,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/ef913826e08e57c57b796a4c84595ba4/6555e83d/video/tos/maliva/tos-maliva-v-0068c799-us/eff24bc2f97247b0a3162d5da0a5999f/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14890&bt=7445&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2szdDU6Zmk8bDMzZzczNEBpM2szdDU6Zmk8bDMzZzczNEBxcHFycjQwcGhgLS1kMS9zYSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128829,
            "Version": "4",
            "VideoSubtitleID": 1649122814
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "kor-KR",
            "LanguageID": "4",
            "Size": 1051,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/442275b0f38944522067a24fc970ac46/6555e83d/video/tos/maliva/tos-maliva-v-0068c799-us/50838c5542a74756a1927fb3b7b3a8c9/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14890&bt=7445&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2szdDU6Zmk8bDMzZzczNEBpM2szdDU6Zmk8bDMzZzczNEBxcHFycjQwcGhgLS1kMS9zYSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128829,
            "Version": "4",
            "VideoSubtitleID": 360150049
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "rus-RU",
            "LanguageID": "6",
            "Size": 1266,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/121a92ab46e6282d1ada5f482046d292/6555e83d/video/tos/maliva/tos-maliva-v-0068c799-us/fdf6dd41c0da456eaf9de935093980d2/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14890&bt=7445&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2szdDU6Zmk8bDMzZzczNEBpM2szdDU6Zmk8bDMzZzczNEBxcHFycjQwcGhgLS1kMS9zYSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128829,
            "Version": "4",
            "VideoSubtitleID": 941711996
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "vie-VN",
            "LanguageID": "10",
            "Size": 1041,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/bdcb68326bc0f73d6e9ddcad95fa3b39/6555e83d/video/tos/maliva/tos-maliva-v-0068c799-us/a6cffcb327054a9dbe5b99570ed23798/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14890&bt=7445&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2szdDU6Zmk8bDMzZzczNEBpM2szdDU6Zmk8bDMzZzczNEBxcHFycjQwcGhgLS1kMS9zYSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128829,
            "Version": "4",
            "VideoSubtitleID": -1130245749
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "fra-FR",
            "LanguageID": "7",
            "Size": 910,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/53253e4596deeb4e82ded364a672a234/6555e83d/video/tos/maliva/tos-maliva-v-0068c799-us/f71fa5309f554518bf4e6ee2bb8172b8/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14890&bt=7445&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2szdDU6Zmk8bDMzZzczNEBpM2szdDU6Zmk8bDMzZzczNEBxcHFycjQwcGhgLS1kMS9zYSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128829,
            "Version": "4",
            "VideoSubtitleID": -956995
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "eng-US",
            "LanguageID": "2",
            "Size": 640,
            "Source": "ASR",
            "Url": "https://v16-webapp.tiktok.com/6127ee16b69f704156e8ae850d217f77/6555e83d/video/tos/maliva/tos-maliva-v-0068c799-us/87f6ee9a6460421d8ce21778f4e0e24f/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14890&bt=7445&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2szdDU6Zmk8bDMzZzczNEBpM2szdDU6Zmk8bDMzZzczNEBxcHFycjQwcGhgLS1kMS9zYSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128829,
            "Version": "1",
            "VideoSubtitleID": 847227016
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ita-IT",
            "LanguageID": "26",
            "Size": 838,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/c6fa7647774c29e152a055d4fb1fb531/6555e83d/video/tos/maliva/tos-maliva-v-0068c799-us/eca48cf5e98a422ba41b2dd3dd5837ff/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14890&bt=7445&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2szdDU6Zmk8bDMzZzczNEBpM2szdDU6Zmk8bDMzZzczNEBxcHFycjQwcGhgLS1kMS9zYSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128829,
            "Version": "4",
            "VideoSubtitleID": -613443651
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "deu-DE",
            "LanguageID": "20",
            "Size": 901,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/c0e55a0df710405f5532517c9a64200b/6555e83d/video/tos/maliva/tos-maliva-v-0068c799-us/0f43a94fbfb34e46aab500b5089a6253/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14890&bt=7445&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2szdDU6Zmk8bDMzZzczNEBpM2szdDU6Zmk8bDMzZzczNEBxcHFycjQwcGhgLS1kMS9zYSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128829,
            "Version": "4",
            "VideoSubtitleID": -1360919936
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "por-PT",
            "LanguageID": "8",
            "Size": 882,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/1b2130fa210fdf7d857848c235772ef8/6555e83d/video/tos/maliva/tos-maliva-v-0068c799-us/809bb6f025624a83afab18ef847e5e82/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14890&bt=7445&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2szdDU6Zmk8bDMzZzczNEBpM2szdDU6Zmk8bDMzZzczNEBxcHFycjQwcGhgLS1kMS9zYSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128829,
            "Version": "4",
            "VideoSubtitleID": -28661795
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "jpn-JP",
            "LanguageID": "3",
            "Size": 1214,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/0141345c5c2de8c5592c30ba4e730c6d/6555e83d/video/tos/maliva/tos-maliva-v-0068c799-us/0def6fdfa8d04050af56ebb03e35d9a3/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14890&bt=7445&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2szdDU6Zmk8bDMzZzczNEBpM2szdDU6Zmk8bDMzZzczNEBxcHFycjQwcGhgLS1kMS9zYSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128829,
            "Version": "4",
            "VideoSubtitleID": -94452522
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "cmn-Hans-CN",
            "LanguageID": "1",
            "Size": 886,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/43488e0adea27cfc4928b7426a057ce5/6555e83d/video/tos/maliva/tos-maliva-v-0068c799-us/75b08e89b57142e6a81e9c796fcc24ad/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14890&bt=7445&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2szdDU6Zmk8bDMzZzczNEBpM2szdDU6Zmk8bDMzZzczNEBxcHFycjQwcGhgLS1kMS9zYSNxcHFycjQwcGhgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128829,
            "Version": "4",
            "VideoSubtitleID": -2019713135
          }
        ],
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -16.4,
          "Peak": 0.96605
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/c3703d3fdd4544c59547248b2327d0a9_1688772383~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=NpXJu72%2B3TBDma4lGdbLvza%2Bd9A%3D",
          "480": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/c3703d3fdd4544c59547248b2327d0a9_1688772383~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=yD6BqyMU6pugx%2FYqsvxxbCB6Jfo%3D",
          "720": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/c3703d3fdd4544c59547248b2327d0a9_1688772383~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=HaKAygMdkly5jw59tsO5QZxbMU8%3D",
          "960": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/c3703d3fdd4544c59547248b2327d0a9_1688772383~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=Rwil7ry4rV%2F2%2FJiDipipblwbO1c%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "7224218912691438638",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Discovering Artists",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAA79sDcMCbl7QatFptF3JKobDAz67Uc0yws24YRf6FRLl8aWSAgSzrw6mu-bd0maiw",
        "secret": false,
        "signature": "\ud83d\udce7: discoveringartists.irl@gmail.com\n\nFollow our Spotify playlist!\ud83d\udc47",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "discoveringartists.irl",
        "verified": false
      },
      "authorStats": {
        "diggCount": 86,
        "followerCount": 515300,
        "followingCount": 13,
        "friendCount": 0,
        "heart": 15800000,
        "heartCount": 15800000,
        "videoCount": 35
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055695396906",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "doyoumakemusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055791882286",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "discoveringartistsirl"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "325757",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "4504990",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "newmusicfriday"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "136517",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musiccurator"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "19474746",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "mitski"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1623149411458054",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "japanesebreakfast"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "2817",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "ryanbeatty"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "17331",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indie"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "496813",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "singersongwriter"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "50864",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musicproducer"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "35857",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indieartist"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "211116",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "area"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "Do you make music?"
        },
        {
          "desc": ""
        },
        {
          "desc": "#doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #mitski #japanesebreakfast #ryanbeatty #indie #singersongwriter #musicproducer #indieartist #indiemusician #area",
          "textExtra": [
            {
              "awemeId": "",
              "end": 15,
              "hashtagId": "7252811055695396906",
              "hashtagName": "doyoumakemusic",
              "isCommerce": false,
              "secUid": "",
              "start": 0,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 38,
              "hashtagId": "7252811055791882286",
              "hashtagName": "discoveringartistsirl",
              "isCommerce": false,
              "secUid": "",
              "start": 16,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 56,
              "hashtagId": "325757",
              "hashtagName": "undergroundmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 39,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 72,
              "hashtagId": "4504990",
              "hashtagName": "newmusicfriday",
              "isCommerce": false,
              "secUid": "",
              "start": 57,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 86,
              "hashtagId": "136517",
              "hashtagName": "musiccurator",
              "isCommerce": false,
              "secUid": "",
              "start": 73,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 94,
              "hashtagId": "19474746",
              "hashtagName": "mitski",
              "isCommerce": false,
              "secUid": "",
              "start": 87,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 113,
              "hashtagId": "1623149411458054",
              "hashtagName": "japanesebreakfast",
              "isCommerce": false,
              "secUid": "",
              "start": 95,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 125,
              "hashtagId": "2817",
              "hashtagName": "ryanbeatty",
              "isCommerce": false,
              "secUid": "",
              "start": 114,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 132,
              "hashtagId": "17331",
              "hashtagName": "indie",
              "isCommerce": false,
              "secUid": "",
              "start": 126,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 150,
              "hashtagId": "496813",
              "hashtagName": "singersongwriter",
              "isCommerce": false,
              "secUid": "",
              "start": 133,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 165,
              "hashtagId": "50864",
              "hashtagName": "musicproducer",
              "isCommerce": false,
              "secUid": "",
              "start": 151,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 178,
              "hashtagId": "35857",
              "hashtagName": "indieartist",
              "isCommerce": false,
              "secUid": "",
              "start": 166,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 193,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 179,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 199,
              "hashtagId": "211116",
              "hashtagName": "area",
              "isCommerce": false,
              "secUid": "",
              "start": 194,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1692745112,
      "desc": "Do you make music? #doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #mitski #japanesebreakfast #ryanbeatty #indie #singersongwriter #musicproducer #indieartist #indiemusician #area",
      "digged": false,
      "diversificationId": 10046,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7270284838500928810",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "",
        "authorName": "Discovering Artists",
        "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "coverMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "coverThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "duration": 32,
        "id": "7270284882931747626",
        "original": true,
        "playUrl": "https://v16m.tiktokcdn.com/b705321b89c4593196d1ca77428ebfa7/6553999b/video/tos/maliva/tos-maliva-ve-27dcd7c799-us/oYRJZ5Dba2j81lSZU32aP0gInbCseDHfbBFkTU/?a=1233&ch=0&cr=0&dr=0&er=2&cd=0%7C0%7C0%7C0&br=250&bt=125&bti=NDU3ZjAwOg%3D%3D&ft=d2A~l-Inz7TPgL-Ziyq8Z&mime_type=audio_mpeg&qs=6&rc=M2VmZDo8NzU4ZWdoM2g6ZUBpanlueGY6ZjltbTMzZzU8NEAxYTYtYzBjXmMxNDRfMS0yYSM1b3FscjRnb2ZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00088000&cc=3&download=true",
        "title": "original sound"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 178100,
        "commentCount": 4633,
        "diggCount": 1700000,
        "playCount": 9200000,
        "shareCount": 8337
      },
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 34,
          "hashtagId": "7252811055695396906",
          "hashtagName": "doyoumakemusic",
          "isCommerce": false,
          "secUid": "",
          "start": 19,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 57,
          "hashtagId": "7252811055791882286",
          "hashtagName": "discoveringartistsirl",
          "isCommerce": false,
          "secUid": "",
          "start": 35,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 75,
          "hashtagId": "325757",
          "hashtagName": "undergroundmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 58,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 91,
          "hashtagId": "4504990",
          "hashtagName": "newmusicfriday",
          "isCommerce": false,
          "secUid": "",
          "start": 76,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 105,
          "hashtagId": "136517",
          "hashtagName": "musiccurator",
          "isCommerce": false,
          "secUid": "",
          "start": 92,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 113,
          "hashtagId": "19474746",
          "hashtagName": "mitski",
          "isCommerce": false,
          "secUid": "",
          "start": 106,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 132,
          "hashtagId": "1623149411458054",
          "hashtagName": "japanesebreakfast",
          "isCommerce": false,
          "secUid": "",
          "start": 114,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 144,
          "hashtagId": "2817",
          "hashtagName": "ryanbeatty",
          "isCommerce": false,
          "secUid": "",
          "start": 133,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 151,
          "hashtagId": "17331",
          "hashtagName": "indie",
          "isCommerce": false,
          "secUid": "",
          "start": 145,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 169,
          "hashtagId": "496813",
          "hashtagName": "singersongwriter",
          "isCommerce": false,
          "secUid": "",
          "start": 152,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 184,
          "hashtagId": "50864",
          "hashtagName": "musicproducer",
          "isCommerce": false,
          "secUid": "",
          "start": 170,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 197,
          "hashtagId": "35857",
          "hashtagName": "indieartist",
          "isCommerce": false,
          "secUid": "",
          "start": 185,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 212,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 198,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 218,
          "hashtagId": "211116",
          "hashtagName": "area",
          "isCommerce": false,
          "secUid": "",
          "start": 213,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 3031952,
        "bitrateInfo": [
          {
            "Bitrate": 3031952,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 12152825,
              "FileCs": "c:0-27514-77e6",
              "FileHash": "4ea709dbe441f358c3c286456b7764bb",
              "Uri": "v12044gd0000cjijo6jc77ud7dh5csq0",
              "UrlKey": "v12044gd0000cjijo6jc77ud7dh5csq0_h264_540p_3031952",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oklQpULNlNyA0qQP2dhNAfcCIBbzOw4pJINvWc/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=5920&bt=2960&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aGgzZDQ5ZDw4OGY6aWU1Z0BpM3Y4Z2c6ZjltbTMzZzczNEAvYV8vL140NjAxXy0uYy8uYSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=a722f7d672d74b0e5e2aa8593e5d08f5&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oklQpULNlNyA0qQP2dhNAfcCIBbzOw4pJINvWc/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=5920&bt=2960&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aGgzZDQ5ZDw4OGY6aWU1Z0BpM3Y4Z2c6ZjltbTMzZzczNEAvYV8vL140NjAxXy0uYy8uYSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=a722f7d672d74b0e5e2aa8593e5d08f5&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 1693701,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 6788778,
              "FileCs": "c:0-28057-62cf",
              "FileHash": "9c96d31583e0fe7625befb6311ce1e1a",
              "Uri": "v12044gd0000cjijo6jc77ud7dh5csq0",
              "UrlKey": "v12044gd0000cjijo6jc77ud7dh5csq0_bytevc1_1080p_1693701",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o0ES1aQgSsAeoDTPXXR5a5bsewnrDBMosgpkdl/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3308&bt=1654&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=NDRnOWRlM2Y2aGY8Ojs3N0BpM3Y4Z2c6ZjltbTMzZzczNEAzYTUvYjQ0NS8xMjIyLmE0YSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c5ca11e30118a3dd437063382b6db238&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o0ES1aQgSsAeoDTPXXR5a5bsewnrDBMosgpkdl/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3308&bt=1654&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=NDRnOWRlM2Y2aGY8Ojs3N0BpM3Y4Z2c6ZjltbTMzZzczNEAzYTUvYjQ0NS8xMjIyLmE0YSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c5ca11e30118a3dd437063382b6db238&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 1383564,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 5545672,
              "FileCs": "c:0-27514-ed05",
              "FileHash": "128d6a4f4b90bbce26fd2cff0b161dad",
              "Uri": "v12044gd0000cjijo6jc77ud7dh5csq0",
              "UrlKey": "v12044gd0000cjijo6jc77ud7dh5csq0_h264_540p_1383564",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oE5lRTockQSKEkDaPB5eafwDoSngdVAsagbyas/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2702&bt=1351&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=aTg4ZjZmZGVnOzQ5NDY2NEBpM3Y4Z2c6ZjltbTMzZzczNEA2LTJiLi8yNTQxMjAuYGJjYSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=4b285b2019618676ce7cc47b616affe9&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oE5lRTockQSKEkDaPB5eafwDoSngdVAsagbyas/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2702&bt=1351&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=aTg4ZjZmZGVnOzQ5NDY2NEBpM3Y4Z2c6ZjltbTMzZzczNEA2LTJiLi8yNTQxMjAuYGJjYSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=4b285b2019618676ce7cc47b616affe9&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 886487,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 3553265,
              "FileCs": "c:0-27514-d581",
              "FileHash": "859ba45465a923ad6d2bec823bb0ea2e",
              "Uri": "v12044gd0000cjijo6jc77ud7dh5csq0",
              "UrlKey": "v12044gd0000cjijo6jc77ud7dh5csq0_h264_540p_886487",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oYDYcVhUIBwyNEcN2PfplR3W0a4JNCAOzApIQN/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1730&bt=865&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=NjhkZjQ3NTg7aWc0aDo8NEBpM3Y4Z2c6ZjltbTMzZzczNEA2YjUzNGFfNi8xLl4tYDY2YSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=baa9185147e83b0428374d320395c81f&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oYDYcVhUIBwyNEcN2PfplR3W0a4JNCAOzApIQN/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1730&bt=865&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=NjhkZjQ3NTg7aWc0aDo8NEBpM3Y4Z2c6ZjltbTMzZzczNEA2YjUzNGFfNi8xLl4tYDY2YSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=baa9185147e83b0428374d320395c81f&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/94d07543547847b0a89dca66d570c4aa_1692745113?x-expires=1700125200&x-signature=iiaDkKjynbzpmsqJs6O%2FJNiTRBk%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oIbSlFaADgBDdsgda8n5Skoa5QRw6PR4TeoEfs/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=4462&bt=2231&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aTNpZTxnPDg3aThpNjxlZ0BpM3Y4Z2c6ZjltbTMzZzczNEBgLi9gXjRiXjQxMjY0MzYtYSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=8e3c8cea0cf2654caa0425ee9ed9a4a6&tk=tt_chain_token",
        "duration": 32,
        "dynamicCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/3081b2aec1094b0c94ea967d0ae4f44b_1692745113?x-expires=1700125200&x-signature=k32CNOAQA282vbsYfpAPU7J6LNU%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7270284838500928810",
        "originCover": "https://p19-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/323b1c106d634c7984834198d8aff9ac_1692745113?x-expires=1700125200&x-signature=drGnxiXxcVbtNgXY0%2Ft%2FsNZ7zow%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oklQpULNlNyA0qQP2dhNAfcCIBbzOw4pJINvWc/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=5920&bt=2960&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aGgzZDQ5ZDw4OGY6aWU1Z0BpM3Y4Z2c6ZjltbTMzZzczNEAvYV8vL140NjAxXy0uYy8uYSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=a722f7d672d74b0e5e2aa8593e5d08f5&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/94d07543547847b0a89dca66d570c4aa_1692745113~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=7rxPu2qZNzcXqk%2F8Lt1r5OD2xFA%3D",
        "shareCover": [
          "",
          "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/323b1c106d634c7984834198d8aff9ac_1692745113~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=SE1UAu5z3DZA8K63d1u92oPd9hU%3D",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/323b1c106d634c7984834198d8aff9ac_1692745113~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=rZtfxa7o3PmHtIeKIEo2ZDZlwzs%3D"
        ],
        "size": 12152825,
        "subtitleInfos": [
          {
            "Format": "webvtt",
            "LanguageCodeName": "ita-IT",
            "LanguageID": "26",
            "Size": 858,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/eb2480b5b2c22bb6910060b374a98223/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/9fd59db7b5f042c1923c331f7b7b91cc/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14742&bt=7371&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Y4Z2c6ZjltbTMzZzczNEBpM3Y4Z2c6ZjltbTMzZzczNEBuYGU0cjRnbGZgLS1kMS9zYSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": -508247118
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "deu-DE",
            "LanguageID": "20",
            "Size": 868,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/787787e70432c256da6fc4a909fafcae/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/aff6baf1f9e341ccb39828f1f532e18c/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14742&bt=7371&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Y4Z2c6ZjltbTMzZzczNEBpM3Y4Z2c6ZjltbTMzZzczNEBuYGU0cjRnbGZgLS1kMS9zYSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": 1894009022
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "rus-RU",
            "LanguageID": "6",
            "Size": 1128,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/5f66a000cdc9cdbd037bb38c78053be8/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/eefa01a559b640e8babc9e60622e0760/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14742&bt=7371&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Y4Z2c6ZjltbTMzZzczNEBpM3Y4Z2c6ZjltbTMzZzczNEBuYGU0cjRnbGZgLS1kMS9zYSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": -66918969
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "por-PT",
            "LanguageID": "8",
            "Size": 843,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/40f77e757e6dc6734b4ae5cecb310096/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/b3a744f0b33c4a09aa7d785bffe5f07c/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14742&bt=7371&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Y4Z2c6ZjltbTMzZzczNEBpM3Y4Z2c6ZjltbTMzZzczNEBuYGU0cjRnbGZgLS1kMS9zYSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": -730414632
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "fra-FR",
            "LanguageID": "7",
            "Size": 874,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/369aefeea8e494acc1f7d8c3f3c4e42e/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/c7da72feea124620be3090e8fe49275a/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14742&bt=7371&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Y4Z2c6ZjltbTMzZzczNEBpM3Y4Z2c6ZjltbTMzZzczNEBuYGU0cjRnbGZgLS1kMS9zYSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": -946907257
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ara-SA",
            "LanguageID": "34",
            "Size": 910,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/9ab4a1f1fb699647a7d502d8c34f9a75/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/446c5dcb7f2347ef93e85d1462425355/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14742&bt=7371&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Y4Z2c6ZjltbTMzZzczNEBpM3Y4Z2c6ZjltbTMzZzczNEBuYGU0cjRnbGZgLS1kMS9zYSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": -1621702551
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "spa-ES",
            "LanguageID": "9",
            "Size": 887,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/76ad9cb989d845940127e339496a77eb/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/3cc86522eba04e9eacce59796a2508f0/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14742&bt=7371&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Y4Z2c6ZjltbTMzZzczNEBpM3Y4Z2c6ZjltbTMzZzczNEBuYGU0cjRnbGZgLS1kMS9zYSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": 2048427888
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "kor-KR",
            "LanguageID": "4",
            "Size": 967,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/88eff221f28468d944e8963210859ecc/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/bf4b6e47a1494fb19095ff8e8cc6e7e9/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14742&bt=7371&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Y4Z2c6ZjltbTMzZzczNEBpM3Y4Z2c6ZjltbTMzZzczNEBuYGU0cjRnbGZgLS1kMS9zYSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": -836709199
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "vie-VN",
            "LanguageID": "10",
            "Size": 978,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/a57ff8c141c93fcaffb4704f5c075455/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/df6b4eb623a44f1ba7d34d01fb965998/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14742&bt=7371&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Y4Z2c6ZjltbTMzZzczNEBpM3Y4Z2c6ZjltbTMzZzczNEBuYGU0cjRnbGZgLS1kMS9zYSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": 1286465354
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "eng-US",
            "LanguageID": "2",
            "Size": 606,
            "Source": "ASR",
            "Url": "https://v16-webapp.tiktok.com/80cd36f71049bf49a570bd294dd5cda8/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/a9e5d4c30bc34ef4b319806b4975cc67/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14742&bt=7371&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Y4Z2c6ZjltbTMzZzczNEBpM3Y4Z2c6ZjltbTMzZzczNEBuYGU0cjRnbGZgLS1kMS9zYSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "1",
            "VideoSubtitleID": 1495039143
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ind-ID",
            "LanguageID": "23",
            "Size": 862,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/4ae2d85f1999005a7db0ae1fa6758047/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/82ec99b874f5418e871fc86c95f90909/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14742&bt=7371&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Y4Z2c6ZjltbTMzZzczNEBpM3Y4Z2c6ZjltbTMzZzczNEBuYGU0cjRnbGZgLS1kMS9zYSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": -734192654
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "cmn-Hans-CN",
            "LanguageID": "1",
            "Size": 823,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/062410b29d377b07552647c50427bf19/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/5809d9619c3d49609201f484abaf2de0/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14742&bt=7371&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Y4Z2c6ZjltbTMzZzczNEBpM3Y4Z2c6ZjltbTMzZzczNEBuYGU0cjRnbGZgLS1kMS9zYSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": -1096345311
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "jpn-JP",
            "LanguageID": "3",
            "Size": 1118,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/6e61c3b7a6cb5758f497332e75c3291c/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/29e8fcf1d94646a8b0a295ad7b91fb79/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14742&bt=7371&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Y4Z2c6ZjltbTMzZzczNEBpM3Y4Z2c6ZjltbTMzZzczNEBuYGU0cjRnbGZgLS1kMS9zYSNuYGU0cjRnbGZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": -372799093
          }
        ],
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -15.2,
          "Peak": 1
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/94d07543547847b0a89dca66d570c4aa_1692745113~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=L2ACuX5Wpm0pg1KcdJTHvtKVWQw%3D",
          "480": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/94d07543547847b0a89dca66d570c4aa_1692745113~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=7PwIfoPNyvyxHwBGjSS8trxoaVU%3D",
          "720": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/94d07543547847b0a89dca66d570c4aa_1692745113~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=XwIeKvlgqqJKAojyPuNPCfHO9rQ%3D",
          "960": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/94d07543547847b0a89dca66d570c4aa_1692745113~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=SJD8sNZRTSqDz4A1giDXrTybmMg%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "7224218912691438638",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Discovering Artists",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAA79sDcMCbl7QatFptF3JKobDAz67Uc0yws24YRf6FRLl8aWSAgSzrw6mu-bd0maiw",
        "secret": false,
        "signature": "\ud83d\udce7: discoveringartists.irl@gmail.com\n\nFollow our Spotify playlist!\ud83d\udc47",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "discoveringartists.irl",
        "verified": false
      },
      "authorStats": {
        "diggCount": 86,
        "followerCount": 515300,
        "followingCount": 13,
        "friendCount": 0,
        "heart": 15800000,
        "heartCount": 15800000,
        "videoCount": 35
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055695396906",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "doyoumakemusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055791882286",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "discoveringartistsirl"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "325757",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "4504990",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "newmusicfriday"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "136517",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musiccurator"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "58019",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "bloodorange"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "19385959",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "stevelacy"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "66487",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "nickhakim"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "2383",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "labrinth"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "17331",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indie"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "496813",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "singersongwriter"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "50864",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musicproducer"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "35857",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indieartist"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "Do you make music?"
        },
        {
          "desc": ""
        },
        {
          "desc": "#doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #bloodorange #stevelacy #nickhakim #labrinth #indie #singersongwriter #musicproducer #indieartist #indiemusician ",
          "textExtra": [
            {
              "awemeId": "",
              "end": 15,
              "hashtagId": "7252811055695396906",
              "hashtagName": "doyoumakemusic",
              "isCommerce": false,
              "secUid": "",
              "start": 0,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 38,
              "hashtagId": "7252811055791882286",
              "hashtagName": "discoveringartistsirl",
              "isCommerce": false,
              "secUid": "",
              "start": 16,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 56,
              "hashtagId": "325757",
              "hashtagName": "undergroundmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 39,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 72,
              "hashtagId": "4504990",
              "hashtagName": "newmusicfriday",
              "isCommerce": false,
              "secUid": "",
              "start": 57,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 86,
              "hashtagId": "136517",
              "hashtagName": "musiccurator",
              "isCommerce": false,
              "secUid": "",
              "start": 73,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 99,
              "hashtagId": "58019",
              "hashtagName": "bloodorange",
              "isCommerce": false,
              "secUid": "",
              "start": 87,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 110,
              "hashtagId": "19385959",
              "hashtagName": "stevelacy",
              "isCommerce": false,
              "secUid": "",
              "start": 100,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 121,
              "hashtagId": "66487",
              "hashtagName": "nickhakim",
              "isCommerce": false,
              "secUid": "",
              "start": 111,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 131,
              "hashtagId": "2383",
              "hashtagName": "labrinth",
              "isCommerce": false,
              "secUid": "",
              "start": 122,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 138,
              "hashtagId": "17331",
              "hashtagName": "indie",
              "isCommerce": false,
              "secUid": "",
              "start": 132,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 156,
              "hashtagId": "496813",
              "hashtagName": "singersongwriter",
              "isCommerce": false,
              "secUid": "",
              "start": 139,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 171,
              "hashtagId": "50864",
              "hashtagName": "musicproducer",
              "isCommerce": false,
              "secUid": "",
              "start": 157,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 184,
              "hashtagId": "35857",
              "hashtagName": "indieartist",
              "isCommerce": false,
              "secUid": "",
              "start": 172,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 199,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 185,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1695835972,
      "desc": "Do you make music? #doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #bloodorange #stevelacy #nickhakim #labrinth #indie #singersongwriter #musicproducer #indieartist #indiemusician ",
      "digged": false,
      "diversificationId": 10081,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7283560006224514346",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "",
        "authorName": "Discovering Artists",
        "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "coverMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "coverThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "duration": 30,
        "id": "7283560021752040238",
        "original": true,
        "playUrl": "https://v16m.tiktokcdn.com/ff9ccdd58ed33a3f046f82daa4bb8bf8/65539999/video/tos/maliva/tos-maliva-ve-27dcd7c799-us/oUBMCzmFpeYu3UGOQlDnZobkY8eUgdcqDSoErJ/?a=1233&ch=0&cr=0&dr=0&er=2&cd=0%7C0%7C0%7C0&br=250&bt=125&bti=NDU3ZjAwOg%3D%3D&ft=d2A~l-Inz7TPgL-Ziyq8Z&mime_type=audio_mpeg&qs=6&rc=ZGk5ZTU4OWU4PDQ4M2Y3N0BpM21zd2Y6Zms5bjMzZzU8NEA2YGFfYDYuNS8xXi0tXzE0YSM2XzZxcjQwYl5gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00088000&cc=3&download=true",
        "title": "original sound"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 124000,
        "commentCount": 3083,
        "diggCount": 1600000,
        "playCount": 13000000,
        "shareCount": 1919
      },
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 34,
          "hashtagId": "7252811055695396906",
          "hashtagName": "doyoumakemusic",
          "isCommerce": false,
          "secUid": "",
          "start": 19,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 57,
          "hashtagId": "7252811055791882286",
          "hashtagName": "discoveringartistsirl",
          "isCommerce": false,
          "secUid": "",
          "start": 35,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 75,
          "hashtagId": "325757",
          "hashtagName": "undergroundmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 58,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 91,
          "hashtagId": "4504990",
          "hashtagName": "newmusicfriday",
          "isCommerce": false,
          "secUid": "",
          "start": 76,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 105,
          "hashtagId": "136517",
          "hashtagName": "musiccurator",
          "isCommerce": false,
          "secUid": "",
          "start": 92,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 118,
          "hashtagId": "58019",
          "hashtagName": "bloodorange",
          "isCommerce": false,
          "secUid": "",
          "start": 106,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 129,
          "hashtagId": "19385959",
          "hashtagName": "stevelacy",
          "isCommerce": false,
          "secUid": "",
          "start": 119,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 140,
          "hashtagId": "66487",
          "hashtagName": "nickhakim",
          "isCommerce": false,
          "secUid": "",
          "start": 130,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 150,
          "hashtagId": "2383",
          "hashtagName": "labrinth",
          "isCommerce": false,
          "secUid": "",
          "start": 141,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 157,
          "hashtagId": "17331",
          "hashtagName": "indie",
          "isCommerce": false,
          "secUid": "",
          "start": 151,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 175,
          "hashtagId": "496813",
          "hashtagName": "singersongwriter",
          "isCommerce": false,
          "secUid": "",
          "start": 158,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 190,
          "hashtagId": "50864",
          "hashtagName": "musicproducer",
          "isCommerce": false,
          "secUid": "",
          "start": 176,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 203,
          "hashtagId": "35857",
          "hashtagName": "indieartist",
          "isCommerce": false,
          "secUid": "",
          "start": 191,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 218,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 204,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 2046001,
        "bitrateInfo": [
          {
            "Bitrate": 2046001,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 7701917,
              "FileCs": "c:0-25926-d7b1",
              "FileHash": "de991943e547f395dbdd0fb1af19cd58",
              "Uri": "v12044gd0000cka6cabc77uen2raov50",
              "UrlKey": "v12044gd0000cka6cabc77uen2raov50_h264_540p_2046001",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ocAWDE1lSDpQHRNEYKUbBeZFEnJQcGIdf2Y1Wg/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3996&bt=1998&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aTk4aTw2OjY6Nzg7ZzQzZEBpM3lkNWg6ZmQ5bjMzZzczNEAwYDBiXjZfX2ExYDFeXjY0YSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=6bf0eb69aaf8df315c706ae2f23ad9c1&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ocAWDE1lSDpQHRNEYKUbBeZFEnJQcGIdf2Y1Wg/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3996&bt=1998&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aTk4aTw2OjY6Nzg7ZzQzZEBpM3lkNWg6ZmQ5bjMzZzczNEAwYDBiXjZfX2ExYDFeXjY0YSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=6bf0eb69aaf8df315c706ae2f23ad9c1&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 1627302,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 6125776,
              "FileCs": "c:0-26429-7d58",
              "FileHash": "821bdde179f42353bec23170bf4b68de",
              "Uri": "v12044gd0000cka6cabc77uen2raov50",
              "UrlKey": "v12044gd0000cka6cabc77uen2raov50_bytevc1_1080p_1627302",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oIZpY6gUQDeqedLDG0lEonBBFIOtSxrbgA48wR/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3178&bt=1589&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=OjtkZDM1OzM8OmhmNzVlZEBpM3lkNWg6ZmQ5bjMzZzczNEBhNi1iNGNfXzMxYWMzLzFjYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=01070ee3f92ecaa8122ae5b4efa75452&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oIZpY6gUQDeqedLDG0lEonBBFIOtSxrbgA48wR/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3178&bt=1589&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=OjtkZDM1OzM8OmhmNzVlZEBpM3lkNWg6ZmQ5bjMzZzczNEBhNi1iNGNfXzMxYWMzLzFjYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=01070ee3f92ecaa8122ae5b4efa75452&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 975941,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 3673808,
              "FileCs": "c:0-25926-c812",
              "FileHash": "81b66683b346c22e8804f9266ad22b79",
              "Uri": "v12044gd0000cka6cabc77uen2raov50",
              "UrlKey": "v12044gd0000cka6cabc77uen2raov50_h264_540p_975941",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oUgDYDG1lDgIZfpdAnBUMtrFeQYISEbR1EWcUK/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1906&bt=953&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=NzQ8Zjc6OjZmNWg5ODtkOEBpM3lkNWg6ZmQ5bjMzZzczNEA0Mi9fMS81NS8xMDRfMC5eYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=5e3dd2c22f21b7a97e94d250e78c9233&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oUgDYDG1lDgIZfpdAnBUMtrFeQYISEbR1EWcUK/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1906&bt=953&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=NzQ8Zjc6OjZmNWg5ODtkOEBpM3lkNWg6ZmQ5bjMzZzczNEA0Mi9fMS81NS8xMDRfMC5eYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=5e3dd2c22f21b7a97e94d250e78c9233&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 815901,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 3071358,
              "FileCs": "c:0-26436-7082",
              "FileHash": "1ee120c85c51b743e9a6c072fe207da9",
              "Uri": "v12044gd0000cka6cabc77uen2raov50",
              "UrlKey": "v12044gd0000cka6cabc77uen2raov50_bytevc1_720p_815901",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o4YYQSU4pnAC0tFp2G86DRlg4ExgeBeFIqb2oD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1592&bt=796&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=M2lkZDU1Z2RoODxmODdlaEBpM3lkNWg6ZmQ5bjMzZzczNEBgY2BgXl4zXy4xL19iMDAvYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=bb40a118b33788972e283960aa35a6d4&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o4YYQSU4pnAC0tFp2G86DRlg4ExgeBeFIqb2oD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1592&bt=796&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=M2lkZDU1Z2RoODxmODdlaEBpM3lkNWg6ZmQ5bjMzZzczNEBgY2BgXl4zXy4xL19iMDAvYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=bb40a118b33788972e283960aa35a6d4&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 680079,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 2560076,
              "FileCs": "c:0-26436-3019",
              "FileHash": "b0ca9a8fb62cc5088f4df836d6c87ac8",
              "Uri": "v12044gd0000cka6cabc77uen2raov50",
              "UrlKey": "v12044gd0000cka6cabc77uen2raov50_bytevc1_540p_680079",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o8SxFUgReQ4qEztDDlGgUYn68pbgABfEgIRo4l/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1328&bt=664&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=ZTpkZGg6ZjU5Ozs3N2k5ZEBpM3lkNWg6ZmQ5bjMzZzczNEA0LzQxMC4vNmAxXzFiX18tYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=869356ce85f5e86219c52db58efec5f9&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o8SxFUgReQ4qEztDDlGgUYn68pbgABfEgIRo4l/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1328&bt=664&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=ZTpkZGg6ZjU5Ozs3N2k5ZEBpM3lkNWg6ZmQ5bjMzZzczNEA0LzQxMC4vNmAxXzFiX18tYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=869356ce85f5e86219c52db58efec5f9&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 663002,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 2495789,
              "FileCs": "c:0-25926-c979",
              "FileHash": "060b9ca4f37801e014134bbff1fdb40b",
              "Uri": "v12044gd0000cka6cabc77uen2raov50",
              "UrlKey": "v12044gd0000cka6cabc77uen2raov50_h264_540p_663002",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/okfeKEBYI8RSUYCp0bdcUEG1l1YZWnFDFQDgAD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1294&bt=647&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=OjY7Nzk2OjppNTNnMzM3OUBpM3lkNWg6ZmQ5bjMzZzczNEAzMWBjNS5eXjAxXjRiNTQtYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=41aadd8d82c2bb10373a913d76a723fb&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/okfeKEBYI8RSUYCp0bdcUEG1l1YZWnFDFQDgAD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1294&bt=647&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=OjY7Nzk2OjppNTNnMzM3OUBpM3lkNWg6ZmQ5bjMzZzczNEAzMWBjNS5eXjAxXjRiNTQtYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=41aadd8d82c2bb10373a913d76a723fb&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/abe781bae20248238fe18d29f857ea60_1695835973?x-expires=1700125200&x-signature=9tYujZbt18UEg22pbvllScN2%2FEA%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/owbRQEY4U6pI4OtoeaB3Ieqzgg8pDlAGFxnSND/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=3866&bt=1933&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZmdkaTQ2aDw1ODVkODw5NEBpM3lkNWg6ZmQ5bjMzZzczNEBhYi1eMTVeNTYxXjQyXy8wYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=fafcc70a2a8f0fbe73d52621285cd3cf&tk=tt_chain_token",
        "duration": 30,
        "dynamicCover": "https://p19-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/0951a383b9554857be517616d2ceb82a_1695835973?x-expires=1700125200&x-signature=80Oo3FDAfM0FKVQUo96JFQZqXv4%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7283560006224514346",
        "originCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/c8279724ff024769b15549fdfc07d47f_1695835972?x-expires=1700125200&x-signature=y7RNUd2zVpUlewBIKXSxt9SvWwE%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ocAWDE1lSDpQHRNEYKUbBeZFEnJQcGIdf2Y1Wg/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3996&bt=1998&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aTk4aTw2OjY6Nzg7ZzQzZEBpM3lkNWg6ZmQ5bjMzZzczNEAwYDBiXjZfX2ExYDFeXjY0YSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=6bf0eb69aaf8df315c706ae2f23ad9c1&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/abe781bae20248238fe18d29f857ea60_1695835973~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=VeAidaH7JZmYimNrS1YCsK3V%2Fz0%3D",
        "shareCover": [
          "",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/c8279724ff024769b15549fdfc07d47f_1695835972~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=tzBB0gFCBbctkgUBgg8liG83kwg%3D",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/c8279724ff024769b15549fdfc07d47f_1695835972~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=GpaljI5p1N2sgR5kKziV91SHFBI%3D"
        ],
        "size": 7701917,
        "subtitleInfos": [
          {
            "Format": "webvtt",
            "LanguageCodeName": "kor-KR",
            "LanguageID": "4",
            "Size": 1177,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/568da19840c80444a21f8c12ebc965ba/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/a2932b16ca854326993955c288fc2a00/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13650&bt=6825&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lkNWg6ZmQ5bjMzZzczNEBpM3lkNWg6ZmQ5bjMzZzczNEAybG9rcjRfYF5gLS1kMS9zYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": 514665688
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "spa-ES",
            "LanguageID": "9",
            "Size": 1110,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/07330854afe9dd02b4022eb4418f80e2/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/b3e307c961024989a24fffbf6cb65ff1/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13650&bt=6825&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lkNWg6ZmQ5bjMzZzczNEBpM3lkNWg6ZmQ5bjMzZzczNEAybG9rcjRfYF5gLS1kMS9zYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": -973663723
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "fra-FR",
            "LanguageID": "7",
            "Size": 1134,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/cb5c110432eccba92e6fe35a3424e8ca/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/967eef55ed4e4f76b293cb8cb01698de/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13650&bt=6825&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lkNWg6ZmQ5bjMzZzczNEBpM3lkNWg6ZmQ5bjMzZzczNEAybG9rcjRfYF5gLS1kMS9zYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": -501010606
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "por-PT",
            "LanguageID": "8",
            "Size": 1047,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/ae837100ed50d0abff78d3afe1662a4e/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/199c10187ddd46fdb10121ff11af2cb1/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13650&bt=6825&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lkNWg6ZmQ5bjMzZzczNEBpM3lkNWg6ZmQ5bjMzZzczNEAybG9rcjRfYF5gLS1kMS9zYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": 1635783570
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "jpn-JP",
            "LanguageID": "3",
            "Size": 1298,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/a7519dc7e70e13a8fa0cd01738e5f8bf/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/13235a3360b548bb8701f935832f9b5a/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13650&bt=6825&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lkNWg6ZmQ5bjMzZzczNEBpM3lkNWg6ZmQ5bjMzZzczNEAybG9rcjRfYF5gLS1kMS9zYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": 891566055
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "deu-DE",
            "LanguageID": "20",
            "Size": 1068,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/e90ac25b4cdd3ce1c228e277ce04714c/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/a14730eeabf64342ba781303a813c2d7/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13650&bt=6825&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lkNWg6ZmQ5bjMzZzczNEBpM3lkNWg6ZmQ5bjMzZzczNEAybG9rcjRfYF5gLS1kMS9zYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": -1690682590
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ind-ID",
            "LanguageID": "23",
            "Size": 1071,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/5cf5aaa1d4ed4e17274185cc76732e66/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/fb8443a8513b446ba0393121c136ed5a/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13650&bt=6825&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lkNWg6ZmQ5bjMzZzczNEBpM3lkNWg6ZmQ5bjMzZzczNEAybG9rcjRfYF5gLS1kMS9zYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": 158105211
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "eng-US",
            "LanguageID": "2",
            "Size": 792,
            "Source": "ASR",
            "Url": "https://v16-webapp.tiktok.com/d4e3c88a49aa9e9b0b1c9d25715f106f/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/1bda9c25940b4ba680f81c7fe18bdd46/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13650&bt=6825&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lkNWg6ZmQ5bjMzZzczNEBpM3lkNWg6ZmQ5bjMzZzczNEAybG9rcjRfYF5gLS1kMS9zYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "1",
            "VideoSubtitleID": 1348227121
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "rus-RU",
            "LanguageID": "6",
            "Size": 1375,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/c57174f260c953d10d909096c1168ce7/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/60ec62c794164b209d8b54816076194f/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13650&bt=6825&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lkNWg6ZmQ5bjMzZzczNEBpM3lkNWg6ZmQ5bjMzZzczNEAybG9rcjRfYF5gLS1kMS9zYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": 2113789539
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ita-IT",
            "LanguageID": "26",
            "Size": 1032,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/a06b4b2b54e0ef53a5ec9ecf5778bcf4/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/93ce1d7ecf5c4a38b1729ae519a43c90/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13650&bt=6825&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lkNWg6ZmQ5bjMzZzczNEBpM3lkNWg6ZmQ5bjMzZzczNEAybG9rcjRfYF5gLS1kMS9zYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": -1893363852
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ara-SA",
            "LanguageID": "34",
            "Size": 1035,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/b74e5f687cd6c7661e92069222f3f260/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/b145ce5a0114407a92560371ade0ec8f/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13650&bt=6825&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lkNWg6ZmQ5bjMzZzczNEBpM3lkNWg6ZmQ5bjMzZzczNEAybG9rcjRfYF5gLS1kMS9zYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": -1412432706
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "vie-VN",
            "LanguageID": "10",
            "Size": 1203,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/61ebe5ea1138571ff3a9c077170ff59f/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/803a7407394142a1bdaaebeaa1533930/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13650&bt=6825&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lkNWg6ZmQ5bjMzZzczNEBpM3lkNWg6ZmQ5bjMzZzczNEAybG9rcjRfYF5gLS1kMS9zYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": -346155581
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "cmn-Hans-CN",
            "LanguageID": "1",
            "Size": 1034,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/7d30ca0866359614bcf367104aec2fcd/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/0012db9405164201b4dde8d8fbd4250d/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13650&bt=6825&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lkNWg6ZmQ5bjMzZzczNEBpM3lkNWg6ZmQ5bjMzZzczNEAybG9rcjRfYF5gLS1kMS9zYSMybG9rcjRfYF5gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": -1341461684
          }
        ],
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -17.7,
          "Peak": 0.50699
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/abe781bae20248238fe18d29f857ea60_1695835973~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=E0SLl0zTyQ2mvDe58VClSVI861Y%3D",
          "480": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/abe781bae20248238fe18d29f857ea60_1695835973~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=wMWfNNdwqEEUkcDpekPoZg5TmUI%3D",
          "720": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/abe781bae20248238fe18d29f857ea60_1695835973~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=BOrG4%2Ftp58%2FTk8ORgMuyBlpdSxw%3D",
          "960": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/abe781bae20248238fe18d29f857ea60_1695835973~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=RIUxTwnAqyEk1pzBhe3yFr1g7Nw%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "7224218912691438638",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Discovering Artists",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAA79sDcMCbl7QatFptF3JKobDAz67Uc0yws24YRf6FRLl8aWSAgSzrw6mu-bd0maiw",
        "secret": false,
        "signature": "\ud83d\udce7: discoveringartists.irl@gmail.com\n\nFollow our Spotify playlist!\ud83d\udc47",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "discoveringartists.irl",
        "verified": false
      },
      "authorStats": {
        "diggCount": 86,
        "followerCount": 515300,
        "followingCount": 13,
        "friendCount": 0,
        "heart": 15800000,
        "heartCount": 15800000,
        "videoCount": 35
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055695396906",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "doyoumakemusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055791882286",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "discoveringartistsirl"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "325757",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "4504990",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "newmusicfriday"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "136517",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musiccurator"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1596",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "thestrokes"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "2122",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "interpol"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "2879",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "modestmouse"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "17331",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indie"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "496813",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "singersongwriter"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "50864",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musicproducer"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "35857",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indieartist"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "Do you make music?"
        },
        {
          "desc": ""
        },
        {
          "desc": "#doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #thestrokes #interpol #modestmouse #indie #singersongwriter #musicproducer #indieartist #indiemusician ",
          "textExtra": [
            {
              "awemeId": "",
              "end": 15,
              "hashtagId": "7252811055695396906",
              "hashtagName": "doyoumakemusic",
              "isCommerce": false,
              "secUid": "",
              "start": 0,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 38,
              "hashtagId": "7252811055791882286",
              "hashtagName": "discoveringartistsirl",
              "isCommerce": false,
              "secUid": "",
              "start": 16,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 56,
              "hashtagId": "325757",
              "hashtagName": "undergroundmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 39,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 72,
              "hashtagId": "4504990",
              "hashtagName": "newmusicfriday",
              "isCommerce": false,
              "secUid": "",
              "start": 57,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 86,
              "hashtagId": "136517",
              "hashtagName": "musiccurator",
              "isCommerce": false,
              "secUid": "",
              "start": 73,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 98,
              "hashtagId": "1596",
              "hashtagName": "thestrokes",
              "isCommerce": false,
              "secUid": "",
              "start": 87,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 108,
              "hashtagId": "2122",
              "hashtagName": "interpol",
              "isCommerce": false,
              "secUid": "",
              "start": 99,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 121,
              "hashtagId": "2879",
              "hashtagName": "modestmouse",
              "isCommerce": false,
              "secUid": "",
              "start": 109,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 128,
              "hashtagId": "17331",
              "hashtagName": "indie",
              "isCommerce": false,
              "secUid": "",
              "start": 122,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 146,
              "hashtagId": "496813",
              "hashtagName": "singersongwriter",
              "isCommerce": false,
              "secUid": "",
              "start": 129,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 161,
              "hashtagId": "50864",
              "hashtagName": "musicproducer",
              "isCommerce": false,
              "secUid": "",
              "start": 147,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 174,
              "hashtagId": "35857",
              "hashtagName": "indieartist",
              "isCommerce": false,
              "secUid": "",
              "start": 162,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 189,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 175,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1688948404,
      "desc": "Do you make music? #doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #thestrokes #interpol #modestmouse #indie #singersongwriter #musicproducer #indieartist #indiemusician ",
      "digged": false,
      "diversificationId": 10046,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7253978105625496878",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "",
        "authorName": "Discovering Artists",
        "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "coverMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "coverThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "duration": 35,
        "id": "7253978125292423982",
        "original": true,
        "playUrl": "https://v16m.tiktokcdn.com/f4935afcd6a3e89a8b836f41c328bc0e/6553999e/video/tos/maliva/tos-maliva-v-27dcd7c799-us/owrrb8dh4cEGIUmIIAfyBTWzwHpKnBsrtaVKpF/?a=1233&ch=0&cr=0&dr=0&er=2&cd=0%7C0%7C0%7C0&br=250&bt=125&bti=NDU3ZjAwOg%3D%3D&ft=d2A~l-Inz7TPgL-Ziyq8Z&mime_type=audio_mpeg&qs=6&rc=aGk4NDs6ZmczZzM5ZzVoZUBpamV0Ojg6ZmdubDMzZzU8NEBeX2M0Ni4wNmIxYGEuMTZjYSMwZy1ycjRvcWlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00088000&cc=3&download=true",
        "title": "original sound"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 119000,
        "commentCount": 4771,
        "diggCount": 1200000,
        "playCount": 6800000,
        "shareCount": 4017
      },
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 34,
          "hashtagId": "7252811055695396906",
          "hashtagName": "doyoumakemusic",
          "isCommerce": false,
          "secUid": "",
          "start": 19,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 57,
          "hashtagId": "7252811055791882286",
          "hashtagName": "discoveringartistsirl",
          "isCommerce": false,
          "secUid": "",
          "start": 35,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 75,
          "hashtagId": "325757",
          "hashtagName": "undergroundmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 58,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 91,
          "hashtagId": "4504990",
          "hashtagName": "newmusicfriday",
          "isCommerce": false,
          "secUid": "",
          "start": 76,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 105,
          "hashtagId": "136517",
          "hashtagName": "musiccurator",
          "isCommerce": false,
          "secUid": "",
          "start": 92,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 117,
          "hashtagId": "1596",
          "hashtagName": "thestrokes",
          "isCommerce": false,
          "secUid": "",
          "start": 106,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 127,
          "hashtagId": "2122",
          "hashtagName": "interpol",
          "isCommerce": false,
          "secUid": "",
          "start": 118,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 140,
          "hashtagId": "2879",
          "hashtagName": "modestmouse",
          "isCommerce": false,
          "secUid": "",
          "start": 128,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 147,
          "hashtagId": "17331",
          "hashtagName": "indie",
          "isCommerce": false,
          "secUid": "",
          "start": 141,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 165,
          "hashtagId": "496813",
          "hashtagName": "singersongwriter",
          "isCommerce": false,
          "secUid": "",
          "start": 148,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 180,
          "hashtagId": "50864",
          "hashtagName": "musicproducer",
          "isCommerce": false,
          "secUid": "",
          "start": 166,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 193,
          "hashtagId": "35857",
          "hashtagName": "indieartist",
          "isCommerce": false,
          "secUid": "",
          "start": 181,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 208,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 194,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 2145759,
        "bitrateInfo": [
          {
            "Bitrate": 2145759,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 9497131,
              "FileCs": "c:0-29952-e41b",
              "FileHash": "1bb0b1df2a5dc396feb19c5da03c44a7",
              "Uri": "v12044gd0000cilkqarc77u974mn8jmg",
              "UrlKey": "v12044gd0000cilkqarc77u974mn8jmg_h264_540p_2145759",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o42zfA1OaCJDTqarIQJrhttVpTVcyUASyB0Iwr/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4190&bt=2095&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=Zzw0PDw7ZTo1PDRpOzwzZ0Bpam1xNzw6ZmRubDMzZzczNEAxMS5fNjRiX2ExLjVgLzNgYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=6e35afe42071620936b09d31f6edec2c&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o42zfA1OaCJDTqarIQJrhttVpTVcyUASyB0Iwr/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4190&bt=2095&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=Zzw0PDw7ZTo1PDRpOzwzZ0Bpam1xNzw6ZmRubDMzZzczNEAxMS5fNjRiX2ExLjVgLzNgYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=6e35afe42071620936b09d31f6edec2c&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 1806951,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 7961203,
              "FileCs": "c:0-30565-3cd9",
              "FileHash": "c36fee832a0b4284079e9e952d58745e",
              "Uri": "v12044gd0000cilkqarc77u974mn8jmg",
              "UrlKey": "v12044gd0000cilkqarc77u974mn8jmg_bytevc1_1080p_1806951",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQE0Itf6lSpV1TJrBQzAUaVJIOywUA6CHah1tq/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3528&bt=1764&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=OWU1PGU7aDdlNTVkZ2czN0Bpam1xNzw6ZmRubDMzZzczNEA0YGItNDExNV8xMWFiLjNgYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=11eeb764ca0ff8cfb1dbc2650e373125&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQE0Itf6lSpV1TJrBQzAUaVJIOywUA6CHah1tq/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3528&bt=1764&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=OWU1PGU7aDdlNTVkZ2czN0Bpam1xNzw6ZmRubDMzZzczNEA0YGItNDExNV8xMWFiLjNgYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=11eeb764ca0ff8cfb1dbc2650e373125&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 1105245,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 4869572,
              "FileCs": "c:0-30566-6e44",
              "FileHash": "d58aca36876c130f8b03d8e537014263",
              "Uri": "v12044gd0000cilkqarc77u974mn8jmg",
              "UrlKey": "v12044gd0000cilkqarc77u974mn8jmg_bytevc1_720p_1105245",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQUIapyAasSOEVtrzBI0TVfOwEQA1wtJhUCpSB/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2158&bt=1079&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=NzRlOzxpZzY1ZDs0ZWZoZUBpam1xNzw6ZmRubDMzZzczNEAxMzVfLmAvX18xY2AxY2IvYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=960938ea2969325ea0a103d8c6c1294b&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQUIapyAasSOEVtrzBI0TVfOwEQA1wtJhUCpSB/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2158&bt=1079&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=NzRlOzxpZzY1ZDs0ZWZoZUBpam1xNzw6ZmRubDMzZzczNEAxMzVfLmAvX18xY2AxY2IvYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=960938ea2969325ea0a103d8c6c1294b&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 1069301,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 4732727,
              "FileCs": "c:0-29952-f189",
              "FileHash": "e20df2bf270bfa5d1364e3a15cf7d12d",
              "Uri": "v12044gd0000cilkqarc77u974mn8jmg",
              "UrlKey": "v12044gd0000cilkqarc77u974mn8jmg_h264_540p_1069301",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oEygp7rOVATIaAfIJB0USr7QEVC1w7t2EaTzht/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2088&bt=1044&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=aGg5NjtnNmZoaTU3OmZoZUBpam1xNzw6ZmRubDMzZzczNEAwNS8uYjFjNmIxMGExNjEtYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=92218ceda626242dca15bf23e682a34c&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oEygp7rOVATIaAfIJB0USr7QEVC1w7t2EaTzht/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2088&bt=1044&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=aGg5NjtnNmZoaTU3OmZoZUBpam1xNzw6ZmRubDMzZzczNEAwNS8uYjFjNmIxMGExNjEtYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=92218ceda626242dca15bf23e682a34c&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 866089,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 3815882,
              "FileCs": "c:0-30566-c968",
              "FileHash": "afaed3940227847e125365ffa563ffc5",
              "Uri": "v12044gd0000cilkqarc77u974mn8jmg",
              "UrlKey": "v12044gd0000cilkqarc77u974mn8jmg_bytevc1_540p_866089",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oEaOJAtSpVUQtTI6CVw1I0fhx3DToyr8zlBaAp/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1690&bt=845&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=OTM8NjhpNzc2OTNkZWg8OkBpam1xNzw6ZmRubDMzZzczNEBhLjA0MC02XmExLmA1MWEzYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=420d79d7756bf2214b56109fd34c9b60&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oEaOJAtSpVUQtTI6CVw1I0fhx3DToyr8zlBaAp/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1690&bt=845&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=OTM8NjhpNzc2OTNkZWg8OkBpam1xNzw6ZmRubDMzZzczNEBhLjA0MC02XmExLmA1MWEzYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=420d79d7756bf2214b56109fd34c9b60&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 715998,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 3169011,
              "FileCs": "c:0-29952-bac4",
              "FileHash": "e2d9f57fafc56ce9dad681203eb90148",
              "Uri": "v12044gd0000cilkqarc77u974mn8jmg",
              "UrlKey": "v12044gd0000cilkqarc77u974mn8jmg_h264_540p_715998",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o4yBpBrOVATIaAfIJB0USs4QDVC1w0ta3aTzht/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1398&bt=699&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=NTxmMzVnaTM1ZmRmPGY7ZkBpam1xNzw6ZmRubDMzZzczNEAyNi9jYDY1NWMxXjE2YGIwYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=e23ab9fbcacab0b43a842b8fb01e824e&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o4yBpBrOVATIaAfIJB0USs4QDVC1w0ta3aTzht/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1398&bt=699&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=NTxmMzVnaTM1ZmRmPGY7ZkBpam1xNzw6ZmRubDMzZzczNEAyNi9jYDY1NWMxXjE2YGIwYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=e23ab9fbcacab0b43a842b8fb01e824e&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/d56b60096bd24780ac9114baf76b5b10_1688948405?x-expires=1700125200&x-signature=EH8SfFgYZh1Z6LFZ2tpSzP%2FVL3I%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/owDprSREQDAbuoergkhglacoSBe0nTqn0DQrpS/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=4286&bt=2143&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ODk4ZTg7Ozo8NWc5ZWhkZUBpam1xNzw6ZmRubDMzZzczNEBiLTU2MWBhNl4xNTNgMWJeYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=facad6fd1707ece3b6b4ef67a778db16&tk=tt_chain_token",
        "duration": 35,
        "dynamicCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/3adf130328fb463c8c56cf52e2937e71_1688948406?x-expires=1700125200&x-signature=qMzcZPL%2BN4eXIfwkiKs5N8Dxb94%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7253978105625496878",
        "originCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/3804336804fd424fbdba29e92137db86_1688948405?x-expires=1700125200&x-signature=0%2FnpipekgE3W%2FuV82DgvpZ%2FmGdE%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o42zfA1OaCJDTqarIQJrhttVpTVcyUASyB0Iwr/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4190&bt=2095&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=Zzw0PDw7ZTo1PDRpOzwzZ0Bpam1xNzw6ZmRubDMzZzczNEAxMS5fNjRiX2ExLjVgLzNgYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=6e35afe42071620936b09d31f6edec2c&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/d56b60096bd24780ac9114baf76b5b10_1688948405~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=fFpoSKiH0PV%2FMyarPDZ6HB956Qw%3D",
        "shareCover": [
          "",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/3804336804fd424fbdba29e92137db86_1688948405~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=xcEgLP9Pv9aiht%2BClm6ml7qvCD0%3D",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/3804336804fd424fbdba29e92137db86_1688948405~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=Fe1mg9jX%2FCXcXOa%2BMRpfHNOVh2E%3D"
        ],
        "size": 9497131,
        "subtitleInfos": [
          {
            "Format": "webvtt",
            "LanguageCodeName": "ara-SA",
            "LanguageID": "34",
            "Size": 1057,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/24b04bafeebb61af387e67c6129abfb6/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/b77347dbc1a44605acee350d409fad5e/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=15136&bt=7568&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=am1xNzw6ZmRubDMzZzczNEBpam1xNzw6ZmRubDMzZzczNEBqNWo0cjRvbmlgLS1kMS9zYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": 1789135028
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "cmn-Hans-CN",
            "LanguageID": "1",
            "Size": 1106,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/518e885426dd91eade3094dfb4b4d199/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/a9887d6865284267b276b2db796e8ac3/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=15136&bt=7568&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=am1xNzw6ZmRubDMzZzczNEBpam1xNzw6ZmRubDMzZzczNEBqNWo0cjRvbmlgLS1kMS9zYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": -1606431827
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ind-ID",
            "LanguageID": "23",
            "Size": 1190,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/7b63f0ebbe4220aefc9f4ca67462a8b0/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/92e6d3e11aab4f568384c04a42e40252/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=15136&bt=7568&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=am1xNzw6ZmRubDMzZzczNEBpam1xNzw6ZmRubDMzZzczNEBqNWo0cjRvbmlgLS1kMS9zYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": -1435831661
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "rus-RU",
            "LanguageID": "6",
            "Size": 1507,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/bb5d060e8a4db8a6bfc2e498f483df3a/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/fe817f827a18495089d6be95c44fcb37/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=15136&bt=7568&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=am1xNzw6ZmRubDMzZzczNEBpam1xNzw6ZmRubDMzZzczNEBqNWo0cjRvbmlgLS1kMS9zYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": 67637704
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "deu-DE",
            "LanguageID": "20",
            "Size": 1153,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/b4e801f670bffd8722ed32885e4b16e0/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/db71ca4081b44e55b9304fe44e4cf431/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=15136&bt=7568&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=am1xNzw6ZmRubDMzZzczNEBpam1xNzw6ZmRubDMzZzczNEBqNWo0cjRvbmlgLS1kMS9zYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": -1869723152
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "spa-ES",
            "LanguageID": "9",
            "Size": 1195,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/37790bf533c16bf22c89326b2aaa618b/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/9630369b24af430db4f17c893d8f9b1c/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=15136&bt=7568&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=am1xNzw6ZmRubDMzZzczNEBpam1xNzw6ZmRubDMzZzczNEBqNWo0cjRvbmlgLS1kMS9zYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": 518564150
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "jpn-JP",
            "LanguageID": "3",
            "Size": 1390,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/6da8318e951e75b31c031f42cf2690f3/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/5d637dc9b8464ac39d79328ee60bdbf9/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=15136&bt=7568&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=am1xNzw6ZmRubDMzZzczNEBpam1xNzw6ZmRubDMzZzczNEBqNWo0cjRvbmlgLS1kMS9zYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": -1341122628
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "kor-KR",
            "LanguageID": "4",
            "Size": 1323,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/93b42751adfa956b25ee0e72d24e3be8/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/58f8ecd47f044e3084cae91aed2c93a9/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=15136&bt=7568&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=am1xNzw6ZmRubDMzZzczNEBpam1xNzw6ZmRubDMzZzczNEBqNWo0cjRvbmlgLS1kMS9zYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": 2119344529
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ita-IT",
            "LanguageID": "26",
            "Size": 1154,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/ea5a9a955ab7b0a5000e1d445bf27714/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/6837c8d387d048029578dc92c6b6171a/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=15136&bt=7568&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=am1xNzw6ZmRubDMzZzczNEBpam1xNzw6ZmRubDMzZzczNEBqNWo0cjRvbmlgLS1kMS9zYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": 1669937475
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "fra-FR",
            "LanguageID": "7",
            "Size": 1239,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/1c9ece3b5a5469ba459a143b0badad67/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/70d877997bb543b9ae0b811d97eb9153/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=15136&bt=7568&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=am1xNzw6ZmRubDMzZzczNEBpam1xNzw6ZmRubDMzZzczNEBqNWo0cjRvbmlgLS1kMS9zYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": -1188354467
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "por-PT",
            "LanguageID": "8",
            "Size": 1167,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/8c90e31cf6f519b92370e2207bf398bb/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/4ab5fa5d021140deac5b330ad5a67fd4/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=15136&bt=7568&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=am1xNzw6ZmRubDMzZzczNEBpam1xNzw6ZmRubDMzZzczNEBqNWo0cjRvbmlgLS1kMS9zYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": -872801781
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "eng-US",
            "LanguageID": "2",
            "Size": 769,
            "Source": "ASR",
            "Url": "https://v16-webapp.tiktok.com/95bccfc125efde46c2411d643a91b047/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/a12c7d22e64146f68245ab0c6df82594/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=15136&bt=7568&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=am1xNzw6ZmRubDMzZzczNEBpam1xNzw6ZmRubDMzZzczNEBqNWo0cjRvbmlgLS1kMS9zYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "1",
            "VideoSubtitleID": 1928237017
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "vie-VN",
            "LanguageID": "10",
            "Size": 1322,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/3ba1446e9033cb3fbfefe3248738888a/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/2be5d18a9ec144f08b33724df5cd4064/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=15136&bt=7568&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=am1xNzw6ZmRubDMzZzczNEBpam1xNzw6ZmRubDMzZzczNEBqNWo0cjRvbmlgLS1kMS9zYSNqNWo0cjRvbmlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": -1651218095
          }
        ],
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -10.3,
          "Peak": 1
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/d56b60096bd24780ac9114baf76b5b10_1688948405~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=WACUBOXzHiRIkQZdJxD1hHTWg%2Bg%3D",
          "480": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/d56b60096bd24780ac9114baf76b5b10_1688948405~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=NGmcTQbbEOtSeHuAIky7wVUkB84%3D",
          "720": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/d56b60096bd24780ac9114baf76b5b10_1688948405~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=GLLCjFr%2F3ftQOo76FA1ly%2BFvyR8%3D",
          "960": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/d56b60096bd24780ac9114baf76b5b10_1688948405~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=xPTFsrOXOkq4btQ3sK3dq1qBpzA%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "7224218912691438638",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Discovering Artists",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAA79sDcMCbl7QatFptF3JKobDAz67Uc0yws24YRf6FRLl8aWSAgSzrw6mu-bd0maiw",
        "secret": false,
        "signature": "\ud83d\udce7: discoveringartists.irl@gmail.com\n\nFollow our Spotify playlist!\ud83d\udc47",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "discoveringartists.irl",
        "verified": false
      },
      "authorStats": {
        "diggCount": 86,
        "followerCount": 515300,
        "followingCount": 13,
        "friendCount": 0,
        "heart": 15800000,
        "heartCount": 15800000,
        "videoCount": 35
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "325757",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "4504990",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "newmusicfriday"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "136517",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musiccurator"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "4412",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "radiohead"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "3090",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "massiveattack"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "496813",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "singersongwriter"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "50864",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musicproducer"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "35857",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indieartist"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "Do you make music?"
        },
        {
          "desc": ""
        },
        {
          "desc": "#Undergroundmusic #newmusicfriday #musiccurator #radiohead #massiveattack #singersongwriter #musicproducer #indieartist #indiemusician ",
          "textExtra": [
            {
              "awemeId": "",
              "end": 17,
              "hashtagId": "325757",
              "hashtagName": "undergroundmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 0,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 33,
              "hashtagId": "4504990",
              "hashtagName": "newmusicfriday",
              "isCommerce": false,
              "secUid": "",
              "start": 18,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 47,
              "hashtagId": "136517",
              "hashtagName": "musiccurator",
              "isCommerce": false,
              "secUid": "",
              "start": 34,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 58,
              "hashtagId": "4412",
              "hashtagName": "radiohead",
              "isCommerce": false,
              "secUid": "",
              "start": 48,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 73,
              "hashtagId": "3090",
              "hashtagName": "massiveattack",
              "isCommerce": false,
              "secUid": "",
              "start": 59,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 91,
              "hashtagId": "496813",
              "hashtagName": "singersongwriter",
              "isCommerce": false,
              "secUid": "",
              "start": 74,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 106,
              "hashtagId": "50864",
              "hashtagName": "musicproducer",
              "isCommerce": false,
              "secUid": "",
              "start": 92,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 119,
              "hashtagId": "35857",
              "hashtagName": "indieartist",
              "isCommerce": false,
              "secUid": "",
              "start": 107,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 134,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 120,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1688592741,
      "desc": "Do you make music? #Undergroundmusic #newmusicfriday #musiccurator #radiohead #massiveattack #singersongwriter #musicproducer #indieartist #indiemusician ",
      "digged": false,
      "diversificationId": 10046,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7252450569887960366",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "",
        "authorName": "Discovering Artists",
        "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "coverMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "coverThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "duration": 32,
        "id": "7252450556667677486",
        "original": true,
        "playUrl": "https://v16m.tiktokcdn.com/21030fdc3f796055f2421e3fbdf10670/6553999b/video/tos/maliva/tos-maliva-v-27dcd7c799-us/osRVWnFIB5eeH4nboAQlekJW4aTPSE6HDUjCHo/?a=1233&ch=0&cr=0&dr=0&er=2&cd=0%7C0%7C0%7C0&br=250&bt=125&bti=NDU3ZjAwOg%3D%3D&ft=d2A~l-Inz7TPgL-Ziyq8Z&mime_type=audio_mpeg&qs=6&rc=NWdlZ2RoNDxkOjVoNDc5ZEBpamtmaTs6ZnN4bDMzZzU8NEAxMGAvMTAwNjUxXi1eNC8yYSMtcmgycjRvL2ZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00088000&cc=3&download=true",
        "title": "original sound"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 86800,
        "commentCount": 2213,
        "diggCount": 949400,
        "playCount": 5900000,
        "shareCount": 1963
      },
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 36,
          "hashtagId": "325757",
          "hashtagName": "undergroundmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 19,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 52,
          "hashtagId": "4504990",
          "hashtagName": "newmusicfriday",
          "isCommerce": false,
          "secUid": "",
          "start": 37,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 66,
          "hashtagId": "136517",
          "hashtagName": "musiccurator",
          "isCommerce": false,
          "secUid": "",
          "start": 53,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 77,
          "hashtagId": "4412",
          "hashtagName": "radiohead",
          "isCommerce": false,
          "secUid": "",
          "start": 67,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 92,
          "hashtagId": "3090",
          "hashtagName": "massiveattack",
          "isCommerce": false,
          "secUid": "",
          "start": 78,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 110,
          "hashtagId": "496813",
          "hashtagName": "singersongwriter",
          "isCommerce": false,
          "secUid": "",
          "start": 93,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 125,
          "hashtagId": "50864",
          "hashtagName": "musicproducer",
          "isCommerce": false,
          "secUid": "",
          "start": 111,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 138,
          "hashtagId": "35857",
          "hashtagName": "indieartist",
          "isCommerce": false,
          "secUid": "",
          "start": 126,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 153,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 139,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 2156813,
        "bitrateInfo": [
          {
            "Bitrate": 2156813,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 8744801,
              "FileCs": "c:0-27561-2e9b",
              "FileHash": "f5e5f05b4488255737a781e4e5741425",
              "Uri": "v12044gd0000ciiu1prc77u0lhdfpun0",
              "UrlKey": "v12044gd0000ciiu1prc77u0lhdfpun0_h264_540p_2156813",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/okbJO5juIknSXlID34eDnIVWHuILUACBeeQkWA/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4212&bt=2106&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZGhmZDUzaDs0OThkaTQ8N0BpM3hpazM6ZnN4bDMzZzczNEAtLjNgLzE0Xy0xYi9jM2IwYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=dd1b13ec25f38f52d28e88ce0901e1d0&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/okbJO5juIknSXlID34eDnIVWHuILUACBeeQkWA/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4212&bt=2106&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZGhmZDUzaDs0OThkaTQ8N0BpM3hpazM6ZnN4bDMzZzczNEAtLjNgLzE0Xy0xYi9jM2IwYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=dd1b13ec25f38f52d28e88ce0901e1d0&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 1936089,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 7810912,
              "FileCs": "c:0-28037-f083",
              "FileHash": "3bdc41eb4a9182ca23d0361ab15b6ff9",
              "Uri": "v12044gd0000ciiu1prc77u0lhdfpun0",
              "UrlKey": "v12044gd0000ciiu1prc77u0lhdfpun0_bytevc1_1080p_1936089",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o0XLDJLWDd0eAeCd5gkQ5AH2fnOSCcnBIoubj9/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3780&bt=1890&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=NzllNzpnOmQ7ZTc1NDdlaEBpM3hpazM6ZnN4bDMzZzczNEBfX2NhMmJiNl8xYTAyXmA0YSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=942669c603af46da956f6cec846deb71&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o0XLDJLWDd0eAeCd5gkQ5AH2fnOSCcnBIoubj9/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3780&bt=1890&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=NzllNzpnOmQ7ZTc1NDdlaEBpM3hpazM6ZnN4bDMzZzczNEBfX2NhMmJiNl8xYTAyXmA0YSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=942669c603af46da956f6cec846deb71&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 1186528,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 4786899,
              "FileCs": "c:0-28038-5526",
              "FileHash": "709262a69199ab7ec132a73bc4a69969",
              "Uri": "v12044gd0000ciiu1prc77u0lhdfpun0",
              "UrlKey": "v12044gd0000ciiu1prc77u0lhdfpun0_bytevc1_720p_1186528",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oM5oAKSQWXIxHeJcLeenIZDJLnBCbIA9jgCDVk/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2316&bt=1158&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=OTQ7NWczOGQzZTVoPDQzZkBpM3hpazM6ZnN4bDMzZzczNEBeXy9hYS1iNTQxYi8vNGMzYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=48400854f9ab9d3979819dfaf706b414&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oM5oAKSQWXIxHeJcLeenIZDJLnBCbIA9jgCDVk/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2316&bt=1158&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=OTQ7NWczOGQzZTVoPDQzZkBpM3hpazM6ZnN4bDMzZzczNEBeXy9hYS1iNTQxYi8vNGMzYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=48400854f9ab9d3979819dfaf706b414&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 1157099,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 4691460,
              "FileCs": "c:0-27561-1182",
              "FileHash": "a95efa2d128e5f86dc4423bae1b3bd1e",
              "Uri": "v12044gd0000ciiu1prc77u0lhdfpun0",
              "UrlKey": "v12044gd0000ciiu1prc77u0lhdfpun0_h264_540p_1157099",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ooifd7CAQuAwxcwC0eM1KLYaycffcKjinAflxv/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2258&bt=1129&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=PDU7OGhlZWQ2aTo4ODdoNUBpM3hpazM6ZnN4bDMzZzczNEAtYy8zMjEyXjIxNGJfLjQtYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=4ef3284c186ed4a5fbd85c98da00a764&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ooifd7CAQuAwxcwC0eM1KLYaycffcKjinAflxv/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2258&bt=1129&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=PDU7OGhlZWQ2aTo4ODdoNUBpM3hpazM6ZnN4bDMzZzczNEAtYy8zMjEyXjIxNGJfLjQtYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=4ef3284c186ed4a5fbd85c98da00a764&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 922836,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 3723067,
              "FileCs": "c:0-28038-a784",
              "FileHash": "4780768e563a87fd4c2539a81e56bf42",
              "Uri": "v12044gd0000ciiu1prc77u0lhdfpun0",
              "UrlKey": "v12044gd0000ciiu1prc77u0lhdfpun0_bytevc1_540p_922836",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ogeljeu0JIkDIjHB4CSecODDUXnQWPA5nAbkhL/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1802&bt=901&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=NGk3O2g4Njk0OTc7NTllN0BpM3hpazM6ZnN4bDMzZzczNEAvMzJfYWNgX18xNTQyNDReYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=e722411c7ff65dfa0f02610c1709749c&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ogeljeu0JIkDIjHB4CSecODDUXnQWPA5nAbkhL/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1802&bt=901&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=NGk3O2g4Njk0OTc7NTllN0BpM3hpazM6ZnN4bDMzZzczNEAvMzJfYWNgX18xNTQyNDReYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=e722411c7ff65dfa0f02610c1709749c&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 797887,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 3235036,
              "FileCs": "c:0-27561-6d4e",
              "FileHash": "bf5633ca5a8a06e8303798ba47d5cce9",
              "Uri": "v12044gd0000ciiu1prc77u0lhdfpun0",
              "UrlKey": "v12044gd0000ciiu1prc77u0lhdfpun0_h264_540p_797887",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ooKX4evWnoeWclBDA5egkFjn9CbjJGHQD2ALIS/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1558&bt=779&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=O2U2Nzc0ZDY8aTpmMzc6ZkBpM3hpazM6ZnN4bDMzZzczNEBeYGJiYzZgXjQxLzQuYmA1YSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=846dc828cc7eb3f85c220ec90b5389b3&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ooKX4evWnoeWclBDA5egkFjn9CbjJGHQD2ALIS/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1558&bt=779&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=O2U2Nzc0ZDY8aTpmMzc6ZkBpM3hpazM6ZnN4bDMzZzczNEBeYGJiYzZgXjQxLzQuYmA1YSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=846dc828cc7eb3f85c220ec90b5389b3&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/4ea147a2e09542a082f5f5fe5db7434d?x-expires=1700125200&x-signature=FzMmED06leUaJT%2FkeGuPWkO%2FHX0%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ogXlneASbAIA2DiOQnjJLXCu4DHkeId5WeIUEB/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=4272&bt=2136&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=Njs1ODU6ZWUzODw3PDNnOUBpM3hpazM6ZnN4bDMzZzczNEBfYWI1MS40NTIxMF82YDMwYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=2b7a270e71e2eba5e4a4f747e3ef2a93&tk=tt_chain_token",
        "duration": 32,
        "dynamicCover": "https://p19-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/1a27a2de413c4d58a71430ca8680ec19_1688592743?x-expires=1700125200&x-signature=pc3k3IGSCcp1FVnfwjQ70E2Mjog%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7252450569887960366",
        "originCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/1dd1f3ae46494f3392cf8a9c1b32b614_1688592742?x-expires=1700125200&x-signature=z8hTqMojlDVv6bPiCAG6tqUC1Xk%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/okbJO5juIknSXlID34eDnIVWHuILUACBeeQkWA/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4212&bt=2106&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZGhmZDUzaDs0OThkaTQ8N0BpM3hpazM6ZnN4bDMzZzczNEAtLjNgLzE0Xy0xYi9jM2IwYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128828&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=dd1b13ec25f38f52d28e88ce0901e1d0&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/4ea147a2e09542a082f5f5fe5db7434d~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=sgcsxVCJpElZQuPd3%2BzyIryx2Dg%3D",
        "shareCover": [
          "",
          "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/1dd1f3ae46494f3392cf8a9c1b32b614_1688592742~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=rTutIqo97uo6nTPiqgrugCYDC%2Fk%3D",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/1dd1f3ae46494f3392cf8a9c1b32b614_1688592742~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=ZLpAJNqwUIEZ4TkGMgSM%2BpT4%2F2w%3D"
        ],
        "size": 8744801,
        "subtitleInfos": [
          {
            "Format": "webvtt",
            "LanguageCodeName": "kor-KR",
            "LanguageID": "4",
            "Size": 996,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/95c46e6431d469082a56cdb191d45761/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/4fec340d827444a5b2fa7e65bd139c9c/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4212&bt=2106&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3hpazM6ZnN4bDMzZzczNEBpM3hpazM6ZnN4bDMzZzczNEBrbWFpcjRvLmZgLS1kMS9zYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": -1057579204
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "spa-ES",
            "LanguageID": "9",
            "Size": 879,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/18f3dd2b30bff2e12b8b9854b36441bd/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/72e73676100f41629b088b3bf4b894df/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4212&bt=2106&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3hpazM6ZnN4bDMzZzczNEBpM3hpazM6ZnN4bDMzZzczNEBrbWFpcjRvLmZgLS1kMS9zYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": 1134505523
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "rus-RU",
            "LanguageID": "6",
            "Size": 1146,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/5256369da6d9cce7817af209cf830c3c/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/75bf153d80cc4bc48d0c4f26af110ca2/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4212&bt=2106&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3hpazM6ZnN4bDMzZzczNEBpM3hpazM6ZnN4bDMzZzczNEBrbWFpcjRvLmZgLS1kMS9zYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": 553937423
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "deu-DE",
            "LanguageID": "20",
            "Size": 958,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/a0cd6bd2050c13ce428868a1758b66e7/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/388bc92724da40849bb1f156472bd0e8/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4212&bt=2106&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3hpazM6ZnN4bDMzZzczNEBpM3hpazM6ZnN4bDMzZzczNEBrbWFpcjRvLmZgLS1kMS9zYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": -866602135
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "cmn-Hans-CN",
            "LanguageID": "1",
            "Size": 799,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/27e317a8d29773a591bbc1646e027be3/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/10a22ae4337945849cb16903393ae3eb/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4212&bt=2106&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3hpazM6ZnN4bDMzZzczNEBpM3hpazM6ZnN4bDMzZzczNEBrbWFpcjRvLmZgLS1kMS9zYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": -1474722082
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "jpn-JP",
            "LanguageID": "3",
            "Size": 1143,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/bf96d5b87756ee123c5084eadcfe919f/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/1e31840cb45e4098adf7358d66f8fc85/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4212&bt=2106&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3hpazM6ZnN4bDMzZzczNEBpM3hpazM6ZnN4bDMzZzczNEBrbWFpcjRvLmZgLS1kMS9zYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": -1421398970
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ind-ID",
            "LanguageID": "23",
            "Size": 849,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/245579d458212651140a4deddd1d3630/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/83143aa8f0614ce28faf48c73e3d9a6d/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4212&bt=2106&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3hpazM6ZnN4bDMzZzczNEBpM3hpazM6ZnN4bDMzZzczNEBrbWFpcjRvLmZgLS1kMS9zYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": 1462140922
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ita-IT",
            "LanguageID": "26",
            "Size": 788,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/3e11426b2357a64ca33aff601bd8c5d0/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/d1424f4b6d9b4a3386689ae7aeb077ef/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4212&bt=2106&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3hpazM6ZnN4bDMzZzczNEBpM3hpazM6ZnN4bDMzZzczNEBrbWFpcjRvLmZgLS1kMS9zYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": -1349232328
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ara-SA",
            "LanguageID": "34",
            "Size": 902,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/05c93e6f6f6ff2f662e90fe57e874ffe/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/266ced710b2141beaadc2e30773bb83d/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4212&bt=2106&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3hpazM6ZnN4bDMzZzczNEBpM3hpazM6ZnN4bDMzZzczNEBrbWFpcjRvLmZgLS1kMS9zYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": 2097080137
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "vie-VN",
            "LanguageID": "10",
            "Size": 995,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/a64b9756d583aa6447778444547cb6bc/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/c23f57d683d84891b6638ad969e7fb35/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4212&bt=2106&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3hpazM6ZnN4bDMzZzczNEBpM3hpazM6ZnN4bDMzZzczNEBrbWFpcjRvLmZgLS1kMS9zYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": 1045194121
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "fra-FR",
            "LanguageID": "7",
            "Size": 905,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/53d29c54d4f7582c475dd0974b78b725/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/a503610e420943b087ea9d7b1cc2589f/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4212&bt=2106&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3hpazM6ZnN4bDMzZzczNEBpM3hpazM6ZnN4bDMzZzczNEBrbWFpcjRvLmZgLS1kMS9zYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": -1704072173
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "por-PT",
            "LanguageID": "8",
            "Size": 842,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/b1ec6d090dc096a404e35c4993fd9a80/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/ba118bd76eb14191bb3dcdfb22ca56cb/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4212&bt=2106&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3hpazM6ZnN4bDMzZzczNEBpM3hpazM6ZnN4bDMzZzczNEBrbWFpcjRvLmZgLS1kMS9zYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "4",
            "VideoSubtitleID": -1601183491
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "eng-US",
            "LanguageID": "2",
            "Size": 666,
            "Source": "ASR",
            "Url": "https://v16-webapp.tiktok.com/9b82dbae6004cf504ce1ff0a604db2af/6555e83c/video/tos/maliva/tos-maliva-v-0068c799-us/31c00c88cacc454f96150252510f7f41/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4212&bt=2106&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3hpazM6ZnN4bDMzZzczNEBpM3hpazM6ZnN4bDMzZzczNEBrbWFpcjRvLmZgLS1kMS9zYSNrbWFpcjRvLmZgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128828,
            "Version": "1",
            "VideoSubtitleID": 746512236
          }
        ],
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -12.9,
          "Peak": 0.98855
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/4ea147a2e09542a082f5f5fe5db7434d~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=rQLYjZ9XmzVGSFMMTWNqEGLHkY4%3D",
          "480": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/4ea147a2e09542a082f5f5fe5db7434d~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=rTJhlaIi%2F0xOG3Wj6N66Y7tNE7Q%3D",
          "720": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/4ea147a2e09542a082f5f5fe5db7434d~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=Xwnl5fkX%2BSK27EXGPu8C8HxzE%2FA%3D",
          "960": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/4ea147a2e09542a082f5f5fe5db7434d~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=xxnzybBNGRrdTmP6zDgfR2wODAQ%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/cbaf602b28e3afaca92d954bc9be9e6a~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=YRxkcH6SsGZtGkW4Jp5EcH5X4aQ%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/cbaf602b28e3afaca92d954bc9be9e6a~c5_720x720.jpeg?x-expires=1700125200&x-signature=nTDNDUki768HNCPdRiBCiYbzCSg%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/cbaf602b28e3afaca92d954bc9be9e6a~c5_100x100.jpeg?x-expires=1700125200&x-signature=SMetBLxbTJm0o1chxws51WFXYbQ%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "6922224332093998086",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "cloudkissed",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAAKxrkVyhFRb7zahuYtgpy-SbFIPQY8XJOJ7cOB-vAe7XIw-DbnNfaHJd7WK3Tgq3i",
        "secret": false,
        "signature": "soft songs for sad ppl\nflight ukulele partner\n\u203c\ufe0fOBLIVION SONG OUT NOW\u203c\ufe0f",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "cloudkissedmusic",
        "verified": false
      },
      "authorStats": {
        "diggCount": 52500,
        "followerCount": 407800,
        "followingCount": 672,
        "friendCount": 0,
        "heart": 5200000,
        "heartCount": 5200000,
        "videoCount": 316
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "Wie begleitet dich der #Wellerman durch den Tag? Teile mit deiner Community!",
          "id": "1676092593052677",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/15d744496ee9d3fffd5ba0221ede567c",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/15d744496ee9d3fffd5ba0221ede567c",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/15d744496ee9d3fffd5ba0221ede567c",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "wellerman"
        },
        {
          "coverLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/ec7151d2d95d981f8241ed018cadbedc",
          "coverMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/ec7151d2d95d981f8241ed018cadbedc",
          "coverThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/ec7151d2d95d981f8241ed018cadbedc",
          "desc": "Pull up your anchor, set sail, and hit the open water with a #SeaShanty.",
          "id": "16997224",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/4ba6d03970789e8408bb2ef84ffeef6a",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/4ba6d03970789e8408bb2ef84ffeef6a",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/4ba6d03970789e8408bb2ef84ffeef6a",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "seashanty"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1661921505731585",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "piratetiktok"
        },
        {
          "coverLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/95dce3164582d8f6f960128e0fa2acb5",
          "coverMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/95dce3164582d8f6f960128e0fa2acb5",
          "coverThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/95dce3164582d8f6f960128e0fa2acb5",
          "desc": "TikTok has new #VoiceEffects, made just for you! Record your video, hit the checkmark and change your voice with new presets, whether you want to sound like a chipmunk or love the autotune life.",
          "id": "1361074",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/c58edc827be2fd0ebe5cb011764b3f35",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/c58edc827be2fd0ebe5cb011764b3f35",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/c58edc827be2fd0ebe5cb011764b3f35",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "voiceeffects"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "42164",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "foryou"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "229207",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "fyp"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1615199676750853",
          "isCommerce": true,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "ItsOurHome"
        },
        {
          "coverLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/172dc8f1a7667e25a2f31c06d8f5c5d9",
          "coverMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/172dc8f1a7667e25a2f31c06d8f5c5d9",
          "coverThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/172dc8f1a7667e25a2f31c06d8f5c5d9",
          "desc": "Flower prints, knitting, plants and mushrooms. Show us your #Cottagecore life.",
          "id": "1615695775731718",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/815513cf65a2df18a00c5bd19d3fc58e",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/815513cf65a2df18a00c5bd19d3fc58e",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/815513cf65a2df18a00c5bd19d3fc58e",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "cottagecore"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1638530602820614",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "fairycore"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1598372609544198",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "piratecore"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "feel free to duet!! #wellerman #seashanty #piratetiktok #voiceeffects #foryou #fyp #indiemusician #ItsOurHome #cottagecore #fairycore #piratecore",
          "textExtra": [
            {
              "awemeId": "",
              "end": 30,
              "hashtagId": "1676092593052677",
              "hashtagName": "wellerman",
              "isCommerce": false,
              "secUid": "",
              "start": 20,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 41,
              "hashtagId": "16997224",
              "hashtagName": "seashanty",
              "isCommerce": false,
              "secUid": "",
              "start": 31,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 55,
              "hashtagId": "1661921505731585",
              "hashtagName": "piratetiktok",
              "isCommerce": false,
              "secUid": "",
              "start": 42,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 69,
              "hashtagId": "1361074",
              "hashtagName": "voiceeffects",
              "isCommerce": false,
              "secUid": "",
              "start": 56,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 77,
              "hashtagId": "42164",
              "hashtagName": "foryou",
              "isCommerce": false,
              "secUid": "",
              "start": 70,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 82,
              "hashtagId": "229207",
              "hashtagName": "fyp",
              "isCommerce": false,
              "secUid": "",
              "start": 78,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 97,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 83,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 109,
              "hashtagId": "",
              "hashtagName": "itsourhome",
              "isCommerce": false,
              "secUid": "",
              "start": 98,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 122,
              "hashtagId": "1615695775731718",
              "hashtagName": "cottagecore",
              "isCommerce": false,
              "secUid": "",
              "start": 110,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 133,
              "hashtagId": "1638530602820614",
              "hashtagName": "fairycore",
              "isCommerce": false,
              "secUid": "",
              "start": 123,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 145,
              "hashtagId": "1598372609544198",
              "hashtagName": "piratecore",
              "isCommerce": false,
              "secUid": "",
              "start": 134,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1618602354,
      "desc": "feel free to duet!! #wellerman #seashanty #piratetiktok #voiceeffects #foryou #fyp #indiemusician #ItsOurHome #cottagecore #fairycore #piratecore",
      "digged": false,
      "diversificationId": 10009,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "6951844161943391494",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "",
        "authorName": "cloudkissed",
        "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/cbaf602b28e3afaca92d954bc9be9e6a~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=YRxkcH6SsGZtGkW4Jp5EcH5X4aQ%3D",
        "coverMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/cbaf602b28e3afaca92d954bc9be9e6a~c5_720x720.jpeg?x-expires=1700125200&x-signature=nTDNDUki768HNCPdRiBCiYbzCSg%3D",
        "coverThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/cbaf602b28e3afaca92d954bc9be9e6a~c5_100x100.jpeg?x-expires=1700125200&x-signature=SMetBLxbTJm0o1chxws51WFXYbQ%3D",
        "duration": 59,
        "id": "6951844173138070277",
        "original": true,
        "playUrl": "https://v16m.tiktokcdn.com/45bad0e3c6ecef4a8573e49714464caa/655399b6/video/tos/useast2a/tos-useast2a-v-27dcd7/ec29359da39442fab8b8537edd7739f2/?a=1233&ch=0&cr=0&dr=0&er=2&cd=0%7C0%7C0%7C0&br=250&bt=125&bti=NDU3ZjAwOg%3D%3D&ft=d2A~l-Inz7TPgL-Ziyq8Z&mime_type=audio_mpeg&qs=6&rc=aTYzNjVmOmg4Zzg0NjloZ0Bpam9saztsdTV4NDMzNzU8M0AwNmJjYi4vNjMxYGA1MjA2YSNhYWliMmtkZ3BgLS1kMTZzcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00088000&cc=3&download=true",
        "title": "original sound"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 79500,
        "commentCount": 6904,
        "diggCount": 846300,
        "playCount": 3300000,
        "shareCount": 8348
      },
      "stickersOnItem": [
        {
          "stickerText": [
            "There once was a ship that put to sea and the name of the ship was the billy of tea",
            "The winds blew hard her bow dipped down oh blow my bully boys blow ",
            "Soon may the wellerman come, to bring us sugar and tea and rum",
            "One day when the tounging is done we\u2019ll take our leave and go",
            "She had not been two weeks from shore when down on her a right whale bore ",
            "The captain called all hands snd swore he\u2019d take that whale in tow",
            "Soon may the wellerman come to bring us sugar and tea and rum ",
            "One day when the tounging os done we\u2019ll take our leave and go",
            "Before the boat had hit the water the whale\u2019s tail came up and caught her ",
            "All hands to the side harpooned and caught her when she dived down low",
            "Soon may the wellerman come to bring us sugar and tea and rum",
            "One day when the tounging is done we\u2019ll take our leave and go"
          ],
          "stickerType": 4
        }
      ],
      "stitchDisplay": 0,
      "stitchEnabled": false,
      "textExtra": [
        {
          "awemeId": "",
          "end": 30,
          "hashtagId": "1676092593052677",
          "hashtagName": "wellerman",
          "isCommerce": false,
          "secUid": "",
          "start": 20,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 41,
          "hashtagId": "16997224",
          "hashtagName": "seashanty",
          "isCommerce": false,
          "secUid": "",
          "start": 31,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 55,
          "hashtagId": "1661921505731585",
          "hashtagName": "piratetiktok",
          "isCommerce": false,
          "secUid": "",
          "start": 42,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 69,
          "hashtagId": "1361074",
          "hashtagName": "voiceeffects",
          "isCommerce": false,
          "secUid": "",
          "start": 56,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 77,
          "hashtagId": "42164",
          "hashtagName": "foryou",
          "isCommerce": false,
          "secUid": "",
          "start": 70,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 82,
          "hashtagId": "229207",
          "hashtagName": "fyp",
          "isCommerce": false,
          "secUid": "",
          "start": 78,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 97,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 83,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 109,
          "hashtagId": "",
          "hashtagName": "itsourhome",
          "isCommerce": false,
          "secUid": "",
          "start": 98,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 122,
          "hashtagId": "1615695775731718",
          "hashtagName": "cottagecore",
          "isCommerce": false,
          "secUid": "",
          "start": 110,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 133,
          "hashtagId": "1638530602820614",
          "hashtagName": "fairycore",
          "isCommerce": false,
          "secUid": "",
          "start": 123,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 145,
          "hashtagId": "1598372609544198",
          "hashtagName": "piratecore",
          "isCommerce": false,
          "secUid": "",
          "start": 134,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 1176594,
        "bitrateInfo": [
          {
            "Bitrate": 1176594,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 8711508,
              "FileCs": "c:0-49829-e14e",
              "FileHash": "99a3be6a9355b95919caa49b29024676",
              "Uri": "v09044g40000c1suhs2cerkaog7ffbpg",
              "UrlKey": "v09044g40000c1suhs2cerkaog7ffbpg_h264_540p_1176594",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c004/6a7c7a8b213b4780b1d30331951b8036/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2298&bt=1149&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NmhpaTU0NTo5NGQzNzhoNUBpamVpamR1ZnZ4NDMzNzczM0BeXzQ0M2M1NjQxMzI1LTBjYSNtYzRsaGIvZXBgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128855&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=0ac88a5ea4b24674b86e478022241d89&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c004/6a7c7a8b213b4780b1d30331951b8036/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2298&bt=1149&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NmhpaTU0NTo5NGQzNzhoNUBpamVpamR1ZnZ4NDMzNzczM0BeXzQ0M2M1NjQxMzI1LTBjYSNtYzRsaGIvZXBgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128855&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=0ac88a5ea4b24674b86e478022241d89&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 955097,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 7071541,
              "FileCs": "c:0-50036-ecaf",
              "FileHash": "77a28fadb5342b4190762af5e1145eb3",
              "Uri": "v09044g40000c1suhs2cerkaog7ffbpg",
              "UrlKey": "v09044g40000c1suhs2cerkaog7ffbpg_bytevc1_720p_955097",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-pve-0068/232e632ad272439ebf513043cdb75d5c/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1864&bt=932&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=OzhlPGc7N2loZzRkNTM5N0BpamVpamR1ZnZ4NDMzNzczM0A2MzFiXi9gXy0xYV8vNjFhYSNtYzRsaGIvZXBgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128855&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=459e1234820e6076ac400303c3f1a6f5&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-pve-0068/232e632ad272439ebf513043cdb75d5c/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1864&bt=932&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=OzhlPGc7N2loZzRkNTM5N0BpamVpamR1ZnZ4NDMzNzczM0A2MzFiXi9gXy0xYV8vNjFhYSNtYzRsaGIvZXBgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128855&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=459e1234820e6076ac400303c3f1a6f5&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 720470,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 5334366,
              "FileCs": "c:0-50036-187c",
              "FileHash": "1a5071d9afd8a52e74cc6a30dc142cd9",
              "Uri": "v09044g40000c1suhs2cerkaog7ffbpg",
              "UrlKey": "v09044g40000c1suhs2cerkaog7ffbpg_bytevc1_540p_720470",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c001/108bdf32e35d4891a1f6a3c4a989f0f4/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1406&bt=703&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=NzNlaWlpNzszOzM5OTk1ZkBpamVpamR1ZnZ4NDMzNzczM0BiLi4uLWJeNWMxMS1jXi81YSNtYzRsaGIvZXBgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128855&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=2335723af47ed71e0c82324b7704583c&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c001/108bdf32e35d4891a1f6a3c4a989f0f4/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1406&bt=703&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=NzNlaWlpNzszOzM5OTk1ZkBpamVpamR1ZnZ4NDMzNzczM0BiLi4uLWJeNWMxMS1jXi81YSNtYzRsaGIvZXBgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128855&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=2335723af47ed71e0c82324b7704583c&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 528857,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 3915664,
              "FileCs": "c:0-49829-bf40",
              "FileHash": "f2d9e0dd4f93d81775fb2d2d76a102a2",
              "Uri": "v09044g40000c1suhs2cerkaog7ffbpg",
              "UrlKey": "v09044g40000c1suhs2cerkaog7ffbpg_h264_540p_528857",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c002/493686f82651461d96d05bf5f5afde28/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1032&bt=516&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=NmRmZ2c0PDg5NWQ4Zjg3PEBpamVpamR1ZnZ4NDMzNzczM0AtNi41L15iNTUxMjYxX2M0YSNtYzRsaGIvZXBgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128855&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=5c346b22b365f5ee4cd467ac31b721cf&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c002/493686f82651461d96d05bf5f5afde28/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1032&bt=516&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=NmRmZ2c0PDg5NWQ4Zjg3PEBpamVpamR1ZnZ4NDMzNzczM0AtNi41L15iNTUxMjYxX2M0YSNtYzRsaGIvZXBgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128855&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=5c346b22b365f5ee4cd467ac31b721cf&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 349152,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 2585122,
              "FileCs": "c:0-49829-4737",
              "FileHash": "5a2b39362a34e93e97f22a377a1b0e48",
              "Uri": "v09044g40000c1suhs2cerkaog7ffbpg",
              "UrlKey": "v09044g40000c1suhs2cerkaog7ffbpg_h264_540p_349152",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-pve-0068/7cecee6c0df14f788a47765e18b87ca5/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=680&bt=340&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=PGk4NjNmOjk4N2c7PDY0Z0BpamVpamR1ZnZ4NDMzNzczM0BgNTEzMGIwNWExNF8vMGJjYSNtYzRsaGIvZXBgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128855&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=11919f43cfb3496b3585cc15a55d99df&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-pve-0068/7cecee6c0df14f788a47765e18b87ca5/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=680&bt=340&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=PGk4NjNmOjk4N2c7PDY0Z0BpamVpamR1ZnZ4NDMzNzczM0BgNTEzMGIwNWExNF8vMGJjYSNtYzRsaGIvZXBgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128855&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=11919f43cfb3496b3585cc15a55d99df&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/5ef9b278e9ac46f4bebf741932e56568_1618602368?x-expires=1700125200&x-signature=eRyKE7APKdV4EYPVvjgtixyx70I%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c004/175ee79eb74f40f68271193a43f153dc/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=2262&bt=1131&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aDg6OTM0PDVmPGkzMzhnaUBpamVpamR1ZnZ4NDMzNzczM0AwL19hM14xNl4xNTZfMjIvYSNtYzRsaGIvZXBgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128855&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=a1367dcec7cee8b477ab0ae73d4ffb9d&tk=tt_chain_token",
        "duration": 59,
        "dynamicCover": "https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/aba8d1bdc8d44fc295ef14fd1e109837_1618602371?x-expires=1700125200&x-signature=qf4esevD%2B9X2LsfRXklwz%2F3DKv8%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "6951844161943391494",
        "originCover": "https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/1937118973e1424bbef4292adb9c103e_1618602395?x-expires=1700125200&x-signature=diN21qTpnPod%2BdDdWZxfYh9mEjM%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c004/6a7c7a8b213b4780b1d30331951b8036/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2298&bt=1149&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NmhpaTU0NTo5NGQzNzhoNUBpamVpamR1ZnZ4NDMzNzczM0BeXzQ0M2M1NjQxMzI1LTBjYSNtYzRsaGIvZXBgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128855&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=0ac88a5ea4b24674b86e478022241d89&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/5ef9b278e9ac46f4bebf741932e56568_1618602368~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=qj79WLxvRTFJAFy%2B%2BGsPd2fTSaQ%3D",
        "shareCover": [
          "",
          "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/1937118973e1424bbef4292adb9c103e_1618602395~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=dYcFPcFTLzj31xuawudJ%2F7ezjqc%3D",
          "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/1937118973e1424bbef4292adb9c103e_1618602395~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=vl2kIfRiVN95yuk%2FgqG0SWXGdIk%3D"
        ],
        "size": 8711508,
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -20.4,
          "Peak": 0.86099
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/5ef9b278e9ac46f4bebf741932e56568_1618602368~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=lmxrEgnDcnvW%2BspBKJdnIBaqLjU%3D",
          "480": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/5ef9b278e9ac46f4bebf741932e56568_1618602368~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=ON1NPskVcKX7y7YOqmxJ%2BTwjDCM%3D",
          "720": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/5ef9b278e9ac46f4bebf741932e56568_1618602368~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=AuKG1E4AKoBtuZuXyr6W9rvE60M%3D",
          "960": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/5ef9b278e9ac46f4bebf741932e56568_1618602368~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=skbZ3KxKRliwGwFeAqJGSAauZvg%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "7224218912691438638",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Discovering Artists",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAA79sDcMCbl7QatFptF3JKobDAz67Uc0yws24YRf6FRLl8aWSAgSzrw6mu-bd0maiw",
        "secret": false,
        "signature": "\ud83d\udce7: discoveringartists.irl@gmail.com\n\nFollow our Spotify playlist!\ud83d\udc47",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "discoveringartists.irl",
        "verified": false
      },
      "authorStats": {
        "diggCount": 86,
        "followerCount": 515300,
        "followingCount": 13,
        "friendCount": 0,
        "heart": 15800000,
        "heartCount": 15800000,
        "videoCount": 35
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055695396906",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "doyoumakemusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055791882286",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "discoveringartistsirl"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "325757",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "4504990",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "newmusicfriday"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "136517",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musiccurator"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "17331",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indie"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "496813",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "singersongwriter"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "50864",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musicproducer"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "35857",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indieartist"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "211116",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "area"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "Do you make music?"
        },
        {
          "desc": ""
        },
        {
          "desc": "#doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #indie #singersongwriter #musicproducer #indieartist #indiemusician #area",
          "textExtra": [
            {
              "awemeId": "",
              "end": 15,
              "hashtagId": "7252811055695396906",
              "hashtagName": "doyoumakemusic",
              "isCommerce": false,
              "secUid": "",
              "start": 0,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 38,
              "hashtagId": "7252811055791882286",
              "hashtagName": "discoveringartistsirl",
              "isCommerce": false,
              "secUid": "",
              "start": 16,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 56,
              "hashtagId": "325757",
              "hashtagName": "undergroundmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 39,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 72,
              "hashtagId": "4504990",
              "hashtagName": "newmusicfriday",
              "isCommerce": false,
              "secUid": "",
              "start": 57,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 86,
              "hashtagId": "136517",
              "hashtagName": "musiccurator",
              "isCommerce": false,
              "secUid": "",
              "start": 73,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 93,
              "hashtagId": "17331",
              "hashtagName": "indie",
              "isCommerce": false,
              "secUid": "",
              "start": 87,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 111,
              "hashtagId": "496813",
              "hashtagName": "singersongwriter",
              "isCommerce": false,
              "secUid": "",
              "start": 94,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 126,
              "hashtagId": "50864",
              "hashtagName": "musicproducer",
              "isCommerce": false,
              "secUid": "",
              "start": 112,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 139,
              "hashtagId": "35857",
              "hashtagName": "indieartist",
              "isCommerce": false,
              "secUid": "",
              "start": 127,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 154,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 140,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 160,
              "hashtagId": "211116",
              "hashtagName": "area",
              "isCommerce": false,
              "secUid": "",
              "start": 155,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1691180382,
      "desc": "Do you make music? #doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #indie #singersongwriter #musicproducer #indieartist #indiemusician #area",
      "digged": false,
      "diversificationId": 10046,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7263564387141602602",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "",
        "authorName": "Discovering Artists",
        "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "coverMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "coverThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "duration": 35,
        "id": "7263564399523154730",
        "original": true,
        "playUrl": "https://v16m.tiktokcdn.com/316f2985fe3171264b968b9f00979320/6553999e/video/tos/maliva/tos-maliva-v-27dcd7c799-us/oMEogUzQxGoXD0kWzeVgUZDdNnCWbSCzJRB1fS/?a=1233&ch=0&cr=0&dr=0&er=2&cd=0%7C0%7C0%7C0&br=250&bt=125&bti=NDU3ZjAwOg%3D%3D&ft=d2A~l-Inz7TPgL-Ziyq8Z&mime_type=audio_mpeg&qs=6&rc=NGZmMzhkNTczOTtmaWY6ZUBpanNmaDQ6ZnJvbTMzZzU8NEAxXzYzNDIvX14xYV9gM2JeYSNpMWBgcjQwbjNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00088000&cc=3&download=true",
        "title": "original sound"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 57000,
        "commentCount": 1777,
        "diggCount": 815400,
        "playCount": 5500000,
        "shareCount": 1787
      },
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 34,
          "hashtagId": "7252811055695396906",
          "hashtagName": "doyoumakemusic",
          "isCommerce": false,
          "secUid": "",
          "start": 19,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 57,
          "hashtagId": "7252811055791882286",
          "hashtagName": "discoveringartistsirl",
          "isCommerce": false,
          "secUid": "",
          "start": 35,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 75,
          "hashtagId": "325757",
          "hashtagName": "undergroundmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 58,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 91,
          "hashtagId": "4504990",
          "hashtagName": "newmusicfriday",
          "isCommerce": false,
          "secUid": "",
          "start": 76,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 105,
          "hashtagId": "136517",
          "hashtagName": "musiccurator",
          "isCommerce": false,
          "secUid": "",
          "start": 92,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 112,
          "hashtagId": "17331",
          "hashtagName": "indie",
          "isCommerce": false,
          "secUid": "",
          "start": 106,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 130,
          "hashtagId": "496813",
          "hashtagName": "singersongwriter",
          "isCommerce": false,
          "secUid": "",
          "start": 113,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 145,
          "hashtagId": "50864",
          "hashtagName": "musicproducer",
          "isCommerce": false,
          "secUid": "",
          "start": 131,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 158,
          "hashtagId": "35857",
          "hashtagName": "indieartist",
          "isCommerce": false,
          "secUid": "",
          "start": 146,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 173,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 159,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 179,
          "hashtagId": "211116",
          "hashtagName": "area",
          "isCommerce": false,
          "secUid": "",
          "start": 174,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 1929661,
        "bitrateInfo": [
          {
            "Bitrate": 1929661,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 8611837,
              "FileCs": "c:0-30375-48ed",
              "FileHash": "61ea2f0a929ddf5eceffa120df937ab1",
              "Uri": "v12044gd0000cj6locrc77u9hoji8s30",
              "UrlKey": "v12044gd0000cj6locrc77u9hoji8s30_h264_540p_1929661",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/osyNpcNjZAcIqAfIJBkUiXNQIZCcwQtnh4XzhG/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3768&bt=1884&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NTUzZWY2ODVpZ2lmNGZlPEBpM3Zscjw6ZmZvbTMzZzczNEA0YzQ2YjNhX14xYy1fYC5iYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c942aaf723b6b16f7d98e5f8a28ec1b9&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/osyNpcNjZAcIqAfIJBkUiXNQIZCcwQtnh4XzhG/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3768&bt=1884&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NTUzZWY2ODVpZ2lmNGZlPEBpM3Zscjw6ZmZvbTMzZzczNEA0YzQ2YjNhX14xYy1fYC5iYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c942aaf723b6b16f7d98e5f8a28ec1b9&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 962640,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 4296145,
              "FileCs": "c:0-30981-16c6",
              "FileHash": "33b270feed9f6708176198c6cd66aa3b",
              "Uri": "v12044gd0000cj6locrc77u9hoji8s30",
              "UrlKey": "v12044gd0000cj6locrc77u9hoji8s30_bytevc1_720p_962640",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oYINUQBGIXcZAXzyjfP2tcDiG4JCZhEwpkquAz/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1880&bt=940&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=aGg4ODY6aGlmZDk5Z2Q8PEBpM3Zscjw6ZmZvbTMzZzczNEA0YF9eMS0wNTExMzVhY18xYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=81ebbb0f1196394943431a44f10c402a&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oYINUQBGIXcZAXzyjfP2tcDiG4JCZhEwpkquAz/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1880&bt=940&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=aGg4ODY6aGlmZDk5Z2Q8PEBpM3Zscjw6ZmZvbTMzZzczNEA0YF9eMS0wNTExMzVhY18xYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=81ebbb0f1196394943431a44f10c402a&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 946002,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 4221890,
              "FileCs": "c:0-30375-a269",
              "FileHash": "1f974b9e702fd9e1abac5e7dd4c11a1e",
              "Uri": "v12044gd0000cj6locrc77u9hoji8s30",
              "UrlKey": "v12044gd0000cj6locrc77u9hoji8s30_h264_540p_946002",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oEszfAcjqCJBcr4NIQEWhtGZpXZ3yUAiUBkIwv/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1846&bt=923&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=aTpkZ2YzPDU3NjgzZTY3PEBpM3Zscjw6ZmZvbTMzZzczNEAtNmM1XzRgXjUxLjQuMWMwYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=ebf3737868aab980c0861cbbb2b67ba5&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oEszfAcjqCJBcr4NIQEWhtGZpXZ3yUAiUBkIwv/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1846&bt=923&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=aTpkZ2YzPDU3NjgzZTY3PEBpM3Zscjw6ZmZvbTMzZzczNEAtNmM1XzRgXjUxLjQuMWMwYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=ebf3737868aab980c0861cbbb2b67ba5&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 690266,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 3080571,
              "FileCs": "c:0-30981-f614",
              "FileHash": "37d2bb54b7db005fa04ffaecdd88d0ec",
              "Uri": "v12044gd0000cj6locrc77u9hoji8s30",
              "UrlKey": "v12044gd0000cj6locrc77u9hoji8s30_bytevc1_540p_690266",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o44eMEBIQeRSGwFztbTHaoV5kP4vEnjnzgDgAD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1348&bt=674&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=ZGZkZWYzNmk8ZTc1ZGQ3OEBpM3Zscjw6ZmZvbTMzZzczNEAyNTQ2Yl4xXjIxX2FeNC1gYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=1fa83fe94b4df73a2a304984cb77ccff&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o44eMEBIQeRSGwFztbTHaoV5kP4vEnjnzgDgAD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1348&bt=674&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=ZGZkZWYzNmk8ZTc1ZGQ3OEBpM3Zscjw6ZmZvbTMzZzczNEAyNTQ2Yl4xXjIxX2FeNC1gYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=1fa83fe94b4df73a2a304984cb77ccff&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 657069,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 2932421,
              "FileCs": "c:0-30375-2d9c",
              "FileHash": "90614088f7c3847799f2320d7dbf8214",
              "Uri": "v12044gd0000cj6locrc77u9hoji8s30",
              "UrlKey": "v12044gd0000cj6locrc77u9hoji8s30_h264_540p_657069",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQl5XVxHnofWMNTwAIegSnRz4BbqkFDQDSgEEz/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1282&bt=641&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=ZjYzNzxpNjk0aWc4OTpkZ0BpM3Zscjw6ZmZvbTMzZzczNEA2Yi0wNGMuXjUxNDIwMF9hYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=f4934f6b6d8f433639882d51a5d83624&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQl5XVxHnofWMNTwAIegSnRz4BbqkFDQDSgEEz/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1282&bt=641&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=ZjYzNzxpNjk0aWc4OTpkZ0BpM3Zscjw6ZmZvbTMzZzczNEA2Yi0wNGMuXjUxNDIwMF9hYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=f4934f6b6d8f433639882d51a5d83624&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/86a6bd93ae4b46b09a10eb32a62667f8_1691180383?x-expires=1700125200&x-signature=7D4xIqHU74j88loi7qAITCk08Gc%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o0cJXylPtAA3QizZCWBN4jUwcfImqZtIkphIBG/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=3922&bt=1961&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=OmY7OjxnOGk1ZDY4ZzQ7NUBpM3Zscjw6ZmZvbTMzZzczNEAyY18yLy0vNmExLjEvXzM0YSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=56b7f6483e927cebf140b42ad4e5eecf&tk=tt_chain_token",
        "duration": 35,
        "dynamicCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/fa04acb27de440f5972582761c22475a_1691180384?x-expires=1700125200&x-signature=uKVgV7NYRaxZhIqDwuSFprnL0Kc%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7263564387141602602",
        "originCover": "https://p19-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/1ce07ec249314cc5bfda484078b8ab06_1691180383?x-expires=1700125200&x-signature=Wv5r7%2BTHPcSK76kQHudPRTYaNGI%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/osyNpcNjZAcIqAfIJBkUiXNQIZCcwQtnh4XzhG/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3768&bt=1884&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NTUzZWY2ODVpZ2lmNGZlPEBpM3Zscjw6ZmZvbTMzZzczNEA0YzQ2YjNhX14xYy1fYC5iYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c942aaf723b6b16f7d98e5f8a28ec1b9&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/86a6bd93ae4b46b09a10eb32a62667f8_1691180383~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=s%2B0ioBqXrmH96HES7QnUfBiVpwE%3D",
        "shareCover": [
          "",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/1ce07ec249314cc5bfda484078b8ab06_1691180383~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=dz2ZHuNZYf2PNuqp6SXiF4Ed3l8%3D",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/1ce07ec249314cc5bfda484078b8ab06_1691180383~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=YAzT%2BxqpppVrMlnGWAGilmlMi4Q%3D"
        ],
        "size": 8611837,
        "subtitleInfos": [
          {
            "Format": "webvtt",
            "LanguageCodeName": "ita-IT",
            "LanguageID": "26",
            "Size": 1110,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/0325d70fa139faf5229fd7d48c6ace3f/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/4f1074e017f94fa7b74c2acd700a9f5b/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13402&bt=6701&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Zscjw6ZmZvbTMzZzczNEBpM3Zscjw6ZmZvbTMzZzczNEAwNWdlcjRvbDNgLS1kMS9zYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": 1116144926
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "kor-KR",
            "LanguageID": "4",
            "Size": 1310,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/e589a384df0386601e21c04d8ce64706/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/d7e4f8ff30ff4613914d177d39aab839/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13402&bt=6701&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Zscjw6ZmZvbTMzZzczNEBpM3Zscjw6ZmZvbTMzZzczNEAwNWdlcjRvbDNgLS1kMS9zYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": -599196632
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "fra-FR",
            "LanguageID": "7",
            "Size": 1219,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/b7cba23b9b12705f203bf03fd978deb2/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/262b9d11733243fc832e9ad299c1a146/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13402&bt=6701&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Zscjw6ZmZvbTMzZzczNEBpM3Zscjw6ZmZvbTMzZzczNEAwNWdlcjRvbDNgLS1kMS9zYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": -1348636710
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "vie-VN",
            "LanguageID": "10",
            "Size": 1279,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/423a7d00fe7ec8a608f1c066bff39ec2/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/6779e18602d14ca4a9b98b776fd04896/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13402&bt=6701&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Zscjw6ZmZvbTMzZzczNEBpM3Zscjw6ZmZvbTMzZzczNEAwNWdlcjRvbDNgLS1kMS9zYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": -1593986139
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "jpn-JP",
            "LanguageID": "3",
            "Size": 1401,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/83581cfeb40549ec0cfb05930367693c/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/2933a485055b4281bbd6e67651d93a9e/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13402&bt=6701&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Zscjw6ZmZvbTMzZzczNEBpM3Zscjw6ZmZvbTMzZzczNEAwNWdlcjRvbDNgLS1kMS9zYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": 1078822308
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ara-SA",
            "LanguageID": "34",
            "Size": 1208,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/d79d6e69c06c5107a01961dffd0ff88e/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/561c7e295f544139bfbb69e4217b89b4/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13402&bt=6701&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Zscjw6ZmZvbTMzZzczNEBpM3Zscjw6ZmZvbTMzZzczNEAwNWdlcjRvbDNgLS1kMS9zYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": -46440926
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ind-ID",
            "LanguageID": "23",
            "Size": 1148,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/88e849946c8c59ae0c15ea7c8ab8da9e/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/8d8929af5d604ca1a40932603fbb564f/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13402&bt=6701&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Zscjw6ZmZvbTMzZzczNEBpM3Zscjw6ZmZvbTMzZzczNEAwNWdlcjRvbDNgLS1kMS9zYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": -717955977
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "rus-RU",
            "LanguageID": "6",
            "Size": 1623,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/db306669b466cf86698506b9d0b0a3c0/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/2e619a29357649c2ac7ee1b81d024011/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13402&bt=6701&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Zscjw6ZmZvbTMzZzczNEBpM3Zscjw6ZmZvbTMzZzczNEAwNWdlcjRvbDNgLS1kMS9zYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": 851612918
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "cmn-Hans-CN",
            "LanguageID": "1",
            "Size": 1182,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/374aedba128c93bb3919a41f8188a071/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/ef8c0bcd1e42494c923ac6b5dab09438/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13402&bt=6701&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Zscjw6ZmZvbTMzZzczNEBpM3Zscjw6ZmZvbTMzZzczNEAwNWdlcjRvbDNgLS1kMS9zYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": 925621105
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "spa-ES",
            "LanguageID": "9",
            "Size": 1127,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/35b7e07616e2653e9eddd0163963501e/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/1e873d920c2b41d78714c7e9aa70c7e9/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13402&bt=6701&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Zscjw6ZmZvbTMzZzczNEBpM3Zscjw6ZmZvbTMzZzczNEAwNWdlcjRvbDNgLS1kMS9zYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": 55935175
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "eng-US",
            "LanguageID": "2",
            "Size": 913,
            "Source": "ASR",
            "Url": "https://v16-webapp.tiktok.com/69588bc70f71ecc51ef10b4183cd091c/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/fa1087e9ca234506bf5b72923eed694b/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13402&bt=6701&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Zscjw6ZmZvbTMzZzczNEBpM3Zscjw6ZmZvbTMzZzczNEAwNWdlcjRvbDNgLS1kMS9zYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "1",
            "VideoSubtitleID": 2039175112
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "deu-DE",
            "LanguageID": "20",
            "Size": 1265,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/99077b6776234ebefbf7a017ca30f812/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/4f6c98e2e7154d949e0ee4d64890c373/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13402&bt=6701&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Zscjw6ZmZvbTMzZzczNEBpM3Zscjw6ZmZvbTMzZzczNEAwNWdlcjRvbDNgLS1kMS9zYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": -1311883285
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "por-PT",
            "LanguageID": "8",
            "Size": 1137,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/2ab9fed3786898e17ecec2e16c853ce0/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/3abdcaf8ae8f4f4cacab630464640da1/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13402&bt=6701&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3Zscjw6ZmZvbTMzZzczNEBpM3Zscjw6ZmZvbTMzZzczNEAwNWdlcjRvbDNgLS1kMS9zYSMwNWdlcjRvbDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": -1173968046
          }
        ],
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -13.3,
          "Peak": 1
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/86a6bd93ae4b46b09a10eb32a62667f8_1691180383~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=vLlmhQRdL1ZGAhzGGXueyIONzho%3D",
          "480": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/86a6bd93ae4b46b09a10eb32a62667f8_1691180383~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=0CYTj%2FHDom%2B3DLmwiBSYvz3nudc%3D",
          "720": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/86a6bd93ae4b46b09a10eb32a62667f8_1691180383~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=49wYnwZNPAYEFtP8hJG21O0Q9F0%3D",
          "960": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/86a6bd93ae4b46b09a10eb32a62667f8_1691180383~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=46p227JjgJN%2Blv50TSHZnf92Nag%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/2458cb9de036988afefdd21925492eb1~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=PskwD%2FqwSPyS9L2tIjId4EzpZJw%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/2458cb9de036988afefdd21925492eb1~c5_720x720.jpeg?x-expires=1700125200&x-signature=nIHdO9nywgekVC1vmqFyaJ7erkg%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/2458cb9de036988afefdd21925492eb1~c5_100x100.jpeg?x-expires=1700125200&x-signature=f%2BjAKoM0TqRXu8eq%2BJrAsF2224I%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "7091539695071773742",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Melo Music",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAAo1gJOQDh-lTOca0mYwpkuONmBOMrSDy3nMIx8zyJoxqTquJjXjU63_4KTKUPsUSv",
        "secret": false,
        "signature": "finding all my underrated artists on the Melo app!\nplaylists and app\ud83d\udc47\ud83c\udffd",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "prazulmelo",
        "verified": false
      },
      "authorStats": {
        "diggCount": 2589,
        "followerCount": 246800,
        "followingCount": 354,
        "friendCount": 0,
        "heart": 5100000,
        "heartCount": 5100000,
        "videoCount": 207
      },
      "challenges": [
        {
          "coverLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/4ce5508a8a28a5116186ab5baefa61d4.png",
          "coverMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/4ce5508a8a28a5116186ab5baefa61d4.png",
          "coverThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/4ce5508a8a28a5116186ab5baefa61d4.png",
          "desc": "Discover best #NewMusic from your favourite artists!",
          "id": "31075",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/32bceb8db4d6aa366454f7ea54116832.jpeg",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/32bceb8db4d6aa366454f7ea54116832.jpeg",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/32bceb8db4d6aa366454f7ea54116832.jpeg",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "newmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1676321800178693",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundrnb"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1689503509315585",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "altrnbsoul"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1600009",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "newrnb"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1662138920172549",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "newrnbmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "52845093",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "brentfaiyaz"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7876360",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "danielcaesar"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1614352860234758",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "omarapollo"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7009411",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musicdiscovery"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "136517",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musiccurator"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "325757",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "35857",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indieartist"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "Replying to @liiky.tv immediate add\ud83d\ude08\ud83d\ude08 @itsmostwntd #newmusic #undergroundrnb #altrnbsoul #newrnb #newrnbmusic #brentfaiyaz #danielcaesar #omarapollo #musicdiscovery #musiccurator #indiemusician #undergroundmusic #indieartist ",
          "textExtra": [
            {
              "awemeId": "",
              "end": 21,
              "hashtagId": "",
              "hashtagName": "",
              "isCommerce": false,
              "secUid": "MS4wLjABAAAAqigC8c-8o1cRjMMAfW2ENQrL5p2DPjHy4Ns7nl55piP2-cS2bN0QQiX_ossMNyoU",
              "start": 12,
              "subType": 2,
              "type": 0,
              "userId": "6692272624871900166",
              "userUniqueId": "liiky.tv"
            },
            {
              "awemeId": "",
              "end": 52,
              "hashtagId": "",
              "hashtagName": "",
              "isCommerce": false,
              "secUid": "MS4wLjABAAAAP4lj67YGawlq6GgOzRTo9axntKqo222XQUBQCjqeQ054zGX1gcTker9SbT_UZoWd",
              "start": 40,
              "subType": 0,
              "type": 0,
              "userId": "6846805985551221766",
              "userUniqueId": "itsmostwntd"
            },
            {
              "awemeId": "",
              "end": 62,
              "hashtagId": "31075",
              "hashtagName": "newmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 53,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 78,
              "hashtagId": "1676321800178693",
              "hashtagName": "undergroundrnb",
              "isCommerce": false,
              "secUid": "",
              "start": 63,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 90,
              "hashtagId": "1689503509315585",
              "hashtagName": "altrnbsoul",
              "isCommerce": false,
              "secUid": "",
              "start": 79,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 98,
              "hashtagId": "1600009",
              "hashtagName": "newrnb",
              "isCommerce": false,
              "secUid": "",
              "start": 91,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 111,
              "hashtagId": "1662138920172549",
              "hashtagName": "newrnbmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 99,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 124,
              "hashtagId": "52845093",
              "hashtagName": "brentfaiyaz",
              "isCommerce": false,
              "secUid": "",
              "start": 112,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 138,
              "hashtagId": "7876360",
              "hashtagName": "danielcaesar",
              "isCommerce": false,
              "secUid": "",
              "start": 125,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 150,
              "hashtagId": "1614352860234758",
              "hashtagName": "omarapollo",
              "isCommerce": false,
              "secUid": "",
              "start": 139,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 166,
              "hashtagId": "7009411",
              "hashtagName": "musicdiscovery",
              "isCommerce": false,
              "secUid": "",
              "start": 151,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 180,
              "hashtagId": "136517",
              "hashtagName": "musiccurator",
              "isCommerce": false,
              "secUid": "",
              "start": 167,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 195,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 181,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 213,
              "hashtagId": "325757",
              "hashtagName": "undergroundmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 196,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 226,
              "hashtagId": "35857",
              "hashtagName": "indieartist",
              "isCommerce": false,
              "secUid": "",
              "start": 214,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1679791376,
      "desc": "Replying to @liiky.tv immediate add\ud83d\ude08\ud83d\ude08 @itsmostwntd #newmusic #undergroundrnb #altrnbsoul #newrnb #newrnbmusic #brentfaiyaz #danielcaesar #omarapollo #musicdiscovery #musiccurator #indiemusician #undergroundmusic #indieartist ",
      "digged": false,
      "diversificationId": 10046,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7214648986559368491",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "Just Say It",
        "authorName": "Most Wanted",
        "coverLarge": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/oYEhDObngIUIzZJaX5hWHehPxC0FfDOAeAAgIA~c5_720x720.jpeg",
        "coverMedium": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/oYEhDObngIUIzZJaX5hWHehPxC0FfDOAeAAgIA~c5_200x200.jpeg",
        "coverThumb": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/oYEhDObngIUIzZJaX5hWHehPxC0FfDOAeAAgIA~c5_100x100.jpeg",
        "duration": 60,
        "id": "7192120413655140353",
        "original": false,
        "playUrl": "https://sf16-ies-music-va.tiktokcdn.com/obj/tos-useast2a-ve-2774/ooVwADQ7mIggTK4bxf44vek0nYfn1F8fAIWGp7",
        "title": "Just Say It"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 166300,
        "commentCount": 4419,
        "diggCount": 785300,
        "playCount": 4200000,
        "shareCount": 2105
      },
      "stickersOnItem": [
        {
          "stickerText": [
            "Artists with \ud83d\udd25 music but \ud83d\udca9 marketing",
            "Part 29"
          ],
          "stickerType": 4
        }
      ],
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 21,
          "hashtagId": "",
          "hashtagName": "",
          "isCommerce": false,
          "secUid": "MS4wLjABAAAAqigC8c-8o1cRjMMAfW2ENQrL5p2DPjHy4Ns7nl55piP2-cS2bN0QQiX_ossMNyoU",
          "start": 12,
          "subType": 2,
          "type": 0,
          "userId": "6692272624871900166",
          "userUniqueId": "liiky.tv"
        },
        {
          "awemeId": "",
          "end": 52,
          "hashtagId": "",
          "hashtagName": "",
          "isCommerce": false,
          "secUid": "MS4wLjABAAAAP4lj67YGawlq6GgOzRTo9axntKqo222XQUBQCjqeQ054zGX1gcTker9SbT_UZoWd",
          "start": 40,
          "subType": 0,
          "type": 0,
          "userId": "6846805985551221766",
          "userUniqueId": "itsmostwntd"
        },
        {
          "awemeId": "",
          "end": 62,
          "hashtagId": "31075",
          "hashtagName": "newmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 53,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 78,
          "hashtagId": "1676321800178693",
          "hashtagName": "undergroundrnb",
          "isCommerce": false,
          "secUid": "",
          "start": 63,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 90,
          "hashtagId": "1689503509315585",
          "hashtagName": "altrnbsoul",
          "isCommerce": false,
          "secUid": "",
          "start": 79,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 98,
          "hashtagId": "1600009",
          "hashtagName": "newrnb",
          "isCommerce": false,
          "secUid": "",
          "start": 91,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 111,
          "hashtagId": "1662138920172549",
          "hashtagName": "newrnbmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 99,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 124,
          "hashtagId": "52845093",
          "hashtagName": "brentfaiyaz",
          "isCommerce": false,
          "secUid": "",
          "start": 112,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 138,
          "hashtagId": "7876360",
          "hashtagName": "danielcaesar",
          "isCommerce": false,
          "secUid": "",
          "start": 125,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 150,
          "hashtagId": "1614352860234758",
          "hashtagName": "omarapollo",
          "isCommerce": false,
          "secUid": "",
          "start": 139,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 166,
          "hashtagId": "7009411",
          "hashtagName": "musicdiscovery",
          "isCommerce": false,
          "secUid": "",
          "start": 151,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 180,
          "hashtagId": "136517",
          "hashtagName": "musiccurator",
          "isCommerce": false,
          "secUid": "",
          "start": 167,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 195,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 181,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 213,
          "hashtagId": "325757",
          "hashtagName": "undergroundmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 196,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 226,
          "hashtagId": "35857",
          "hashtagName": "indieartist",
          "isCommerce": false,
          "secUid": "",
          "start": 214,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 820604,
        "bitrateInfo": [
          {
            "Bitrate": 822217,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 2484124,
              "FileCs": "c:0-27572-fb0e",
              "FileHash": "8f4890b91a03468ae0a0ac8ee0696f29",
              "Uri": "v12044gd0000cgfp5jrc77u2mt3858r0",
              "UrlKey": "v12044gd0000cgfp5jrc77u2mt3858r0_h264_540p_822217",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/15abae30ea1d4ef4a9b2e4d5863bbb0d/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1604&bt=802&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=Njs8Zjg1ODQzODM0Nzs1NUBpMzs7dzU6Zm1zajMzZzczNEBfM2EwYDA2X2AxLWIuYTAvYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=5d42eccd4eaec697efc1e3c13645825d&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/15abae30ea1d4ef4a9b2e4d5863bbb0d/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1604&bt=802&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=Njs8Zjg1ODQzODM0Nzs1NUBpMzs7dzU6Zm1zajMzZzczNEBfM2EwYDA2X2AxLWIuYTAvYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=5d42eccd4eaec697efc1e3c13645825d&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 820604,
            "CodecType": "h264",
            "GearName": "normal_720_0",
            "PlayAddr": {
              "DataSize": 2465301,
              "FileCs": "c:0-21276-037d",
              "FileHash": "1bb3b5b29eb33ddef26c5a04e0a6cede",
              "Uri": "v12044gd0000cgfp5jrc77u2mt3858r0",
              "UrlKey": "v12044gd0000cgfp5jrc77u2mt3858r0_h264_720p_820604",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/bda0578af79448e086faecafd96c9ac5/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1602&bt=801&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aDo4N2hoNTc6Zjc4aGZmM0BpMzs7dzU6Zm1zajMzZzczNEA2NS5hX2IwXjYxLy9iNDE1YSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=3e19cff93eee1601f24fb6911ee1f113&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/bda0578af79448e086faecafd96c9ac5/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1602&bt=801&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aDo4N2hoNTc6Zjc4aGZmM0BpMzs7dzU6Zm1zajMzZzczNEA2NS5hX2IwXjYxLy9iNDE1YSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=3e19cff93eee1601f24fb6911ee1f113&tk=tt_chain_token"
              ]
            },
            "QualityType": 10
          },
          {
            "Bitrate": 756522,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 2270324,
              "FileCs": "c:0-27825-fd3a",
              "FileHash": "d8b74be191e4e3a133defcd0923586f5",
              "Uri": "v12044gd0000cgfp5jrc77u2mt3858r0",
              "UrlKey": "v12044gd0000cgfp5jrc77u2mt3858r0_bytevc1_1080p_756522",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/6b34f8ca333f461e99b1595f7e60af83/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1476&bt=738&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=ZjM1ODtmZ2c1OTQ4NDs3ZkBpMzs7dzU6Zm1zajMzZzczNEBhMjRgMzIuX2MxYDFfLmBjYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=5b6091251239314688eecd270cc01165&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/6b34f8ca333f461e99b1595f7e60af83/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1476&bt=738&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=ZjM1ODtmZ2c1OTQ4NDs3ZkBpMzs7dzU6Zm1zajMzZzczNEBhMjRgMzIuX2MxYDFfLmBjYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=5b6091251239314688eecd270cc01165&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 467781,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 1413284,
              "FileCs": "c:0-27572-e2dc",
              "FileHash": "1e0667359c00be0260eaa961968a4147",
              "Uri": "v12044gd0000cgfp5jrc77u2mt3858r0",
              "UrlKey": "v12044gd0000cgfp5jrc77u2mt3858r0_h264_540p_467781",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/6ac67664d53149b8a082828841c58370/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=912&bt=456&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=O2Y1ZjY2NDw2Z2ZnNWdlZUBpMzs7dzU6Zm1zajMzZzczNEAyYDI2MC1gXy4xMDNeMy9gYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=5d34dc27980c2710b3009f9686befe33&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/6ac67664d53149b8a082828841c58370/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=912&bt=456&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=O2Y1ZjY2NDw2Z2ZnNWdlZUBpMzs7dzU6Zm1zajMzZzczNEAyYDI2MC1gXy4xMDNeMy9gYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=5d34dc27980c2710b3009f9686befe33&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 465150,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 1397429,
              "FileCs": "c:0-21410-7047",
              "FileHash": "0ec15c8a416b04567c41a155aaff16e7",
              "Uri": "v12044gd0000cgfp5jrc77u2mt3858r0",
              "UrlKey": "v12044gd0000cgfp5jrc77u2mt3858r0_bytevc1_720p_465150",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/8b09d5f9c3834f1f9e548a44e885ad79/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=908&bt=454&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=O2g3ZGQ0ZjQ0ODg2N2Q7M0BpMzs7dzU6Zm1zajMzZzczNEAvNDAtYjMxX18xMzVhYF9iYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=a7910b53b440ff41401b7c60de6b70cf&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/8b09d5f9c3834f1f9e548a44e885ad79/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=908&bt=454&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=O2g3ZGQ0ZjQ0ODg2N2Q7M0BpMzs7dzU6Zm1zajMzZzczNEAvNDAtYjMxX18xMzVhYF9iYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=a7910b53b440ff41401b7c60de6b70cf&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 363298,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 1091440,
              "FileCs": "c:0-21410-7461",
              "FileHash": "d7c764529006b94491c86dc9a02143fa",
              "Uri": "v12044gd0000cgfp5jrc77u2mt3858r0",
              "UrlKey": "v12044gd0000cgfp5jrc77u2mt3858r0_bytevc1_540p_363298",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/92ea4e6eefcf42e5ac44f37214eede57/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=708&bt=354&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=ZGU2NzQ3ZWg8Ozw6O2Q0aEBpMzs7dzU6Zm1zajMzZzczNEBjMjAxNjE0XjIxMzU1YWJhYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=4ecaffabf0b5d600cc610d791c207ced&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/92ea4e6eefcf42e5ac44f37214eede57/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=708&bt=354&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=ZGU2NzQ3ZWg8Ozw6O2Q0aEBpMzs7dzU6Zm1zajMzZzczNEBjMjAxNjE0XjIxMzU1YWJhYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=4ecaffabf0b5d600cc610d791c207ced&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 345777,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 1044679,
              "FileCs": "c:0-27572-945a",
              "FileHash": "47ce149009d4fe0737abad203efdc3b2",
              "Uri": "v12044gd0000cgfp5jrc77u2mt3858r0",
              "UrlKey": "v12044gd0000cgfp5jrc77u2mt3858r0_h264_540p_345777",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/9843a5886cb64d4bacdf538eaac9ccbd/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=674&bt=337&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=NGc8aWk5Zjc3ZGVkZjRpO0BpMzs7dzU6Zm1zajMzZzczNEBhMGM1MzU2Nl4xLzAzYTUvYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=f179f5fbf4f6fae0a6d170bf0d127fd1&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/9843a5886cb64d4bacdf538eaac9ccbd/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=674&bt=337&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=NGc8aWk5Zjc3ZGVkZjRpO0BpMzs7dzU6Zm1zajMzZzczNEBhMGM1MzU2Nl4xLzAzYTUvYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=f179f5fbf4f6fae0a6d170bf0d127fd1&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/5eabdf377c68443395008965f175af4e?x-expires=1700125200&x-signature=%2Fn4tYsP9tfhvq49Ib9c7jibejDU%3D",
        "definition": "720p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/8e85012c08c94eafba28514395626fab/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=1676&bt=838&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZWY4ZmY8PDdkPGRlNDVoO0BpMzs7dzU6Zm1zajMzZzczNEAxNC4uNF4vNWExMDBjMl41YSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=03324930c5a59d0ea134842ed7abd153&tk=tt_chain_token",
        "duration": 24,
        "dynamicCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/3e597faf712446a3958a7fbbe0e8b440_1679791381?x-expires=1700125200&x-signature=Yk82frEOR1IIcyKd2mCU35tzCnk%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1280,
        "id": "7214648986559368491",
        "originCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/3831f70058ce481e9b5700cb670f6a39_1679791381?x-expires=1700125200&x-signature=vWi2u3rawi6Op3htpxPON%2FWaWps%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/bda0578af79448e086faecafd96c9ac5/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1602&bt=801&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aDo4N2hoNTc6Zjc4aGZmM0BpMzs7dzU6Zm1zajMzZzczNEA2NS5hX2IwXjYxLy9iNDE1YSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=3e19cff93eee1601f24fb6911ee1f113&tk=tt_chain_token",
        "ratio": "720p",
        "reflowCover": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/5eabdf377c68443395008965f175af4e~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=SN331iDznfpBAf3TluZygxgiRzM%3D",
        "shareCover": [
          "",
          "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/3831f70058ce481e9b5700cb670f6a39_1679791381~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=Bblgvd%2FrTr%2BPSZwb%2BIWCJlq1jts%3D",
          "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/3831f70058ce481e9b5700cb670f6a39_1679791381~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=ZsBKPxRkKtj4cVzvVqIzaN4%2FdOo%3D"
        ],
        "size": 2465301,
        "subtitleInfos": [
          {
            "Format": "webvtt",
            "LanguageCodeName": "jpn-JP",
            "LanguageID": "3",
            "Size": 731,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/61185bcee45764a51d507b98b5ab049d/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/52e2f3cd28e74db1b0b786d236c00537/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1602&bt=801&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzs7dzU6Zm1zajMzZzczNEBpMzs7dzU6Zm1zajMzZzczNEBvMjBqcjRvMmNgLS1kMS9zYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": -1439371019
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ara-SA",
            "LanguageID": "34",
            "Size": 635,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/f8e5c99fcc5105b0cd17074466300b4b/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/5ef5ab1607a043c28678e6d6101ab40a/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1602&bt=801&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzs7dzU6Zm1zajMzZzczNEBpMzs7dzU6Zm1zajMzZzczNEBvMjBqcjRvMmNgLS1kMS9zYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": -1325601145
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "por-PT",
            "LanguageID": "8",
            "Size": 581,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/7ade3a7903401ad24f1d509fc381f002/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/df82f6a9b4864d89980c0b8199323307/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1602&bt=801&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzs7dzU6Zm1zajMzZzczNEBpMzs7dzU6Zm1zajMzZzczNEBvMjBqcjRvMmNgLS1kMS9zYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": -502009553
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "fra-FR",
            "LanguageID": "7",
            "Size": 599,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/3d3ff40dd84ddddd5fd1a30cd5cd35a7/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/0f4abf2dca504654951e67a91a4fca41/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1602&bt=801&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzs7dzU6Zm1zajMzZzczNEBpMzs7dzU6Zm1zajMzZzczNEBvMjBqcjRvMmNgLS1kMS9zYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": -1563583611
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "rus-RU",
            "LanguageID": "6",
            "Size": 763,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/b452e185194fbed64315a4cc24b27dea/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/b32c5f161b15441eafea4edb1a708282/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1602&bt=801&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzs7dzU6Zm1zajMzZzczNEBpMzs7dzU6Zm1zajMzZzczNEBvMjBqcjRvMmNgLS1kMS9zYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": 2046394989
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "eng-US",
            "LanguageID": "2",
            "Size": 488,
            "Source": "ASR",
            "Url": "https://v16-webapp.tiktok.com/085bd500eeb89af5f768f91ebc326acc/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/82b17a56adf34769b890b429ab1b9d84/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1602&bt=801&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzs7dzU6Zm1zajMzZzczNEBpMzs7dzU6Zm1zajMzZzczNEBvMjBqcjRvMmNgLS1kMS9zYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "1",
            "VideoSubtitleID": -859507706
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "deu-DE",
            "LanguageID": "20",
            "Size": 580,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/a3fc158d78894e83d0cd4680ece86db0/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/9a36d5c3b8714133a0156918e364553f/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1602&bt=801&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzs7dzU6Zm1zajMzZzczNEBpMzs7dzU6Zm1zajMzZzczNEBvMjBqcjRvMmNgLS1kMS9zYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": 578442059
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "spa-ES",
            "LanguageID": "9",
            "Size": 586,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/e47681981962ee8f60a257a58580081f/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/a038b3dfe112454ca47c6749cf7c01c7/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1602&bt=801&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzs7dzU6Zm1zajMzZzczNEBpMzs7dzU6Zm1zajMzZzczNEBvMjBqcjRvMmNgLS1kMS9zYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": 1546670087
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "kor-KR",
            "LanguageID": "4",
            "Size": 648,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/0c5b344ed3936eb5657b1cc6f8a38112/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/75d5d403fcee42469d635f4d5527f8a6/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1602&bt=801&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzs7dzU6Zm1zajMzZzczNEBpMzs7dzU6Zm1zajMzZzczNEBvMjBqcjRvMmNgLS1kMS9zYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": -637745466
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ind-ID",
            "LanguageID": "23",
            "Size": 593,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/97ff7d11e71c60102dc73ce93a45d499/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/f38cf4d3a25649a0be440fde85e08e42/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1602&bt=801&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzs7dzU6Zm1zajMzZzczNEBpMzs7dzU6Zm1zajMzZzczNEBvMjBqcjRvMmNgLS1kMS9zYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": -1551180178
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "vie-VN",
            "LanguageID": "10",
            "Size": 620,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/5bd8a9b1c9834cf14d6dbb2fab870063/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/e1efe19b61b34bf9834557610fb12e10/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1602&bt=801&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzs7dzU6Zm1zajMzZzczNEBpMzs7dzU6Zm1zajMzZzczNEBvMjBqcjRvMmNgLS1kMS9zYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": 1636288522
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "cmn-Hans-CN",
            "LanguageID": "1",
            "Size": 576,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/a264acaa81499d1dd847b0a7132d87e0/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/dfb8891584d84954bcbf503ac8b2d2f6/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1602&bt=801&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzs7dzU6Zm1zajMzZzczNEBpMzs7dzU6Zm1zajMzZzczNEBvMjBqcjRvMmNgLS1kMS9zYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": -1195768933
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ita-IT",
            "LanguageID": "26",
            "Size": 580,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/9f00b5d5485b851521a51339815a3956/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/588abfcf9ce340e9969462c322069df0/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1602&bt=801&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzs7dzU6Zm1zajMzZzczNEBpMzs7dzU6Zm1zajMzZzczNEBvMjBqcjRvMmNgLS1kMS9zYSNvMjBqcjRvMmNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": -1977887258
          }
        ],
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -14.7,
          "Peak": 0.69984
        },
        "width": 720,
        "zoomCover": {
          "240": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/5eabdf377c68443395008965f175af4e~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=f9%2Fyh8kATbxitCJ6CWvnR54sD44%3D",
          "480": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/5eabdf377c68443395008965f175af4e~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=hKW8uIz9Z%2BqezIqpj6RiYKbm6Yg%3D",
          "720": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/5eabdf377c68443395008965f175af4e~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=ilt07H8CgfNQ%2BEgFVby58KDGLuU%3D",
          "960": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/5eabdf377c68443395008965f175af4e~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=hGRDRV6z8Tc1ugQGS5%2F83sGfYIE%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "7224218912691438638",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Discovering Artists",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAA79sDcMCbl7QatFptF3JKobDAz67Uc0yws24YRf6FRLl8aWSAgSzrw6mu-bd0maiw",
        "secret": false,
        "signature": "\ud83d\udce7: discoveringartists.irl@gmail.com\n\nFollow our Spotify playlist!\ud83d\udc47",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "discoveringartists.irl",
        "verified": false
      },
      "authorStats": {
        "diggCount": 86,
        "followerCount": 515300,
        "followingCount": 13,
        "friendCount": 0,
        "heart": 15800000,
        "heartCount": 15800000,
        "videoCount": 35
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055695396906",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "doyoumakemusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055791882286",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "discoveringartistsirl"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "325757",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "4504990",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "newmusicfriday"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "136517",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musiccurator"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "925",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "macmiller"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "52845093",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "brentfaiyaz"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "17331",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indie"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "496813",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "singersongwriter"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "50864",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musicproducer"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "35857",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indieartist"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "Do you make music?"
        },
        {
          "desc": ""
        },
        {
          "desc": "#doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #macmiller #brentfaiyaz #indie #singersongwriter #musicproducer #indieartist #indiemusician ",
          "textExtra": [
            {
              "awemeId": "",
              "end": 15,
              "hashtagId": "7252811055695396906",
              "hashtagName": "doyoumakemusic",
              "isCommerce": false,
              "secUid": "",
              "start": 0,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 38,
              "hashtagId": "7252811055791882286",
              "hashtagName": "discoveringartistsirl",
              "isCommerce": false,
              "secUid": "",
              "start": 16,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 56,
              "hashtagId": "325757",
              "hashtagName": "undergroundmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 39,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 72,
              "hashtagId": "4504990",
              "hashtagName": "newmusicfriday",
              "isCommerce": false,
              "secUid": "",
              "start": 57,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 86,
              "hashtagId": "136517",
              "hashtagName": "musiccurator",
              "isCommerce": false,
              "secUid": "",
              "start": 73,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 97,
              "hashtagId": "925",
              "hashtagName": "macmiller",
              "isCommerce": false,
              "secUid": "",
              "start": 87,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 110,
              "hashtagId": "52845093",
              "hashtagName": "brentfaiyaz",
              "isCommerce": false,
              "secUid": "",
              "start": 98,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 117,
              "hashtagId": "17331",
              "hashtagName": "indie",
              "isCommerce": false,
              "secUid": "",
              "start": 111,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 135,
              "hashtagId": "496813",
              "hashtagName": "singersongwriter",
              "isCommerce": false,
              "secUid": "",
              "start": 118,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 150,
              "hashtagId": "50864",
              "hashtagName": "musicproducer",
              "isCommerce": false,
              "secUid": "",
              "start": 136,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 163,
              "hashtagId": "35857",
              "hashtagName": "indieartist",
              "isCommerce": false,
              "secUid": "",
              "start": 151,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 178,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 164,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1689112575,
      "desc": "Do you make music? #doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #macmiller #brentfaiyaz #indie #singersongwriter #musicproducer #indieartist #indiemusician ",
      "digged": false,
      "diversificationId": 10046,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7254683233923730731",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "A Color Like Violet",
        "authorName": "Nevan & Isaiah Mostafa",
        "coverLarge": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/06d011c6baba460c8d5380dc749f4c45~c5_720x720.jpeg",
        "coverMedium": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/06d011c6baba460c8d5380dc749f4c45~c5_200x200.jpeg",
        "coverThumb": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/06d011c6baba460c8d5380dc749f4c45~c5_100x100.jpeg",
        "duration": 60,
        "id": "6815796687877965825",
        "original": false,
        "playUrl": "https://sf16-ies-music-va.tiktokcdn.com/obj/tos-useast2a-ve-2774/3933e0c55ce34c3cb1001b3473494f5d",
        "title": "Parta Me"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 56900,
        "commentCount": 1774,
        "diggCount": 732100,
        "playCount": 5200000,
        "shareCount": 2205
      },
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 34,
          "hashtagId": "7252811055695396906",
          "hashtagName": "doyoumakemusic",
          "isCommerce": false,
          "secUid": "",
          "start": 19,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 57,
          "hashtagId": "7252811055791882286",
          "hashtagName": "discoveringartistsirl",
          "isCommerce": false,
          "secUid": "",
          "start": 35,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 75,
          "hashtagId": "325757",
          "hashtagName": "undergroundmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 58,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 91,
          "hashtagId": "4504990",
          "hashtagName": "newmusicfriday",
          "isCommerce": false,
          "secUid": "",
          "start": 76,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 105,
          "hashtagId": "136517",
          "hashtagName": "musiccurator",
          "isCommerce": false,
          "secUid": "",
          "start": 92,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 116,
          "hashtagId": "925",
          "hashtagName": "macmiller",
          "isCommerce": false,
          "secUid": "",
          "start": 106,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 129,
          "hashtagId": "52845093",
          "hashtagName": "brentfaiyaz",
          "isCommerce": false,
          "secUid": "",
          "start": 117,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 136,
          "hashtagId": "17331",
          "hashtagName": "indie",
          "isCommerce": false,
          "secUid": "",
          "start": 130,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 154,
          "hashtagId": "496813",
          "hashtagName": "singersongwriter",
          "isCommerce": false,
          "secUid": "",
          "start": 137,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 169,
          "hashtagId": "50864",
          "hashtagName": "musicproducer",
          "isCommerce": false,
          "secUid": "",
          "start": 155,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 182,
          "hashtagId": "35857",
          "hashtagName": "indieartist",
          "isCommerce": false,
          "secUid": "",
          "start": 170,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 197,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 183,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 1818708,
        "bitrateInfo": [
          {
            "Bitrate": 1818708,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 7880693,
              "FileCs": "c:0-28940-3a59",
              "FileHash": "f34a948d5f44fb21e62f5d4243745c5d",
              "Uri": "v12044gd0000cimsuirc77u02i66k0j0",
              "UrlKey": "v12044gd0000cimsuirc77u02i66k0j0_h264_540p_1818708",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/okDRPkcBKU9DEleRUBogEGASj0gbMLAtBerQCn/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3552&bt=1776&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NDRkZWU7NDU0ZDg7NDk0ZkBpMzM5bDM6Zmx2bDMzZzczNEA0MjAtYTA2NTExNDE1YDNiYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=2b017b2e23a6950d008dfb52388f81f8&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/okDRPkcBKU9DEleRUBogEGASj0gbMLAtBerQCn/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3552&bt=1776&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NDRkZWU7NDU0ZDg7NDk0ZkBpMzM5bDM6Zmx2bDMzZzczNEA0MjAtYTA2NTExNDE1YDNiYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=2b017b2e23a6950d008dfb52388f81f8&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 1540743,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 6645228,
              "FileCs": "c:0-29961-5365",
              "FileHash": "a282aa5db76679658cb1cab51e209771",
              "Uri": "v12044gd0000cimsuirc77u02i66k0j0",
              "UrlKey": "v12044gd0000cimsuirc77u02i66k0j0_bytevc1_1080p_1540743",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ochDUmSRVEicFmzqDGgfArZ9It0xklhBIQeAtA/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3008&bt=1504&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=aTs7MzU3ODc4NmU5ZjxpZ0BpMzM5bDM6Zmx2bDMzZzczNEAvMl9iNWIwNTIxYGJfXzU2YSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=90950b5321e88847bcdd3537276ccad0&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ochDUmSRVEicFmzqDGgfArZ9It0xklhBIQeAtA/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3008&bt=1504&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=aTs7MzU3ODc4NmU5ZjxpZ0BpMzM5bDM6Zmx2bDMzZzczNEAvMl9iNWIwNTIxYGJfXzU2YSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=90950b5321e88847bcdd3537276ccad0&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 985353,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 4269660,
              "FileCs": "c:0-28940-7c18",
              "FileHash": "654fbf5d9c0c2f692c081883f5a50ea9",
              "Uri": "v12044gd0000cimsuirc77u02i66k0j0",
              "UrlKey": "v12044gd0000cimsuirc77u02i66k0j0_h264_540p_985353",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/owQDG9kjAQRCDLUE8cAafhRn3gsSrSoBYfbXGA/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1924&bt=962&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=ZmY7ZmRmPDs6aTM8aDpoN0BpMzM5bDM6Zmx2bDMzZzczNEBeNV40MS8yNTExNi42Yy4xYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=9773dabdd22099479689ca06e8c9a2e8&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/owQDG9kjAQRCDLUE8cAafhRn3gsSrSoBYfbXGA/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1924&bt=962&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=ZmY7ZmRmPDs6aTM8aDpoN0BpMzM5bDM6Zmx2bDMzZzczNEBeNV40MS8yNTExNi42Yy4xYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=9773dabdd22099479689ca06e8c9a2e8&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 937401,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 4043014,
              "FileCs": "c:0-29868-8a71",
              "FileHash": "19433f347766c13b2a13e30e0651ee78",
              "Uri": "v12044gd0000cimsuirc77u02i66k0j0",
              "UrlKey": "v12044gd0000cimsuirc77u02i66k0j0_bytevc1_720p_937401",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o4tv0clSrBDfxNmIQUq2ADA1AFegYk4IzERthy/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1830&bt=915&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=NWU2ZDs4Zmk8ZTVkZjllZEBpMzM5bDM6Zmx2bDMzZzczNEBgXy5hLzBhNjYxLzYxMGA0YSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=9dfef02435876c836f5b4265662ceac3&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o4tv0clSrBDfxNmIQUq2ADA1AFegYk4IzERthy/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1830&bt=915&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=NWU2ZDs4Zmk8ZTVkZjllZEBpMzM5bDM6Zmx2bDMzZzczNEBgXy5hLzBhNjYxLzYxMGA0YSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=9dfef02435876c836f5b4265662ceac3&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 688693,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 2984195,
              "FileCs": "c:0-28940-d6d5",
              "FileHash": "7ac9b21fa44c17127963d6430dfb274c",
              "Uri": "v12044gd0000cimsuirc77u02i66k0j0",
              "UrlKey": "v12044gd0000cimsuirc77u02i66k0j0_h264_540p_688693",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o0hCUS5tcAAIxjERl0rkFQfem2OzIKgDAqAltB/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1344&bt=672&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=NTZpMzNmZWllNWloNjU2NkBpMzM5bDM6Zmx2bDMzZzczNEA2L2I2Y2EvNTExYC1hNTAzYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=7428fcbe034e31deadd34cc7f6d86f82&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o0hCUS5tcAAIxjERl0rkFQfem2OzIKgDAqAltB/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1344&bt=672&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=NTZpMzNmZWllNWloNjU2NkBpMzM5bDM6Zmx2bDMzZzczNEA2L2I2Y2EvNTExYC1hNTAzYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=7428fcbe034e31deadd34cc7f6d86f82&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          },
          {
            "Bitrate": 658531,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 2840245,
              "FileCs": "c:0-29868-a37b",
              "FileHash": "053e9518777d9a9afa7eb2ac1c6c5acb",
              "Uri": "v12044gd0000cimsuirc77u02i66k0j0",
              "UrlKey": "v12044gd0000cimsuirc77u02i66k0j0_bytevc1_540p_658531",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/octD9knWeFBLrobfaRUMABUEKAjQoSgbgkcRBD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1286&bt=643&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=Z2g3NWY4aTk7ZGZnaTdlZ0BpMzM5bDM6Zmx2bDMzZzczNEA1Mi01NTU1NV4xYWA0YjAvYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=773b25572a0e6ab40e91a5481a595048&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/octD9knWeFBLrobfaRUMABUEKAjQoSgbgkcRBD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1286&bt=643&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=Z2g3NWY4aTk7ZGZnaTdlZ0BpMzM5bDM6Zmx2bDMzZzczNEA1Mi01NTU1NV4xYWA0YjAvYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=773b25572a0e6ab40e91a5481a595048&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/443f679b8f644f6f87706f3a32825267_1689112576?x-expires=1700125200&x-signature=bqUxrMT%2BRTPmHfkUo%2BryODAft2w%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ogI0BQHmkCDmKAxtBAfhgezlErARXIvUIFtqSc/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=3654&bt=1827&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZTo0NGZnO2hoODpnZmlkM0BpMzM5bDM6Zmx2bDMzZzczNEA1L15iXmItX2AxNTQyXi4uYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=d5b4b21e9ef6a79429a19b054620c3ce&tk=tt_chain_token",
        "duration": 34,
        "dynamicCover": "https://p19-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/4fe858a0cc2e4b5d8ef364be5e027314_1689112577?x-expires=1700125200&x-signature=F2AMylTIfP86mZRoCK7WS%2F0MP2M%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7254683233923730731",
        "originCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/119318f32513456fa828d06ec9e663b3_1689112576?x-expires=1700125200&x-signature=QzhtXMLbiuoQ5GFk9j%2BhvPZQluw%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/okDRPkcBKU9DEleRUBogEGASj0gbMLAtBerQCn/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3552&bt=1776&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NDRkZWU7NDU0ZDg7NDk0ZkBpMzM5bDM6Zmx2bDMzZzczNEA0MjAtYTA2NTExNDE1YDNiYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=2b017b2e23a6950d008dfb52388f81f8&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/443f679b8f644f6f87706f3a32825267_1689112576~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=nX8rdUq0kvBul3QJ9NGxQ0uTDEQ%3D",
        "shareCover": [
          "",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/119318f32513456fa828d06ec9e663b3_1689112576~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=62raldGco1V%2B0PDZFO8vCyZeQyk%3D",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/119318f32513456fa828d06ec9e663b3_1689112576~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=iXVHdqKmJDPXvqiP91h%2FH1um0fE%3D"
        ],
        "size": 7880693,
        "subtitleInfos": [
          {
            "Format": "webvtt",
            "LanguageCodeName": "jpn-JP",
            "LanguageID": "3",
            "Size": 1049,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/3d7ab97135ba27d465e4cb0b2430577e/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/3139793701e243fdabcfc15b3c43e2c1/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3552&bt=1776&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzM5bDM6Zmx2bDMzZzczNEBpMzM5bDM6Zmx2bDMzZzczNEBnaDMvcjRvcmpgLS1kMS9zYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": 790562829
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ind-ID",
            "LanguageID": "23",
            "Size": 897,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/db692041d8a1c5633b074bdcc465ef82/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/5f1033bf3f7149b297c348dcd6460ce0/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3552&bt=1776&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzM5bDM6Zmx2bDMzZzczNEBpMzM5bDM6Zmx2bDMzZzczNEBnaDMvcjRvcmpgLS1kMS9zYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": 2103976447
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "kor-KR",
            "LanguageID": "4",
            "Size": 1013,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/0b8ae0b612cc21a5faec06a66256cdd6/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/cc745ef00b2942e7b23e4af288706e56/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3552&bt=1776&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzM5bDM6Zmx2bDMzZzczNEBpMzM5bDM6Zmx2bDMzZzczNEBnaDMvcjRvcmpgLS1kMS9zYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": -1118517495
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ara-SA",
            "LanguageID": "34",
            "Size": 884,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/aaae6a401781d01b29f2f28656b1993c/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/9c344dac9f034ad09ccb094d689de145/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3552&bt=1776&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzM5bDM6Zmx2bDMzZzczNEBpMzM5bDM6Zmx2bDMzZzczNEBnaDMvcjRvcmpgLS1kMS9zYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": 1117763320
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "deu-DE",
            "LanguageID": "20",
            "Size": 906,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/38e52608feccf2858e2d83454667800b/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/53e601d8088c4a0296161de009868a8e/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3552&bt=1776&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzM5bDM6Zmx2bDMzZzczNEBpMzM5bDM6Zmx2bDMzZzczNEBnaDMvcjRvcmpgLS1kMS9zYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": -1145220012
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "cmn-Hans-CN",
            "LanguageID": "1",
            "Size": 887,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/80a6d8b39363e68f7770749f79942388/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/a045e837d99443f9aea7c6016f0338c8/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3552&bt=1776&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzM5bDM6Zmx2bDMzZzczNEBpMzM5bDM6Zmx2bDMzZzczNEBnaDMvcjRvcmpgLS1kMS9zYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": 256579552
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "vie-VN",
            "LanguageID": "10",
            "Size": 983,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/ecbd74964d3bf5d96e295e0f3be1f1d1/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/e938545dd9164d0f847bd20966a5cf78/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3552&bt=1776&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzM5bDM6Zmx2bDMzZzczNEBpMzM5bDM6Zmx2bDMzZzczNEBnaDMvcjRvcmpgLS1kMS9zYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": -983942574
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "eng-US",
            "LanguageID": "2",
            "Size": 709,
            "Source": "ASR",
            "Url": "https://v16-webapp.tiktok.com/673da758ff723e055f0b88305f994e1d/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/62c200a33d7945b3a073e470dbca4832/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3552&bt=1776&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzM5bDM6Zmx2bDMzZzczNEBpMzM5bDM6Zmx2bDMzZzczNEBnaDMvcjRvcmpgLS1kMS9zYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "1",
            "VideoSubtitleID": -488254941
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "rus-RU",
            "LanguageID": "6",
            "Size": 1212,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/3d244cbf78bcafd288b120109b2234f8/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/e26c53fd0b934deabcadbf9a6d688446/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3552&bt=1776&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzM5bDM6Zmx2bDMzZzczNEBpMzM5bDM6Zmx2bDMzZzczNEBnaDMvcjRvcmpgLS1kMS9zYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": 1494336340
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ita-IT",
            "LanguageID": "26",
            "Size": 824,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/11bc5e5e824163872bf0bcacc7e1a1f6/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/e3ea6c5c7c844862a92670c4ba91a0c9/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3552&bt=1776&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzM5bDM6Zmx2bDMzZzczNEBpMzM5bDM6Zmx2bDMzZzczNEBnaDMvcjRvcmpgLS1kMS9zYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": -412571845
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "spa-ES",
            "LanguageID": "9",
            "Size": 867,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/cfa3ca84509587a16fae90e6498bbe4f/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/7d7566dd73ac48529ab73a8fb8a1bffc/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3552&bt=1776&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzM5bDM6Zmx2bDMzZzczNEBpMzM5bDM6Zmx2bDMzZzczNEBnaDMvcjRvcmpgLS1kMS9zYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": -183043060
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "fra-FR",
            "LanguageID": "7",
            "Size": 921,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/3f387d03607dd8525e2f24a6d781746b/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/a3e53c16eef0425bb020bcf33468c875/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3552&bt=1776&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzM5bDM6Zmx2bDMzZzczNEBpMzM5bDM6Zmx2bDMzZzczNEBnaDMvcjRvcmpgLS1kMS9zYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": -185573048
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "por-PT",
            "LanguageID": "8",
            "Size": 836,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/8dc7ecda765395e3f24e59dba9a05705/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/39d459f9ebf143cb9d357d37c3a1ee2f/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3552&bt=1776&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzM5bDM6Zmx2bDMzZzczNEBpMzM5bDM6Zmx2bDMzZzczNEBnaDMvcjRvcmpgLS1kMS9zYSNnaDMvcjRvcmpgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": 1557744855
          }
        ],
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -13.7,
          "Peak": 1
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/443f679b8f644f6f87706f3a32825267_1689112576~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=yeBUagzklqhB6dQvPRL0d0fZkmI%3D",
          "480": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/443f679b8f644f6f87706f3a32825267_1689112576~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=p9NNMnFrfplp%2B7pQxCRKJZwADjI%3D",
          "720": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/443f679b8f644f6f87706f3a32825267_1689112576~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=FEaBsBtHfbKLunvBKYF3gDQkMpw%3D",
          "960": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/443f679b8f644f6f87706f3a32825267_1689112576~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=qnDkXsDIfVAppmHugaLJEnMqg9g%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "7224218912691438638",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Discovering Artists",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAA79sDcMCbl7QatFptF3JKobDAz67Uc0yws24YRf6FRLl8aWSAgSzrw6mu-bd0maiw",
        "secret": false,
        "signature": "\ud83d\udce7: discoveringartists.irl@gmail.com\n\nFollow our Spotify playlist!\ud83d\udc47",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "discoveringartists.irl",
        "verified": false
      },
      "authorStats": {
        "diggCount": 86,
        "followerCount": 515300,
        "followingCount": 13,
        "friendCount": 0,
        "heart": 15800000,
        "heartCount": 15800000,
        "videoCount": 35
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055695396906",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "doyoumakemusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055791882286",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "discoveringartistsirl"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "325757",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "4504990",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "newmusicfriday"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "136517",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musiccurator"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "852361",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "eliottsmith"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "457194",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "sparklehorse"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "17331",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indie"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "496813",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "singersongwriter"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "50864",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musicproducer"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "35857",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indieartist"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "211116",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "area"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "Do you make music?"
        },
        {
          "desc": ""
        },
        {
          "desc": "#doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #eliottsmith #sparklehorse #indie #singersongwriter #musicproducer #indieartist #indiemusician #area",
          "textExtra": [
            {
              "awemeId": "",
              "end": 15,
              "hashtagId": "7252811055695396906",
              "hashtagName": "doyoumakemusic",
              "isCommerce": false,
              "secUid": "",
              "start": 0,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 38,
              "hashtagId": "7252811055791882286",
              "hashtagName": "discoveringartistsirl",
              "isCommerce": false,
              "secUid": "",
              "start": 16,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 56,
              "hashtagId": "325757",
              "hashtagName": "undergroundmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 39,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 72,
              "hashtagId": "4504990",
              "hashtagName": "newmusicfriday",
              "isCommerce": false,
              "secUid": "",
              "start": 57,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 86,
              "hashtagId": "136517",
              "hashtagName": "musiccurator",
              "isCommerce": false,
              "secUid": "",
              "start": 73,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 99,
              "hashtagId": "852361",
              "hashtagName": "eliottsmith",
              "isCommerce": false,
              "secUid": "",
              "start": 87,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 113,
              "hashtagId": "457194",
              "hashtagName": "sparklehorse",
              "isCommerce": false,
              "secUid": "",
              "start": 100,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 120,
              "hashtagId": "17331",
              "hashtagName": "indie",
              "isCommerce": false,
              "secUid": "",
              "start": 114,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 138,
              "hashtagId": "496813",
              "hashtagName": "singersongwriter",
              "isCommerce": false,
              "secUid": "",
              "start": 121,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 153,
              "hashtagId": "50864",
              "hashtagName": "musicproducer",
              "isCommerce": false,
              "secUid": "",
              "start": 139,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 166,
              "hashtagId": "35857",
              "hashtagName": "indieartist",
              "isCommerce": false,
              "secUid": "",
              "start": 154,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 181,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 167,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 187,
              "hashtagId": "211116",
              "hashtagName": "area",
              "isCommerce": false,
              "secUid": "",
              "start": 182,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1693339985,
      "desc": "Do you make music? #doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #eliottsmith #sparklehorse #indie #singersongwriter #musicproducer #indieartist #indiemusician #area",
      "digged": false,
      "diversificationId": 10046,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7272839786816212267",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "",
        "authorName": "Discovering Artists",
        "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "coverMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "coverThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "duration": 28,
        "id": "7272839817937881898",
        "original": true,
        "playUrl": "https://v77.tiktokcdn.com/5acd352347a6ac6b546d629a8b3d3daa/65539997/video/tos/maliva/tos-maliva-ve-27dcd7c799-us/oofFQSApSnCqow0gKleCDJkN3UcgQB17oGbzcD/?a=1233&ch=0&cr=0&dr=0&er=2&cd=0%7C0%7C0%7C0&br=250&bt=125&bti=NDU3ZjAwOg%3D%3D&ft=d2A~l-Inz7TPgL-Ziyq8Z&mime_type=audio_mpeg&qs=6&rc=ZWU7ZWRpaGRlODdmZDQ5ZEBpMztmaDQ6Zm44bTMzZzU8NEBhNDVhYDNeNTMxYmMvYDYtYSMtcC1ecjRnL2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00088000&cc=13&download=true",
        "title": "original sound"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 72600,
        "commentCount": 1906,
        "diggCount": 722700,
        "playCount": 4800000,
        "shareCount": 2021
      },
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 34,
          "hashtagId": "7252811055695396906",
          "hashtagName": "doyoumakemusic",
          "isCommerce": false,
          "secUid": "",
          "start": 19,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 57,
          "hashtagId": "7252811055791882286",
          "hashtagName": "discoveringartistsirl",
          "isCommerce": false,
          "secUid": "",
          "start": 35,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 75,
          "hashtagId": "325757",
          "hashtagName": "undergroundmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 58,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 91,
          "hashtagId": "4504990",
          "hashtagName": "newmusicfriday",
          "isCommerce": false,
          "secUid": "",
          "start": 76,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 105,
          "hashtagId": "136517",
          "hashtagName": "musiccurator",
          "isCommerce": false,
          "secUid": "",
          "start": 92,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 118,
          "hashtagId": "852361",
          "hashtagName": "eliottsmith",
          "isCommerce": false,
          "secUid": "",
          "start": 106,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 132,
          "hashtagId": "457194",
          "hashtagName": "sparklehorse",
          "isCommerce": false,
          "secUid": "",
          "start": 119,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 139,
          "hashtagId": "17331",
          "hashtagName": "indie",
          "isCommerce": false,
          "secUid": "",
          "start": 133,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 157,
          "hashtagId": "496813",
          "hashtagName": "singersongwriter",
          "isCommerce": false,
          "secUid": "",
          "start": 140,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 172,
          "hashtagId": "50864",
          "hashtagName": "musicproducer",
          "isCommerce": false,
          "secUid": "",
          "start": 158,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 185,
          "hashtagId": "35857",
          "hashtagName": "indieartist",
          "isCommerce": false,
          "secUid": "",
          "start": 173,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 200,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 186,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 206,
          "hashtagId": "211116",
          "hashtagName": "area",
          "isCommerce": false,
          "secUid": "",
          "start": 201,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 2245595,
        "bitrateInfo": [
          {
            "Bitrate": 2245595,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 7925549,
              "FileCs": "c:0-24346-c0dd",
              "FileHash": "635767abd80b3eccd21470a98ed265e8",
              "Uri": "v12044gd0000cjn4v4rc77u7qludqc3g",
              "UrlKey": "v12044gd0000cjn4v4rc77u7qludqc3g_h264_540p_2245595",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o4MfecbbA9OigAsn5nQxcypcCKeYleKcKAfoiV/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4384&bt=2192&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ODc7MzZnNzU4ZDs7NDo4ZUBpamZnbzo6Zjc3bTMzZzczNEBjYWEyMS0vNjYxMC4tYTVhYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128824&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=591803e4979ace5fd1de3c218fdf7fd8&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o4MfecbbA9OigAsn5nQxcypcCKeYleKcKAfoiV/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4384&bt=2192&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ODc7MzZnNzU4ZDs7NDo4ZUBpamZnbzo6Zjc3bTMzZzczNEBjYWEyMS0vNjYxMC4tYTVhYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128824&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=591803e4979ace5fd1de3c218fdf7fd8&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 1597820,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 5639306,
              "FileCs": "c:0-24921-c3ce",
              "FileHash": "7b56d479a8c07d293d31cb2e386fa145",
              "Uri": "v12044gd0000cjn4v4rc77u7qludqc3g",
              "UrlKey": "v12044gd0000cjn4v4rc77u7qludqc3g_bytevc1_1080p_1597820",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQBMEFuhRSXtdfPHUgQkuUY7IA1kzHzl5JqDfB/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3120&bt=1560&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=NWk4ZTs5ZDNoN2U1NWdlZUBpamZnbzo6Zjc3bTMzZzczNEBhLzEtLjBiXjIxYTI1XzA1YSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128824&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=b4bb8a9ce7539ba607c9d247493c39b7&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQBMEFuhRSXtdfPHUgQkuUY7IA1kzHzl5JqDfB/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3120&bt=1560&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=NWk4ZTs5ZDNoN2U1NWdlZUBpamZnbzo6Zjc3bTMzZzczNEBhLzEtLjBiXjIxYTI1XzA1YSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128824&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=b4bb8a9ce7539ba607c9d247493c39b7&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 1043855,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 3684156,
              "FileCs": "c:0-24346-77fc",
              "FileHash": "8a7a57db7513a282efa73216a2725659",
              "Uri": "v12044gd0000cjn4v4rc77u7qludqc3g",
              "UrlKey": "v12044gd0000cjn4v4rc77u7qludqc3g_h264_540p_1043855",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oIDgM4hUIDgkJF7tXUfSuB3dHVfzgEA1zBkRQH/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2038&bt=1019&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=NGg8aDxmM2RnNTo6Ojs2Z0BpamZnbzo6Zjc3bTMzZzczNEBiMTM2YGAtXzMxXmMwNTUwYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128824&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=067249b4006f6d5dae77ebe9194c7110&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oIDgM4hUIDgkJF7tXUfSuB3dHVfzgEA1zBkRQH/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2038&bt=1019&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=NGg8aDxmM2RnNTo6Ojs2Z0BpamZnbzo6Zjc3bTMzZzczNEBiMTM2YGAtXzMxXmMwNTUwYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128824&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=067249b4006f6d5dae77ebe9194c7110&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 842128,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 2972189,
              "FileCs": "c:0-24900-f5ea",
              "FileHash": "d87b8fae44553c39746416f6900958c9",
              "Uri": "v12044gd0000cjn4v4rc77u7qludqc3g",
              "UrlKey": "v12044gd0000cjn4v4rc77u7qludqc3g_bytevc1_720p_842128",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/osU0hcxMVfQbfwr5cAfARyceMqnATKuiLncNYf/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1644&bt=822&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=aDhlaGYzNDQ0aTo0OzppZ0BpamZnbzo6Zjc3bTMzZzczNEAyXjU2Yy8wNjMxYzE1MDFjYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128824&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=dad7b823be859907e3d1e389548db679&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/osU0hcxMVfQbfwr5cAfARyceMqnATKuiLncNYf/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1644&bt=822&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=aDhlaGYzNDQ0aTo0OzppZ0BpamZnbzo6Zjc3bTMzZzczNEAyXjU2Yy8wNjMxYzE1MDFjYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128824&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=dad7b823be859907e3d1e389548db679&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 698285,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 2464511,
              "FileCs": "c:0-24900-5b4f",
              "FileHash": "31b93dd7065e2b48f5863f3d5ad9fa42",
              "Uri": "v12044gd0000cjn4v4rc77u7qludqc3g",
              "UrlKey": "v12044gd0000cjn4v4rc77u7qludqc3g_bytevc1_540p_698285",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oM1XIjBkDgWUObRHChbptQMAuJiz7fHfkSdUzE/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1362&bt=681&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=ZjNkPDQ1ZTxkPDo0aDM1aUBpamZnbzo6Zjc3bTMzZzczNEAzLy1iYF8zNi0xY182MC8wYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128824&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=0835761b3792e556a041dd0b5e1e7dbe&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oM1XIjBkDgWUObRHChbptQMAuJiz7fHfkSdUzE/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1362&bt=681&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=ZjNkPDQ1ZTxkPDo0aDM1aUBpamZnbzo6Zjc3bTMzZzczNEAzLy1iYF8zNi0xY182MC8wYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128824&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=0835761b3792e556a041dd0b5e1e7dbe&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 695971,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 2456343,
              "FileCs": "c:0-24346-baac",
              "FileHash": "36be38901d167f05cd155240012826fe",
              "Uri": "v12044gd0000cjn4v4rc77u7qludqc3g",
              "UrlKey": "v12044gd0000cjn4v4rc77u7qludqc3g_h264_540p_695971",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/osk1SfzsRED0GUVJcuhIXfBAUQdgVHCz7ktHM7/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1358&bt=679&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=ZTw1OzNmNTRpZDVlaDtlZ0BpamZnbzo6Zjc3bTMzZzczNEBjYWAxNGJjXzYxLS5eLS8xYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128824&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=73c0b8935da1d4cf62d9486fed72f52d&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/osk1SfzsRED0GUVJcuhIXfBAUQdgVHCz7ktHM7/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1358&bt=679&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=ZTw1OzNmNTRpZDVlaDtlZ0BpamZnbzo6Zjc3bTMzZzczNEBjYWAxNGJjXzYxLS5eLS8xYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128824&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=73c0b8935da1d4cf62d9486fed72f52d&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p19-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/d88ae2f77f1041159f670ecca84a1bea_1693339986?x-expires=1700125200&x-signature=nDJFQgHf%2F8gPzX3j4fhPD2pxyaQ%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o8C6AoY3ue3NqIJgQJDnPrbOojUeAemvHkcT6j/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=3932&bt=1966&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=OThnNmk8aGdnPDo5NWc7NEBpamZnbzo6Zjc3bTMzZzczNEAyMl4yYWMtNjYxLjVfNi5iYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128824&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=3461b29a7d0eaed7e42d8f8c1f2d4508&tk=tt_chain_token",
        "duration": 28,
        "dynamicCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/267fad3a452148d3b3dd40f868ad75af_1693339987?x-expires=1700125200&x-signature=5w2GRICnebs7SdmnygkoehtrW5I%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7272839786816212267",
        "originCover": "https://p19-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/0cd69e2396ea4ed7bcabf2e913578db2_1693339986?x-expires=1700125200&x-signature=78WGAkDzM7%2BkQXAMk2xtKojsctQ%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o4MfecbbA9OigAsn5nQxcypcCKeYleKcKAfoiV/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4384&bt=2192&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ODc7MzZnNzU4ZDs7NDo4ZUBpamZnbzo6Zjc3bTMzZzczNEBjYWEyMS0vNjYxMC4tYTVhYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128824&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=591803e4979ace5fd1de3c218fdf7fd8&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/d88ae2f77f1041159f670ecca84a1bea_1693339986~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=smwaVBMioI8ZE%2BcnnMumdfxJUlM%3D",
        "shareCover": [
          "",
          "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/0cd69e2396ea4ed7bcabf2e913578db2_1693339986~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=AJ9N2Z760CvWeoq50RpZnY5loOY%3D",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/0cd69e2396ea4ed7bcabf2e913578db2_1693339986~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=xA%2FF1jMxPgJmV%2FOKKKOWEtQY2as%3D"
        ],
        "size": 7925549,
        "subtitleInfos": [
          {
            "Format": "webvtt",
            "LanguageCodeName": "ara-SA",
            "LanguageID": "34",
            "Size": 853,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/f6abc922e183ccb099910cd19b5483e3/6555e838/video/tos/maliva/tos-maliva-v-0068c799-us/507c2f2451064050a461d9975cf3fec4/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14046&bt=7023&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=amZnbzo6Zjc3bTMzZzczNEBpamZnbzo6Zjc3bTMzZzczNEAwbnJucjRvc2tgLS1kMS9zYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128824,
            "Version": "4",
            "VideoSubtitleID": 353529889
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "spa-ES",
            "LanguageID": "9",
            "Size": 906,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/8a48fdf0ba05289a94a4c0124e099391/6555e838/video/tos/maliva/tos-maliva-v-0068c799-us/3d48deb2a7ac4c6f9842cef3ac97286d/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14046&bt=7023&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=amZnbzo6Zjc3bTMzZzczNEBpamZnbzo6Zjc3bTMzZzczNEAwbnJucjRvc2tgLS1kMS9zYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128824,
            "Version": "4",
            "VideoSubtitleID": 2043320307
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "kor-KR",
            "LanguageID": "4",
            "Size": 993,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/bc9a598032f526a63978c15ae83b34aa/6555e838/video/tos/maliva/tos-maliva-v-0068c799-us/bd65e4a416d242b58b97cb4864300ce8/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14046&bt=7023&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=amZnbzo6Zjc3bTMzZzczNEBpamZnbzo6Zjc3bTMzZzczNEAwbnJucjRvc2tgLS1kMS9zYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128824,
            "Version": "4",
            "VideoSubtitleID": -970061357
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ind-ID",
            "LanguageID": "23",
            "Size": 816,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/04d379f1a12ffd21b51b0e4ad3d2ccb8/6555e838/video/tos/maliva/tos-maliva-v-0068c799-us/5cee9b6b7eab43bd825cf4b33479d864/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14046&bt=7023&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=amZnbzo6Zjc3bTMzZzczNEBpamZnbzo6Zjc3bTMzZzczNEAwbnJucjRvc2tgLS1kMS9zYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128824,
            "Version": "4",
            "VideoSubtitleID": -60180894
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "fra-FR",
            "LanguageID": "7",
            "Size": 881,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/65901176fc7e2e4abf53fcedbd80ccc6/6555e838/video/tos/maliva/tos-maliva-v-0068c799-us/37af708bc5754bde9781fe812ef9db9e/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14046&bt=7023&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=amZnbzo6Zjc3bTMzZzczNEBpamZnbzo6Zjc3bTMzZzczNEAwbnJucjRvc2tgLS1kMS9zYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128824,
            "Version": "4",
            "VideoSubtitleID": -2053623770
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "eng-US",
            "LanguageID": "2",
            "Size": 652,
            "Source": "ASR",
            "Url": "https://v16-webapp.tiktok.com/c0d720ef7798e0502f44c24ecf6117d8/6555e838/video/tos/maliva/tos-maliva-v-0068c799-us/38a2795b7c394172b37ddfbff301a91e/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14046&bt=7023&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=amZnbzo6Zjc3bTMzZzczNEBpamZnbzo6Zjc3bTMzZzczNEAwbnJucjRvc2tgLS1kMS9zYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128824,
            "Version": "1",
            "VideoSubtitleID": 1640164244
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "vie-VN",
            "LanguageID": "10",
            "Size": 982,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/aae148c7d439ee36cee2b37cedef3d8b/6555e838/video/tos/maliva/tos-maliva-v-0068c799-us/e2a9c8f33dd64758b374750f012365d8/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14046&bt=7023&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=amZnbzo6Zjc3bTMzZzczNEBpamZnbzo6Zjc3bTMzZzczNEAwbnJucjRvc2tgLS1kMS9zYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128824,
            "Version": "4",
            "VideoSubtitleID": -1162545996
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "rus-RU",
            "LanguageID": "6",
            "Size": 1190,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/dee2d4aae3b47039e4cf1a47af76d528/6555e838/video/tos/maliva/tos-maliva-v-0068c799-us/4608a6881f0c48608d699864cdf8d504/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14046&bt=7023&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=amZnbzo6Zjc3bTMzZzczNEBpamZnbzo6Zjc3bTMzZzczNEAwbnJucjRvc2tgLS1kMS9zYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128824,
            "Version": "4",
            "VideoSubtitleID": 1167332238
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "deu-DE",
            "LanguageID": "20",
            "Size": 927,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/762e407d2dd7633b8f4bf5949d5af6bb/6555e838/video/tos/maliva/tos-maliva-v-0068c799-us/71093a72e04c40f0a3c508b099ae862e/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14046&bt=7023&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=amZnbzo6Zjc3bTMzZzczNEBpamZnbzo6Zjc3bTMzZzczNEAwbnJucjRvc2tgLS1kMS9zYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128824,
            "Version": "4",
            "VideoSubtitleID": 179300412
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "cmn-Hans-CN",
            "LanguageID": "1",
            "Size": 843,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/1ddcdde225de32d86dbf376cd10c7d2f/6555e838/video/tos/maliva/tos-maliva-v-0068c799-us/e5f3604ff4e34558b3a392df0e631895/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14046&bt=7023&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=amZnbzo6Zjc3bTMzZzczNEBpamZnbzo6Zjc3bTMzZzczNEAwbnJucjRvc2tgLS1kMS9zYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128824,
            "Version": "4",
            "VideoSubtitleID": 461435340
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "jpn-JP",
            "LanguageID": "3",
            "Size": 1097,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/3d42f21b6db480061c857fbdce24cbf1/6555e838/video/tos/maliva/tos-maliva-v-0068c799-us/1e858421a7f445c08817c935c8507e4b/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14046&bt=7023&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=amZnbzo6Zjc3bTMzZzczNEBpamZnbzo6Zjc3bTMzZzczNEAwbnJucjRvc2tgLS1kMS9zYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128824,
            "Version": "4",
            "VideoSubtitleID": 34186201
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "por-PT",
            "LanguageID": "8",
            "Size": 857,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/ffee594dfdca9c64d049f96c3d5ba3ea/6555e838/video/tos/maliva/tos-maliva-v-0068c799-us/a19b7b12aed14075bdf022646c486f48/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14046&bt=7023&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=amZnbzo6Zjc3bTMzZzczNEBpamZnbzo6Zjc3bTMzZzczNEAwbnJucjRvc2tgLS1kMS9zYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128824,
            "Version": "4",
            "VideoSubtitleID": 1413223276
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ita-IT",
            "LanguageID": "26",
            "Size": 846,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/edf229a30d191b8c8603d94186637ff6/6555e838/video/tos/maliva/tos-maliva-v-0068c799-us/98b11f0ac6b84cabaf8d3f9f55c6cdc4/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14046&bt=7023&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=amZnbzo6Zjc3bTMzZzczNEBpamZnbzo6Zjc3bTMzZzczNEAwbnJucjRvc2tgLS1kMS9zYSMwbnJucjRvc2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128824,
            "Version": "4",
            "VideoSubtitleID": -441540575
          }
        ],
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -12.7,
          "Peak": 1
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/d88ae2f77f1041159f670ecca84a1bea_1693339986~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=dQFzt6xyUnSoTGFT4yxSG%2FFB1RY%3D",
          "480": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/d88ae2f77f1041159f670ecca84a1bea_1693339986~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=uCCSt8BvW%2BxcEbJw3pWBWiRqlcQ%3D",
          "720": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/d88ae2f77f1041159f670ecca84a1bea_1693339986~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=AhzV9ptk7cAzhSQXDGnclxHexNg%3D",
          "960": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/d88ae2f77f1041159f670ecca84a1bea_1693339986~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=A8R8jmVH442LFnastZTLOmMLZGc%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/musically-maliva-obj/1663893277781014~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=%2BHBGV8cau%2BL2etSRQxMGfLhkV50%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/musically-maliva-obj/1663893277781014~c5_720x720.jpeg?x-expires=1700125200&x-signature=pIVMmod%2BvvHi%2FbI0qn%2FsndIlb3c%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/musically-maliva-obj/1663893277781014~c5_100x100.jpeg?x-expires=1700125200&x-signature=ljZqXtfz0wEiBE4d%2BKxIl6C8%2Bm0%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "6754672413755474950",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "lee_cleaveland",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAAQ00O_nRmNFjo2HE_nEiYdc_qewnRiQRK5kl94hLJmI__h3GZt8P5qkK73au8_KGf",
        "secret": false,
        "signature": "Lee Cleaveland & The Lefthand Band - new live EP out now! tickets & streaming \u2935\ufe0f",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "lee_cleaveland",
        "verified": false
      },
      "authorStats": {
        "diggCount": 22200,
        "followerCount": 25300,
        "followingCount": 1563,
        "friendCount": 0,
        "heart": 1500000,
        "heartCount": 1500000,
        "videoCount": 133
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7071743787176820742",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "maryalexg"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7157140274102140971",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "maryalexgcover"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "229207",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "fyp"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "Whether you're playing sold-out stadiums or just learning a few chords, here's to the entire #GuitarTok community.",
          "id": "1652355189616642",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/0c5ef8e23e56cc0e4930aa42ab9c67e7",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/0c5ef8e23e56cc0e4930aa42ab9c67e7",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/0c5ef8e23e56cc0e4930aa42ab9c67e7",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "guitartok"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "72508507",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "sandyalexg"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7172658463876022277",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "maryalexgguitar"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "748933",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "guitarcover"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1908664",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "fender"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "Love #IndieMusic? Start here.",
          "id": "247262",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/164263ca9942461c79d1ac0ee9fa0b21.jpeg",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/164263ca9942461c79d1ac0ee9fa0b21.jpeg",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/164263ca9942461c79d1ac0ee9fa0b21.jpeg",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "446335",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "electricguitar"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "9054071",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "amputee"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1660296817155078",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "disabilitytiktok"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1644973220213766",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "slackerrock"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "4602873",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "lofi"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "6500",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "alternative"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "6783",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "macdemarco"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "29969082",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "bedroompop"
        },
        {
          "coverLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/70747c46515eb513d687023785dfb463.png",
          "coverMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/70747c46515eb513d687023785dfb463.png",
          "coverThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/70747c46515eb513d687023785dfb463.png",
          "desc": "Sing or share your fav, Jazz songs.\ud83c\udfb5",
          "id": "9040",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/056ce27e9c70bcf195519482f4469d83.png",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/056ce27e9c70bcf195519482f4469d83.png",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/056ce27e9c70bcf195519482f4469d83.png",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "jazz"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "48690708",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "fendermustang"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7194466457362071553",
          "isCommerce": true,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "DanceWithTurboTax"
        },
        {
          "coverLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/e764a5162d8d56f7954b74aee8722ca2.jpeg",
          "coverMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/e764a5162d8d56f7954b74aee8722ca2.jpeg",
          "coverThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/e764a5162d8d56f7954b74aee8722ca2.jpeg",
          "desc": "Welcome to the central pit for the best content and artists across the heavier side of music! \ud83c\udfb8",
          "id": "13187",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/c25565488e2ec0bfcf7b414d3d8f71c6.png",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/c25565488e2ec0bfcf7b414d3d8f71c6.png",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/c25565488e2ec0bfcf7b414d3d8f71c6.png",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "rock"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "69596378",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "guitartutorial"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1608125691213829",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "horsesoftiktok"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "32007",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "savetheanimals"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7099272933734318086",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "godsavetheanimals"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "288846",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "beachmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "14841",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "rocket"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "5332",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "trick"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "34534",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "germanshepherd"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1627697747948550",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "horsetok"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "Replying to @omgcairo  neigh, i cant #maryalexg #maryalexgcover #fyp #guitartok #sandyalexg #maryalexgguitar #guitarcover #fender #indiemusic #electricguitar #indiemusician #amputee #disabilitytiktok #slackerrock #lofi #alternative #macdemarco #bedroompop #jazz #fendermustang #DanceWithTurboTax #rock #guitartutorial #horsesoftiktok #savetheanimals #godsavetheanimals #beachmusic #rocket #trick #germanshepherd #horsetok ",
          "textExtra": [
            {
              "awemeId": "",
              "end": 21,
              "hashtagId": "",
              "hashtagName": "",
              "isCommerce": false,
              "secUid": "MS4wLjABAAAA2myg_VukjiDy8xvv6BvZAdiFrvHzHtaKw4YEH_NkoCf7dx8GxW813gAQ6SggXMgE",
              "start": 12,
              "subType": 2,
              "type": 0,
              "userId": "7040518716895233030",
              "userUniqueId": "lacymercuryenthusiast"
            },
            {
              "awemeId": "",
              "end": 47,
              "hashtagId": "7071743787176820742",
              "hashtagName": "maryalexg",
              "isCommerce": false,
              "secUid": "",
              "start": 37,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 63,
              "hashtagId": "7157140274102140971",
              "hashtagName": "maryalexgcover",
              "isCommerce": false,
              "secUid": "",
              "start": 48,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 68,
              "hashtagId": "229207",
              "hashtagName": "fyp",
              "isCommerce": false,
              "secUid": "",
              "start": 64,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 79,
              "hashtagId": "1652355189616642",
              "hashtagName": "guitartok",
              "isCommerce": false,
              "secUid": "",
              "start": 69,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 91,
              "hashtagId": "72508507",
              "hashtagName": "sandyalexg",
              "isCommerce": false,
              "secUid": "",
              "start": 80,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 108,
              "hashtagId": "7172658463876022277",
              "hashtagName": "maryalexgguitar",
              "isCommerce": false,
              "secUid": "",
              "start": 92,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 121,
              "hashtagId": "748933",
              "hashtagName": "guitarcover",
              "isCommerce": false,
              "secUid": "",
              "start": 109,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 129,
              "hashtagId": "1908664",
              "hashtagName": "fender",
              "isCommerce": false,
              "secUid": "",
              "start": 122,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 141,
              "hashtagId": "247262",
              "hashtagName": "indiemusic",
              "isCommerce": false,
              "secUid": "",
              "start": 130,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 157,
              "hashtagId": "446335",
              "hashtagName": "electricguitar",
              "isCommerce": false,
              "secUid": "",
              "start": 142,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 172,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 158,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 181,
              "hashtagId": "9054071",
              "hashtagName": "amputee",
              "isCommerce": false,
              "secUid": "",
              "start": 173,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 199,
              "hashtagId": "1660296817155078",
              "hashtagName": "disabilitytiktok",
              "isCommerce": false,
              "secUid": "",
              "start": 182,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 212,
              "hashtagId": "1644973220213766",
              "hashtagName": "slackerrock",
              "isCommerce": false,
              "secUid": "",
              "start": 200,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 218,
              "hashtagId": "4602873",
              "hashtagName": "lofi",
              "isCommerce": false,
              "secUid": "",
              "start": 213,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 231,
              "hashtagId": "6500",
              "hashtagName": "alternative",
              "isCommerce": false,
              "secUid": "",
              "start": 219,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 243,
              "hashtagId": "6783",
              "hashtagName": "macdemarco",
              "isCommerce": false,
              "secUid": "",
              "start": 232,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 255,
              "hashtagId": "29969082",
              "hashtagName": "bedroompop",
              "isCommerce": false,
              "secUid": "",
              "start": 244,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 261,
              "hashtagId": "9040",
              "hashtagName": "jazz",
              "isCommerce": false,
              "secUid": "",
              "start": 256,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 276,
              "hashtagId": "48690708",
              "hashtagName": "fendermustang",
              "isCommerce": false,
              "secUid": "",
              "start": 262,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 295,
              "hashtagId": "",
              "hashtagName": "dancewithturbotax",
              "isCommerce": false,
              "secUid": "",
              "start": 277,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 301,
              "hashtagId": "13187",
              "hashtagName": "rock",
              "isCommerce": false,
              "secUid": "",
              "start": 296,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 317,
              "hashtagId": "69596378",
              "hashtagName": "guitartutorial",
              "isCommerce": false,
              "secUid": "",
              "start": 302,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 333,
              "hashtagId": "1608125691213829",
              "hashtagName": "horsesoftiktok",
              "isCommerce": false,
              "secUid": "",
              "start": 318,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 349,
              "hashtagId": "32007",
              "hashtagName": "savetheanimals",
              "isCommerce": false,
              "secUid": "",
              "start": 334,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 368,
              "hashtagId": "7099272933734318086",
              "hashtagName": "godsavetheanimals",
              "isCommerce": false,
              "secUid": "",
              "start": 350,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 380,
              "hashtagId": "288846",
              "hashtagName": "beachmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 369,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 388,
              "hashtagId": "14841",
              "hashtagName": "rocket",
              "isCommerce": false,
              "secUid": "",
              "start": 381,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 395,
              "hashtagId": "5332",
              "hashtagName": "trick",
              "isCommerce": false,
              "secUid": "",
              "start": 389,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 411,
              "hashtagId": "34534",
              "hashtagName": "germanshepherd",
              "isCommerce": false,
              "secUid": "",
              "start": 396,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 421,
              "hashtagId": "1627697747948550",
              "hashtagName": "horsetok",
              "isCommerce": false,
              "secUid": "",
              "start": 412,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1676344244,
      "desc": "Replying to @omgcairo  neigh, i cant #maryalexg #maryalexgcover #fyp #guitartok #sandyalexg #maryalexgguitar #guitarcover #fender #indiemusic #electricguitar #indiemusician #amputee #disabilitytiktok #slackerrock #lofi #alternative #macdemarco #bedroompop #jazz #fendermustang #DanceWithTurboTax #rock #guitartutorial #horsesoftiktok #savetheanimals #godsavetheanimals #beachmusic #rocket #trick #germanshepherd #horsetok ",
      "digged": false,
      "diversificationId": 10009,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7199843647955946798",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "",
        "authorName": "lee_cleaveland",
        "coverLarge": "https://p16-sign-va.tiktokcdn.com/musically-maliva-obj/1663893277781014~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=%2BHBGV8cau%2BL2etSRQxMGfLhkV50%3D",
        "coverMedium": "https://p16-sign-va.tiktokcdn.com/musically-maliva-obj/1663893277781014~c5_720x720.jpeg?x-expires=1700125200&x-signature=pIVMmod%2BvvHi%2FbI0qn%2FsndIlb3c%3D",
        "coverThumb": "https://p16-sign-va.tiktokcdn.com/musically-maliva-obj/1663893277781014~c5_100x100.jpeg?x-expires=1700125200&x-signature=ljZqXtfz0wEiBE4d%2BKxIl6C8%2Bm0%3D",
        "duration": 25,
        "id": "7199843676738308910",
        "original": true,
        "playUrl": "https://v16m.tiktokcdn.com/a49d1c7b01c02f610d33eb17486c7c19/65539994/video/tos/maliva/tos-maliva-v-27dcd7c799-us/327730e095204a448a095dc18eb6af13/?a=1233&ch=0&cr=0&dr=0&er=2&cd=0%7C0%7C0%7C0&br=250&bt=125&bti=NDU3ZjAwOg%3D%3D&ft=d2A~l-Inz7TPgL-Ziyq8Z&mime_type=audio_mpeg&qs=6&rc=ZTg5NWk3OTo3Z2YzaDo7M0BpMzNlbWk6ZmdpaTMzZzU8NEBeL2IvY2JeXjIxMDYzMDJeYSNwNmswcjRfa2lgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00088000&cc=3&download=true",
        "title": "original sound"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "7257382391063251754",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 64800,
        "commentCount": 7549,
        "diggCount": 691900,
        "playCount": 5500000,
        "shareCount": 4432
      },
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 21,
          "hashtagId": "",
          "hashtagName": "",
          "isCommerce": false,
          "secUid": "MS4wLjABAAAA2myg_VukjiDy8xvv6BvZAdiFrvHzHtaKw4YEH_NkoCf7dx8GxW813gAQ6SggXMgE",
          "start": 12,
          "subType": 2,
          "type": 0,
          "userId": "7040518716895233030",
          "userUniqueId": "lacymercuryenthusiast"
        },
        {
          "awemeId": "",
          "end": 47,
          "hashtagId": "7071743787176820742",
          "hashtagName": "maryalexg",
          "isCommerce": false,
          "secUid": "",
          "start": 37,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 63,
          "hashtagId": "7157140274102140971",
          "hashtagName": "maryalexgcover",
          "isCommerce": false,
          "secUid": "",
          "start": 48,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 68,
          "hashtagId": "229207",
          "hashtagName": "fyp",
          "isCommerce": false,
          "secUid": "",
          "start": 64,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 79,
          "hashtagId": "1652355189616642",
          "hashtagName": "guitartok",
          "isCommerce": false,
          "secUid": "",
          "start": 69,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 91,
          "hashtagId": "72508507",
          "hashtagName": "sandyalexg",
          "isCommerce": false,
          "secUid": "",
          "start": 80,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 108,
          "hashtagId": "7172658463876022277",
          "hashtagName": "maryalexgguitar",
          "isCommerce": false,
          "secUid": "",
          "start": 92,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 121,
          "hashtagId": "748933",
          "hashtagName": "guitarcover",
          "isCommerce": false,
          "secUid": "",
          "start": 109,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 129,
          "hashtagId": "1908664",
          "hashtagName": "fender",
          "isCommerce": false,
          "secUid": "",
          "start": 122,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 141,
          "hashtagId": "247262",
          "hashtagName": "indiemusic",
          "isCommerce": false,
          "secUid": "",
          "start": 130,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 157,
          "hashtagId": "446335",
          "hashtagName": "electricguitar",
          "isCommerce": false,
          "secUid": "",
          "start": 142,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 172,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 158,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 181,
          "hashtagId": "9054071",
          "hashtagName": "amputee",
          "isCommerce": false,
          "secUid": "",
          "start": 173,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 199,
          "hashtagId": "1660296817155078",
          "hashtagName": "disabilitytiktok",
          "isCommerce": false,
          "secUid": "",
          "start": 182,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 212,
          "hashtagId": "1644973220213766",
          "hashtagName": "slackerrock",
          "isCommerce": false,
          "secUid": "",
          "start": 200,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 218,
          "hashtagId": "4602873",
          "hashtagName": "lofi",
          "isCommerce": false,
          "secUid": "",
          "start": 213,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 231,
          "hashtagId": "6500",
          "hashtagName": "alternative",
          "isCommerce": false,
          "secUid": "",
          "start": 219,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 243,
          "hashtagId": "6783",
          "hashtagName": "macdemarco",
          "isCommerce": false,
          "secUid": "",
          "start": 232,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 255,
          "hashtagId": "29969082",
          "hashtagName": "bedroompop",
          "isCommerce": false,
          "secUid": "",
          "start": 244,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 261,
          "hashtagId": "9040",
          "hashtagName": "jazz",
          "isCommerce": false,
          "secUid": "",
          "start": 256,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 276,
          "hashtagId": "48690708",
          "hashtagName": "fendermustang",
          "isCommerce": false,
          "secUid": "",
          "start": 262,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 295,
          "hashtagId": "",
          "hashtagName": "dancewithturbotax",
          "isCommerce": false,
          "secUid": "",
          "start": 277,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 301,
          "hashtagId": "13187",
          "hashtagName": "rock",
          "isCommerce": false,
          "secUid": "",
          "start": 296,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 317,
          "hashtagId": "69596378",
          "hashtagName": "guitartutorial",
          "isCommerce": false,
          "secUid": "",
          "start": 302,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 333,
          "hashtagId": "1608125691213829",
          "hashtagName": "horsesoftiktok",
          "isCommerce": false,
          "secUid": "",
          "start": 318,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 349,
          "hashtagId": "32007",
          "hashtagName": "savetheanimals",
          "isCommerce": false,
          "secUid": "",
          "start": 334,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 368,
          "hashtagId": "7099272933734318086",
          "hashtagName": "godsavetheanimals",
          "isCommerce": false,
          "secUid": "",
          "start": 350,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 380,
          "hashtagId": "288846",
          "hashtagName": "beachmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 369,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 388,
          "hashtagId": "14841",
          "hashtagName": "rocket",
          "isCommerce": false,
          "secUid": "",
          "start": 381,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 395,
          "hashtagId": "5332",
          "hashtagName": "trick",
          "isCommerce": false,
          "secUid": "",
          "start": 389,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 411,
          "hashtagId": "34534",
          "hashtagName": "germanshepherd",
          "isCommerce": false,
          "secUid": "",
          "start": 396,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 421,
          "hashtagId": "1627697747948550",
          "hashtagName": "horsetok",
          "isCommerce": false,
          "secUid": "",
          "start": 412,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 1083942,
        "bitrateInfo": [
          {
            "Bitrate": 1238548,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 3963355,
              "FileCs": "c:0-22681-3caa",
              "FileHash": "b86dd7440cda507005aedf1fb6e5ab0d",
              "Uri": "v12044gd0000cflfga3c77u4vs0j7kl0",
              "UrlKey": "v12044gd0000cflfga3c77u4vs0j7kl0_bytevc1_1080p_1238548",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ec07044a6fb443b09876e27e3f33020f/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2418&bt=1209&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=Nmg6ZWQ7OzxoOjo8ZWZoaUBpM25tdjc6ZmRpaTMzZzczNEBjLjUuYGAvNS0xNTItXjQyYSNpNC1zcjQwZGlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128821&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=61f8b631211b4633553f9cc11ba1cd37&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ec07044a6fb443b09876e27e3f33020f/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2418&bt=1209&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=Nmg6ZWQ7OzxoOjo8ZWZoaUBpM25tdjc6ZmRpaTMzZzczNEBjLjUuYGAvNS0xNTItXjQyYSNpNC1zcjQwZGlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128821&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=61f8b631211b4633553f9cc11ba1cd37&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 1083942,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 3488806,
              "FileCs": "c:0-22422-7960",
              "FileHash": "0cda6d8e7a2cc300fdc118b4d8467556",
              "Uri": "v12044gd0000cflfga3c77u4vs0j7kl0",
              "UrlKey": "v12044gd0000cflfga3c77u4vs0j7kl0_h264_540p_1083942",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/5381f92ce81f4c6b94af803da94eeded/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2116&bt=1058&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NGdkPDQ6ZjpoaTlmZTM1NkBpM25tdjc6ZmRpaTMzZzczNEAwMGMvNC9gX2MxLmMxMzYwYSNpNC1zcjQwZGlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128821&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=7cfe0e7a7f25e636f1e4c6430f74bd65&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/5381f92ce81f4c6b94af803da94eeded/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2116&bt=1058&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NGdkPDQ6ZjpoaTlmZTM1NkBpM25tdjc6ZmRpaTMzZzczNEAwMGMvNC9gX2MxLmMxMzYwYSNpNC1zcjQwZGlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128821&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=7cfe0e7a7f25e636f1e4c6430f74bd65&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 772684,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 2472589,
              "FileCs": "c:0-22682-929e",
              "FileHash": "bdf761a3fcc35d661bba56a6d27027fe",
              "Uri": "v12044gd0000cflfga3c77u4vs0j7kl0",
              "UrlKey": "v12044gd0000cflfga3c77u4vs0j7kl0_bytevc1_720p_772684",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/eb678d230fa643b4bbaaf796ef4267cc/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1508&bt=754&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=OWhpaTM7N2c1ZGdpOmQ1OUBpM25tdjc6ZmRpaTMzZzczNEAwLjYyXjI2X2IxNDFjY19eYSNpNC1zcjQwZGlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128821&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=1c66bb0336fb704bc04a5be247f890ad&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/eb678d230fa643b4bbaaf796ef4267cc/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1508&bt=754&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=OWhpaTM7N2c1ZGdpOmQ1OUBpM25tdjc6ZmRpaTMzZzczNEAwLjYyXjI2X2IxNDFjY19eYSNpNC1zcjQwZGlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128821&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=1c66bb0336fb704bc04a5be247f890ad&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 633992,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 2028777,
              "FileCs": "c:0-22682-c3ac",
              "FileHash": "4cae07328ef2bfa490fdb2638d8fd379",
              "Uri": "v12044gd0000cflfga3c77u4vs0j7kl0",
              "UrlKey": "v12044gd0000cflfga3c77u4vs0j7kl0_bytevc1_540p_633992",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/d5e45475dda24ecf9e109b7469bbbf05/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1238&bt=619&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=NWU3NWY5NzU8aTtmM2Q7M0BpM25tdjc6ZmRpaTMzZzczNEAtNV40NTMwNTExLi4zXy4xYSNpNC1zcjQwZGlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128821&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=94793ae4b2f214b5f0460c3fd99208a5&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/d5e45475dda24ecf9e109b7469bbbf05/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1238&bt=619&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=NWU3NWY5NzU8aTtmM2Q7M0BpM25tdjc6ZmRpaTMzZzczNEAtNV40NTMwNTExLi4zXy4xYSNpNC1zcjQwZGlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128821&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=94793ae4b2f214b5f0460c3fd99208a5&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 543504,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 1749338,
              "FileCs": "c:0-22422-5b6b",
              "FileHash": "c4cd57763f0d20c6b25e6e02c23e2c52",
              "Uri": "v12044gd0000cflfga3c77u4vs0j7kl0",
              "UrlKey": "v12044gd0000cflfga3c77u4vs0j7kl0_h264_540p_543504",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/f4391942fa344b0e86c88f9d5d4c1897/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1060&bt=530&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=NjlpNTQ0ZWQ6O2c1PGk0NkBpM25tdjc6ZmRpaTMzZzczNEBeY19hYjMyX2MxMi0uXl8wYSNpNC1zcjQwZGlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128821&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=aa3e83a1d2f2dc4ce9f6d5343e5f8a6d&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/f4391942fa344b0e86c88f9d5d4c1897/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1060&bt=530&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=NjlpNTQ0ZWQ6O2c1PGk0NkBpM25tdjc6ZmRpaTMzZzczNEBeY19hYjMyX2MxMi0uXl8wYSNpNC1zcjQwZGlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128821&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=aa3e83a1d2f2dc4ce9f6d5343e5f8a6d&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 386096,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 1242699,
              "FileCs": "c:0-22422-b9be",
              "FileHash": "fb85b4bccd45d1234771d44d132f976a",
              "Uri": "v12044gd0000cflfga3c77u4vs0j7kl0",
              "UrlKey": "v12044gd0000cflfga3c77u4vs0j7kl0_h264_540p_386096",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/29fc9bf22ff34a0c875f9047d52adb6f/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=754&bt=377&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=OWgzO2VoOjgzM2Q7OjQ2O0BpM25tdjc6ZmRpaTMzZzczNEAwNl8wYmIyX2MxYmM0MTAwYSNpNC1zcjQwZGlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128821&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=e7bf020c984e4b569d3093e5a48cea7f&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/29fc9bf22ff34a0c875f9047d52adb6f/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=754&bt=377&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=OWgzO2VoOjgzM2Q7OjQ2O0BpM25tdjc6ZmRpaTMzZzczNEAwNl8wYmIyX2MxYmM0MTAwYSNpNC1zcjQwZGlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128821&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=e7bf020c984e4b569d3093e5a48cea7f&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/74d8d9149da64a4194cea02e89c026ec?x-expires=1700125200&x-signature=Ub1EySsuO%2FMgbWT%2FcGSFjYBOVMk%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ac833bd12ade40b8a2e57796ea1c1139/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=2212&bt=1106&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aTg6ZGY8NjM5Mzg8Ozc1NkBpM25tdjc6ZmRpaTMzZzczNEBhNmMvYGE0NV4xYWMvLjJfYSNpNC1zcjQwZGlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128821&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=225fc5da2dba60c5f7c002f4a458a541&tk=tt_chain_token",
        "duration": 25,
        "dynamicCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/7f952756a55c448799b662b7794bdbd7_1676344246?x-expires=1700125200&x-signature=omXvkrz1A%2BHeYxkw4lz5qzyIoMs%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7199843647955946798",
        "originCover": "https://p19-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/3d0268de573b41f1865764c6e6c21337_1676344245?x-expires=1700125200&x-signature=B%2BSqmGe0cYIJgn9dEOTKxiMKmqY%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/5381f92ce81f4c6b94af803da94eeded/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2116&bt=1058&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NGdkPDQ6ZjpoaTlmZTM1NkBpM25tdjc6ZmRpaTMzZzczNEAwMGMvNC9gX2MxLmMxMzYwYSNpNC1zcjQwZGlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128821&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=7cfe0e7a7f25e636f1e4c6430f74bd65&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/74d8d9149da64a4194cea02e89c026ec~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=iKFtHTqO%2Bm9kziPT9owTP9XgIOY%3D",
        "shareCover": [
          "",
          "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/3d0268de573b41f1865764c6e6c21337_1676344245~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=yzjp2rXJYoYyaTuhu3EGJ7Fe%2B98%3D",
          "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/3d0268de573b41f1865764c6e6c21337_1676344245~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=mAmS%2Fx0lDkBzLJg1X8bvpbImEKQ%3D"
        ],
        "size": 3488806,
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -15.6,
          "Peak": 0.93325
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/74d8d9149da64a4194cea02e89c026ec~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=9eNVKVGB%2BTQ2Ru%2F99HQi5ViRWLE%3D",
          "480": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/74d8d9149da64a4194cea02e89c026ec~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=psNeHFYONXvsUwIwpdNRl78OCyQ%3D",
          "720": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/74d8d9149da64a4194cea02e89c026ec~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=KPF16kZLycgQb0C3WLrQy1UY5kc%3D",
          "960": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/74d8d9149da64a4194cea02e89c026ec~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=0pXuzaEiu%2By%2Ff3scUCnKqIpC%2BQc%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/cbaf602b28e3afaca92d954bc9be9e6a~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=YRxkcH6SsGZtGkW4Jp5EcH5X4aQ%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/cbaf602b28e3afaca92d954bc9be9e6a~c5_720x720.jpeg?x-expires=1700125200&x-signature=nTDNDUki768HNCPdRiBCiYbzCSg%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/cbaf602b28e3afaca92d954bc9be9e6a~c5_100x100.jpeg?x-expires=1700125200&x-signature=SMetBLxbTJm0o1chxws51WFXYbQ%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "6922224332093998086",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "cloudkissed",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAAKxrkVyhFRb7zahuYtgpy-SbFIPQY8XJOJ7cOB-vAe7XIw-DbnNfaHJd7WK3Tgq3i",
        "secret": false,
        "signature": "soft songs for sad ppl\nflight ukulele partner\n\u203c\ufe0fOBLIVION SONG OUT NOW\u203c\ufe0f",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "cloudkissedmusic",
        "verified": false
      },
      "authorStats": {
        "diggCount": 52500,
        "followerCount": 407800,
        "followingCount": 672,
        "friendCount": 0,
        "heart": 5200000,
        "heartCount": 5200000,
        "videoCount": 316
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1661178135579654",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "astheworldcavesin"
        },
        {
          "coverLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/95dce3164582d8f6f960128e0fa2acb5",
          "coverMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/95dce3164582d8f6f960128e0fa2acb5",
          "coverThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/95dce3164582d8f6f960128e0fa2acb5",
          "desc": "TikTok has new #VoiceEffects, made just for you! Record your video, hit the checkmark and change your voice with new presets, whether you want to sound like a chipmunk or love the autotune life.",
          "id": "1361074",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/c58edc827be2fd0ebe5cb011764b3f35",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/c58edc827be2fd0ebe5cb011764b3f35",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/c58edc827be2fd0ebe5cb011764b3f35",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "voiceeffects"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "42164",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "foryou"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "88764338",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "foryoupage"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        },
        {
          "coverLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/172dc8f1a7667e25a2f31c06d8f5c5d9",
          "coverMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/172dc8f1a7667e25a2f31c06d8f5c5d9",
          "coverThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/172dc8f1a7667e25a2f31c06d8f5c5d9",
          "desc": "Flower prints, knitting, plants and mushrooms. Show us your #Cottagecore life.",
          "id": "1615695775731718",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/815513cf65a2df18a00c5bd19d3fc58e",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/815513cf65a2df18a00c5bd19d3fc58e",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/815513cf65a2df18a00c5bd19d3fc58e",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "cottagecore"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1638530602820614",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "fairycore"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "anyways #astheworldcavesin #voiceeffects #foryou #foryoupage #indiemusician #cottagecore #fairycore",
          "textExtra": [
            {
              "awemeId": "",
              "end": 26,
              "hashtagId": "1661178135579654",
              "hashtagName": "astheworldcavesin",
              "isCommerce": false,
              "secUid": "",
              "start": 8,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 40,
              "hashtagId": "1361074",
              "hashtagName": "voiceeffects",
              "isCommerce": false,
              "secUid": "",
              "start": 27,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 48,
              "hashtagId": "42164",
              "hashtagName": "foryou",
              "isCommerce": false,
              "secUid": "",
              "start": 41,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 60,
              "hashtagId": "88764338",
              "hashtagName": "foryoupage",
              "isCommerce": false,
              "secUid": "",
              "start": 49,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 75,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 61,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 88,
              "hashtagId": "1615695775731718",
              "hashtagName": "cottagecore",
              "isCommerce": false,
              "secUid": "",
              "start": 76,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 99,
              "hashtagId": "1638530602820614",
              "hashtagName": "fairycore",
              "isCommerce": false,
              "secUid": "",
              "start": 89,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1623350440,
      "desc": "anyways #astheworldcavesin #voiceeffects #foryou #foryoupage #indiemusician #cottagecore #fairycore",
      "digged": false,
      "diversificationId": 10009,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "6972237036317052166",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "",
        "authorName": "cloudkissed",
        "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/cbaf602b28e3afaca92d954bc9be9e6a~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=YRxkcH6SsGZtGkW4Jp5EcH5X4aQ%3D",
        "coverMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/cbaf602b28e3afaca92d954bc9be9e6a~c5_720x720.jpeg?x-expires=1700125200&x-signature=nTDNDUki768HNCPdRiBCiYbzCSg%3D",
        "coverThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/cbaf602b28e3afaca92d954bc9be9e6a~c5_100x100.jpeg?x-expires=1700125200&x-signature=SMetBLxbTJm0o1chxws51WFXYbQ%3D",
        "duration": 47,
        "id": "6972237435644201733",
        "original": true,
        "playUrl": "https://v16m.tiktokcdn.com/4851d58a178bb6b74d28109ab031f980/655399aa/video/tos/useast2a/tos-useast2a-v-27dcd7/ef992228bf30479da357514d48230a4f/?a=1233&ch=0&cr=0&dr=0&er=2&cd=0%7C0%7C0%7C0&br=250&bt=125&bti=NDU3ZjAwOg%3D%3D&ft=d2A~l-Inz7TPgL-Ziyq8Z&mime_type=audio_mpeg&qs=6&rc=ZDNnaWdnMzhlNjNoOmU7ZUBpanZseXBrcWQ4NjMzNzU8M0A0YGM1YF5gNTMxNF82XjQxYSMyZGBrMzVzbS5gLS1kMTZzcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00088000&cc=3&download=true",
        "title": "original sound"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "6976056870066801414",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 76200,
        "commentCount": 7668,
        "diggCount": 662100,
        "playCount": 2500000,
        "shareCount": 7549
      },
      "stickersOnItem": [
        {
          "stickerText": [
            "As the earth burns to the ground",
            "Oh love, it\u2019s you",
            "That I lie with",
            "As the atom bomb locks in ",
            "Oh love, its you I watch TV with ",
            "As the world caves in "
          ],
          "stickerType": 4
        }
      ],
      "stitchDisplay": 0,
      "stitchEnabled": false,
      "textExtra": [
        {
          "awemeId": "",
          "end": 26,
          "hashtagId": "1661178135579654",
          "hashtagName": "astheworldcavesin",
          "isCommerce": false,
          "secUid": "",
          "start": 8,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 40,
          "hashtagId": "1361074",
          "hashtagName": "voiceeffects",
          "isCommerce": false,
          "secUid": "",
          "start": 27,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 48,
          "hashtagId": "42164",
          "hashtagName": "foryou",
          "isCommerce": false,
          "secUid": "",
          "start": 41,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 60,
          "hashtagId": "88764338",
          "hashtagName": "foryoupage",
          "isCommerce": false,
          "secUid": "",
          "start": 49,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 75,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 61,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 88,
          "hashtagId": "1615695775731718",
          "hashtagName": "cottagecore",
          "isCommerce": false,
          "secUid": "",
          "start": 76,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 99,
          "hashtagId": "1638530602820614",
          "hashtagName": "fairycore",
          "isCommerce": false,
          "secUid": "",
          "start": 89,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 1250901,
        "bitrateInfo": [
          {
            "Bitrate": 1250901,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 7489775,
              "FileCs": "c:0-40557-dc47",
              "FileHash": "4384d82cadb7f107d137ee74371619cf",
              "Uri": "v09044g40000c315osvk0vv163a7udfg",
              "UrlKey": "v09044g40000c315osvk0vv163a7udfg_h264_540p_1250901",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-pve-0068/35dd962e259c4be3bc0e941d97ac0f93/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2442&bt=1221&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NGU6NTQ5OjxmaDU4NTtlO0Bpamc6NjR5bnY4NjMzNzczM0BjYS4xXjQtNS8xMTZgNWEuYSNjcl4zcy1zbC5gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128843&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=65cfafe4388b5a338fc579616b012ba1&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-pve-0068/35dd962e259c4be3bc0e941d97ac0f93/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2442&bt=1221&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NGU6NTQ5OjxmaDU4NTtlO0Bpamc6NjR5bnY4NjMzNzczM0BjYS4xXjQtNS8xMTZgNWEuYSNjcl4zcy1zbC5gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128843&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=65cfafe4388b5a338fc579616b012ba1&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 1079698,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 6464693,
              "FileCs": "c:0-40760-c4ce",
              "FileHash": "e39b4b507ebbdc62423a107072f3bc59",
              "Uri": "v09044g40000c315osvk0vv163a7udfg",
              "UrlKey": "v09044g40000c315osvk0vv163a7udfg_bytevc1_720p_1079698",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-pve-0068/f3b69e21abe7468bb8d55250800b129f/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2108&bt=1054&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=OzQ5Ojs6Zzc7Zzo4OzpoZUBpamc6NjR5bnY4NjMzNzczM0AuLzE1Ni5hXzMxMi0zLTAwYSNjcl4zcy1zbC5gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128843&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=a7695c1121c99e7616738f3f9a348ab5&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-pve-0068/f3b69e21abe7468bb8d55250800b129f/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2108&bt=1054&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=OzQ5Ojs6Zzc7Zzo4OzpoZUBpamc6NjR5bnY4NjMzNzczM0AuLzE1Ni5hXzMxMi0zLTAwYSNjcl4zcy1zbC5gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128843&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=a7695c1121c99e7616738f3f9a348ab5&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 795402,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 4762475,
              "FileCs": "c:0-40752-1cc3",
              "FileHash": "6612e2af08c26d9a6bbe2c0f54d0d489",
              "Uri": "v09044g40000c315osvk0vv163a7udfg",
              "UrlKey": "v09044g40000c315osvk0vv163a7udfg_bytevc1_540p_795402",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c004/1af3e0db6672425c9fdf44e20799bb65/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1552&bt=776&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=NTs8NDRnaDs2N2Q2ZGczO0Bpamc6NjR5bnY4NjMzNzczM0BfNGNjLzRhXjQxMGE2LTYvYSNjcl4zcy1zbC5gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128843&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=70901e7e69cb9c9ece0fe086016a97ba&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c004/1af3e0db6672425c9fdf44e20799bb65/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1552&bt=776&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=NTs8NDRnaDs2N2Q2ZGczO0Bpamc6NjR5bnY4NjMzNzczM0BfNGNjLzRhXjQxMGE2LTYvYSNjcl4zcy1zbC5gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128843&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=70901e7e69cb9c9ece0fe086016a97ba&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 578550,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 3464074,
              "FileCs": "c:0-40557-a1db",
              "FileHash": "7e8d064f7db67a90265631473230d12d",
              "Uri": "v09044g40000c315osvk0vv163a7udfg",
              "UrlKey": "v09044g40000c315osvk0vv163a7udfg_h264_540p_578550",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c004/5d0740c1e20046ef9fbec1bd7a17c9fc/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1128&bt=564&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=aTg5OzppaDo6OmVlMzU3OEBpamc6NjR5bnY4NjMzNzczM0BiX141LzYwNS4xMWJjXzItYSNjcl4zcy1zbC5gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128843&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=1ef8fe5388dea6cd59957e97f19b0de1&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c004/5d0740c1e20046ef9fbec1bd7a17c9fc/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1128&bt=564&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=aTg5OzppaDo6OmVlMzU3OEBpamc6NjR5bnY4NjMzNzczM0BiX141LzYwNS4xMWJjXzItYSNjcl4zcy1zbC5gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128843&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=1ef8fe5388dea6cd59957e97f19b0de1&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 383665,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 2297198,
              "FileCs": "c:0-40557-8870",
              "FileHash": "d0d4c7869bcdfdcad10ac17e527b0a80",
              "Uri": "v09044g40000c315osvk0vv163a7udfg",
              "UrlKey": "v09044g40000c315osvk0vv163a7udfg_h264_540p_383665",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c001/b6b182a6dfea4bb9a48bf9758000ef7a/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=748&bt=374&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=OWY2N2U7aWRpOGdnZDhlN0Bpamc6NjR5bnY4NjMzNzczM0AzMjIyYC0wXzExMjUtLy40YSNjcl4zcy1zbC5gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128843&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=ed4a1dd272fc6443cf7df8431f7dbdcb&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c001/b6b182a6dfea4bb9a48bf9758000ef7a/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=748&bt=374&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=OWY2N2U7aWRpOGdnZDhlN0Bpamc6NjR5bnY4NjMzNzczM0AzMjIyYC0wXzExMjUtLy40YSNjcl4zcy1zbC5gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128843&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=ed4a1dd272fc6443cf7df8431f7dbdcb&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/ba4502b6fab047b5b480eb18f0ac0e45_1623350445?x-expires=1700125200&x-signature=vmx0VxJseMfYYhDmLNY7W%2FIsQDs%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c003/6bace42927f14aaeabfdab9831475034/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=2412&bt=1206&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NGY5Ozs3OGg6Ozw0N2Q7Z0Bpamc6NjR5bnY4NjMzNzczM0A0XzNiMC1gNV4xNWNiXi4xYSNjcl4zcy1zbC5gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128843&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=a529aca94b24b6f18fb673aa2da1fda0&tk=tt_chain_token",
        "duration": 47,
        "dynamicCover": "https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/4e3218743b7c48e3826520a231d177c7_1623350442?x-expires=1700125200&x-signature=jSifJnegEwXssCf9Zi%2FwyYlwG%2Bk%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "6972237036317052166",
        "originCover": "https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/06a80c4bb7fc4943bb8fb8c893d80051_1623350442?x-expires=1700125200&x-signature=43zP4UfjoFP%2Fr2f%2BwamaOeDeruo%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-pve-0068/35dd962e259c4be3bc0e941d97ac0f93/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2442&bt=1221&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NGU6NTQ5OjxmaDU4NTtlO0Bpamc6NjR5bnY4NjMzNzczM0BjYS4xXjQtNS8xMTZgNWEuYSNjcl4zcy1zbC5gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128843&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=65cfafe4388b5a338fc579616b012ba1&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/ba4502b6fab047b5b480eb18f0ac0e45_1623350445~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=sYUmoT81I2uckoggV7x6EQwvOUU%3D",
        "shareCover": [
          "",
          "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/06a80c4bb7fc4943bb8fb8c893d80051_1623350442~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=s4HNh5MgT2L4eejz54SooxzIGdU%3D",
          "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/06a80c4bb7fc4943bb8fb8c893d80051_1623350442~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=9a%2BXM%2BiA6F6Dwv%2FoMKHnPaN4iVY%3D"
        ],
        "size": 7489775,
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -12.8,
          "Peak": 0.91201
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/ba4502b6fab047b5b480eb18f0ac0e45_1623350445~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=cMXi7tPEmX180Ax06xOczPKFDvw%3D",
          "480": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/ba4502b6fab047b5b480eb18f0ac0e45_1623350445~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=dHHeXm%2FJPSDEj0SXsLE4NKL21PQ%3D",
          "720": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/ba4502b6fab047b5b480eb18f0ac0e45_1623350445~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=FBhFBaOTd17E1TNQ2wPeQOowjrI%3D",
          "960": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/ba4502b6fab047b5b480eb18f0ac0e45_1623350445~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=K%2FXcwX42RNPrOThLkUYiUpAXr8c%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/2458cb9de036988afefdd21925492eb1~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=PskwD%2FqwSPyS9L2tIjId4EzpZJw%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/2458cb9de036988afefdd21925492eb1~c5_720x720.jpeg?x-expires=1700125200&x-signature=nIHdO9nywgekVC1vmqFyaJ7erkg%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/2458cb9de036988afefdd21925492eb1~c5_100x100.jpeg?x-expires=1700125200&x-signature=f%2BjAKoM0TqRXu8eq%2BJrAsF2224I%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "7091539695071773742",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Melo Music",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAAo1gJOQDh-lTOca0mYwpkuONmBOMrSDy3nMIx8zyJoxqTquJjXjU63_4KTKUPsUSv",
        "secret": false,
        "signature": "finding all my underrated artists on the Melo app!\nplaylists and app\ud83d\udc47\ud83c\udffd",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "prazulmelo",
        "verified": false
      },
      "authorStats": {
        "diggCount": 2589,
        "followerCount": 246800,
        "followingCount": 354,
        "friendCount": 0,
        "heart": 5100000,
        "heartCount": 5100000,
        "videoCount": 207
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "194742",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundrap"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "325757",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "233934",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundhiphop"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "50864",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musicproducer"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "136517",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musiccurator"
        },
        {
          "coverLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/4ce5508a8a28a5116186ab5baefa61d4.png",
          "coverMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/4ce5508a8a28a5116186ab5baefa61d4.png",
          "coverThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/4ce5508a8a28a5116186ab5baefa61d4.png",
          "desc": "Discover best #NewMusic from your favourite artists!",
          "id": "31075",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/32bceb8db4d6aa366454f7ea54116832.jpeg",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/32bceb8db4d6aa366454f7ea54116832.jpeg",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/32bceb8db4d6aa366454f7ea54116832.jpeg",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "newmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "2531016",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "jid"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1549858",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "smino"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "699",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "kendricklamar"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "Replying to @jaiton1000 this man\u2019s pen game is on point @jaiton1000  #undergroundrap #undergroundmusic #undergroundhiphop #indiemusician #musicproducer #musiccurator #newmusic #jid #smino #kendricklamar ",
          "textExtra": [
            {
              "awemeId": "",
              "end": 23,
              "hashtagId": "",
              "hashtagName": "",
              "isCommerce": false,
              "secUid": "MS4wLjABAAAA_sAPvmNCraVePMV25ov7YGPfxi-k9X3YwjOLivTyhFtkETC6bCg6dVbxqZsliT6z",
              "start": 12,
              "subType": 2,
              "type": 0,
              "userId": "6712174594880603142",
              "userUniqueId": "jaiton1000"
            },
            {
              "awemeId": "",
              "end": 67,
              "hashtagId": "",
              "hashtagName": "",
              "isCommerce": false,
              "secUid": "MS4wLjABAAAA_sAPvmNCraVePMV25ov7YGPfxi-k9X3YwjOLivTyhFtkETC6bCg6dVbxqZsliT6z",
              "start": 56,
              "subType": 0,
              "type": 0,
              "userId": "6712174594880603142",
              "userUniqueId": "jaiton1000"
            },
            {
              "awemeId": "",
              "end": 84,
              "hashtagId": "194742",
              "hashtagName": "undergroundrap",
              "isCommerce": false,
              "secUid": "",
              "start": 69,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 102,
              "hashtagId": "325757",
              "hashtagName": "undergroundmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 85,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 121,
              "hashtagId": "233934",
              "hashtagName": "undergroundhiphop",
              "isCommerce": false,
              "secUid": "",
              "start": 103,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 136,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 122,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 151,
              "hashtagId": "50864",
              "hashtagName": "musicproducer",
              "isCommerce": false,
              "secUid": "",
              "start": 137,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 165,
              "hashtagId": "136517",
              "hashtagName": "musiccurator",
              "isCommerce": false,
              "secUid": "",
              "start": 152,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 175,
              "hashtagId": "31075",
              "hashtagName": "newmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 166,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 180,
              "hashtagId": "2531016",
              "hashtagName": "jid",
              "isCommerce": false,
              "secUid": "",
              "start": 176,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 187,
              "hashtagId": "1549858",
              "hashtagName": "smino",
              "isCommerce": false,
              "secUid": "",
              "start": 181,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 202,
              "hashtagId": "699",
              "hashtagName": "kendricklamar",
              "isCommerce": false,
              "secUid": "",
              "start": 188,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1680572372,
      "desc": "Replying to @jaiton1000 this man\u2019s pen game is on point @jaiton1000  #undergroundrap #undergroundmusic #undergroundhiphop #indiemusician #musicproducer #musiccurator #newmusic #jid #smino #kendricklamar ",
      "digged": false,
      "diversificationId": 10046,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7218003341526256942",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "nothing really matters",
        "authorName": "Jaiton",
        "coverLarge": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/12d0b8fffac248d28a6815e25e979ea7~c5_720x720.jpeg",
        "coverMedium": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/12d0b8fffac248d28a6815e25e979ea7~c5_200x200.jpeg",
        "coverThumb": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/12d0b8fffac248d28a6815e25e979ea7~c5_100x100.jpeg",
        "duration": 53,
        "id": "7188785483424630786",
        "original": false,
        "playUrl": "https://sf16-ies-music-va.tiktokcdn.com/obj/tos-useast2a-ve-2774/okSmNNYrzAXxfa6WFDhsdWIv4AoUCLIwHcsjlB",
        "title": "nothing really matters"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 76000,
        "commentCount": 2968,
        "diggCount": 595500,
        "playCount": 3600000,
        "shareCount": 686
      },
      "stickersOnItem": [
        {
          "stickerText": [
            "Part 36",
            "Artists with \ud83d\udd25 music but \ud83d\udca9 marketing"
          ],
          "stickerType": 4
        }
      ],
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 23,
          "hashtagId": "",
          "hashtagName": "",
          "isCommerce": false,
          "secUid": "MS4wLjABAAAA_sAPvmNCraVePMV25ov7YGPfxi-k9X3YwjOLivTyhFtkETC6bCg6dVbxqZsliT6z",
          "start": 12,
          "subType": 2,
          "type": 0,
          "userId": "6712174594880603142",
          "userUniqueId": "jaiton1000"
        },
        {
          "awemeId": "",
          "end": 67,
          "hashtagId": "",
          "hashtagName": "",
          "isCommerce": false,
          "secUid": "MS4wLjABAAAA_sAPvmNCraVePMV25ov7YGPfxi-k9X3YwjOLivTyhFtkETC6bCg6dVbxqZsliT6z",
          "start": 56,
          "subType": 0,
          "type": 0,
          "userId": "6712174594880603142",
          "userUniqueId": "jaiton1000"
        },
        {
          "awemeId": "",
          "end": 84,
          "hashtagId": "194742",
          "hashtagName": "undergroundrap",
          "isCommerce": false,
          "secUid": "",
          "start": 69,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 102,
          "hashtagId": "325757",
          "hashtagName": "undergroundmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 85,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 121,
          "hashtagId": "233934",
          "hashtagName": "undergroundhiphop",
          "isCommerce": false,
          "secUid": "",
          "start": 103,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 136,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 122,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 151,
          "hashtagId": "50864",
          "hashtagName": "musicproducer",
          "isCommerce": false,
          "secUid": "",
          "start": 137,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 165,
          "hashtagId": "136517",
          "hashtagName": "musiccurator",
          "isCommerce": false,
          "secUid": "",
          "start": 152,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 175,
          "hashtagId": "31075",
          "hashtagName": "newmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 166,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 180,
          "hashtagId": "2531016",
          "hashtagName": "jid",
          "isCommerce": false,
          "secUid": "",
          "start": 176,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 187,
          "hashtagId": "1549858",
          "hashtagName": "smino",
          "isCommerce": false,
          "secUid": "",
          "start": 181,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 202,
          "hashtagId": "699",
          "hashtagName": "kendricklamar",
          "isCommerce": false,
          "secUid": "",
          "start": 188,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 833277,
        "bitrateInfo": [
          {
            "Bitrate": 902726,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 2800709,
              "FileCs": "c:0-28308-edd0",
              "FileHash": "9e1ad99f011b08121989d1b494acae68",
              "Uri": "v12044gd0000cglnr6rc77u9k9qim5cg",
              "UrlKey": "v12044gd0000cglnr6rc77u9k9qim5cg_h264_540p_902726",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/bf3cc4ac4ba942068f00de85285e328d/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1762&bt=881&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=Nzw8NzRkPDllOWg5OTVlOEBpajhsPDw6ZjlxajMzZzczNEA0LzQxLjI0XmExNDA1Y2EtYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=027c6d3d91fb0973728a4e786710cae2&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/bf3cc4ac4ba942068f00de85285e328d/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1762&bt=881&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=Nzw8NzRkPDllOWg5OTVlOEBpajhsPDw6ZjlxajMzZzczNEA0LzQxLjI0XmExNDA1Y2EtYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=027c6d3d91fb0973728a4e786710cae2&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 833277,
            "CodecType": "h264",
            "GearName": "normal_720_0",
            "PlayAddr": {
              "DataSize": 2572744,
              "FileCs": "c:0-21768-fbf6",
              "FileHash": "4b84126e1dc22d4e3fa16423ebdb9ea3",
              "Uri": "v12044gd0000cglnr6rc77u9k9qim5cg",
              "UrlKey": "v12044gd0000cglnr6rc77u9k9qim5cg_h264_720p_833277",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/b8f8b2a9480046749204466f6e25365c/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1626&bt=813&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aTg8ZTY8MzU4aGY6Omg3M0BpajhsPDw6ZjlxajMzZzczNEA1NmMyYjReXmAxYjZgYmFhYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=9204e4e64b5e299e6cccaa28fc000e84&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/b8f8b2a9480046749204466f6e25365c/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1626&bt=813&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aTg8ZTY8MzU4aGY6Omg3M0BpajhsPDw6ZjlxajMzZzczNEA1NmMyYjReXmAxYjZgYmFhYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=9204e4e64b5e299e6cccaa28fc000e84&tk=tt_chain_token"
              ]
            },
            "QualityType": 10
          },
          {
            "Bitrate": 801032,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 2469084,
              "FileCs": "c:0-28577-6347",
              "FileHash": "9ae8e1001b1d001555916fdc6fc9652a",
              "Uri": "v12044gd0000cglnr6rc77u9k9qim5cg",
              "UrlKey": "v12044gd0000cglnr6rc77u9k9qim5cg_bytevc1_1080p_801032",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/425e9690920446e0af7f39b305b93cf1/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1564&bt=782&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=ZzVmPDgzOThoZzhnNmVoM0BpajhsPDw6ZjlxajMzZzczNEAzNTEzYzNiNl8xXjRgLTY0YSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=754820b281e250a29e3af8442817d1d6&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/425e9690920446e0af7f39b305b93cf1/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1564&bt=782&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=ZzVmPDgzOThoZzhnNmVoM0BpajhsPDw6ZjlxajMzZzczNEAzNTEzYzNiNl8xXjRgLTY0YSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=754820b281e250a29e3af8442817d1d6&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 515001,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 1597791,
              "FileCs": "c:0-28308-29b3",
              "FileHash": "e2a9e1907aa6610240a6d6feb13459b2",
              "Uri": "v12044gd0000cglnr6rc77u9k9qim5cg",
              "UrlKey": "v12044gd0000cglnr6rc77u9k9qim5cg_h264_540p_515001",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/f929df54e5624bf289045a5886b61fd2/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1004&bt=502&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=ZGdmNzpkOWQ1OmRpZjNkNEBpajhsPDw6ZjlxajMzZzczNEBjMGEuMGMuXmMxM2BgNDNhYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=8239d63758a6b586bacbc459df5e669b&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/f929df54e5624bf289045a5886b61fd2/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1004&bt=502&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=ZGdmNzpkOWQ1OmRpZjNkNEBpajhsPDw6ZjlxajMzZzczNEBjMGEuMGMuXmMxM2BgNDNhYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=8239d63758a6b586bacbc459df5e669b&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 504820,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 1558634,
              "FileCs": "c:0-21922-dafd",
              "FileHash": "5d7540debfe42735986a5e583a687a6a",
              "Uri": "v12044gd0000cglnr6rc77u9k9qim5cg",
              "UrlKey": "v12044gd0000cglnr6rc77u9k9qim5cg_bytevc1_720p_504820",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/cc10a95e8d2a4013b8ca5a92dd677c24/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=984&bt=492&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=NWlnO2U0aTk6N2c5ZTM2M0BpajhsPDw6ZjlxajMzZzczNEAxLjAxM15hXzExMmJeLTQ0YSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=0ebf7bf403acd56b4d8199e3049b69f6&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/cc10a95e8d2a4013b8ca5a92dd677c24/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=984&bt=492&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=NWlnO2U0aTk6N2c5ZTM2M0BpajhsPDw6ZjlxajMzZzczNEAxLjAxM15hXzExMmJeLTQ0YSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=0ebf7bf403acd56b4d8199e3049b69f6&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 404030,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 1247443,
              "FileCs": "c:0-21922-1533",
              "FileHash": "c85eb8e9a787083408530ec6657ceee9",
              "Uri": "v12044gd0000cglnr6rc77u9k9qim5cg",
              "UrlKey": "v12044gd0000cglnr6rc77u9k9qim5cg_bytevc1_540p_404030",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/22fec7f5b8d648f1bd62934205f9572c/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=788&bt=394&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=Zjg3NTs2ZjY0PDRpZzozNUBpajhsPDw6ZjlxajMzZzczNEAyXl9jNDAvXi8xMi01YmFfYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=94ce52084c7e1abc0029f080819c4afe&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/22fec7f5b8d648f1bd62934205f9572c/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=788&bt=394&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=Zjg3NTs2ZjY0PDRpZzozNUBpajhsPDw6ZjlxajMzZzczNEAyXl9jNDAvXi8xMi01YmFfYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=94ce52084c7e1abc0029f080819c4afe&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 381377,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 1183225,
              "FileCs": "c:0-28308-4bdc",
              "FileHash": "b8babad3706ad9c61baf65b75a7b7d95",
              "Uri": "v12044gd0000cglnr6rc77u9k9qim5cg",
              "UrlKey": "v12044gd0000cglnr6rc77u9k9qim5cg_h264_540p_381377",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/b5fb1d127d844baebdd5f73417aeb34a/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=744&bt=372&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=PGY4ZGloOGg1N2U6PGQ1PEBpajhsPDw6ZjlxajMzZzczNEA2Yi0xLTMzNjQxNV9eXi0vYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=e11e479f7f2a71e9e0127bad70ff8eca&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/b5fb1d127d844baebdd5f73417aeb34a/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=744&bt=372&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=PGY4ZGloOGg1N2U6PGQ1PEBpajhsPDw6ZjlxajMzZzczNEA2Yi0xLTMzNjQxNV9eXi0vYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=e11e479f7f2a71e9e0127bad70ff8eca&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p19-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/67427e05850c4f6c8212d82de1efc2f7_1680572375?x-expires=1700125200&x-signature=UkVt%2Bd31IJVLzyYt3oZjp0uFYV8%3D",
        "definition": "720p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/106e9e2cf3d249ca81cdd1834ec64447/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=1846&bt=923&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=OjNoZTRlaWk5OzQ0aTUzZUBpajhsPDw6ZjlxajMzZzczNEBfNTVeMmMwNV8xNS9hNV8vYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=8e217cc3d5c1125cfefb534f211ec7ac&tk=tt_chain_token",
        "duration": 24,
        "dynamicCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/0a390cca0e3e4a138829199faae970fe_1680572374?x-expires=1700125200&x-signature=JpoXRMKdkg1cFNN8Sr7ScGhra8E%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1280,
        "id": "7218003341526256942",
        "originCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/2a4cfae4f27042ebac141bdab0214e67_1680572375?x-expires=1700125200&x-signature=YeOh2XlNqwkvcHGzpofcXrEbfX8%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/b8f8b2a9480046749204466f6e25365c/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1626&bt=813&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aTg8ZTY8MzU4aGY6Omg3M0BpajhsPDw6ZjlxajMzZzczNEA1NmMyYjReXmAxYjZgYmFhYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128820&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=9204e4e64b5e299e6cccaa28fc000e84&tk=tt_chain_token",
        "ratio": "720p",
        "reflowCover": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/67427e05850c4f6c8212d82de1efc2f7_1680572375~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=%2Fg7rOq5F0pCwCtQLaheExQQlvy8%3D",
        "shareCover": [
          "",
          "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/2a4cfae4f27042ebac141bdab0214e67_1680572375~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=U%2BpJERpZ00%2FPocxj7psfg%2Bxx74U%3D",
          "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/2a4cfae4f27042ebac141bdab0214e67_1680572375~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=7k4fS7POCH5QJr97bynTXeahjGk%3D"
        ],
        "size": 2572744,
        "subtitleInfos": [
          {
            "Format": "webvtt",
            "LanguageCodeName": "vie-VN",
            "LanguageID": "10",
            "Size": 1427,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/042153fdd3e35701ab6180c061eba0a1/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/22e9d99a1714457596ef6dc560c50346/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1626&bt=813&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=ajhsPDw6ZjlxajMzZzczNEBpajhsPDw6ZjlxajMzZzczNEBgam5ocjRvb2lgLS1kMS9zYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": 81345943
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "por-PT",
            "LanguageID": "8",
            "Size": 1144,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/f8e7084b26848d2b56acbc52fb769b24/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/c5b69758485e462ea2f223565e402c64/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1626&bt=813&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=ajhsPDw6ZjlxajMzZzczNEBpajhsPDw6ZjlxajMzZzczNEBgam5ocjRvb2lgLS1kMS9zYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": -1021083909
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "spa-ES",
            "LanguageID": "9",
            "Size": 1188,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/c73a12b517d8e607b4bb1db10891a7ef/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/e3f9ffa1b9fa44a09c9139e14a0d7794/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1626&bt=813&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=ajhsPDw6ZjlxajMzZzczNEBpajhsPDw6ZjlxajMzZzczNEBgam5ocjRvb2lgLS1kMS9zYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": -29974613
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "rus-RU",
            "LanguageID": "6",
            "Size": 1695,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/7047c6c187584e9be49f5f725fe6c1fc/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/1cb17ce5ce8e48e4b6fade54f1d0dbe3/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1626&bt=813&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=ajhsPDw6ZjlxajMzZzczNEBpajhsPDw6ZjlxajMzZzczNEBgam5ocjRvb2lgLS1kMS9zYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": -1667647014
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ara-SA",
            "LanguageID": "34",
            "Size": 1332,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/4f8938f15746fccee960ed686f3f7526/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/42346a71520044e8aa4f044666439996/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1626&bt=813&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=ajhsPDw6ZjlxajMzZzczNEBpajhsPDw6ZjlxajMzZzczNEBgam5ocjRvb2lgLS1kMS9zYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": -1896091472
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "jpn-JP",
            "LanguageID": "3",
            "Size": 1354,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/d806bcac8fa756e08f77c770963d4c9c/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/5a153eab103d423da18e1984a09440a9/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1626&bt=813&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=ajhsPDw6ZjlxajMzZzczNEBpajhsPDw6ZjlxajMzZzczNEBgam5ocjRvb2lgLS1kMS9zYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": -282549522
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "kor-KR",
            "LanguageID": "4",
            "Size": 1378,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/a21021f0d711cd1bf96b9611340fe6fb/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/504d66dca34244b28865f39949adc432/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1626&bt=813&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=ajhsPDw6ZjlxajMzZzczNEBpajhsPDw6ZjlxajMzZzczNEBgam5ocjRvb2lgLS1kMS9zYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": 2109418568
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ind-ID",
            "LanguageID": "23",
            "Size": 1253,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/02fef1495c7e738f299d256f8e37554f/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/88b53c18e5b44faf94c5c639679494eb/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1626&bt=813&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=ajhsPDw6ZjlxajMzZzczNEBpajhsPDw6ZjlxajMzZzczNEBgam5ocjRvb2lgLS1kMS9zYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": 1940382024
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "eng-US",
            "LanguageID": "2",
            "Size": 1014,
            "Source": "ASR",
            "Url": "https://v16-webapp.tiktok.com/9bb6154749e6b9c393427d5695e2f98d/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/9a7595b2890640c7a7f565f80709db7b/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1626&bt=813&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=ajhsPDw6ZjlxajMzZzczNEBpajhsPDw6ZjlxajMzZzczNEBgam5ocjRvb2lgLS1kMS9zYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "1",
            "VideoSubtitleID": -1953196074
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ita-IT",
            "LanguageID": "26",
            "Size": 1132,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/b0b6815d68fef12874994160ef72904d/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/f1d6c1dbda2342a3b3aada31f6f341e4/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1626&bt=813&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=ajhsPDw6ZjlxajMzZzczNEBpajhsPDw6ZjlxajMzZzczNEBgam5ocjRvb2lgLS1kMS9zYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": -1124809333
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "cmn-Hans-CN",
            "LanguageID": "1",
            "Size": 1182,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/3cb8778aca4b2d76adffc79498f0083b/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/9d7d438b690341fe8f12688fa4f50c5b/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1626&bt=813&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=ajhsPDw6ZjlxajMzZzczNEBpajhsPDw6ZjlxajMzZzczNEBgam5ocjRvb2lgLS1kMS9zYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": 520292549
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "fra-FR",
            "LanguageID": "7",
            "Size": 1268,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/90ff2d7d31b34ecb70747623a106e43d/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/fc23db575af9403b995f01f8ea243fa6/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1626&bt=813&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=ajhsPDw6ZjlxajMzZzczNEBpajhsPDw6ZjlxajMzZzczNEBgam5ocjRvb2lgLS1kMS9zYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": -716462729
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "deu-DE",
            "LanguageID": "20",
            "Size": 1335,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/6292e1a215a3ca0455559fc43f056c21/6555e834/video/tos/maliva/tos-maliva-v-0068c799-us/1b54dc4d9a854b6b9caaa7a1ee8537b8/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1626&bt=813&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=ajhsPDw6ZjlxajMzZzczNEBpajhsPDw6ZjlxajMzZzczNEBgam5ocjRvb2lgLS1kMS9zYSNgam5ocjRvb2lgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128820,
            "Version": "4",
            "VideoSubtitleID": -1694422647
          }
        ],
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -15.8,
          "Peak": 0.93325
        },
        "width": 720,
        "zoomCover": {
          "240": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/67427e05850c4f6c8212d82de1efc2f7_1680572375~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=%2BQ1uLNzOOL8DB5ft%2FanYbwR8NtE%3D",
          "480": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/67427e05850c4f6c8212d82de1efc2f7_1680572375~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=Q2xtOgdBV3WwVgnnjNYe9spD6Ls%3D",
          "720": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/67427e05850c4f6c8212d82de1efc2f7_1680572375~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=uWbeASpBq2xceXnn30bGJa8BoLc%3D",
          "960": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/67427e05850c4f6c8212d82de1efc2f7_1680572375~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=fA5KMAgys0euzgIZbl9sy82r7dw%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "7224218912691438638",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Discovering Artists",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAA79sDcMCbl7QatFptF3JKobDAz67Uc0yws24YRf6FRLl8aWSAgSzrw6mu-bd0maiw",
        "secret": false,
        "signature": "\ud83d\udce7: discoveringartists.irl@gmail.com\n\nFollow our Spotify playlist!\ud83d\udc47",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "discoveringartists.irl",
        "verified": false
      },
      "authorStats": {
        "diggCount": 86,
        "followerCount": 515300,
        "followingCount": 13,
        "friendCount": 0,
        "heart": 15800000,
        "heartCount": 15800000,
        "videoCount": 35
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055695396906",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "doyoumakemusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055791882286",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "discoveringartistsirl"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "325757",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "194742",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundrap"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "4504990",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "newmusicfriday"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "136517",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musiccurator"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "3228",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "isaiahrashad"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "50864",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musicproducer"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1609867014255781",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "newrapmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "Do you make music?"
        },
        {
          "desc": ""
        },
        {
          "desc": "#doyoumakemusic #discoveringartistsirl #Undergroundmusic #Undergroundrap #newmusicfriday #musiccurator #isaiahrashad #musicproducer #newrapmusic #indiemusician ",
          "textExtra": [
            {
              "awemeId": "",
              "end": 15,
              "hashtagId": "7252811055695396906",
              "hashtagName": "doyoumakemusic",
              "isCommerce": false,
              "secUid": "",
              "start": 0,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 38,
              "hashtagId": "7252811055791882286",
              "hashtagName": "discoveringartistsirl",
              "isCommerce": false,
              "secUid": "",
              "start": 16,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 56,
              "hashtagId": "325757",
              "hashtagName": "undergroundmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 39,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 72,
              "hashtagId": "194742",
              "hashtagName": "undergroundrap",
              "isCommerce": false,
              "secUid": "",
              "start": 57,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 88,
              "hashtagId": "4504990",
              "hashtagName": "newmusicfriday",
              "isCommerce": false,
              "secUid": "",
              "start": 73,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 102,
              "hashtagId": "136517",
              "hashtagName": "musiccurator",
              "isCommerce": false,
              "secUid": "",
              "start": 89,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 116,
              "hashtagId": "3228",
              "hashtagName": "isaiahrashad",
              "isCommerce": false,
              "secUid": "",
              "start": 103,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 131,
              "hashtagId": "50864",
              "hashtagName": "musicproducer",
              "isCommerce": false,
              "secUid": "",
              "start": 117,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 144,
              "hashtagId": "1609867014255781",
              "hashtagName": "newrapmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 132,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 159,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 145,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1696440879,
      "desc": "Do you make music? #doyoumakemusic #discoveringartistsirl #Undergroundmusic #Undergroundrap #newmusicfriday #musiccurator #isaiahrashad #musicproducer #newrapmusic #indiemusician ",
      "digged": false,
      "diversificationId": 10046,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7286157959388876074",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "",
        "authorName": "Discovering Artists",
        "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "coverMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "coverThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "duration": 30,
        "id": "7286158063583857454",
        "original": true,
        "playUrl": "https://v16m.tiktokcdn.com/202a0cad5d4dd3cfb9834d7c81a2b2ba/65539999/video/tos/maliva/tos-maliva-ve-27dcd7c799-us/oElsS0d1wB0IaC8JfD5MfjIHYHUigxbk3IonD8/?a=1233&ch=0&cr=0&dr=0&er=2&cd=0%7C0%7C0%7C0&br=250&bt=125&bti=NDU3ZjAwOg%3D%3D&ft=d2A~l-Inz7TPgL-Ziyq8Z&mime_type=audio_mpeg&qs=6&rc=NTc5N2c0aWRoaGg4M2Q0PEBpM25laWk6ZmZ0bjMzZzU8NEBjYTZjLTQtNjMxNS1eNDEyYSNyZ3BxcjQwMWJgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00088000&cc=3&download=true",
        "title": "original sound"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 43700,
        "commentCount": 1149,
        "diggCount": 519100,
        "playCount": 3800000,
        "shareCount": 816
      },
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 34,
          "hashtagId": "7252811055695396906",
          "hashtagName": "doyoumakemusic",
          "isCommerce": false,
          "secUid": "",
          "start": 19,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 57,
          "hashtagId": "7252811055791882286",
          "hashtagName": "discoveringartistsirl",
          "isCommerce": false,
          "secUid": "",
          "start": 35,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 75,
          "hashtagId": "325757",
          "hashtagName": "undergroundmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 58,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 91,
          "hashtagId": "194742",
          "hashtagName": "undergroundrap",
          "isCommerce": false,
          "secUid": "",
          "start": 76,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 107,
          "hashtagId": "4504990",
          "hashtagName": "newmusicfriday",
          "isCommerce": false,
          "secUid": "",
          "start": 92,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 121,
          "hashtagId": "136517",
          "hashtagName": "musiccurator",
          "isCommerce": false,
          "secUid": "",
          "start": 108,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 135,
          "hashtagId": "3228",
          "hashtagName": "isaiahrashad",
          "isCommerce": false,
          "secUid": "",
          "start": 122,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 150,
          "hashtagId": "50864",
          "hashtagName": "musicproducer",
          "isCommerce": false,
          "secUid": "",
          "start": 136,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 163,
          "hashtagId": "1609867014255781",
          "hashtagName": "newrapmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 151,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 178,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 164,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 2487317,
        "bitrateInfo": [
          {
            "Bitrate": 2487317,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 9388691,
              "FileCs": "c:0-26066-1cac",
              "FileHash": "9851743b77815886ec75ddf9f97897b4",
              "Uri": "v12044gd0000ckeq233c77ucttd9dbk0",
              "UrlKey": "v12044gd0000ckeq233c77ucttd9dbk0_h264_540p_2487317",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oA5QrBNhFwjkyGop7EACIWAdI1IvfqC9zMAtod/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4858&bt=2429&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=MzUzNDQzNDQ8NGZmOjVoNUBpM2U8d2Y6ZjZ0bjMzZzczNEAzMTYuMi8yXl8xMTMyLWJgYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=bb68c463089e0d92375624eb2da462d0&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oA5QrBNhFwjkyGop7EACIWAdI1IvfqC9zMAtod/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4858&bt=2429&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=MzUzNDQzNDQ8NGZmOjVoNUBpM2U8d2Y6ZjZ0bjMzZzczNEAzMTYuMi8yXl8xMTMyLWJgYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=bb68c463089e0d92375624eb2da462d0&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 1510481,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 5701503,
              "FileCs": "c:0-26513-0fe3",
              "FileHash": "fcc3b41b4ee8bf182562c27117c74cfe",
              "Uri": "v12044gd0000ckeq233c77ucttd9dbk0",
              "UrlKey": "v12044gd0000ckeq233c77ucttd9dbk0_bytevc1_1080p_1510481",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oAIdEQBbIoqjA5zy7f87Nrr1oCMCGhFwpWtuAk/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2950&bt=1475&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=aGc7ZTdmZmVlZTc8NTQ1NEBpM2U8d2Y6ZjZ0bjMzZzczNEAtLTRhNTMwX2IxY2A0YWMzYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=d3462c40cff4a93cac2a1f7ccaca4bd0&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oAIdEQBbIoqjA5zy7f87Nrr1oCMCGhFwpWtuAk/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2950&bt=1475&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=aGc7ZTdmZmVlZTc8NTQ1NEBpM2U8d2Y6ZjZ0bjMzZzczNEAtLTRhNTMwX2IxY2A0YWMzYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=d3462c40cff4a93cac2a1f7ccaca4bd0&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 1102719,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 4162351,
              "FileCs": "c:0-26066-4ef3",
              "FileHash": "a835dfec65cfb5ec0699bb56aedb63cd",
              "Uri": "v12044gd0000ckeq233c77ucttd9dbk0",
              "UrlKey": "v12044gd0000ckeq233c77ucttd9dbk0_h264_540p_1102719",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQBgSRiNgAEEDvzuR8bWalnBfPHegDEaO4pMA2/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2152&bt=1076&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=aTY4OTo3aDU1ODZmaTdkO0BpM2U8d2Y6ZjZ0bjMzZzczNEAvMjYvM2MvNV4xLi8zYi9iYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=8aeed8c6ff7060c152fc47e0cd496523&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQBgSRiNgAEEDvzuR8bWalnBfPHegDEaO4pMA2/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2152&bt=1076&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=aTY4OTo3aDU1ODZmaTdkO0BpM2U8d2Y6ZjZ0bjMzZzczNEAvMjYvM2MvNV4xLi8zYi9iYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=8aeed8c6ff7060c152fc47e0cd496523&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 790523,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 2983931,
              "FileCs": "c:0-26516-232e",
              "FileHash": "38e595292d631c861fb3a43d1369b35c",
              "Uri": "v12044gd0000ckeq233c77ucttd9dbk0",
              "UrlKey": "v12044gd0000ckeq233c77ucttd9dbk0_bytevc1_720p_790523",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oAqdGrwjNAIhLCE0QzIy1uAoohBpfC2W7tCEKM/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1542&bt=771&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=NDplaGU0ZjxkPDQ7OWc5M0BpM2U8d2Y6ZjZ0bjMzZzczNEAuLjYxYTA2X2IxNV9hX2JfYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c4a564ef913d5374e413e4c6ff9cb10f&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oAqdGrwjNAIhLCE0QzIy1uAoohBpfC2W7tCEKM/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1542&bt=771&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=NDplaGU0ZjxkPDQ7OWc5M0BpM2U8d2Y6ZjZ0bjMzZzczNEAuLjYxYTA2X2IxNV9hX2JfYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c4a564ef913d5374e413e4c6ff9cb10f&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 712461,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 2689275,
              "FileCs": "c:0-26066-90d0",
              "FileHash": "8bd5c3c571b343b93ba0b35f09d588c7",
              "Uri": "v12044gd0000ckeq233c77ucttd9dbk0",
              "UrlKey": "v12044gd0000ckeq233c77ucttd9dbk0_h264_540p_712461",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o8NywWIICPzjqdQhtofCGIkjBoC1EwAMtrQp7A/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1390&bt=695&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=OzU5ZjlpZDo8NTloNGY1ZUBpM2U8d2Y6ZjZ0bjMzZzczNEBiYmBfMWEzNTMxYzU1M14yYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=e8fa89115b623d191c1de72db041f5d1&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o8NywWIICPzjqdQhtofCGIkjBoC1EwAMtrQp7A/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1390&bt=695&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=OzU5ZjlpZDo8NTloNGY1ZUBpM2U8d2Y6ZjZ0bjMzZzczNEBiYmBfMWEzNTMxYzU1M14yYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=e8fa89115b623d191c1de72db041f5d1&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          },
          {
            "Bitrate": 640923,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 2419247,
              "FileCs": "c:0-26516-054b",
              "FileHash": "165a347306042f8bfcdd315c15a71c42",
              "Uri": "v12044gd0000ckeq233c77ucttd9dbk0",
              "UrlKey": "v12044gd0000ckeq233c77ucttd9dbk0_bytevc1_540p_640923",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oIfrDUbnSQZ1RtlE3R3QQHXa7BZAFDEnegEMIg/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1250&bt=625&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=MzpoODNlaDQ6aDg4ZDdpZ0BpM2U8d2Y6ZjZ0bjMzZzczNEBhLmEtMWIzXmExMy0uM2ExYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=8ad0b8d8cacc730262ca8e9e8c408fdf&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oIfrDUbnSQZ1RtlE3R3QQHXa7BZAFDEnegEMIg/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1250&bt=625&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=MzpoODNlaDQ6aDg4ZDdpZ0BpM2U8d2Y6ZjZ0bjMzZzczNEBhLmEtMWIzXmExMy0uM2ExYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=8ad0b8d8cacc730262ca8e9e8c408fdf&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/38d308e09b684f5d8cf8779e435464eb_1696440880?x-expires=1700125200&x-signature=abXq1qerfN%2BAtMFpFVJld3SjvyA%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ocEICpyAtqL7mGodzBIWrjfcYHQAqwNMhoCJ1k/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=3878&bt=1939&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZjRkaDVkOjM7aDNkOmVkNkBpM2U8d2Y6ZjZ0bjMzZzczNEAvMjVfLTQ0X14xNjA1MWI1YSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=def91a0f5f442e64dcc293a30f45d3d6&tk=tt_chain_token",
        "duration": 30,
        "dynamicCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/6c6a2f1a21fb410f87ba5bccdd06de09_1696440881?x-expires=1700125200&x-signature=KsHr1XOmLgPrz%2BR56CxWjkE17vI%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7286157959388876074",
        "originCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/b19f10d0de06417d94460c35fee76b3c_1696440880?x-expires=1700125200&x-signature=u7comNmUQ%2Bhi9a1O4IeErbtPNWA%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oA5QrBNhFwjkyGop7EACIWAdI1IvfqC9zMAtod/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4858&bt=2429&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=MzUzNDQzNDQ8NGZmOjVoNUBpM2U8d2Y6ZjZ0bjMzZzczNEAzMTYuMi8yXl8xMTMyLWJgYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128826&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=bb68c463089e0d92375624eb2da462d0&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/38d308e09b684f5d8cf8779e435464eb_1696440880~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=NGIGpJo4Kc4MxiD7SBz%2Fg5VDtbk%3D",
        "shareCover": [
          "",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/b19f10d0de06417d94460c35fee76b3c_1696440880~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=fm4%2FJ%2BgDNXHx510kVIWvklBQa28%3D",
          "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/b19f10d0de06417d94460c35fee76b3c_1696440880~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=5sm6pGfFI3ukC5nIbWifAchgfeI%3D"
        ],
        "size": 9388691,
        "subtitleInfos": [
          {
            "Format": "webvtt",
            "LanguageCodeName": "kor-KR",
            "LanguageID": "4",
            "Size": 934,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/c71f45279082ad77a28dd7dd1f52e107/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/85e9e6279fe74802abbe370c33e1ed98/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14722&bt=7361&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2U8d2Y6ZjZ0bjMzZzczNEBpM2U8d2Y6ZjZ0bjMzZzczNEBoYWFxcjQwL2JgLS1kMS9zYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": -431085349
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "por-PT",
            "LanguageID": "8",
            "Size": 778,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/479bc415e1f0675400d928d64f49897f/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/4bcd1cd2a8a14cf2982672340c13f8e9/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14722&bt=7361&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2U8d2Y6ZjZ0bjMzZzczNEBpM2U8d2Y6ZjZ0bjMzZzczNEBoYWFxcjQwL2JgLS1kMS9zYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": 1818044900
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ara-SA",
            "LanguageID": "34",
            "Size": 682,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/1ffd139425a5154907f0bd8bf25618d6/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/656113f4a4034e1885b558af7c886a3e/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14722&bt=7361&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2U8d2Y6ZjZ0bjMzZzczNEBpM2U8d2Y6ZjZ0bjMzZzczNEBoYWFxcjQwL2JgLS1kMS9zYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": 1872868353
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "spa-ES",
            "LanguageID": "9",
            "Size": 820,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/63fc477f6e5d8d74b6feaec21e43d114/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/87c3bea8b6624d8cb3b53aa041867438/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14722&bt=7361&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2U8d2Y6ZjZ0bjMzZzczNEBpM2U8d2Y6ZjZ0bjMzZzczNEBoYWFxcjQwL2JgLS1kMS9zYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": -1185098964
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "eng-US",
            "LanguageID": "2",
            "Size": 479,
            "Source": "ASR",
            "Url": "https://v16-webapp.tiktok.com/d40b8aa9bbd12649d8910dc2e6941b6a/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/18ef40e0b3224c25a80e68f8037dc3ce/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14722&bt=7361&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2U8d2Y6ZjZ0bjMzZzczNEBpM2U8d2Y6ZjZ0bjMzZzczNEBoYWFxcjQwL2JgLS1kMS9zYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "1",
            "VideoSubtitleID": -103709482
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "jpn-JP",
            "LanguageID": "3",
            "Size": 975,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/18276f69e96faee9d18fa0aa9c696ae1/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/4b1471863e7a4cdabeb9417f55eca7d3/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14722&bt=7361&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2U8d2Y6ZjZ0bjMzZzczNEBpM2U8d2Y6ZjZ0bjMzZzczNEBoYWFxcjQwL2JgLS1kMS9zYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": 2088041216
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "vie-VN",
            "LanguageID": "10",
            "Size": 913,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/0f822a71be1ab64bbb7abf75ade1bbb9/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/31ebbefd75d747cd9510fe9ea97145a3/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14722&bt=7361&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2U8d2Y6ZjZ0bjMzZzczNEBpM2U8d2Y6ZjZ0bjMzZzczNEBoYWFxcjQwL2JgLS1kMS9zYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": -503989824
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "cmn-Hans-CN",
            "LanguageID": "1",
            "Size": 743,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/960a7a8801469ca0223c44a08e0d5749/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/e9af5f206b4f47869ecc130ff849c507/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14722&bt=7361&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2U8d2Y6ZjZ0bjMzZzczNEBpM2U8d2Y6ZjZ0bjMzZzczNEBoYWFxcjQwL2JgLS1kMS9zYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": -1032879095
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ind-ID",
            "LanguageID": "23",
            "Size": 739,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/76c9ba9f8be6a6967f21f4bdcf4858fe/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/1e069adfa9f6472496178e182cb0b1ea/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14722&bt=7361&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2U8d2Y6ZjZ0bjMzZzczNEBpM2U8d2Y6ZjZ0bjMzZzczNEBoYWFxcjQwL2JgLS1kMS9zYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": -379237160
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "rus-RU",
            "LanguageID": "6",
            "Size": 988,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/45fa53ef924d7febc16a500a6babf24e/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/7c987c58eb764883bfc3c5f57723881d/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14722&bt=7361&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2U8d2Y6ZjZ0bjMzZzczNEBpM2U8d2Y6ZjZ0bjMzZzczNEBoYWFxcjQwL2JgLS1kMS9zYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": 264348501
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ita-IT",
            "LanguageID": "26",
            "Size": 745,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/ac98396ce17c9b5bf3edde9cbb3b0707/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/5f3ad1010c3e4e4b9294b22b6ad2cfbf/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14722&bt=7361&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2U8d2Y6ZjZ0bjMzZzczNEBpM2U8d2Y6ZjZ0bjMzZzczNEBoYWFxcjQwL2JgLS1kMS9zYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": 15796558
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "deu-DE",
            "LanguageID": "20",
            "Size": 866,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/a6339b372c5ccce6038d94f6beb40b6f/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/9b36b955fda443958ce121a875843fbd/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14722&bt=7361&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2U8d2Y6ZjZ0bjMzZzczNEBpM2U8d2Y6ZjZ0bjMzZzczNEBoYWFxcjQwL2JgLS1kMS9zYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": -1585828008
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "fra-FR",
            "LanguageID": "7",
            "Size": 864,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/02409b4b3e725318d62771cab1c2bc68/6555e83a/video/tos/maliva/tos-maliva-v-0068c799-us/653413e43fd549fabd2d051fc7d3772a/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14722&bt=7361&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2U8d2Y6ZjZ0bjMzZzczNEBpM2U8d2Y6ZjZ0bjMzZzczNEBoYWFxcjQwL2JgLS1kMS9zYSNoYWFxcjQwL2JgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128826,
            "Version": "4",
            "VideoSubtitleID": -218876955
          }
        ],
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -17.6,
          "Peak": 0.51286
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/38d308e09b684f5d8cf8779e435464eb_1696440880~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=wc0rr7lsxDCv%2BZMBs5NcZOc1W3c%3D",
          "480": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/38d308e09b684f5d8cf8779e435464eb_1696440880~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=rnIEC%2FNh1XpgvPKgUA6%2BymoDGks%3D",
          "720": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/38d308e09b684f5d8cf8779e435464eb_1696440880~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=heVFAZBC5%2FEBWl4Vd%2B71Qupu%2BQ4%3D",
          "960": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/38d308e09b684f5d8cf8779e435464eb_1696440880~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=Ll3ZafwcXgtJwOokFdomt0sI6mg%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/7c903ffd7492d0b2c45c1ab00e2f3740~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=aJ0x9MwhSuCPttKKayH3WRDIJco%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/7c903ffd7492d0b2c45c1ab00e2f3740~c5_720x720.jpeg?x-expires=1700125200&x-signature=mlmIl%2FY8oWEvqw2zsFky0yiyykI%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/7c903ffd7492d0b2c45c1ab00e2f3740~c5_100x100.jpeg?x-expires=1700125200&x-signature=A7zAD%2BgR1pyiozrBzSi3FzaWAj4%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "6750099502227375110",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Project Atlantic",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAA8h6OBuL5VLHKYYTzeV1sDd0Hkm0PCvxpXhNZpYpB2mMIxgJYOqYNwwXuVAJLJwlw",
        "secret": false,
        "signature": "Solo project of Josh Zimmer\n99% Sarcasm, 1% Indie rock\n\ud83d\udc47Get a Synth-a-Sette\ud83d\udc47",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "projectatlanticmusic",
        "verified": false
      },
      "authorStats": {
        "diggCount": 2195,
        "followerCount": 52600,
        "followingCount": 421,
        "friendCount": 0,
        "heart": 4700000,
        "heartCount": 4700000,
        "videoCount": 914
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "5636",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "music"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "16103",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "\ud83e\ude97What's your favourite instrument?",
          "id": "1609427218899973",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musiciansoftiktok"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "323291",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indierock"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "37634",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "independentartist"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "19244024",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musichumor"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1365008",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "synth"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "725932",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "cassette"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1624925290039301",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "analogsynth"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7175559384712364078",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "synthasette"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1351",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "mgmt"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "Pois pode separar o seu V3, a sua cal\u00e7a de cintura baixa e o seu piercing no umbigo: entra no carro amiga, n\u00f3s vamos pros anos 2000!",
          "id": "204050",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/58d552f6bdff064386244f8018e4a46f",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/58d552f6bdff064386244f8018e4a46f",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/58d552f6bdff064386244f8018e4a46f",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "2000s"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1645809370293254",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "independentmusician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "Love #IndieMusic? Start here.",
          "id": "247262",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/164263ca9942461c79d1ac0ee9fa0b21.jpeg",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/164263ca9942461c79d1ac0ee9fa0b21.jpeg",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/164263ca9942461c79d1ac0ee9fa0b21.jpeg",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "35857",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indieartist"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "39733345",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "projectatlantic"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "Click the lincoln bye-oh to get your very own Synth-a-Sette "
        },
        {
          "desc": ""
        },
        {
          "desc": "Synth-a-Sette built by @MicroKits ",
          "textExtra": [
            {
              "awemeId": "",
              "end": 33,
              "hashtagId": "",
              "hashtagName": "",
              "isCommerce": false,
              "secUid": "",
              "start": 23,
              "subType": 0,
              "type": 0,
              "userId": "7280546587989296171",
              "userUniqueId": ""
            }
          ]
        },
        {
          "desc": ""
        },
        {
          "desc": "#music #musician #musiciansoftiktok #indierock #independentartist #musichumor #synth #cassette #analogsynth #synthasette #mgmt #2000s #independentmusician #indiemusician #indiemusic #indieartist #projectatlantic  ",
          "textExtra": [
            {
              "awemeId": "",
              "end": 6,
              "hashtagId": "5636",
              "hashtagName": "music",
              "isCommerce": false,
              "secUid": "",
              "start": 0,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 16,
              "hashtagId": "16103",
              "hashtagName": "musician",
              "isCommerce": false,
              "secUid": "",
              "start": 7,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 35,
              "hashtagId": "1609427218899973",
              "hashtagName": "musiciansoftiktok",
              "isCommerce": false,
              "secUid": "",
              "start": 17,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 46,
              "hashtagId": "323291",
              "hashtagName": "indierock",
              "isCommerce": false,
              "secUid": "",
              "start": 36,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 65,
              "hashtagId": "37634",
              "hashtagName": "independentartist",
              "isCommerce": false,
              "secUid": "",
              "start": 47,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 77,
              "hashtagId": "19244024",
              "hashtagName": "musichumor",
              "isCommerce": false,
              "secUid": "",
              "start": 66,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 84,
              "hashtagId": "1365008",
              "hashtagName": "synth",
              "isCommerce": false,
              "secUid": "",
              "start": 78,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 94,
              "hashtagId": "725932",
              "hashtagName": "cassette",
              "isCommerce": false,
              "secUid": "",
              "start": 85,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 107,
              "hashtagId": "1624925290039301",
              "hashtagName": "analogsynth",
              "isCommerce": false,
              "secUid": "",
              "start": 95,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 120,
              "hashtagId": "7175559384712364078",
              "hashtagName": "synthasette",
              "isCommerce": false,
              "secUid": "",
              "start": 108,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 126,
              "hashtagId": "1351",
              "hashtagName": "mgmt",
              "isCommerce": false,
              "secUid": "",
              "start": 121,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 133,
              "hashtagId": "204050",
              "hashtagName": "2000s",
              "isCommerce": false,
              "secUid": "",
              "start": 127,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 154,
              "hashtagId": "1645809370293254",
              "hashtagName": "independentmusician",
              "isCommerce": false,
              "secUid": "",
              "start": 134,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 169,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 155,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 181,
              "hashtagId": "247262",
              "hashtagName": "indiemusic",
              "isCommerce": false,
              "secUid": "",
              "start": 170,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 194,
              "hashtagId": "35857",
              "hashtagName": "indieartist",
              "isCommerce": false,
              "secUid": "",
              "start": 182,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 211,
              "hashtagId": "39733345",
              "hashtagName": "projectatlantic",
              "isCommerce": false,
              "secUid": "",
              "start": 195,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1698581738,
      "desc": "Click the lincoln bye-oh to get your very own Synth-a-Sette  Synth-a-Sette built by @MicroKits  #music #musician #musiciansoftiktok #indierock #independentartist #musichumor #synth #cassette #analogsynth #synthasette #mgmt #2000s #independentmusician #indiemusician #indiemusic #indieartist #projectatlantic  ",
      "digged": false,
      "diversificationId": 10009,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7295352896038636842",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "",
        "authorName": "Project Atlantic",
        "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/7c903ffd7492d0b2c45c1ab00e2f3740~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=aJ0x9MwhSuCPttKKayH3WRDIJco%3D",
        "coverMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/7c903ffd7492d0b2c45c1ab00e2f3740~c5_720x720.jpeg?x-expires=1700125200&x-signature=mlmIl%2FY8oWEvqw2zsFky0yiyykI%3D",
        "coverThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/7c903ffd7492d0b2c45c1ab00e2f3740~c5_100x100.jpeg?x-expires=1700125200&x-signature=A7zAD%2BgR1pyiozrBzSi3FzaWAj4%3D",
        "duration": 10,
        "id": "7295352984987372331",
        "original": true,
        "playUrl": "https://v16m.tiktokcdn.com/cefb3e13024b263f43f6562ebd4e259e/65539985/video/tos/maliva/tos-maliva-ve-27dcd7c799-us/oMOfQuLzyhIVEPpeOFGJYnfHmEUATFBQOJIHeL/?a=1233&ch=0&cr=0&dr=0&er=2&cd=0%7C0%7C0%7C0&br=250&bt=125&bti=NDU3ZjAwOg%3D%3D&ft=d2A~l-Inz7TPgL-Ziyq8Z&mime_type=audio_mpeg&qs=6&rc=aGhoZmU6OzQ2Ojw5ZjQ8O0Bpajo6bGs5cnQ3bjMzZzU8NEA2L2AtMC5hXzUxLl9fY140YSNvcy9sMmRrbXNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00088000&cc=3&download=true",
        "title": "original sound"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "7295278724411558702",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 61800,
        "commentCount": 2269,
        "diggCount": 495400,
        "playCount": 8900000,
        "shareCount": 4726
      },
      "stickersOnItem": [
        {
          "stickerText": [
            "Playing the best song of the 2000s on my \nSynth-a-Sette",
            "Guess the song and the band, win bragging rights"
          ],
          "stickerType": 4
        },
        {
          "stickerText": [
            "Best Song of\nthe 2000s On My\nSynth-a-Sette"
          ],
          "stickerType": 9
        }
      ],
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 94,
          "hashtagId": "",
          "hashtagName": "",
          "isCommerce": false,
          "secUid": "MS4wLjABAAAAkwuwoTcK9yutb0QWrNI-HKKIDoiPdbB99XK4lPtLIonBI9XxxuMCj0rUZi43J2xP",
          "start": 84,
          "subType": 0,
          "type": 0,
          "userId": "7280546587989296171",
          "userUniqueId": "buildmicrokits"
        },
        {
          "awemeId": "",
          "end": 102,
          "hashtagId": "5636",
          "hashtagName": "music",
          "isCommerce": false,
          "secUid": "",
          "start": 96,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 112,
          "hashtagId": "16103",
          "hashtagName": "musician",
          "isCommerce": false,
          "secUid": "",
          "start": 103,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 131,
          "hashtagId": "1609427218899973",
          "hashtagName": "musiciansoftiktok",
          "isCommerce": false,
          "secUid": "",
          "start": 113,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 142,
          "hashtagId": "323291",
          "hashtagName": "indierock",
          "isCommerce": false,
          "secUid": "",
          "start": 132,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 161,
          "hashtagId": "37634",
          "hashtagName": "independentartist",
          "isCommerce": false,
          "secUid": "",
          "start": 143,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 173,
          "hashtagId": "19244024",
          "hashtagName": "musichumor",
          "isCommerce": false,
          "secUid": "",
          "start": 162,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 180,
          "hashtagId": "1365008",
          "hashtagName": "synth",
          "isCommerce": false,
          "secUid": "",
          "start": 174,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 190,
          "hashtagId": "725932",
          "hashtagName": "cassette",
          "isCommerce": false,
          "secUid": "",
          "start": 181,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 203,
          "hashtagId": "1624925290039301",
          "hashtagName": "analogsynth",
          "isCommerce": false,
          "secUid": "",
          "start": 191,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 216,
          "hashtagId": "7175559384712364078",
          "hashtagName": "synthasette",
          "isCommerce": false,
          "secUid": "",
          "start": 204,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 222,
          "hashtagId": "1351",
          "hashtagName": "mgmt",
          "isCommerce": false,
          "secUid": "",
          "start": 217,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 229,
          "hashtagId": "204050",
          "hashtagName": "2000s",
          "isCommerce": false,
          "secUid": "",
          "start": 223,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 250,
          "hashtagId": "1645809370293254",
          "hashtagName": "independentmusician",
          "isCommerce": false,
          "secUid": "",
          "start": 230,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 265,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 251,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 277,
          "hashtagId": "247262",
          "hashtagName": "indiemusic",
          "isCommerce": false,
          "secUid": "",
          "start": 266,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 290,
          "hashtagId": "35857",
          "hashtagName": "indieartist",
          "isCommerce": false,
          "secUid": "",
          "start": 278,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 307,
          "hashtagId": "39733345",
          "hashtagName": "projectatlantic",
          "isCommerce": false,
          "secUid": "",
          "start": 291,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 731824,
        "bitrateInfo": [
          {
            "Bitrate": 731824,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 972778,
              "FileCs": "c:0-10327-1456",
              "FileHash": "1f96707272f88a6c7334a91bd59dab7f",
              "Uri": "v12044gd0000ckv4pgfog65lf28jdcgg",
              "UrlKey": "v12044gd0000ckv4pgfog65lf28jdcgg_h264_540p_731824",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oYZeB8zE9SRJdI9QNBFlATRDgCSZrDkOfkPEtq/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1428&bt=714&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=PGVoNjVmZTk6NGU8ZGlkZkBpamZtNW85cmo3bjMzZzczNEAwMy0tNDIxXmMxNS0tMF41YSNkYTVjMmRjbXNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128806&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=0e26271e058b6b26a9ccfe94ff07ad30&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oYZeB8zE9SRJdI9QNBFlATRDgCSZrDkOfkPEtq/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1428&bt=714&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=PGVoNjVmZTk6NGU8ZGlkZkBpamZtNW85cmo3bjMzZzczNEAwMy0tNDIxXmMxNS0tMF41YSNkYTVjMmRjbXNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128806&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=0e26271e058b6b26a9ccfe94ff07ad30&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 549326,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 730192,
              "FileCs": "c:0-10477-a07c",
              "FileHash": "375ed455a7590d13669fb9507adeafa2",
              "Uri": "v12044gd0000ckv4pgfog65lf28jdcgg",
              "UrlKey": "v12044gd0000ckv4pgfog65lf28jdcgg_bytevc1_1080p_549326",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o0FzWSilPAUtDZBWEqSkBRTBS6efIXJKE8CgQD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1072&bt=536&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=OTY4Nzc0aDlkaDY1ZjU7aUBpamZtNW85cmo3bjMzZzczNEBhXi0xMGEwX2MxXmE1LmNfYSNkYTVjMmRjbXNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128806&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=97f5cc80c15ed698f9e9760f25985ad2&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o0FzWSilPAUtDZBWEqSkBRTBS6efIXJKE8CgQD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1072&bt=536&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=OTY4Nzc0aDlkaDY1ZjU7aUBpamZtNW85cmo3bjMzZzczNEBhXi0xMGEwX2MxXmE1LmNfYSNkYTVjMmRjbXNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128806&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=97f5cc80c15ed698f9e9760f25985ad2&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 386476,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 513724,
              "FileCs": "c:0-10327-e4e9",
              "FileHash": "b06656370752ef5c7d6fcd69091e8da7",
              "Uri": "v12044gd0000ckv4pgfog65lf28jdcgg",
              "UrlKey": "v12044gd0000ckv4pgfog65lf28jdcgg_h264_540p_386476",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/osSDCrYXEh9PIOFgl4ZAJTkf8zqRBBEPeQDtSt/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=754&bt=377&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=OTM1ZTk0OjpkZWQ7NDY4NkBpamZtNW85cmo3bjMzZzczNEAvNGBiMzViNS0xYzUyNDIxYSNkYTVjMmRjbXNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128806&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=2ee753eb634c16642b0ac0384c66a6ed&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/osSDCrYXEh9PIOFgl4ZAJTkf8zqRBBEPeQDtSt/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=754&bt=377&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=OTM1ZTk0OjpkZWQ7NDY4NkBpamZtNW85cmo3bjMzZzczNEAvNGBiMzViNS0xYzUyNDIxYSNkYTVjMmRjbXNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128806&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=2ee753eb634c16642b0ac0384c66a6ed&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 361926,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 481091,
              "FileCs": "c:0-10478-7e15",
              "FileHash": "68f08717910cca13a530b84589f0d72b",
              "Uri": "v12044gd0000ckv4pgfog65lf28jdcgg",
              "UrlKey": "v12044gd0000ckv4pgfog65lf28jdcgg_bytevc1_720p_361926",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o8z8qAfSSPIOCTRZEBgJQtFEuQrZoleQD6DkBP/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=706&bt=353&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=OGQ7NGk5OTUzPDlkaTw1Z0BpamZtNW85cmo3bjMzZzczNEA0NjYwLjAtXzQxXjIvMWM0YSNkYTVjMmRjbXNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128806&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=9eb2cf3fa0b2f28501d5b506f86749e1&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o8z8qAfSSPIOCTRZEBgJQtFEuQrZoleQD6DkBP/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=706&bt=353&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=OGQ7NGk5OTUzPDlkaTw1Z0BpamZtNW85cmo3bjMzZzczNEA0NjYwLjAtXzQxXjIvMWM0YSNkYTVjMmRjbXNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128806&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=9eb2cf3fa0b2f28501d5b506f86749e1&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 286720,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 381123,
              "FileCs": "c:0-10478-c25f",
              "FileHash": "6847e33f8df7d1533b2565426579e47f",
              "Uri": "v12044gd0000ckv4pgfog65lf28jdcgg",
              "UrlKey": "v12044gd0000ckv4pgfog65lf28jdcgg_bytevc1_540p_286720",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/os8lEOzJoAYqLRBlgQFCPTSDBfSZtQkEDEBZeI/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=560&bt=280&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=ODplOGY0PDc0aTk8M2ZkO0BpamZtNW85cmo3bjMzZzczNEAtMjQzY15gX2AxXzY0MjBeYSNkYTVjMmRjbXNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128806&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=251ebbf7b775a126063231b8d28fbc75&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/os8lEOzJoAYqLRBlgQFCPTSDBfSZtQkEDEBZeI/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=560&bt=280&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=ODplOGY0PDc0aTk8M2ZkO0BpamZtNW85cmo3bjMzZzczNEAtMjQzY15gX2AxXzY0MjBeYSNkYTVjMmRjbXNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128806&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=251ebbf7b775a126063231b8d28fbc75&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 286547,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 380893,
              "FileCs": "c:0-10327-0ae1",
              "FileHash": "a572a52ddc992aeaa4b2fa1d77850468",
              "Uri": "v12044gd0000ckv4pgfog65lf28jdcgg",
              "UrlKey": "v12044gd0000ckv4pgfog65lf28jdcgg_h264_540p_286547",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oA8SQmBlDg7I6pRCnE4OfLZAeBwDPFqzkStTJE/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=558&bt=279&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=OTQ4aTtpNzw1MzU0NjM6ZEBpamZtNW85cmo3bjMzZzczNEA1LzY0MzAvNl8xLl81NTQxYSNkYTVjMmRjbXNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128806&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=43a3ec414cd1904b9acddce9014e65c6&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oA8SQmBlDg7I6pRCnE4OfLZAeBwDPFqzkStTJE/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=558&bt=279&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=OTQ4aTtpNzw1MzU0NjM6ZEBpamZtNW85cmo3bjMzZzczNEA1LzY0MzAvNl8xLl81NTQxYSNkYTVjMmRjbXNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128806&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=43a3ec414cd1904b9acddce9014e65c6&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/owBNRsDTPRkfz8NJe8aSSnBECCqFIEltDQgAOZ~tplv-dmt-logom:tos-useast5-i-0068-tx/o8mwDrfDSIuxR5lErTzmBPykeCOAnEzAFAApQg.image?x-expires=1700125200&x-signature=vCkWSHfNx7iCA8bPAYSMJW81g%2Fs%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oo8SQFBlDgDIIBRCCEYPfgZAeB8DPFqzkStTJE/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=1576&bt=788&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aTw6OWY6OThpOjpoNDUzM0BpamZtNW85cmo3bjMzZzczNEA2Yi9eLTFiXjYxXzFjMGIzYSNkYTVjMmRjbXNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128806&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=a48a3ef5ea05a22fb9ba8cf091cbbc57&tk=tt_chain_token",
        "duration": 10,
        "dynamicCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/1aa2d8cf7917452abc00598773a5861e_1698581739?x-expires=1700125200&x-signature=LWAPPlzBv0qgyPMCzP%2Fv4OVL7Ac%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7295352896038636842",
        "originCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/f695ba3ade144e0e9471b59f96d435ec_1698581739?x-expires=1700125200&x-signature=KFPsRAgd2SSnnaGobF8BJMUJ5xw%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oYZeB8zE9SRJdI9QNBFlATRDgCSZrDkOfkPEtq/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1428&bt=714&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=PGVoNjVmZTk6NGU8ZGlkZkBpamZtNW85cmo3bjMzZzczNEAwMy0tNDIxXmMxNS0tMF41YSNkYTVjMmRjbXNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128806&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=0e26271e058b6b26a9ccfe94ff07ad30&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/owBNRsDTPRkfz8NJe8aSSnBECCqFIEltDQgAOZ~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=8bE9rcXdUkQfZQreDOwd%2BTYZ%2F78%3D",
        "shareCover": [
          "",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/f695ba3ade144e0e9471b59f96d435ec_1698581739~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=OudAgQ30dtiUf269cqSL7Vu7Khw%3D",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/f695ba3ade144e0e9471b59f96d435ec_1698581739~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=f2wfwCkxgSHZftGvKn4FAHINWvg%3D"
        ],
        "size": 972778,
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -21.3,
          "Peak": 0.54325
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/owBNRsDTPRkfz8NJe8aSSnBECCqFIEltDQgAOZ~tplv-dmt-logom:tos-useast5-i-0068-tx/o8mwDrfDSIuxR5lErTzmBPykeCOAnEzAFAApQg.image?x-expires=1700125200&x-signature=vCkWSHfNx7iCA8bPAYSMJW81g%2Fs%3D",
          "480": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/owBNRsDTPRkfz8NJe8aSSnBECCqFIEltDQgAOZ~tplv-dmt-logom:tos-useast5-i-0068-tx/o8mwDrfDSIuxR5lErTzmBPykeCOAnEzAFAApQg.image?x-expires=1700125200&x-signature=vCkWSHfNx7iCA8bPAYSMJW81g%2Fs%3D",
          "720": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/owBNRsDTPRkfz8NJe8aSSnBECCqFIEltDQgAOZ~tplv-dmt-logom:tos-useast5-i-0068-tx/o8mwDrfDSIuxR5lErTzmBPykeCOAnEzAFAApQg.image?x-expires=1700125200&x-signature=vCkWSHfNx7iCA8bPAYSMJW81g%2Fs%3D",
          "960": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/owBNRsDTPRkfz8NJe8aSSnBECCqFIEltDQgAOZ~tplv-dmt-logom:tos-useast5-i-0068-tx/o8mwDrfDSIuxR5lErTzmBPykeCOAnEzAFAApQg.image?x-expires=1700125200&x-signature=%2FoMMqlWYy4NGfPMKsyhsgnA9C28%3D"
        }
      },
      "videoSuggestWordsList": {
        "video_suggest_words_struct": [
          {
            "hint_text": "Search \u00b7  ",
            "scene": "feed_bar",
            "words": [
              {
                "word": "synthasette alan walker",
                "word_id": "8748539121057214230"
              }
            ]
          }
        ]
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/699ba7074b36f305e85a292b35b7ce3e~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=qlBArAY%2BsfzknRzHphWHBaHkwfg%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/699ba7074b36f305e85a292b35b7ce3e~c5_720x720.jpeg?x-expires=1700125200&x-signature=MyjUNv0zn1urTgr6kXF%2B%2FAxwf10%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/699ba7074b36f305e85a292b35b7ce3e~c5_100x100.jpeg?x-expires=1700125200&x-signature=yLq3QdQeb4EsxjWfAel2sbFL9yI%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 1,
        "ftc": false,
        "id": "6721790868301480966",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "alex",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAAZ7qWJ6smvt0fYpKLRKvtWFmAny_S7I4aEqv6Cpxh_JeH8bTTxqb6uD54zc1XvSwi",
        "secret": false,
        "signature": "musician",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "dogberryisdead",
        "verified": false
      },
      "authorStats": {
        "diggCount": 82700,
        "followerCount": 24900,
        "followingCount": 391,
        "friendCount": 0,
        "heart": 495400,
        "heartCount": 495400,
        "videoCount": 6
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "229207",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "fyp"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "28658389",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "phoebebridgers"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "5435",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musicvideo"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "453459",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "samia"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "35857",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indieartist"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "u can stream rubber band / enabler everywhere now !! #fyp #indiemusician #phoebebridgers #musicvideo #samia #indieartist",
          "textExtra": [
            {
              "awemeId": "",
              "end": 57,
              "hashtagId": "229207",
              "hashtagName": "fyp",
              "isCommerce": false,
              "secUid": "",
              "start": 53,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 72,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 58,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 88,
              "hashtagId": "28658389",
              "hashtagName": "phoebebridgers",
              "isCommerce": false,
              "secUid": "",
              "start": 73,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 100,
              "hashtagId": "5435",
              "hashtagName": "musicvideo",
              "isCommerce": false,
              "secUid": "",
              "start": 89,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 107,
              "hashtagId": "453459",
              "hashtagName": "samia",
              "isCommerce": false,
              "secUid": "",
              "start": 101,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 120,
              "hashtagId": "35857",
              "hashtagName": "indieartist",
              "isCommerce": false,
              "secUid": "",
              "start": 108,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1646081829,
      "desc": "u can stream rubber band / enabler everywhere now !! #fyp #indiemusician #phoebebridgers #musicvideo #samia #indieartist",
      "digged": false,
      "diversificationId": 10046,
      "duetDisplay": 0,
      "duetEnabled": false,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7069867619297365290",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "",
        "authorName": "alex",
        "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/699ba7074b36f305e85a292b35b7ce3e~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=qlBArAY%2BsfzknRzHphWHBaHkwfg%3D",
        "coverMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/699ba7074b36f305e85a292b35b7ce3e~c5_720x720.jpeg?x-expires=1700125200&x-signature=MyjUNv0zn1urTgr6kXF%2B%2FAxwf10%3D",
        "coverThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/699ba7074b36f305e85a292b35b7ce3e~c5_100x100.jpeg?x-expires=1700125200&x-signature=yLq3QdQeb4EsxjWfAel2sbFL9yI%3D",
        "duration": 81,
        "id": "7069867614335421226",
        "original": true,
        "playUrl": "https://v16m.tiktokcdn.com/8a9e2b919afa19494b43c91d8c2f581c/655399cc/video/tos/maliva/tos-maliva-v-27dcd7c799-us/37c3ede87bbe45629ed2656057b3e653/?a=1233&ch=0&cr=0&dr=0&er=2&cd=0%7C0%7C0%7C0&br=250&bt=125&bti=NDU3ZjAwOg%3D%3D&ft=d2A~l-Inz7TPgL-Ziyq8Z&mime_type=audio_mpeg&qs=6&rc=NztkZzNpNDk6aGVmN2lnaEBpamw8d2Y6ZjxtOzMzZzU8NEBeLjYxLV9gX2AxYGAwXjU1YSNncnNrcjRnYmJgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00090000&cc=3&download=true",
        "title": "original sound"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 8516,
        "commentCount": 635,
        "diggCount": 487900,
        "playCount": 3300000,
        "shareCount": 247
      },
      "stickersOnItem": [
        {
          "stickerText": [
            "here's me getting buried in snow for my music video",
            "- am i too far back?\n- no no no\n- okay",
            "- oh the moon is out!\n- oh she's here!\n- oh she's here! she looks so good *cries in cold*",
            "- ready?\n- yeah",
            "getting prepared to freeze",
            "*shovelling snow sounds*",
            "i was so cold at this point since we'd been filming outside all day but we were determined to get this shot",
            "a few more seconds",
            "- it's soon\n- i know",
            "*genuinely scared*",
            "- one more?\n- yeah\n- okay let's go!! (he's so sweet trying to make sure i'm okay and not pushing too far)"
          ],
          "stickerType": 4
        },
        {
          "stickerText": [
            "getting buried in snow for my music video"
          ],
          "stickerType": 9
        }
      ],
      "stitchDisplay": 0,
      "stitchEnabled": false,
      "textExtra": [
        {
          "awemeId": "",
          "end": 57,
          "hashtagId": "229207",
          "hashtagName": "fyp",
          "isCommerce": false,
          "secUid": "",
          "start": 53,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 72,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 58,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 88,
          "hashtagId": "28658389",
          "hashtagName": "phoebebridgers",
          "isCommerce": false,
          "secUid": "",
          "start": 73,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 100,
          "hashtagId": "5435",
          "hashtagName": "musicvideo",
          "isCommerce": false,
          "secUid": "",
          "start": 89,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 107,
          "hashtagId": "453459",
          "hashtagName": "samia",
          "isCommerce": false,
          "secUid": "",
          "start": 101,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 120,
          "hashtagId": "35857",
          "hashtagName": "indieartist",
          "isCommerce": false,
          "secUid": "",
          "start": 108,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 784362,
        "bitrateInfo": [
          {
            "Bitrate": 896835,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 9183375,
              "FileCs": "c:0-51015-e869",
              "FileHash": "9e9af4f055ea6ab7b4018fd0d3fb9fe2",
              "Uri": "v12044gd0000c8ej9d3c77u14iik0vi0",
              "UrlKey": "v12044gd0000c8ej9d3c77u14iik0vi0_bytevc1_1080p_896835",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/fd6a7af0935c4d339e049e9d4f71dce0/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1750&bt=875&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=ZDY1aGU1NTZpOGk2OjM8N0BpM3lubDQ6ZmdtOzMzZzczNEBfL2NiMmFfNTAxY2IxNmAtYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128877&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=67983ee1ac9bdc42818e5d0594ba97f7&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/fd6a7af0935c4d339e049e9d4f71dce0/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1750&bt=875&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=ZDY1aGU1NTZpOGk2OjM8N0BpM3lubDQ6ZmdtOzMzZzczNEBfL2NiMmFfNTAxY2IxNmAtYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128877&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=67983ee1ac9bdc42818e5d0594ba97f7&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 784362,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 8031678,
              "FileCs": "c:0-50725-c514",
              "FileHash": "e6b2cdfd94cb36e0bb95decf3c5a2bce",
              "Uri": "v12044gd0000c8ej9d3c77u14iik0vi0",
              "UrlKey": "v12044gd0000c8ej9d3c77u14iik0vi0_h264_540p_784362",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/7a360c9ffa1240c88441edf015736924/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1530&bt=765&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZGczNDY4ZmdoOjY3NGg2ZEBpM3lubDQ6ZmdtOzMzZzczNEA0MDEyYGAuXzYxMDZjM2BeYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128877&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=74fe1dcdc95808380b89c6d2cc91f4ce&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/7a360c9ffa1240c88441edf015736924/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1530&bt=765&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZGczNDY4ZmdoOjY3NGg2ZEBpM3lubDQ6ZmdtOzMzZzczNEA0MDEyYGAuXzYxMDZjM2BeYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128877&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=74fe1dcdc95808380b89c6d2cc91f4ce&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 477107,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 4885458,
              "FileCs": "c:0-51016-128a",
              "FileHash": "87cb2542a56394ca526fee98ffa53900",
              "Uri": "v12044gd0000c8ej9d3c77u14iik0vi0",
              "UrlKey": "v12044gd0000c8ej9d3c77u14iik0vi0_bytevc1_720p_477107",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/4014ec275a4a43529d8b126893c8c770/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=930&bt=465&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=ODg0PDY1ZzQzaTc1ZzQ7Z0BpM3lubDQ6ZmdtOzMzZzczNEA2LV9fMjItNl4xYF4vLWAuYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128877&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=ffc38c2f85ade37b19926f2c90247be3&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/4014ec275a4a43529d8b126893c8c770/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=930&bt=465&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=ODg0PDY1ZzQzaTc1ZzQ7Z0BpM3lubDQ6ZmdtOzMzZzczNEA2LV9fMjItNl4xYF4vLWAuYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128877&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=ffc38c2f85ade37b19926f2c90247be3&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 417798,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 4278151,
              "FileCs": "c:0-50725-5547",
              "FileHash": "37f50814347bc4f616ff580bec1ba2e8",
              "Uri": "v12044gd0000c8ej9d3c77u14iik0vi0",
              "UrlKey": "v12044gd0000c8ej9d3c77u14iik0vi0_h264_540p_417798",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/c650c3bf47ee44a39ef2ccbc308ef1fb/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=816&bt=408&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=NjxmZ2hmZTk0ZGloNGQ2NkBpM3lubDQ6ZmdtOzMzZzczNEBiLmA2MzQtNjYxNV80MDRgYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128877&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=ec791435d851961d2a4dfe3990bbd7e8&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/c650c3bf47ee44a39ef2ccbc308ef1fb/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=816&bt=408&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=NjxmZ2hmZTk0ZGloNGQ2NkBpM3lubDQ6ZmdtOzMzZzczNEBiLmA2MzQtNjYxNV80MDRgYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128877&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=ec791435d851961d2a4dfe3990bbd7e8&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 406999,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 4167576,
              "FileCs": "c:0-51016-e4b6",
              "FileHash": "85ca42280021618d843050c187b9bba4",
              "Uri": "v12044gd0000c8ej9d3c77u14iik0vi0",
              "UrlKey": "v12044gd0000c8ej9d3c77u14iik0vi0_bytevc1_540p_406999",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/a56f502002364d4881919631382a86b4/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=794&bt=397&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=ZGQ3NTVkPDs3Nzw5OGhnNEBpM3lubDQ6ZmdtOzMzZzczNEAxNTEwYjUvXzMxYjUtNjNhYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128877&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=1ee0f860f1ff11be7a1048ec656b79a8&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/a56f502002364d4881919631382a86b4/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=794&bt=397&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=ZGQ3NTVkPDs3Nzw5OGhnNEBpM3lubDQ6ZmdtOzMzZzczNEAxNTEwYjUvXzMxYjUtNjNhYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128877&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=1ee0f860f1ff11be7a1048ec656b79a8&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 297570,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 3047052,
              "FileCs": "c:0-50725-e2a4",
              "FileHash": "3ab41369fc2fa7949725349263cfa601",
              "Uri": "v12044gd0000c8ej9d3c77u14iik0vi0",
              "UrlKey": "v12044gd0000c8ej9d3c77u14iik0vi0_h264_540p_297570",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ae5e0e21e73545b493d3f67386e006dd/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=580&bt=290&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=NmY5Nzg8OGZmZWg5NWhoZ0BpM3lubDQ6ZmdtOzMzZzczNEA2YDRiY19hNi0xXjVfNV5fYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128877&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=2423032ade0712d422b2c5a1d74a2681&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ae5e0e21e73545b493d3f67386e006dd/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=580&bt=290&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=NmY5Nzg8OGZmZWg5NWhoZ0BpM3lubDQ6ZmdtOzMzZzczNEA2YDRiY19hNi0xXjVfNV5fYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128877&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=2423032ade0712d422b2c5a1d74a2681&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/101160f5262c42daaf6564e7a669e268_1646081842~tplv-dmt-logom:tos-useast5-p-0000-tx/b03c83450efe46df838f4a2a72a49e48.image?x-expires=1700125200&x-signature=R8i5zkLv%2FvlDuO7gYG%2F42KQTeIU%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/d71c0fab30a8423b97bb94618ee15277/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=1552&bt=776&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZzY1Z2U5Zmg6NzU1ODpnZkBpM3lubDQ6ZmdtOzMzZzczNEA1NDIwXy4uNS8xLjZfLzEzYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128877&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=f62acd2d6ba70465c01c3dd744810a79&tk=tt_chain_token",
        "duration": 81,
        "dynamicCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/69bd9a95984a4908a7d6c14803e0feed_1646081831?x-expires=1700125200&x-signature=29oQdZvvD7CJ1Jkna4EPBTUVmSE%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7069867619297365290",
        "originCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/1219a3f58dff42289676500916e8435f_1646081830?x-expires=1700125200&x-signature=DsEkl8SUn7F%2BTRYeSgExTPJlrKs%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/7a360c9ffa1240c88441edf015736924/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1530&bt=765&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZGczNDY4ZmdoOjY3NGg2ZEBpM3lubDQ6ZmdtOzMzZzczNEA0MDEyYGAuXzYxMDZjM2BeYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128877&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=74fe1dcdc95808380b89c6d2cc91f4ce&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/101160f5262c42daaf6564e7a669e268_1646081842~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=pUlTiG3aFY8xI7juS%2FJnIyCdt9s%3D",
        "shareCover": [
          "",
          "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/1219a3f58dff42289676500916e8435f_1646081830~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=qxWoxYsPFarIsDy7cbjGW33o2ZM%3D",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/1219a3f58dff42289676500916e8435f_1646081830~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=iQaj%2FcDqaeGXOu1krikE0ABFY44%3D"
        ],
        "size": 8031678,
        "subtitleInfos": [
          {
            "Format": "webvtt",
            "LanguageCodeName": "ita-IT",
            "LanguageID": "26",
            "Size": 476,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/03ae67b0a639f384447ae66501c72c2d/6555e86d/video/tos/useast2a/tos-useast2a-v-0068/3e8c641d4f92436f99b7a47226fc90c4/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13804&bt=6902&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lubDQ6ZmdtOzMzZzczNEBpM3lubDQ6ZmdtOzMzZzczNEBmLWYxcjQwNmJgLS1kMS9zYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128877,
            "Version": "4",
            "VideoSubtitleID": 1666872199
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "por-PT",
            "LanguageID": "8",
            "Size": 496,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/44c77cc88c8e2b4692c0dacb88da9804/6555e86d/video/tos/useast2a/tos-useast2a-v-0068/1a613c92eb8441b09c5a98eb9f199390/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13804&bt=6902&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lubDQ6ZmdtOzMzZzczNEBpM3lubDQ6ZmdtOzMzZzczNEBmLWYxcjQwNmJgLS1kMS9zYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128877,
            "Version": "4",
            "VideoSubtitleID": -1993752366
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "rus-RU",
            "LanguageID": "6",
            "Size": 605,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/8ac4748ff322c3fdc287c0c91e57dc65/6555e86d/video/tos/useast2a/tos-useast2a-v-0068/7c874234e21f400fb9c2d11c4bd9704f/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13804&bt=6902&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lubDQ6ZmdtOzMzZzczNEBpM3lubDQ6ZmdtOzMzZzczNEBmLWYxcjQwNmJgLS1kMS9zYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128877,
            "Version": "4",
            "VideoSubtitleID": -1836486111
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "spa-ES",
            "LanguageID": "9",
            "Size": 483,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/a55bd19823e4fd31c3b1f36c3c54b270/6555e86d/video/tos/useast2a/tos-useast2a-v-0068/612be8dc9bae4a07bfca525fcd1532f4/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13804&bt=6902&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lubDQ6ZmdtOzMzZzczNEBpM3lubDQ6ZmdtOzMzZzczNEBmLWYxcjQwNmJgLS1kMS9zYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128877,
            "Version": "4",
            "VideoSubtitleID": 1700160026
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "spa-ES",
            "LanguageID": "9",
            "Size": 498,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/a9d11e11989f0a5571a1bf7ac7d16a85/6555e86d/video/tos/useast2a/tos-useast2a-v-0068/af24e45075174e238b7fe1c96c298c02/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13804&bt=6902&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lubDQ6ZmdtOzMzZzczNEBpM3lubDQ6ZmdtOzMzZzczNEBmLWYxcjQwNmJgLS1kMS9zYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128877,
            "Version": "20",
            "VideoSubtitleID": 998771309
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "deu-DE",
            "LanguageID": "20",
            "Size": 503,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/71fe99e86e41c0f60f70ff309a633540/6555e86d/video/tos/useast2a/tos-useast2a-v-0068/cf778cf6381c43e5a547a8ec6db16495/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13804&bt=6902&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lubDQ6ZmdtOzMzZzczNEBpM3lubDQ6ZmdtOzMzZzczNEBmLWYxcjQwNmJgLS1kMS9zYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128877,
            "Version": "4",
            "VideoSubtitleID": -2051126289
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ara-SA",
            "LanguageID": "34",
            "Size": 578,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/ed62ae94cc578bb4247f2e593977950e/6555e86d/video/tos/useast2a/tos-useast2a-v-0068/af7bc599fedf4d888be2c1543c692327/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13804&bt=6902&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lubDQ6ZmdtOzMzZzczNEBpM3lubDQ6ZmdtOzMzZzczNEBmLWYxcjQwNmJgLS1kMS9zYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128877,
            "Version": "4",
            "VideoSubtitleID": -1722534459
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "fra-FR",
            "LanguageID": "7",
            "Size": 498,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/f775dfb031b92297238b940f70c10165/6555e86d/video/tos/useast2a/tos-useast2a-v-0068/90e8c3b7451e4008ab037545aba4af0a/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13804&bt=6902&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lubDQ6ZmdtOzMzZzczNEBpM3lubDQ6ZmdtOzMzZzczNEBmLWYxcjQwNmJgLS1kMS9zYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128877,
            "Version": "4",
            "VideoSubtitleID": 1602565430
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "kor-KR",
            "LanguageID": "4",
            "Size": 547,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/4bcfa8345ba5eb2fb7681db297f0aa26/6555e86d/video/tos/useast2a/tos-useast2a-v-0068/ce53954dd38f4fe39540156c073d972e/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13804&bt=6902&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lubDQ6ZmdtOzMzZzczNEBpM3lubDQ6ZmdtOzMzZzczNEBmLWYxcjQwNmJgLS1kMS9zYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128877,
            "Version": "4",
            "VideoSubtitleID": 1492076527
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ind-ID",
            "LanguageID": "23",
            "Size": 494,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/6f0734df18e6668629ca547abe4ed7db/6555e86d/video/tos/useast2a/tos-useast2a-v-0068/5afbcb48663e4b54b6abf7d1e03fda0b/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13804&bt=6902&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lubDQ6ZmdtOzMzZzczNEBpM3lubDQ6ZmdtOzMzZzczNEBmLWYxcjQwNmJgLS1kMS9zYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128877,
            "Version": "4",
            "VideoSubtitleID": -987288788
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "eng-US",
            "LanguageID": "2",
            "Size": 442,
            "Source": "ASR",
            "Url": "https://v16-webapp.tiktok.com/5b46a0c56428eff2b05a540fb3693ecb/6555e86d/video/tos/useast2a/tos-useast2a-v-0068/5b8643622c6444ec836d52664cd9a259/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13804&bt=6902&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lubDQ6ZmdtOzMzZzczNEBpM3lubDQ6ZmdtOzMzZzczNEBmLWYxcjQwNmJgLS1kMS9zYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128877,
            "Version": "1",
            "VideoSubtitleID": -1220420279
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "jpn-JP",
            "LanguageID": "3",
            "Size": 559,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/9bac567cdf994e2f0ac92328cbd62f73/6555e86d/video/tos/useast2a/tos-useast2a-v-0068/ca8fe39b1a754faba2a5646f56957120/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=13804&bt=6902&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3lubDQ6ZmdtOzMzZzczNEBpM3lubDQ6ZmdtOzMzZzczNEBmLWYxcjQwNmJgLS1kMS9zYSNmLWYxcjQwNmJgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128877,
            "Version": "4",
            "VideoSubtitleID": 852262863
          }
        ],
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -14.3,
          "Peak": 1
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/101160f5262c42daaf6564e7a669e268_1646081842~tplv-dmt-logom:tos-useast5-p-0000-tx/b03c83450efe46df838f4a2a72a49e48.image?x-expires=1700125200&x-signature=L2lOQoatapmsvgDZJZGeoUOcDgk%3D",
          "480": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/101160f5262c42daaf6564e7a669e268_1646081842~tplv-dmt-logom:tos-useast5-p-0000-tx/b03c83450efe46df838f4a2a72a49e48.image?x-expires=1700125200&x-signature=R8i5zkLv%2FvlDuO7gYG%2F42KQTeIU%3D",
          "720": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/101160f5262c42daaf6564e7a669e268_1646081842~tplv-dmt-logom:tos-useast5-p-0000-tx/b03c83450efe46df838f4a2a72a49e48.image?x-expires=1700125200&x-signature=R8i5zkLv%2FvlDuO7gYG%2F42KQTeIU%3D",
          "960": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/101160f5262c42daaf6564e7a669e268_1646081842~tplv-dmt-logom:tos-useast5-p-0000-tx/b03c83450efe46df838f4a2a72a49e48.image?x-expires=1700125200&x-signature=R8i5zkLv%2FvlDuO7gYG%2F42KQTeIU%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/2afecdc497385c0732abe7d6ff97e73b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=uXJLUB4WM%2FkLaoxOoW96hy%2FKpDo%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/2afecdc497385c0732abe7d6ff97e73b~c5_720x720.jpeg?x-expires=1700125200&x-signature=qSy0RcDyZke%2FUb%2B%2BbKPEbYzEE0I%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/2afecdc497385c0732abe7d6ff97e73b~c5_100x100.jpeg?x-expires=1700125200&x-signature=BJCkA88RPNhUQQVPbX8KDp%2FEgd4%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "6599265971915456517",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Kelechi",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAAZM9gomE1nLfD6p9ipbo7-BQNO3owbM2IAgVvmpAfF5kepiP95Sh2HI0g8uFH9e9S",
        "secret": false,
        "signature": "Indie Music\nYour Gay Pop Singing Bestie \ud83d\ude0a\ud83c\udf08",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "itskelechi",
        "verified": false
      },
      "authorStats": {
        "diggCount": 6337,
        "followerCount": 54600,
        "followingCount": 605,
        "friendCount": 0,
        "heart": 1200000,
        "heartCount": 1200000,
        "videoCount": 102
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "10381824",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "justanothersong"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "Love #IndieMusic? Start here.",
          "id": "247262",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/164263ca9942461c79d1ac0ee9fa0b21.jpeg",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/164263ca9942461c79d1ac0ee9fa0b21.jpeg",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/164263ca9942461c79d1ac0ee9fa0b21.jpeg",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "294930",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiepop"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1654829400136709",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "songwritersoftiktok"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        },
        {
          "coverLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/d7fd84c55a7b432d6eca7b4eae06321b.jpeg",
          "coverMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/d7fd84c55a7b432d6eca7b4eae06321b.jpeg",
          "coverThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/d7fd84c55a7b432d6eca7b4eae06321b.jpeg",
          "desc": "Whether you're hitting those high notes or sharing the perfect playlist, we're here for all things #PopMusic.",
          "id": "35831",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/5ca653b2457e9fd57cf4d97dcc659cec.png",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/5ca653b2457e9fd57cf4d97dcc659cec.png",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/5ca653b2457e9fd57cf4d97dcc659cec.png",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "popmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "229207",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "fyp"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "welcome to the party bestie :))) \u201cjust another song\u201d by kelechi is streaming everywhere #justanothersong #indiemusic #indiepop #songwritersoftiktok #indiemusician #popmusic #fyp",
          "textExtra": [
            {
              "awemeId": "",
              "end": 104,
              "hashtagId": "10381824",
              "hashtagName": "justanothersong",
              "isCommerce": false,
              "secUid": "",
              "start": 88,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 116,
              "hashtagId": "247262",
              "hashtagName": "indiemusic",
              "isCommerce": false,
              "secUid": "",
              "start": 105,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 126,
              "hashtagId": "294930",
              "hashtagName": "indiepop",
              "isCommerce": false,
              "secUid": "",
              "start": 117,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 147,
              "hashtagId": "1654829400136709",
              "hashtagName": "songwritersoftiktok",
              "isCommerce": false,
              "secUid": "",
              "start": 127,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 162,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 148,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 172,
              "hashtagId": "35831",
              "hashtagName": "popmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 163,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 177,
              "hashtagId": "229207",
              "hashtagName": "fyp",
              "isCommerce": false,
              "secUid": "",
              "start": 173,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1655766108,
      "desc": "welcome to the party bestie :))) \u201cjust another song\u201d by kelechi is streaming everywhere #justanothersong #indiemusic #indiepop #songwritersoftiktok #indiemusician #popmusic #fyp",
      "digged": false,
      "diversificationId": 10046,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7111461278882745646",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "just another song",
        "authorName": "Kelechi",
        "coverLarge": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/133c44e173144e83b34e2b51f4fa0825~c5_720x720.jpeg",
        "coverMedium": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/133c44e173144e83b34e2b51f4fa0825~c5_200x200.jpeg",
        "coverThumb": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/133c44e173144e83b34e2b51f4fa0825~c5_100x100.jpeg",
        "duration": 60,
        "id": "7094042706875271170",
        "original": false,
        "playUrl": "https://sf16-ies-music-va.tiktokcdn.com/obj/tos-useast2a-ve-2774/17631b850ffb48e78f032830a5036e0f",
        "title": "just another song"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 23500,
        "commentCount": 6666,
        "diggCount": 415500,
        "playCount": 2100000,
        "shareCount": 15700
      },
      "stickersOnItem": [
        {
          "stickerText": [
            "Hi!\nIf you\u2019re seeing this,\nthen this is your chance\nto help a Black queer\nindie pop artist reach his dreams. Use this sound.\nYou don\u2019t have to do anything. You can just sit there. Or keep it in your drafts. Or make it private. Thank you \ud83e\udd79\u2764\ufe0f\u200d\ud83d\udd25"
          ],
          "stickerType": 4
        }
      ],
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 104,
          "hashtagId": "10381824",
          "hashtagName": "justanothersong",
          "isCommerce": false,
          "secUid": "",
          "start": 88,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 116,
          "hashtagId": "247262",
          "hashtagName": "indiemusic",
          "isCommerce": false,
          "secUid": "",
          "start": 105,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 126,
          "hashtagId": "294930",
          "hashtagName": "indiepop",
          "isCommerce": false,
          "secUid": "",
          "start": 117,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 147,
          "hashtagId": "1654829400136709",
          "hashtagName": "songwritersoftiktok",
          "isCommerce": false,
          "secUid": "",
          "start": 127,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 162,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 148,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 172,
          "hashtagId": "35831",
          "hashtagName": "popmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 163,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 177,
          "hashtagId": "229207",
          "hashtagName": "fyp",
          "isCommerce": false,
          "secUid": "",
          "start": 173,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 1185004,
        "bitrateInfo": [
          {
            "Bitrate": 1399090,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 1672613,
              "FileCs": "c:0-9223-a7b3",
              "FileHash": "1173a8e4e62970c6342b982f479027d8",
              "Uri": "v12044gd0000caofmarc77u9eu7trm8g",
              "UrlKey": "v12044gd0000caofmarc77u9eu7trm8g_bytevc1_1080p_1399090",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/d46266e06a9c4cd1b07cdf99904be0af/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2732&bt=1366&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=Z2U7N2ZoO2Y2OWVpNDQ1aUBpanB3eDw6ZmRpZDMzZzczNEAwYi1iMzI0NV8xYDM2YGFiYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=b24a7963a298bdefb55efc89c9c62ef5&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/d46266e06a9c4cd1b07cdf99904be0af/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2732&bt=1366&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=Z2U7N2ZoO2Y2OWVpNDQ1aUBpanB3eDw6ZmRpZDMzZzczNEAwYi1iMzI0NV8xYDM2YGFiYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=b24a7963a298bdefb55efc89c9c62ef5&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 1185004,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 1416673,
              "FileCs": "c:0-9062-001b",
              "FileHash": "914af6abc91f7f8ddc2ed7ec65b1665f",
              "Uri": "v12044gd0000caofmarc77u9eu7trm8g",
              "UrlKey": "v12044gd0000caofmarc77u9eu7trm8g_h264_540p_1185004",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/75c0ed48553347aab66152433cf5f600/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2314&bt=1157&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=Zjg3ZTtpZmloNDk4ZWhoaUBpanB3eDw6ZmRpZDMzZzczNEAyXjE0Xl8tX2AxNi0uLTEtYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=964714ba9907e9c69abd140504abdf66&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/75c0ed48553347aab66152433cf5f600/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2314&bt=1157&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=Zjg3ZTtpZmloNDk4ZWhoaUBpanB3eDw6ZmRpZDMzZzczNEAyXjE0Xl8tX2AxNi0uLTEtYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=964714ba9907e9c69abd140504abdf66&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 897819,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 1073343,
              "FileCs": "c:0-9224-b642",
              "FileHash": "9faa181f71ffa043ff46ae1204d0df7b",
              "Uri": "v12044gd0000caofmarc77u9eu7trm8g",
              "UrlKey": "v12044gd0000caofmarc77u9eu7trm8g_bytevc1_720p_897819",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/8bb1795598ad491dadccc4c89b5eaeae/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1752&bt=876&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=aTQ6OGdkNTllZzg6Nzs4PEBpanB3eDw6ZmRpZDMzZzczNEBgMDAuMy9gXjUxYDU1M2BfYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=b21138e327c99dd903a5b69d336c5391&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/8bb1795598ad491dadccc4c89b5eaeae/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1752&bt=876&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=aTQ6OGdkNTllZzg6Nzs4PEBpanB3eDw6ZmRpZDMzZzczNEBgMDAuMy9gXjUxYDU1M2BfYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=b21138e327c99dd903a5b69d336c5391&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 731063,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 873987,
              "FileCs": "c:0-9224-31d8",
              "FileHash": "b3e7257a7569c461d3d10699a447daec",
              "Uri": "v12044gd0000caofmarc77u9eu7trm8g",
              "UrlKey": "v12044gd0000caofmarc77u9eu7trm8g_bytevc1_540p_731063",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/3ec7b0aba6514e90aa3dbaa5bb9ace15/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1426&bt=713&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=ZjlnOGg4OTY2aTw6NjQ7aUBpanB3eDw6ZmRpZDMzZzczNEAuMjQzMjMyNjAxLzE1MjVgYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=d42849a2b5e8c93e9248f78f7b1f0f46&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/3ec7b0aba6514e90aa3dbaa5bb9ace15/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1426&bt=713&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=ZjlnOGg4OTY2aTw6NjQ7aUBpanB3eDw6ZmRpZDMzZzczNEAuMjQzMjMyNjAxLzE1MjVgYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=d42849a2b5e8c93e9248f78f7b1f0f46&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 606749,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 725369,
              "FileCs": "c:0-9062-527a",
              "FileHash": "e294eb7da1ab7a2ba9dabc6bab3a20e6",
              "Uri": "v12044gd0000caofmarc77u9eu7trm8g",
              "UrlKey": "v12044gd0000caofmarc77u9eu7trm8g_h264_540p_606749",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/8be748db388e42e5ac353eab82fe7bc5/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1184&bt=592&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=Z2lnNmg7aGdnZzdmO2Y1OEBpanB3eDw6ZmRpZDMzZzczNEAxMjBhLzMxNmIxLTVgY15iYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=e2fae67f4a920f8b6feaba3c31d52359&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/8be748db388e42e5ac353eab82fe7bc5/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1184&bt=592&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=Z2lnNmg7aGdnZzdmO2Y1OEBpanB3eDw6ZmRpZDMzZzczNEAxMjBhLzMxNmIxLTVgY15iYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=e2fae67f4a920f8b6feaba3c31d52359&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 424255,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 507197,
              "FileCs": "c:0-9062-7d2a",
              "FileHash": "51f5c447ae1e88990c85877149d6b50a",
              "Uri": "v12044gd0000caofmarc77u9eu7trm8g",
              "UrlKey": "v12044gd0000caofmarc77u9eu7trm8g_h264_540p_424255",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/f40a118aeeea472db0b02abc698b109f/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=828&bt=414&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=ZTk4ODloaGQ8aDtnNGU3O0BpanB3eDw6ZmRpZDMzZzczNEBeNGJgYzQtNmExYTYxLjBhYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=5801bd4e5b80324a88d73f9f01ed5064&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/f40a118aeeea472db0b02abc698b109f/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=828&bt=414&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=ZTk4ODloaGQ8aDtnNGU3O0BpanB3eDw6ZmRpZDMzZzczNEBeNGJgYzQtNmExYTYxLjBhYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=5801bd4e5b80324a88d73f9f01ed5064&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/ab40f8a5a20f44afaa3f6cd4fe91501f?x-expires=1700125200&x-signature=AD9HFB0pKNLjNN7nNxmdTAyQE88%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/fdaab031b3194f84904ec3e6bdadf7f0/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=2330&bt=1165&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=OzQ3Ozs4M2g8ZjQ1ZzY8O0BpanB3eDw6ZmRpZDMzZzczNEBgLS01MS8yNS0xLzItLTQ0YSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=e8307963b629d20a309b2d0e4db0f485&tk=tt_chain_token",
        "duration": 9,
        "dynamicCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/05ad47b41b8d41d3b2e70e67d485374d_1655766109?x-expires=1700125200&x-signature=l61Y3Ux%2FGkH9dXCYtdCPXIksT4U%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7111461278882745646",
        "originCover": "https://p19-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/aa5d27a51b1c440499226d5d678ff681_1655766109?x-expires=1700125200&x-signature=lbRGzKUiE71nj68gYiHY%2FY6%2BPfo%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/75c0ed48553347aab66152433cf5f600/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2314&bt=1157&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=Zjg3ZTtpZmloNDk4ZWhoaUBpanB3eDw6ZmRpZDMzZzczNEAyXjE0Xl8tX2AxNi0uLTEtYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=964714ba9907e9c69abd140504abdf66&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/ab40f8a5a20f44afaa3f6cd4fe91501f~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=aC%2FCm4F8WEFJjXFOz9b1mbYc%2BrE%3D",
        "shareCover": [
          "",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/aa5d27a51b1c440499226d5d678ff681_1655766109~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=sfXXgY4XZmwzgVogCbOz1HiwSds%3D",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/aa5d27a51b1c440499226d5d678ff681_1655766109~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=sLT%2BenccVFXjAGc%2FSSnfJ5%2BMz6M%3D"
        ],
        "size": 1416673,
        "subtitleInfos": [
          {
            "Format": "webvtt",
            "LanguageCodeName": "ita-IT",
            "LanguageID": "26",
            "Size": 249,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/a8309580980a25731bbbfa27d92b3df0/6555e825/video/tos/maliva/tos-maliva-v-0068c799-us/5712fee3b67d48fa8f93b50f27e4e56f/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2314&bt=1157&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=anB3eDw6ZmRpZDMzZzczNEBpanB3eDw6ZmRpZDMzZzczNEA1bzRicjRvamxgLS1kMS9zYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128805,
            "Version": "4",
            "VideoSubtitleID": -1426979090
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "kor-KR",
            "LanguageID": "4",
            "Size": 275,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/249eefc3dcbc0c5cbc48544a8495e261/6555e825/video/tos/maliva/tos-maliva-v-0068c799-us/1647007567134ffb9a3d232dd922eb6e/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2314&bt=1157&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=anB3eDw6ZmRpZDMzZzczNEBpanB3eDw6ZmRpZDMzZzczNEA1bzRicjRvamxgLS1kMS9zYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128805,
            "Version": "4",
            "VideoSubtitleID": -354745465
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ind-ID",
            "LanguageID": "23",
            "Size": 247,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/f51526d117d6a3f72bcbdf9b86358384/6555e825/video/tos/maliva/tos-maliva-v-0068c799-us/2461f913e8194f7f804ade6fca9e08fc/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2314&bt=1157&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=anB3eDw6ZmRpZDMzZzczNEBpanB3eDw6ZmRpZDMzZzczNEA1bzRicjRvamxgLS1kMS9zYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128805,
            "Version": "4",
            "VideoSubtitleID": 1396989133
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "jpn-JP",
            "LanguageID": "3",
            "Size": 318,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/ac11e01d13560d4b36a160730e07e5d1/6555e825/video/tos/maliva/tos-maliva-v-0068c799-us/c2f6be1f4a2c4f2798f3fc4d4e873553/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2314&bt=1157&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=anB3eDw6ZmRpZDMzZzczNEBpanB3eDw6ZmRpZDMzZzczNEA1bzRicjRvamxgLS1kMS9zYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128805,
            "Version": "4",
            "VideoSubtitleID": -1711610210
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "rus-RU",
            "LanguageID": "6",
            "Size": 297,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/99b5f6d6940ff40a6297288c6c5e0ccb/6555e825/video/tos/maliva/tos-maliva-v-0068c799-us/54621db6870549d29f45210cf3c40d05/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2314&bt=1157&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=anB3eDw6ZmRpZDMzZzczNEBpanB3eDw6ZmRpZDMzZzczNEA1bzRicjRvamxgLS1kMS9zYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128805,
            "Version": "4",
            "VideoSubtitleID": 1749683255
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ara-SA",
            "LanguageID": "34",
            "Size": 242,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/1691e9d25a2cea24c76f2fade03a5192/6555e825/video/tos/maliva/tos-maliva-v-0068c799-us/fe2d69e7fbac468089b8063c139c0b2a/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2314&bt=1157&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=anB3eDw6ZmRpZDMzZzczNEBpanB3eDw6ZmRpZDMzZzczNEA1bzRicjRvamxgLS1kMS9zYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128805,
            "Version": "4",
            "VideoSubtitleID": -1732982369
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "deu-DE",
            "LanguageID": "20",
            "Size": 252,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/7926522894f9240df74c80c267ab9ed0/6555e825/video/tos/maliva/tos-maliva-v-0068c799-us/2da8122a8f324e2fbd5ba8441a06e9d6/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2314&bt=1157&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=anB3eDw6ZmRpZDMzZzczNEBpanB3eDw6ZmRpZDMzZzczNEA1bzRicjRvamxgLS1kMS9zYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128805,
            "Version": "4",
            "VideoSubtitleID": 1829458958
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "fra-FR",
            "LanguageID": "7",
            "Size": 257,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/b4a06288392f0100ce6a09c40ff177ec/6555e825/video/tos/maliva/tos-maliva-v-0068c799-us/33592ecbb1e84a0485b02b4e01bde738/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2314&bt=1157&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=anB3eDw6ZmRpZDMzZzczNEBpanB3eDw6ZmRpZDMzZzczNEA1bzRicjRvamxgLS1kMS9zYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128805,
            "Version": "4",
            "VideoSubtitleID": -585683259
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "por-PT",
            "LanguageID": "8",
            "Size": 201,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/6ffe8bfb5f6f29b634cc5bcde9fac8ef/6555e825/video/tos/maliva/tos-maliva-v-0068c799-us/a87dd32a53ae444998967600b35a5239/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2314&bt=1157&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=anB3eDw6ZmRpZDMzZzczNEBpanB3eDw6ZmRpZDMzZzczNEA1bzRicjRvamxgLS1kMS9zYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128805,
            "Version": "4",
            "VideoSubtitleID": -613910442
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "spa-ES",
            "LanguageID": "9",
            "Size": 248,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/17657bf1bde9d53e0a0719a03633662c/6555e825/video/tos/maliva/tos-maliva-v-0068c799-us/23b27430c9e84435b0ea93bdf2d93254/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2314&bt=1157&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=anB3eDw6ZmRpZDMzZzczNEBpanB3eDw6ZmRpZDMzZzczNEA1bzRicjRvamxgLS1kMS9zYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128805,
            "Version": "4",
            "VideoSubtitleID": 684885313
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "vie-VN",
            "LanguageID": "10",
            "Size": 268,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/bde9a74df2a1eeddaf09edd8a21e4bb0/6555e825/video/tos/maliva/tos-maliva-v-0068c799-us/42bd5d7849d84c4da3062cf4da24e2e1/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2314&bt=1157&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=anB3eDw6ZmRpZDMzZzczNEBpanB3eDw6ZmRpZDMzZzczNEA1bzRicjRvamxgLS1kMS9zYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128805,
            "Version": "4",
            "VideoSubtitleID": 2075435474
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "cmn-Hans-CN",
            "LanguageID": "1",
            "Size": 194,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/8c684d304b284108e382770add56ac34/6555e825/video/tos/maliva/tos-maliva-v-0068c799-us/3f6d9e3f3d164c3692f7b795012b3a23/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2314&bt=1157&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=anB3eDw6ZmRpZDMzZzczNEBpanB3eDw6ZmRpZDMzZzczNEA1bzRicjRvamxgLS1kMS9zYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128805,
            "Version": "4",
            "VideoSubtitleID": 874825984
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "eng-US",
            "LanguageID": "2",
            "Size": 242,
            "Source": "ASR",
            "Url": "https://v16-webapp.tiktok.com/e166474a33ba74daa2eeccf379c76ff7/6555e825/video/tos/maliva/tos-maliva-v-0068c799-us/3479bc686cad4cc99fded0509e78c391/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2314&bt=1157&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=anB3eDw6ZmRpZDMzZzczNEBpanB3eDw6ZmRpZDMzZzczNEA1bzRicjRvamxgLS1kMS9zYSM1bzRicjRvamxgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128805,
            "Version": "1",
            "VideoSubtitleID": -1618964322
          }
        ],
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -8.3,
          "Peak": 1
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/ab40f8a5a20f44afaa3f6cd4fe91501f~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=V2kFBSUj8ePSmzetzfAao6AJL0Q%3D",
          "480": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/ab40f8a5a20f44afaa3f6cd4fe91501f~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=RqU4fmNpyD42E0SW%2FuNlIIgbt0s%3D",
          "720": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/ab40f8a5a20f44afaa3f6cd4fe91501f~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=Q2YLoqFrvG0u8V8sGaY%2BGjJpMas%3D",
          "960": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/ab40f8a5a20f44afaa3f6cd4fe91501f~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=Q0YbbXC5V2aQwcEGDNVaswPhQck%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "7224218912691438638",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Discovering Artists",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAA79sDcMCbl7QatFptF3JKobDAz67Uc0yws24YRf6FRLl8aWSAgSzrw6mu-bd0maiw",
        "secret": false,
        "signature": "\ud83d\udce7: discoveringartists.irl@gmail.com\n\nFollow our Spotify playlist!\ud83d\udc47",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "discoveringartists.irl",
        "verified": false
      },
      "authorStats": {
        "diggCount": 86,
        "followerCount": 515300,
        "followingCount": 13,
        "friendCount": 0,
        "heart": 15800000,
        "heartCount": 15800000,
        "videoCount": 35
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055695396906",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "doyoumakemusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055791882286",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "discoveringartistsirl"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "325757",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "4504990",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "newmusicfriday"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "136517",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musiccurator"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1597596688307206",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "alicephoebelou"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "17954633",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "juliajacklin"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "17331",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indie"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "496813",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "singersongwriter"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "50864",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musicproducer"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "35857",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indieartist"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "Do you make music?"
        },
        {
          "desc": ""
        },
        {
          "desc": "#doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #alicephoebelou #juliajacklin #indie #singersongwriter #musicproducer #indieartist #indiemusician ",
          "textExtra": [
            {
              "awemeId": "",
              "end": 15,
              "hashtagId": "7252811055695396906",
              "hashtagName": "doyoumakemusic",
              "isCommerce": false,
              "secUid": "",
              "start": 0,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 38,
              "hashtagId": "7252811055791882286",
              "hashtagName": "discoveringartistsirl",
              "isCommerce": false,
              "secUid": "",
              "start": 16,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 56,
              "hashtagId": "325757",
              "hashtagName": "undergroundmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 39,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 72,
              "hashtagId": "4504990",
              "hashtagName": "newmusicfriday",
              "isCommerce": false,
              "secUid": "",
              "start": 57,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 86,
              "hashtagId": "136517",
              "hashtagName": "musiccurator",
              "isCommerce": false,
              "secUid": "",
              "start": 73,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 102,
              "hashtagId": "1597596688307206",
              "hashtagName": "alicephoebelou",
              "isCommerce": false,
              "secUid": "",
              "start": 87,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 116,
              "hashtagId": "17954633",
              "hashtagName": "juliajacklin",
              "isCommerce": false,
              "secUid": "",
              "start": 103,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 123,
              "hashtagId": "17331",
              "hashtagName": "indie",
              "isCommerce": false,
              "secUid": "",
              "start": 117,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 141,
              "hashtagId": "496813",
              "hashtagName": "singersongwriter",
              "isCommerce": false,
              "secUid": "",
              "start": 124,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 156,
              "hashtagId": "50864",
              "hashtagName": "musicproducer",
              "isCommerce": false,
              "secUid": "",
              "start": 142,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 169,
              "hashtagId": "35857",
              "hashtagName": "indieartist",
              "isCommerce": false,
              "secUid": "",
              "start": 157,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 184,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 170,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1695334292,
      "desc": "Do you make music? #doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #alicephoebelou #juliajacklin #indie #singersongwriter #musicproducer #indieartist #indiemusician ",
      "digged": false,
      "diversificationId": 10046,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7281405310290611502",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "",
        "authorName": "Discovering Artists",
        "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "coverMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "coverThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "duration": 31,
        "id": "7281405335716236074",
        "original": true,
        "playUrl": "https://v16m.tiktokcdn.com/82a94f0d44c1361de2276d0329d40ebd/6553999a/video/tos/maliva/tos-maliva-ve-27dcd7c799-us/owL4SfzFJUDnSMWLuMhIIfBCvHUg5KlwDlNTKY/?a=1233&ch=0&cr=0&dr=0&er=2&cd=0%7C0%7C0%7C0&br=250&bt=125&bti=NDU3ZjAwOg%3D%3D&ft=d2A~l-Inz7TPgL-Ziyq8Z&mime_type=audio_mpeg&qs=6&rc=Zjo0NzY7aWVlaDk7ZmRoaEBpamQ0NWQ6ZjhlbjMzZzU8NEAzYzFjNWI1Xl8xXi0zMDFhYSM2NTNwcjRnczNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00088000&cc=3&download=true",
        "title": "original sound"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 34700,
        "commentCount": 1079,
        "diggCount": 388000,
        "playCount": 2500000,
        "shareCount": 753
      },
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 34,
          "hashtagId": "7252811055695396906",
          "hashtagName": "doyoumakemusic",
          "isCommerce": false,
          "secUid": "",
          "start": 19,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 57,
          "hashtagId": "7252811055791882286",
          "hashtagName": "discoveringartistsirl",
          "isCommerce": false,
          "secUid": "",
          "start": 35,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 75,
          "hashtagId": "325757",
          "hashtagName": "undergroundmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 58,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 91,
          "hashtagId": "4504990",
          "hashtagName": "newmusicfriday",
          "isCommerce": false,
          "secUid": "",
          "start": 76,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 105,
          "hashtagId": "136517",
          "hashtagName": "musiccurator",
          "isCommerce": false,
          "secUid": "",
          "start": 92,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 121,
          "hashtagId": "1597596688307206",
          "hashtagName": "alicephoebelou",
          "isCommerce": false,
          "secUid": "",
          "start": 106,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 135,
          "hashtagId": "17954633",
          "hashtagName": "juliajacklin",
          "isCommerce": false,
          "secUid": "",
          "start": 122,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 142,
          "hashtagId": "17331",
          "hashtagName": "indie",
          "isCommerce": false,
          "secUid": "",
          "start": 136,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 160,
          "hashtagId": "496813",
          "hashtagName": "singersongwriter",
          "isCommerce": false,
          "secUid": "",
          "start": 143,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 175,
          "hashtagId": "50864",
          "hashtagName": "musicproducer",
          "isCommerce": false,
          "secUid": "",
          "start": 161,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 188,
          "hashtagId": "35857",
          "hashtagName": "indieartist",
          "isCommerce": false,
          "secUid": "",
          "start": 176,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 203,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 189,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 2158444,
        "bitrateInfo": [
          {
            "Bitrate": 2158444,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 8363433,
              "FileCs": "c:0-26646-bdf8",
              "FileHash": "9937195387cca2b10b915e34808faf5f",
              "Uri": "v12044gd0000ck6bsojc77ud3sopc3i0",
              "UrlKey": "v12044gd0000ck6bsojc77ud3sopc3i0_h264_540p_2158444",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ogBMAvHD5ecPPELgQlDna6bIIe7og5MmDSMisR/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4214&bt=2107&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=Zzo3OzRnNzU4OzpoNTxlZ0BpMzZzdmc6ZnJlbjMzZzczNEAyNF8tMy01Nl8xYDE1MmBfYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128827&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=8973e456c7c2c1fde846d3e66b0841bb&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ogBMAvHD5ecPPELgQlDna6bIIe7og5MmDSMisR/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4214&bt=2107&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=Zzo3OzRnNzU4OzpoNTxlZ0BpMzZzdmc6ZnJlbjMzZzczNEAyNF8tMy01Nl8xYDE1MmBfYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128827&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=8973e456c7c2c1fde846d3e66b0841bb&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 1790361,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 6937205,
              "FileCs": "c:0-27181-5e12",
              "FileHash": "c02496ed2737688d633cc21020de3bf7",
              "Uri": "v12044gd0000ck6bsojc77ud3sopc3i0",
              "UrlKey": "v12044gd0000ck6bsojc77ud3sopc3i0_bytevc1_1080p_1790361",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oMCG7xIwp3AfkhlwYCIDNhNzn8zYBAGEWQOyHM/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3496&bt=1748&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=PDRkaTk8N2g2ZjQ7N2k0ZkBpMzZzdmc6ZnJlbjMzZzczNEA1NS9gLWIvXzQxMmE0Nl9jYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128827&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=dd1a3d84ae022e72f1effff2c0a3771a&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oMCG7xIwp3AfkhlwYCIDNhNzn8zYBAGEWQOyHM/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3496&bt=1748&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=PDRkaTk8N2g2ZjQ7N2k0ZkBpMzZzdmc6ZnJlbjMzZzczNEA1NS9gLWIvXzQxMmE0Nl9jYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128827&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=dd1a3d84ae022e72f1effff2c0a3771a&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 1010815,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 3916659,
              "FileCs": "c:0-26646-5d7c",
              "FileHash": "3c42c8d11800dbf309a345bb60ffcf93",
              "Uri": "v12044gd0000ck6bsojc77ud3sopc3i0",
              "UrlKey": "v12044gd0000ck6bsojc77ud3sopc3i0_h264_540p_1010815",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oEAzgkHP8f2HYoeAfKXyIOQR1O53MjAGZECjVA/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1974&bt=987&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=NzNpZDY3ZTllaTQzZTdoPEBpMzZzdmc6ZnJlbjMzZzczNEA1YTEyNWEwNS0xYWJfYzIuYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128827&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c68325f8b867262eee87be8275658dd6&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oEAzgkHP8f2HYoeAfKXyIOQR1O53MjAGZECjVA/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1974&bt=987&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=NzNpZDY3ZTllaTQzZTdoPEBpMzZzdmc6ZnJlbjMzZzczNEA1YTEyNWEwNS0xYWJfYzIuYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128827&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c68325f8b867262eee87be8275658dd6&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 863459,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 3345690,
              "FileCs": "c:0-27176-3e78",
              "FileHash": "84e7fb14ce246e00dd5ee5f44825fa88",
              "Uri": "v12044gd0000ck6bsojc77ud3sopc3i0",
              "UrlKey": "v12044gd0000ck6bsojc77ud3sopc3i0_bytevc1_720p_863459",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o4EM7iQgSoAeIDLgYwRHaDbmY5nfDBGIsgjlPZ/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1686&bt=843&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=OmVlNTNnMzo7Z2U7ZWg2NEBpMzZzdmc6ZnJlbjMzZzczNEBgMjNhXl9iXjExMzIzYl8vYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128827&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c46b7f23bfd0a5b52de94e0f9dcbdab0&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o4EM7iQgSoAeIDLgYwRHaDbmY5nfDBGIsgjlPZ/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1686&bt=843&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=OmVlNTNnMzo7Z2U7ZWg2NEBpMzZzdmc6ZnJlbjMzZzczNEBgMjNhXl9iXjExMzIzYl8vYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128827&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c46b7f23bfd0a5b52de94e0f9dcbdab0&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 712384,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 2760313,
              "FileCs": "c:0-27176-74c4",
              "FileHash": "310fe243630b2fcdf184163758242d8b",
              "Uri": "v12044gd0000ck6bsojc77ud3sopc3i0",
              "UrlKey": "v12044gd0000ck6bsojc77ud3sopc3i0_bytevc1_540p_712384",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oIIoMyWCnAlCEwIzNGEAcfhB8wzUDhQ3HNkxKp/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1390&bt=695&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=OmU7Ojw4N2ZoPDZoNmVpOUBpMzZzdmc6ZnJlbjMzZzczNEAwXmMzNjE1NTUxNi9eNDEvYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128827&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=0dd5e5d4b60aded43f39b17b9522f453&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oIIoMyWCnAlCEwIzNGEAcfhB8wzUDhQ3HNkxKp/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1390&bt=695&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=OmU7Ojw4N2ZoPDZoNmVpOUBpMzZzdmc6ZnJlbjMzZzczNEAwXmMzNjE1NTUxNi9eNDEvYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128827&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=0dd5e5d4b60aded43f39b17b9522f453&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 680488,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 2636723,
              "FileCs": "c:0-26646-2848",
              "FileHash": "91c0a7ca063074a5cce8b5dcbfc69ac8",
              "Uri": "v12044gd0000ck6bsojc77ud3sopc3i0",
              "UrlKey": "v12044gd0000ck6bsojc77ud3sopc3i0_h264_540p_680488",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o4NGgM8hlxIDhC6yAHQEIvzfCN4v9FCpBzkAwW/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1328&bt=664&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=aWVpZGg6ZjY3O2RlaGU4aEBpMzZzdmc6ZnJlbjMzZzczNEA2NDU0YGI1Xy8xMC5iNTUyYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128827&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=52d54679ebfcf9645a54a456aa4739d4&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o4NGgM8hlxIDhC6yAHQEIvzfCN4v9FCpBzkAwW/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1328&bt=664&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=aWVpZGg6ZjY3O2RlaGU4aEBpMzZzdmc6ZnJlbjMzZzczNEA2NDU0YGI1Xy8xMC5iNTUyYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128827&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=52d54679ebfcf9645a54a456aa4739d4&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p19-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/0ff8044a0f5f472fb1ce4fe6c99a1642_1695334294?x-expires=1700125200&x-signature=1qo6QwQJPPx3QKAi3hRjrlKqFw0%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oMHWpmzxIfA8EUAhkQhp4vCHBNlwNIGMyz7wwD/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=4168&bt=2084&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=OmU3PGU3ZjM3ZzU2NGQzNUBpMzZzdmc6ZnJlbjMzZzczNEBeMWM1MDEyNjExMV8vYzNiYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128827&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=629e3e75411ce3b9cb21022df4a602f8&tk=tt_chain_token",
        "duration": 30,
        "dynamicCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/34e04cba0fb44f598fb7523779f1c5b4_1695334295?x-expires=1700125200&x-signature=2tczs%2FNCr9uoeiKY1FaEbdYhoGw%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7281405310290611502",
        "originCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/3e5c0bd0cd1645349673f2e421d1d740_1695334295?x-expires=1700125200&x-signature=Z5nt57TjLADkmKlyWH%2FRNWX6s2I%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ogBMAvHD5ecPPELgQlDna6bIIe7og5MmDSMisR/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4214&bt=2107&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=Zzo3OzRnNzU4OzpoNTxlZ0BpMzZzdmc6ZnJlbjMzZzczNEAyNF8tMy01Nl8xYDE1MmBfYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128827&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=8973e456c7c2c1fde846d3e66b0841bb&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/0ff8044a0f5f472fb1ce4fe6c99a1642_1695334294~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=%2BuCSlOwgsE3Ko0sUy4FqSLkgfRM%3D",
        "shareCover": [
          "",
          "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/3e5c0bd0cd1645349673f2e421d1d740_1695334295~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=0QYsSXAWaS%2FmTj%2Fnq2tj0fRQaHM%3D",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/3e5c0bd0cd1645349673f2e421d1d740_1695334295~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=Pz0ovkjfFqVxR38hYlXa0lVADkI%3D"
        ],
        "size": 8363433,
        "subtitleInfos": [
          {
            "Format": "webvtt",
            "LanguageCodeName": "spa-ES",
            "LanguageID": "9",
            "Size": 871,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/79bf1a5048588eb2e9a1fbd8d59bcb29/6555e83b/video/tos/maliva/tos-maliva-v-0068c799-us/0e27960cd07a45488a6e6d56b1834840/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14316&bt=7158&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzZzdmc6ZnJlbjMzZzczNEBpMzZzdmc6ZnJlbjMzZzczNEBmYGwwcjRncDNgLS1kMS9zYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128827,
            "Version": "4",
            "VideoSubtitleID": 46411190
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "eng-US",
            "LanguageID": "2",
            "Size": 529,
            "Source": "ASR",
            "Url": "https://v16-webapp.tiktok.com/d57289b83d192c764adf364e0a99f9ee/6555e83b/video/tos/maliva/tos-maliva-v-0068c799-us/64208f9e17964e8782b84721f54f3cdb/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14316&bt=7158&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzZzdmc6ZnJlbjMzZzczNEBpMzZzdmc6ZnJlbjMzZzczNEBmYGwwcjRncDNgLS1kMS9zYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128827,
            "Version": "1",
            "VideoSubtitleID": 2101762564
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "kor-KR",
            "LanguageID": "4",
            "Size": 993,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/3e4865ecb2678b0c3357535508387f71/6555e83b/video/tos/maliva/tos-maliva-v-0068c799-us/cb5935a9771348ddb3501d04c802fd47/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14316&bt=7158&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzZzdmc6ZnJlbjMzZzczNEBpMzZzdmc6ZnJlbjMzZzczNEBmYGwwcjRncDNgLS1kMS9zYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128827,
            "Version": "4",
            "VideoSubtitleID": -1798961808
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "cmn-Hans-CN",
            "LanguageID": "1",
            "Size": 806,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/9aa3c75eae80146d0a2ef68a5e877aa1/6555e83b/video/tos/maliva/tos-maliva-v-0068c799-us/a072f5f58c11404e98ed954f2e1d7e65/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14316&bt=7158&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzZzdmc6ZnJlbjMzZzczNEBpMzZzdmc6ZnJlbjMzZzczNEBmYGwwcjRncDNgLS1kMS9zYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128827,
            "Version": "4",
            "VideoSubtitleID": 90376911
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "fra-FR",
            "LanguageID": "7",
            "Size": 896,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/574b08cb1649bfad12ecab34e3b4bd65/6555e83b/video/tos/maliva/tos-maliva-v-0068c799-us/803337a4e7534b4aad0357d1535a8cc4/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14316&bt=7158&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzZzdmc6ZnJlbjMzZzczNEBpMzZzdmc6ZnJlbjMzZzczNEBmYGwwcjRncDNgLS1kMS9zYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128827,
            "Version": "4",
            "VideoSubtitleID": 441179369
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ind-ID",
            "LanguageID": "23",
            "Size": 850,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/4894880eb36beccc35b714566d6c2ca3/6555e83b/video/tos/maliva/tos-maliva-v-0068c799-us/dab38e1d60bf46a9845b1d7d0afac7d5/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14316&bt=7158&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzZzdmc6ZnJlbjMzZzczNEBpMzZzdmc6ZnJlbjMzZzczNEBmYGwwcjRncDNgLS1kMS9zYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128827,
            "Version": "4",
            "VideoSubtitleID": -1434899737
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "jpn-JP",
            "LanguageID": "3",
            "Size": 1076,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/0204e09fab55ef0a7b58b8292ee14b3e/6555e83b/video/tos/maliva/tos-maliva-v-0068c799-us/e72c332171a245cc9bbb4ace0bd933e5/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14316&bt=7158&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzZzdmc6ZnJlbjMzZzczNEBpMzZzdmc6ZnJlbjMzZzczNEBmYGwwcjRncDNgLS1kMS9zYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128827,
            "Version": "4",
            "VideoSubtitleID": -68182191
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "por-PT",
            "LanguageID": "8",
            "Size": 822,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/7e1d32c07dcf0a209f06ca079323057b/6555e83b/video/tos/maliva/tos-maliva-v-0068c799-us/5893a49bffda4a97b466c59451103def/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14316&bt=7158&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzZzdmc6ZnJlbjMzZzczNEBpMzZzdmc6ZnJlbjMzZzczNEBmYGwwcjRncDNgLS1kMS9zYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128827,
            "Version": "4",
            "VideoSubtitleID": -1448840929
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ita-IT",
            "LanguageID": "26",
            "Size": 802,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/5ed2b33a6594dde2ca0dd2aadc0bacf8/6555e83b/video/tos/maliva/tos-maliva-v-0068c799-us/15a5d993906b4a5f98420d599a494abb/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14316&bt=7158&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzZzdmc6ZnJlbjMzZzczNEBpMzZzdmc6ZnJlbjMzZzczNEBmYGwwcjRncDNgLS1kMS9zYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128827,
            "Version": "4",
            "VideoSubtitleID": 1982931094
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "rus-RU",
            "LanguageID": "6",
            "Size": 1087,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/b856bf2fe35d817f530718b8dc4bdb87/6555e83b/video/tos/maliva/tos-maliva-v-0068c799-us/bca3730ee54249abb49244ea92298c98/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14316&bt=7158&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzZzdmc6ZnJlbjMzZzczNEBpMzZzdmc6ZnJlbjMzZzczNEBmYGwwcjRncDNgLS1kMS9zYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128827,
            "Version": "4",
            "VideoSubtitleID": -1220816314
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ara-SA",
            "LanguageID": "34",
            "Size": 699,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/7ed172c6a7875e74e241635cb61d0628/6555e83b/video/tos/maliva/tos-maliva-v-0068c799-us/f5ad772635b341d79b138a5c7c6c048e/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14316&bt=7158&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzZzdmc6ZnJlbjMzZzczNEBpMzZzdmc6ZnJlbjMzZzczNEBmYGwwcjRncDNgLS1kMS9zYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128827,
            "Version": "4",
            "VideoSubtitleID": 118324194
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "deu-DE",
            "LanguageID": "20",
            "Size": 854,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/b688c205a97a1418dd5514919b9e9546/6555e83b/video/tos/maliva/tos-maliva-v-0068c799-us/6ed15ade6d92428ebf833bafde42549a/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14316&bt=7158&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzZzdmc6ZnJlbjMzZzczNEBpMzZzdmc6ZnJlbjMzZzczNEBmYGwwcjRncDNgLS1kMS9zYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128827,
            "Version": "4",
            "VideoSubtitleID": -2147468431
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "vie-VN",
            "LanguageID": "10",
            "Size": 975,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/a3644342eea21604d069c4ce465c5190/6555e83b/video/tos/maliva/tos-maliva-v-0068c799-us/2f45bb391c974cde968a0053bff33bcc/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14316&bt=7158&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=MzZzdmc6ZnJlbjMzZzczNEBpMzZzdmc6ZnJlbjMzZzczNEBmYGwwcjRncDNgLS1kMS9zYSNmYGwwcjRncDNgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128827,
            "Version": "4",
            "VideoSubtitleID": -1753437355
          }
        ],
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -9.7,
          "Peak": 0.98855
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/0ff8044a0f5f472fb1ce4fe6c99a1642_1695334294~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=cRJX%2Bc0t1jVfTz9lIlXVOpF124s%3D",
          "480": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/0ff8044a0f5f472fb1ce4fe6c99a1642_1695334294~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=4XbTp9vKzLjrZDJy6h9M0ziwSWk%3D",
          "720": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/0ff8044a0f5f472fb1ce4fe6c99a1642_1695334294~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=6Bf4xUiboxwXDQgFXZ4ZW0uYSBc%3D",
          "960": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/0ff8044a0f5f472fb1ce4fe6c99a1642_1695334294~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=zt0s%2FIwKE9qtNOE6Z%2BAXUKi2oyw%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "7224218912691438638",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Discovering Artists",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAA79sDcMCbl7QatFptF3JKobDAz67Uc0yws24YRf6FRLl8aWSAgSzrw6mu-bd0maiw",
        "secret": false,
        "signature": "\ud83d\udce7: discoveringartists.irl@gmail.com\n\nFollow our Spotify playlist!\ud83d\udc47",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "discoveringartists.irl",
        "verified": false
      },
      "authorStats": {
        "diggCount": 86,
        "followerCount": 515300,
        "followingCount": 13,
        "friendCount": 0,
        "heart": 15800000,
        "heartCount": 15800000,
        "videoCount": 35
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055695396906",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "doyoumakemusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055791882286",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "discoveringartistsirl"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "325757",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "4504990",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "newmusicfriday"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "136517",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musiccurator"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "3695",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "mazzystar"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "637568",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "sonicyouth"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "196080",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "thepixies"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "17331",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indie"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "496813",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "singersongwriter"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "50864",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musicproducer"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "35857",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indieartist"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "211116",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "area"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "Do you make music?"
        },
        {
          "desc": ""
        },
        {
          "desc": "#doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #mazzystar #sonicyouth #thepixies #indie #singersongwriter #musicproducer #indieartist #indiemusician #area",
          "textExtra": [
            {
              "awemeId": "",
              "end": 15,
              "hashtagId": "7252811055695396906",
              "hashtagName": "doyoumakemusic",
              "isCommerce": false,
              "secUid": "",
              "start": 0,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 38,
              "hashtagId": "7252811055791882286",
              "hashtagName": "discoveringartistsirl",
              "isCommerce": false,
              "secUid": "",
              "start": 16,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 56,
              "hashtagId": "325757",
              "hashtagName": "undergroundmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 39,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 72,
              "hashtagId": "4504990",
              "hashtagName": "newmusicfriday",
              "isCommerce": false,
              "secUid": "",
              "start": 57,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 86,
              "hashtagId": "136517",
              "hashtagName": "musiccurator",
              "isCommerce": false,
              "secUid": "",
              "start": 73,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 97,
              "hashtagId": "3695",
              "hashtagName": "mazzystar",
              "isCommerce": false,
              "secUid": "",
              "start": 87,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 109,
              "hashtagId": "637568",
              "hashtagName": "sonicyouth",
              "isCommerce": false,
              "secUid": "",
              "start": 98,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 120,
              "hashtagId": "196080",
              "hashtagName": "thepixies",
              "isCommerce": false,
              "secUid": "",
              "start": 110,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 127,
              "hashtagId": "17331",
              "hashtagName": "indie",
              "isCommerce": false,
              "secUid": "",
              "start": 121,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 145,
              "hashtagId": "496813",
              "hashtagName": "singersongwriter",
              "isCommerce": false,
              "secUid": "",
              "start": 128,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 160,
              "hashtagId": "50864",
              "hashtagName": "musicproducer",
              "isCommerce": false,
              "secUid": "",
              "start": 146,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 173,
              "hashtagId": "35857",
              "hashtagName": "indieartist",
              "isCommerce": false,
              "secUid": "",
              "start": 161,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 188,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 174,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 194,
              "hashtagId": "211116",
              "hashtagName": "area",
              "isCommerce": false,
              "secUid": "",
              "start": 189,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1691772294,
      "desc": "Do you make music? #doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #mazzystar #sonicyouth #thepixies #indie #singersongwriter #musicproducer #indieartist #indiemusician #area",
      "digged": false,
      "diversificationId": 10046,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7266106587859782954",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "",
        "authorName": "Discovering Artists",
        "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "coverMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "coverThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "duration": 40,
        "id": "7266106633573370666",
        "original": true,
        "playUrl": "https://v16m.tiktokcdn.com/f2196849c9cb7b56269be870f2bc25aa/655399a3/video/tos/maliva/tos-maliva-ve-27dcd7c799-us/oQIWUJDbJZh1Csnkeer2bzgt5WDUID1gSbmYBI/?a=1233&ch=0&cr=0&dr=0&er=2&cd=0%7C0%7C0%7C0&br=250&bt=125&bti=NDU3ZjAwOg%3D%3D&ft=d2A~l-Inz7TPgL-Ziyq8Z&mime_type=audio_mpeg&qs=6&rc=NGU7aDg4OTs0OTRlZTY3aEBpam04ZTQ6ZjU5bTMzZzU8NEBfYzNhLTMtNWExYzAuX2MwYSNwa2BzcjQwX19gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00088000&cc=3&download=true",
        "title": "original sound"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 39200,
        "commentCount": 1377,
        "diggCount": 382800,
        "playCount": 2400000,
        "shareCount": 1541
      },
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 34,
          "hashtagId": "7252811055695396906",
          "hashtagName": "doyoumakemusic",
          "isCommerce": false,
          "secUid": "",
          "start": 19,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 57,
          "hashtagId": "7252811055791882286",
          "hashtagName": "discoveringartistsirl",
          "isCommerce": false,
          "secUid": "",
          "start": 35,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 75,
          "hashtagId": "325757",
          "hashtagName": "undergroundmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 58,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 91,
          "hashtagId": "4504990",
          "hashtagName": "newmusicfriday",
          "isCommerce": false,
          "secUid": "",
          "start": 76,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 105,
          "hashtagId": "136517",
          "hashtagName": "musiccurator",
          "isCommerce": false,
          "secUid": "",
          "start": 92,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 116,
          "hashtagId": "3695",
          "hashtagName": "mazzystar",
          "isCommerce": false,
          "secUid": "",
          "start": 106,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 128,
          "hashtagId": "637568",
          "hashtagName": "sonicyouth",
          "isCommerce": false,
          "secUid": "",
          "start": 117,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 139,
          "hashtagId": "196080",
          "hashtagName": "thepixies",
          "isCommerce": false,
          "secUid": "",
          "start": 129,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 146,
          "hashtagId": "17331",
          "hashtagName": "indie",
          "isCommerce": false,
          "secUid": "",
          "start": 140,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 164,
          "hashtagId": "496813",
          "hashtagName": "singersongwriter",
          "isCommerce": false,
          "secUid": "",
          "start": 147,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 179,
          "hashtagId": "50864",
          "hashtagName": "musicproducer",
          "isCommerce": false,
          "secUid": "",
          "start": 165,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 192,
          "hashtagId": "35857",
          "hashtagName": "indieartist",
          "isCommerce": false,
          "secUid": "",
          "start": 180,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 207,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 193,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 213,
          "hashtagId": "211116",
          "hashtagName": "area",
          "isCommerce": false,
          "secUid": "",
          "start": 208,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 2165819,
        "bitrateInfo": [
          {
            "Bitrate": 2165819,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 10887575,
              "FileCs": "c:0-34322-c36f",
              "FileHash": "6216db5497968e3a73d7c5c813a620c6",
              "Uri": "v12044gd0000cjb6an3c77u1hjg7m4g0",
              "UrlKey": "v12044gd0000cjb6an3c77u1hjg7m4g0_h264_540p_2165819",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ogEBZyYjNAAXQOzuCZJWKjUwBfImzaTK7phIBg/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4230&bt=2115&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZGllN2ZpNTM3aTQ1ZGc5ZUBpMzc6bTQ6ZnE5bTMzZzczNEAzX2NhMzAuNS0xNTM0Xy4yYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128836&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=4474e953b917405aaffd451f41e83374&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ogEBZyYjNAAXQOzuCZJWKjUwBfImzaTK7phIBg/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4230&bt=2115&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZGllN2ZpNTM3aTQ1ZGc5ZUBpMzc6bTQ6ZnE5bTMzZzczNEAzX2NhMzAuNS0xNTM0Xy4yYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128836&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=4474e953b917405aaffd451f41e83374&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 1752924,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 8811950,
              "FileCs": "c:0-34741-4005",
              "FileHash": "328538c43f54a4be4bb479e6d1296bf1",
              "Uri": "v12044gd0000cjb6an3c77u1hjg7m4g0",
              "UrlKey": "v12044gd0000cjb6an3c77u1hjg7m4g0_bytevc1_1080p_1752924",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ognuKBQgIhEB6nIBzZyCWztAaU5Op7ZNwAjf3I/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3422&bt=1711&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=aWQ3ZTtnZDVmNTdlZ2RoZ0BpMzc6bTQ6ZnE5bTMzZzczNEAvNC02MzQ0X2AxNTA2YGAvYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128836&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=d5d6fe624ee2fc95585fed72a3be5f78&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ognuKBQgIhEB6nIBzZyCWztAaU5Op7ZNwAjf3I/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3422&bt=1711&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=aWQ3ZTtnZDVmNTdlZ2RoZ0BpMzc6bTQ6ZnE5bTMzZzczNEAvNC02MzQ0X2AxNTA2YGAvYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128836&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=d5d6fe624ee2fc95585fed72a3be5f78&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 1043727,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 5246816,
              "FileCs": "c:0-34322-cf4d",
              "FileHash": "5514e35a4f5b9413cc26597a71ef60f0",
              "Uri": "v12044gd0000cjb6an3c77u1hjg7m4g0",
              "UrlKey": "v12044gd0000cjb6an3c77u1hjg7m4g0_h264_540p_1043727",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/okV1nbGcEDCSRyySMAkg8B4shORmAAsAeDlUHf/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2038&bt=1019&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=aTM5NjYzNDs6Z2k6NmRnOUBpMzc6bTQ6ZnE5bTMzZzczNEBhNmMwLTVeNTAxL2M0NV9iYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128836&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=9e560745db5bc3878ec338dc8bb55dd3&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/okV1nbGcEDCSRyySMAkg8B4shORmAAsAeDlUHf/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2038&bt=1019&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=aTM5NjYzNDs6Z2k6NmRnOUBpMzc6bTQ6ZnE5bTMzZzczNEBhNmMwLTVeNTAxL2M0NV9iYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128836&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=9e560745db5bc3878ec338dc8bb55dd3&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 1039463,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 5225385,
              "FileCs": "c:0-34744-1817",
              "FileHash": "85cde6ea1a099e62d8b9c6c3adda49c1",
              "Uri": "v12044gd0000cjb6an3c77u1hjg7m4g0",
              "UrlKey": "v12044gd0000cjb6an3c77u1hjg7m4g0_bytevc1_720p_1039463",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o46VQSAGcnAtZcJ6emeDDRkg7E2gJBy1oDbUWD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2030&bt=1015&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=PDs6ZjQ0OjU1ZmVnaDQzN0BpMzc6bTQ6ZnE5bTMzZzczNEBgYjU2NWExNjAxLzY2My8vYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128836&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=e5950028abdd04f8f1550272803408cb&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o46VQSAGcnAtZcJ6emeDDRkg7E2gJBy1oDbUWD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2030&bt=1015&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=PDs6ZjQ0OjU1ZmVnaDQzN0BpMzc6bTQ6ZnE5bTMzZzczNEBgYjU2NWExNjAxLzY2My8vYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128836&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=e5950028abdd04f8f1550272803408cb&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 710783,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 3573111,
              "FileCs": "c:0-34744-f05c",
              "FileHash": "3315f9a74795ffd697689cc05a9d8e0c",
              "Uri": "v12044gd0000cjb6an3c77u1hjg7m4g0",
              "UrlKey": "v12044gd0000cjb6an3c77u1hjg7m4g0_bytevc1_540p_710783",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o0BDhBAf7IaBHIQwjcCaEKUzrgZuzNMApqnyOW/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1388&bt=694&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=Nzo6aGY4ODs2Nzg7NjllNkBpMzc6bTQ6ZnE5bTMzZzczNEBgNDEuYGFhNTAxNTJeYmIuYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128836&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=91a9add3435c69ab2974d2994215d8f4&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o0BDhBAf7IaBHIQwjcCaEKUzrgZuzNMApqnyOW/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1388&bt=694&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=Nzo6aGY4ODs2Nzg7NjllNkBpMzc6bTQ6ZnE5bTMzZzczNEBgNDEuYGFhNTAxNTJeYmIuYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128836&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=91a9add3435c69ab2974d2994215d8f4&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 706060,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 3549366,
              "FileCs": "c:0-34322-b913",
              "FileHash": "016e9e0fde2c18f921f2cb8e04cf50a6",
              "Uri": "v12044gd0000cjb6an3c77u1hjg7m4g0",
              "UrlKey": "v12044gd0000cjb6an3c77u1hjg7m4g0_h264_540p_706060",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oADbQNEOzBryfYBCuWhZzFIZaApUjg7wBaIxKA/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1378&bt=689&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=aWY3O2dlM2Q2PDRmNWVkZkBpMzc6bTQ6ZnE5bTMzZzczNEBjLjBfYDM1Xy8xMC5hLjRiYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128836&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=888dad68d8f5579da48a63519d8f2bb6&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oADbQNEOzBryfYBCuWhZzFIZaApUjg7wBaIxKA/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1378&bt=689&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=aWY3O2dlM2Q2PDRmNWVkZkBpMzc6bTQ6ZnE5bTMzZzczNEBjLjBfYDM1Xy8xMC5hLjRiYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128836&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=888dad68d8f5579da48a63519d8f2bb6&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/a996c50b0f7c4638b2eaf1010b3c7954_1691772295?x-expires=1700125200&x-signature=3Eqn%2F%2Fb6PWqn%2FYk%2FSbz%2FdeNrnaY%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o0jygrKBJAaBQIgiwIfgaZpzuNOE7azhBCADWU/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=3816&bt=1908&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZzQ7ZTZlZjMzOTM2Ozw7ZEBpMzc6bTQ6ZnE5bTMzZzczNEAwYTZgNjZjXmExYTM2YzE0YSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128836&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=78c75a27403388a72af2679bb6a1459d&tk=tt_chain_token",
        "duration": 40,
        "dynamicCover": "https://p19-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/419918a6c8ca4533b658409768ee60f5_1691772296?x-expires=1700125200&x-signature=vm0Q3ee%2FJhIkWCD7vUAxP2JYXOQ%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7266106587859782954",
        "originCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/6724143faee842e2919c4bd4ba27b8d6_1691772295?x-expires=1700125200&x-signature=1FmcNEDoqziSvuyOyZ4LrZIQxT4%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ogEBZyYjNAAXQOzuCZJWKjUwBfImzaTK7phIBg/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4230&bt=2115&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZGllN2ZpNTM3aTQ1ZGc5ZUBpMzc6bTQ6ZnE5bTMzZzczNEAzX2NhMzAuNS0xNTM0Xy4yYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128836&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=4474e953b917405aaffd451f41e83374&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/a996c50b0f7c4638b2eaf1010b3c7954_1691772295~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=GqTmKfFfieCP2O461IdZHsgv8rw%3D",
        "shareCover": [
          "",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/6724143faee842e2919c4bd4ba27b8d6_1691772295~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=5QkiKvIWXoYYFAAXe%2B7W7r4VjRM%3D",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/6724143faee842e2919c4bd4ba27b8d6_1691772295~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=AxjzIugjUoX4NN2HiYZZCmmZnWs%3D"
        ],
        "size": 10887575,
        "subtitleInfos": [
          {
            "Format": "webvtt",
            "LanguageCodeName": "kor-KR",
            "LanguageID": "4",
            "Size": 1145,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/52008cf2f7055b67ecb8dc8e2c9cea68/6555e844/video/tos/maliva/tos-maliva-v-0068c799-us/b04152ec600240b8af74988d4b17caa0/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14106&bt=7053&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzc6bTQ6ZnE5bTMzZzczNEBpMzc6bTQ6ZnE5bTMzZzczNEBkamRlcjQwXl9gLS1kMS9zYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128836,
            "Version": "4",
            "VideoSubtitleID": 494197779
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ita-IT",
            "LanguageID": "26",
            "Size": 1056,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/3f86021b99aebee865dd1aea5353346b/6555e844/video/tos/maliva/tos-maliva-v-0068c799-us/e76a9b590ffc4a339ae814369b147c47/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14106&bt=7053&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzc6bTQ6ZnE5bTMzZzczNEBpMzc6bTQ6ZnE5bTMzZzczNEBkamRlcjQwXl9gLS1kMS9zYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128836,
            "Version": "4",
            "VideoSubtitleID": -96991665
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "rus-RU",
            "LanguageID": "6",
            "Size": 1315,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/e4f663e74c03c01304f253d7bfd949ce/6555e844/video/tos/maliva/tos-maliva-v-0068c799-us/133f6cbe30c4442691310d3ddf0a65bf/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14106&bt=7053&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzc6bTQ6ZnE5bTMzZzczNEBpMzc6bTQ6ZnE5bTMzZzczNEBkamRlcjQwXl9gLS1kMS9zYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128836,
            "Version": "4",
            "VideoSubtitleID": 503000444
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "cmn-Hans-CN",
            "LanguageID": "1",
            "Size": 1088,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/c3543314c660686532c9af933b4ced0d/6555e844/video/tos/maliva/tos-maliva-v-0068c799-us/993d5fbbc7bf4cf4a1361a0093c72f91/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14106&bt=7053&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzc6bTQ6ZnE5bTMzZzczNEBpMzc6bTQ6ZnE5bTMzZzczNEBkamRlcjQwXl9gLS1kMS9zYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128836,
            "Version": "4",
            "VideoSubtitleID": -1972921613
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "vie-VN",
            "LanguageID": "10",
            "Size": 1253,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/1bbd83c34c68dd3ee3b6b026f2fb4dd9/6555e844/video/tos/maliva/tos-maliva-v-0068c799-us/6fcad0e1a48f4decbea95fab198a343b/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14106&bt=7053&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzc6bTQ6ZnE5bTMzZzczNEBpMzc6bTQ6ZnE5bTMzZzczNEBkamRlcjQwXl9gLS1kMS9zYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128836,
            "Version": "4",
            "VideoSubtitleID": 251392209
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "spa-ES",
            "LanguageID": "9",
            "Size": 1117,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/cee960907eb74cb9f2964c9767970141/6555e844/video/tos/maliva/tos-maliva-v-0068c799-us/1ed7173be6e948bf9d6e749faf4d3f28/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14106&bt=7053&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzc6bTQ6ZnE5bTMzZzczNEBpMzc6bTQ6ZnE5bTMzZzczNEBkamRlcjQwXl9gLS1kMS9zYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128836,
            "Version": "4",
            "VideoSubtitleID": 467127217
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ind-ID",
            "LanguageID": "23",
            "Size": 1107,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/b898ec8e168d04afdadd13c5888adb07/6555e844/video/tos/maliva/tos-maliva-v-0068c799-us/d00df88335f94fc38834a908062c37ec/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14106&bt=7053&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzc6bTQ6ZnE5bTMzZzczNEBpMzc6bTQ6ZnE5bTMzZzczNEBkamRlcjQwXl9gLS1kMS9zYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128836,
            "Version": "4",
            "VideoSubtitleID": -2064572008
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "fra-FR",
            "LanguageID": "7",
            "Size": 1133,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/35f934db1de2871aa390845bfded33e6/6555e844/video/tos/maliva/tos-maliva-v-0068c799-us/ea28ea93246f4655b20abf45665548d2/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14106&bt=7053&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzc6bTQ6ZnE5bTMzZzczNEBpMzc6bTQ6ZnE5bTMzZzczNEBkamRlcjQwXl9gLS1kMS9zYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128836,
            "Version": "4",
            "VideoSubtitleID": 535904001
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "por-PT",
            "LanguageID": "8",
            "Size": 1058,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/38618ff73abafd50eacfc11df7b119aa/6555e844/video/tos/maliva/tos-maliva-v-0068c799-us/0acd89d4a0194c81b5c0d4b1e4cc0aef/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14106&bt=7053&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzc6bTQ6ZnE5bTMzZzczNEBpMzc6bTQ6ZnE5bTMzZzczNEBkamRlcjQwXl9gLS1kMS9zYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128836,
            "Version": "4",
            "VideoSubtitleID": 760880872
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ara-SA",
            "LanguageID": "34",
            "Size": 1024,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/2973aa44459525e85a2c9c7554b2520c/6555e844/video/tos/maliva/tos-maliva-v-0068c799-us/fe879657bfb146ef9cdcbc0c05795bd7/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14106&bt=7053&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzc6bTQ6ZnE5bTMzZzczNEBpMzc6bTQ6ZnE5bTMzZzczNEBkamRlcjQwXl9gLS1kMS9zYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128836,
            "Version": "4",
            "VideoSubtitleID": -1504992614
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "jpn-JP",
            "LanguageID": "3",
            "Size": 1351,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/977e821d8ba2718ddf827671f7669b18/6555e844/video/tos/maliva/tos-maliva-v-0068c799-us/4d6161a6bb824b229097b3d43a017964/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14106&bt=7053&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzc6bTQ6ZnE5bTMzZzczNEBpMzc6bTQ6ZnE5bTMzZzczNEBkamRlcjQwXl9gLS1kMS9zYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128836,
            "Version": "4",
            "VideoSubtitleID": 560044714
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "eng-US",
            "LanguageID": "2",
            "Size": 816,
            "Source": "ASR",
            "Url": "https://v16-webapp.tiktok.com/b619b93d1451116f927e1ccc1e0ab1a9/6555e844/video/tos/maliva/tos-maliva-v-0068c799-us/e5ba7b7096be45308fe105d3614d349a/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14106&bt=7053&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzc6bTQ6ZnE5bTMzZzczNEBpMzc6bTQ6ZnE5bTMzZzczNEBkamRlcjQwXl9gLS1kMS9zYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128836,
            "Version": "1",
            "VideoSubtitleID": 2018028339
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "deu-DE",
            "LanguageID": "20",
            "Size": 1168,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/ad79ab639ff5af2fdb4987e4ca18a8d2/6555e844/video/tos/maliva/tos-maliva-v-0068c799-us/1a3f77753c4045788abdd083cc1edb9a/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14106&bt=7053&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=Mzc6bTQ6ZnE5bTMzZzczNEBpMzc6bTQ6ZnE5bTMzZzczNEBkamRlcjQwXl9gLS1kMS9zYSNkamRlcjQwXl9gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128836,
            "Version": "4",
            "VideoSubtitleID": 399965640
          }
        ],
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -9.5,
          "Peak": 1
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/a996c50b0f7c4638b2eaf1010b3c7954_1691772295~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=NRoFmMA%2BQsdYJnNzEAzARQd%2Bims%3D",
          "480": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/a996c50b0f7c4638b2eaf1010b3c7954_1691772295~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=um3M%2BjZrbR%2FAKJQaqjMUhA5rys4%3D",
          "720": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/a996c50b0f7c4638b2eaf1010b3c7954_1691772295~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=Z3yEt66inHLImXo7VSwW3V9AKOI%3D",
          "960": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/a996c50b0f7c4638b2eaf1010b3c7954_1691772295~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=iMaO6eYM%2BjIGrUyVW5DY%2FuT3%2F4M%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/2458cb9de036988afefdd21925492eb1~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=PskwD%2FqwSPyS9L2tIjId4EzpZJw%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/2458cb9de036988afefdd21925492eb1~c5_720x720.jpeg?x-expires=1700125200&x-signature=nIHdO9nywgekVC1vmqFyaJ7erkg%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/2458cb9de036988afefdd21925492eb1~c5_100x100.jpeg?x-expires=1700125200&x-signature=f%2BjAKoM0TqRXu8eq%2BJrAsF2224I%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "7091539695071773742",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Melo Music",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAAo1gJOQDh-lTOca0mYwpkuONmBOMrSDy3nMIx8zyJoxqTquJjXjU63_4KTKUPsUSv",
        "secret": false,
        "signature": "finding all my underrated artists on the Melo app!\nplaylists and app\ud83d\udc47\ud83c\udffd",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "prazulmelo",
        "verified": false
      },
      "authorStats": {
        "diggCount": 2589,
        "followerCount": 246800,
        "followingCount": 354,
        "friendCount": 0,
        "heart": 5100000,
        "heartCount": 5100000,
        "videoCount": 207
      },
      "challenges": [
        {
          "coverLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/4ce5508a8a28a5116186ab5baefa61d4.png",
          "coverMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/4ce5508a8a28a5116186ab5baefa61d4.png",
          "coverThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/4ce5508a8a28a5116186ab5baefa61d4.png",
          "desc": "Discover best #NewMusic from your favourite artists!",
          "id": "31075",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/32bceb8db4d6aa366454f7ea54116832.jpeg",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/32bceb8db4d6aa366454f7ea54116832.jpeg",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/32bceb8db4d6aa366454f7ea54116832.jpeg",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "newmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7013453502701633541",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundhyperpop"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "325757",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1646514385777670",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "hyperpop"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7747871",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "altpop"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1691436528129030",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "pinkpantheress"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7195852066214903851",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "boysaliarpart2"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "136517",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musiccurator"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "496813",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "singersongwriter"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "50864",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musicproducer"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "Replying to @iswearidonthavehostages who\u2019s rockin with @lowkeybaitss",
          "textExtra": [
            {
              "awemeId": "",
              "end": 36,
              "hashtagId": "",
              "hashtagName": "",
              "isCommerce": false,
              "secUid": "",
              "start": 12,
              "subType": 2,
              "type": 0,
              "userId": "6712267733390132230",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 68,
              "hashtagId": "",
              "hashtagName": "",
              "isCommerce": false,
              "secUid": "",
              "start": 55,
              "subType": 0,
              "type": 0,
              "userId": "1774312",
              "userUniqueId": ""
            }
          ]
        },
        {
          "desc": ""
        },
        {
          "desc": "#newmusic #undergroundhyperpop #undergroundmusic #hyperpop #altpop #pinkpantheress #boysaliarpart2 #musiccurator #indiemusician #singersongwriter #musicproducer ",
          "textExtra": [
            {
              "awemeId": "",
              "end": 9,
              "hashtagId": "31075",
              "hashtagName": "newmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 0,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 30,
              "hashtagId": "7013453502701633541",
              "hashtagName": "undergroundhyperpop",
              "isCommerce": false,
              "secUid": "",
              "start": 10,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 48,
              "hashtagId": "325757",
              "hashtagName": "undergroundmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 31,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 58,
              "hashtagId": "1646514385777670",
              "hashtagName": "hyperpop",
              "isCommerce": false,
              "secUid": "",
              "start": 49,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 66,
              "hashtagId": "7747871",
              "hashtagName": "altpop",
              "isCommerce": false,
              "secUid": "",
              "start": 59,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 82,
              "hashtagId": "1691436528129030",
              "hashtagName": "pinkpantheress",
              "isCommerce": false,
              "secUid": "",
              "start": 67,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 98,
              "hashtagId": "7195852066214903851",
              "hashtagName": "boysaliarpart2",
              "isCommerce": false,
              "secUid": "",
              "start": 83,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 112,
              "hashtagId": "136517",
              "hashtagName": "musiccurator",
              "isCommerce": false,
              "secUid": "",
              "start": 99,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 127,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 113,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 145,
              "hashtagId": "496813",
              "hashtagName": "singersongwriter",
              "isCommerce": false,
              "secUid": "",
              "start": 128,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 160,
              "hashtagId": "50864",
              "hashtagName": "musicproducer",
              "isCommerce": false,
              "secUid": "",
              "start": 146,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1680747118,
      "desc": "Replying to @iswearidonthavehostages who\u2019s rockin with @lowkeybaitss #newmusic #undergroundhyperpop #undergroundmusic #hyperpop #altpop #pinkpantheress #boysaliarpart2 #musiccurator #indiemusician #singersongwriter #musicproducer ",
      "digged": false,
      "diversificationId": 10046,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7218753853364243755",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "Feel Complete",
        "authorName": "LowkeyBaitss",
        "coverLarge": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/fec20f9c2619454ca5c24588c2c2b29a~c5_720x720.jpeg",
        "coverMedium": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/fec20f9c2619454ca5c24588c2c2b29a~c5_200x200.jpeg",
        "coverThumb": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/fec20f9c2619454ca5c24588c2c2b29a~c5_100x100.jpeg",
        "duration": 60,
        "id": "7203377484606212097",
        "original": false,
        "playUrl": "https://sf16-ies-music-va.tiktokcdn.com/obj/tos-useast2a-ve-2774/oocBIrfCpUQfjT93BOzCUm9OZljgiBjt14hjNZ",
        "title": "Feel Complete"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 45600,
        "commentCount": 1269,
        "diggCount": 355900,
        "playCount": 2100000,
        "shareCount": 288
      },
      "stickersOnItem": [
        {
          "stickerText": [
            "Part 38",
            "Artists with \ud83d\udd25 music but \ud83d\udca9 marketing"
          ],
          "stickerType": 4
        }
      ],
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 36,
          "hashtagId": "",
          "hashtagName": "",
          "isCommerce": false,
          "secUid": "MS4wLjABAAAA0qFor7hZtyq7H7Q-PTht79ANHOT1Vf6O4eqzMlBPRfTBbgtttQnJB_b_3DLFczCx",
          "start": 12,
          "subType": 2,
          "type": 0,
          "userId": "6712267733390132230",
          "userUniqueId": "iswearidonthavehostages"
        },
        {
          "awemeId": "",
          "end": 68,
          "hashtagId": "",
          "hashtagName": "",
          "isCommerce": false,
          "secUid": "MS4wLjABAAAAVPC5trDNumXd5St8XcNH0Vy-uyoKekhlw5oJkEMTvZI",
          "start": 55,
          "subType": 0,
          "type": 0,
          "userId": "1774312",
          "userUniqueId": "lowkeybaitss"
        },
        {
          "awemeId": "",
          "end": 78,
          "hashtagId": "31075",
          "hashtagName": "newmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 69,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 99,
          "hashtagId": "7013453502701633541",
          "hashtagName": "undergroundhyperpop",
          "isCommerce": false,
          "secUid": "",
          "start": 79,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 117,
          "hashtagId": "325757",
          "hashtagName": "undergroundmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 100,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 127,
          "hashtagId": "1646514385777670",
          "hashtagName": "hyperpop",
          "isCommerce": false,
          "secUid": "",
          "start": 118,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 135,
          "hashtagId": "7747871",
          "hashtagName": "altpop",
          "isCommerce": false,
          "secUid": "",
          "start": 128,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 151,
          "hashtagId": "1691436528129030",
          "hashtagName": "pinkpantheress",
          "isCommerce": false,
          "secUid": "",
          "start": 136,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 167,
          "hashtagId": "7195852066214903851",
          "hashtagName": "boysaliarpart2",
          "isCommerce": false,
          "secUid": "",
          "start": 152,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 181,
          "hashtagId": "136517",
          "hashtagName": "musiccurator",
          "isCommerce": false,
          "secUid": "",
          "start": 168,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 196,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 182,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 214,
          "hashtagId": "496813",
          "hashtagName": "singersongwriter",
          "isCommerce": false,
          "secUid": "",
          "start": 197,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 229,
          "hashtagId": "50864",
          "hashtagName": "musicproducer",
          "isCommerce": false,
          "secUid": "",
          "start": 215,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 1093396,
        "bitrateInfo": [
          {
            "Bitrate": 1414881,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 4800515,
              "FileCs": "c:0-31157-8747",
              "FileHash": "7fbee4a36aad6c1e4d2e77e5ef2eced8",
              "Uri": "v12044gd0000cgn2fsbc77u0i6hb2lf0",
              "UrlKey": "v12044gd0000cgn2fsbc77u0i6hb2lf0_bytevc1_1080p_1414881",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oYlVqEIInUBFedBfAieQkUjxqCbbIEHIDlA0IX/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2762&bt=1381&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=ODdlZTpoZGhlaTZkaWg6aUBpM29lOTM6ZnY1ajMzZzczNEA1Mi4uNTAtXmIxNi9iXmM0YSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128823&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=0964830dbbed98c2a51961bb403de084&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oYlVqEIInUBFedBfAieQkUjxqCbbIEHIDlA0IX/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2762&bt=1381&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=ODdlZTpoZGhlaTZkaWg6aUBpM29lOTM6ZnY1ajMzZzczNEA1Mi4uNTAtXmIxNi9iXmM0YSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128823&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=0964830dbbed98c2a51961bb403de084&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 1206408,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 4117472,
              "FileCs": "c:0-30624-0085",
              "FileHash": "5727c32c3747fa8ba113ad9f78d234b2",
              "Uri": "v12044gd0000cgn2fsbc77u0i6hb2lf0",
              "UrlKey": "v12044gd0000cgn2fsbc77u0i6hb2lf0_h264_540p_1206408",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQonIXgBxVxEIC8IgefH8i2ADhkOcqHQ0AbejV/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2356&bt=1178&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=PGhlM2g4aGhpZDU0PDVmO0BpM29lOTM6ZnY1ajMzZzczNEBiLzQvMDJgXzUxMWExXzExYSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128823&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=cabf2572345d7f988fc3f4b354dd56e7&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQonIXgBxVxEIC8IgefH8i2ADhkOcqHQ0AbejV/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2356&bt=1178&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=PGhlM2g4aGhpZDU0PDVmO0BpM29lOTM6ZnY1ajMzZzczNEBiLzQvMDJgXzUxMWExXzExYSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128823&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=cabf2572345d7f988fc3f4b354dd56e7&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 1093396,
            "CodecType": "h264",
            "GearName": "normal_720_0",
            "PlayAddr": {
              "DataSize": 3713038,
              "FileCs": "c:0-23792-6470",
              "FileHash": "a802b8b3c1eeac926f3802b60619b5c5",
              "Uri": "v12044gd0000cgn2fsbc77u0i6hb2lf0",
              "UrlKey": "v12044gd0000cgn2fsbc77u0i6hb2lf0_h264_720p_1093396",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/owII8Xq0HnpXVkidSeepAfUDjABWQlxbIDCIiE/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2134&bt=1067&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=Ojk6PDhkOTVnaGgzNWY7ZUBpM29lOTM6ZnY1ajMzZzczNEAtMWAtYzEtNi0xXzMuYjU0YSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128823&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c19353657d944e478e6e8d6d812a38ab&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/owII8Xq0HnpXVkidSeepAfUDjABWQlxbIDCIiE/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2134&bt=1067&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=Ojk6PDhkOTVnaGgzNWY7ZUBpM29lOTM6ZnY1ajMzZzczNEAtMWAtYzEtNi0xXzMuYjU0YSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128823&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c19353657d944e478e6e8d6d812a38ab&tk=tt_chain_token"
              ]
            },
            "QualityType": 10
          },
          {
            "Bitrate": 758213,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 2574797,
              "FileCs": "c:0-23926-898d",
              "FileHash": "da2aeb9f33d5a5209e43c7164a7bea5a",
              "Uri": "v12044gd0000cgn2fsbc77u0i6hb2lf0",
              "UrlKey": "v12044gd0000cgn2fsbc77u0i6hb2lf0_bytevc1_720p_758213",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o8I0Q2CIHA6oiSj8EDzfbgqA7xAnXeBfEkiTVI/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1480&bt=740&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=Nzw8NDU3M2Y8NGk8NzppO0BpM29lOTM6ZnY1ajMzZzczNEBhNF80LmFiNmAxX18zLzJgYSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128823&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=75448fde92b468ebfed2f45e2425c088&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o8I0Q2CIHA6oiSj8EDzfbgqA7xAnXeBfEkiTVI/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1480&bt=740&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=Nzw8NDU3M2Y8NGk8NzppO0BpM29lOTM6ZnY1ajMzZzczNEBhNF80LmFiNmAxX18zLzJgYSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128823&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=75448fde92b468ebfed2f45e2425c088&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 653778,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 2231345,
              "FileCs": "c:0-30624-43da",
              "FileHash": "d1d4b84ed8bbbf570dbc208e01a5eeb5",
              "Uri": "v12044gd0000cgn2fsbc77u0i6hb2lf0",
              "UrlKey": "v12044gd0000cgn2fsbc77u0i6hb2lf0_h264_540p_653778",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oYuiqCtIjUIAnkZbBMIQ0VnIIleeDHxECAGfKX/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1276&bt=638&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=OWc7M2k3O2c7OTppODo6M0BpM29lOTM6ZnY1ajMzZzczNEBjX2ExL2NhNmAxYGNhNTY0YSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128823&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=963a6af1f0108f53713a40e3570faf66&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oYuiqCtIjUIAnkZbBMIQ0VnIIleeDHxECAGfKX/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1276&bt=638&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=OWc7M2k3O2c7OTppODo6M0BpM29lOTM6ZnY1ajMzZzczNEBjX2ExL2NhNmAxYGNhNTY0YSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128823&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=963a6af1f0108f53713a40e3570faf66&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 623931,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 2118793,
              "FileCs": "c:0-23926-98e0",
              "FileHash": "3c9ed74e045f832aff7de91fa2282ff2",
              "Uri": "v12044gd0000cgn2fsbc77u0i6hb2lf0",
              "UrlKey": "v12044gd0000cgn2fsbc77u0i6hb2lf0_bytevc1_540p_623931",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oAgq0x8bjIDPqkHQCVn7ifeECf0XAABQIrTEoI/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1218&bt=609&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=O2U6Nzk6OWhoaTQ1ZzllM0BpM29lOTM6ZnY1ajMzZzczNEBiYzAvMy4wXmIxYWBjLTAwYSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128823&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=83579eaed7567ebeec5592ee305c7b4f&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oAgq0x8bjIDPqkHQCVn7ifeECf0XAABQIrTEoI/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1218&bt=609&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=O2U6Nzk6OWhoaTQ1ZzllM0BpM29lOTM6ZnY1ajMzZzczNEBiYzAvMy4wXmIxYWBjLTAwYSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128823&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=83579eaed7567ebeec5592ee305c7b4f&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 468198,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 1597963,
              "FileCs": "c:0-30624-9bee",
              "FileHash": "a627cc5062c57ab1f1fffe283cf52120",
              "Uri": "v12044gd0000cgn2fsbc77u0i6hb2lf0",
              "UrlKey": "v12044gd0000cgn2fsbc77u0i6hb2lf0_h264_540p_468198",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQECDixbBcBIeHABQfR8jVqnADZIkXg0IVXfoD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=914&bt=457&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=aDs7NjRnPGk6Ojc0Mzs5M0BpM29lOTM6ZnY1ajMzZzczNEA0YF8tX2NiX18xYy0uLjI0YSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128823&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=eae81b96da2c86dcb6722fb102ad812b&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQECDixbBcBIeHABQfR8jVqnADZIkXg0IVXfoD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=914&bt=457&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=aDs7NjRnPGk6Ojc0Mzs5M0BpM29lOTM6ZnY1ajMzZzczNEA0YF8tX2NiX18xYy0uLjI0YSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128823&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=eae81b96da2c86dcb6722fb102ad812b&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/2851a1f691204807ae8174c7f15557ed_1680747119?x-expires=1700125200&x-signature=td%2B7H%2FL%2F0oMAwSLejdPB2Lok8NQ%3D",
        "definition": "720p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/osjCfeHUQ0xbwBXIpIgDAekurFIIUEiVnEKqDA/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=2454&bt=1227&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NGk3PDNkZzw3aDVnOjtlM0BpM29lOTM6ZnY1ajMzZzczNEAwYDYyNjY0NmMxYmMuNl5hYSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128823&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=a01438189c81e634d78dc11f2d43d4dd&tk=tt_chain_token",
        "duration": 27,
        "dynamicCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/931e3325e9184ad3bf10dcdce3e0b53d_1680747121?x-expires=1700125200&x-signature=oJBaYIWLz1SQye0gMcle8cX%2Fg70%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1280,
        "id": "7218753853364243755",
        "originCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/cc74fe5b2205488baf3fa9637a0e840d_1680747119?x-expires=1700125200&x-signature=w1TYORlfsKgDEDSMINj7%2B%2FdY7KI%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/owII8Xq0HnpXVkidSeepAfUDjABWQlxbIDCIiE/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2134&bt=1067&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=Ojk6PDhkOTVnaGgzNWY7ZUBpM29lOTM6ZnY1ajMzZzczNEAtMWAtYzEtNi0xXzMuYjU0YSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128823&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c19353657d944e478e6e8d6d812a38ab&tk=tt_chain_token",
        "ratio": "720p",
        "reflowCover": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/2851a1f691204807ae8174c7f15557ed_1680747119~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=7HkBeJZM%2FmoLXC6uT%2Bftcrtiup8%3D",
        "shareCover": [
          "",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/cc74fe5b2205488baf3fa9637a0e840d_1680747119~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=h5Pa0D4EUhGMbTgJvewMP7FE9Hg%3D",
          "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/cc74fe5b2205488baf3fa9637a0e840d_1680747119~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=a31SakR7s79fGpGUP6FMYKx1EEk%3D"
        ],
        "size": 3713038,
        "subtitleInfos": [
          {
            "Format": "webvtt",
            "LanguageCodeName": "ita-IT",
            "LanguageID": "26",
            "Size": 663,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/601a3e9dbb2062deacbf4900e5364064/6555e837/video/tos/maliva/tos-maliva-v-0068c799-us/03ef74a890064b0d933f56d1b04874ca/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2134&bt=1067&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M29lOTM6ZnY1ajMzZzczNEBpM29lOTM6ZnY1ajMzZzczNEBjL2VmcjRfY2tgLS1kMS9zYSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128823,
            "Version": "4",
            "VideoSubtitleID": -1123451650
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "por-PT",
            "LanguageID": "8",
            "Size": 690,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/e8f264116082e2a1058c9feb6a0fa202/6555e837/video/tos/maliva/tos-maliva-v-0068c799-us/3ff3b020ec514cb18d88f32e174bf3e2/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2134&bt=1067&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M29lOTM6ZnY1ajMzZzczNEBpM29lOTM6ZnY1ajMzZzczNEBjL2VmcjRfY2tgLS1kMS9zYSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128823,
            "Version": "4",
            "VideoSubtitleID": -762754414
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "vie-VN",
            "LanguageID": "10",
            "Size": 779,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/ccc76473f112091fdac1e476e1d6224f/6555e837/video/tos/maliva/tos-maliva-v-0068c799-us/2d5e7abe45d04e8d91df2352e0a81657/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2134&bt=1067&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M29lOTM6ZnY1ajMzZzczNEBpM29lOTM6ZnY1ajMzZzczNEBjL2VmcjRfY2tgLS1kMS9zYSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128823,
            "Version": "4",
            "VideoSubtitleID": -1167224696
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "cmn-Hans-CN",
            "LanguageID": "1",
            "Size": 598,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/4aa26b013c5feca4036beb91d63d871e/6555e837/video/tos/maliva/tos-maliva-v-0068c799-us/9801af551daa458a8d382dbea8952187/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2134&bt=1067&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M29lOTM6ZnY1ajMzZzczNEBpM29lOTM6ZnY1ajMzZzczNEBjL2VmcjRfY2tgLS1kMS9zYSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128823,
            "Version": "4",
            "VideoSubtitleID": -1507272492
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "deu-DE",
            "LanguageID": "20",
            "Size": 771,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/1558d6e1863277583764de0852cf9ea7/6555e837/video/tos/maliva/tos-maliva-v-0068c799-us/23b9c1ca9f594e39b58a25815bdb32d7/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2134&bt=1067&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M29lOTM6ZnY1ajMzZzczNEBpM29lOTM6ZnY1ajMzZzczNEBjL2VmcjRfY2tgLS1kMS9zYSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128823,
            "Version": "4",
            "VideoSubtitleID": -1789171188
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ind-ID",
            "LanguageID": "23",
            "Size": 692,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/efc72647ff24b67fe77d2cb7a59a38a3/6555e837/video/tos/maliva/tos-maliva-v-0068c799-us/af580bd8e9ce47ecbda8786c3e0807ac/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2134&bt=1067&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M29lOTM6ZnY1ajMzZzczNEBpM29lOTM6ZnY1ajMzZzczNEBjL2VmcjRfY2tgLS1kMS9zYSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128823,
            "Version": "4",
            "VideoSubtitleID": -729933694
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "rus-RU",
            "LanguageID": "6",
            "Size": 1029,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/72ac708a8d981d40f78121fc8cd6560a/6555e837/video/tos/maliva/tos-maliva-v-0068c799-us/7dfec147fa2440419e7a59a7378d514a/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2134&bt=1067&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M29lOTM6ZnY1ajMzZzczNEBpM29lOTM6ZnY1ajMzZzczNEBjL2VmcjRfY2tgLS1kMS9zYSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128823,
            "Version": "4",
            "VideoSubtitleID": 584272588
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "eng-US",
            "LanguageID": "2",
            "Size": 591,
            "Source": "ASR",
            "Url": "https://v16-webapp.tiktok.com/0779b460968aa421a98055268b3ab234/6555e837/video/tos/maliva/tos-maliva-v-0068c799-us/7bd31509c797480dbd80368ab460ca48/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2134&bt=1067&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M29lOTM6ZnY1ajMzZzczNEBpM29lOTM6ZnY1ajMzZzczNEBjL2VmcjRfY2tgLS1kMS9zYSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128823,
            "Version": "1",
            "VideoSubtitleID": 2084431425
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "jpn-JP",
            "LanguageID": "3",
            "Size": 833,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/f5d2e8a3876ddcf5cbe795cb3e9e89fc/6555e837/video/tos/maliva/tos-maliva-v-0068c799-us/561b0f6c12f448dcb189358542214dda/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2134&bt=1067&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M29lOTM6ZnY1ajMzZzczNEBpM29lOTM6ZnY1ajMzZzczNEBjL2VmcjRfY2tgLS1kMS9zYSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128823,
            "Version": "4",
            "VideoSubtitleID": -991525930
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ara-SA",
            "LanguageID": "34",
            "Size": 746,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/9d0b00688d1c6e92f55478c66fc4eefe/6555e837/video/tos/maliva/tos-maliva-v-0068c799-us/76e2c7074e69424aa331fef17e86a14b/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2134&bt=1067&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M29lOTM6ZnY1ajMzZzczNEBpM29lOTM6ZnY1ajMzZzczNEBjL2VmcjRfY2tgLS1kMS9zYSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128823,
            "Version": "4",
            "VideoSubtitleID": 1144499200
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "fra-FR",
            "LanguageID": "7",
            "Size": 733,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/b6356cd2436f6c889f0fe81eae466dde/6555e837/video/tos/maliva/tos-maliva-v-0068c799-us/13dcecd1b93e4d30b4dbe2f0af623611/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2134&bt=1067&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M29lOTM6ZnY1ajMzZzczNEBpM29lOTM6ZnY1ajMzZzczNEBjL2VmcjRfY2tgLS1kMS9zYSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128823,
            "Version": "4",
            "VideoSubtitleID": 317766007
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "kor-KR",
            "LanguageID": "4",
            "Size": 745,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/42c3169bd98f7bab56a33331a39b40de/6555e837/video/tos/maliva/tos-maliva-v-0068c799-us/3f3180a7f6eb423e92c83bfbbc1ac63c/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2134&bt=1067&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M29lOTM6ZnY1ajMzZzczNEBpM29lOTM6ZnY1ajMzZzczNEBjL2VmcjRfY2tgLS1kMS9zYSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128823,
            "Version": "4",
            "VideoSubtitleID": 1229677975
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "spa-ES",
            "LanguageID": "9",
            "Size": 678,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/9d47f9181bfd71584145301e2224159b/6555e837/video/tos/maliva/tos-maliva-v-0068c799-us/76052df004c5459dad9ef3c442dfd3bb/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2134&bt=1067&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M29lOTM6ZnY1ajMzZzczNEBpM29lOTM6ZnY1ajMzZzczNEBjL2VmcjRfY2tgLS1kMS9zYSNjL2VmcjRfY2tgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128823,
            "Version": "4",
            "VideoSubtitleID": -776833047
          }
        ],
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -16.1,
          "Peak": 0.89125
        },
        "width": 720,
        "zoomCover": {
          "240": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/2851a1f691204807ae8174c7f15557ed_1680747119~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=HkeOExBuRkeGzjV1gs6yBeqr4nE%3D",
          "480": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/2851a1f691204807ae8174c7f15557ed_1680747119~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=hsG%2F8fxfHLDxnp%2Fscu%2FQythXGzM%3D",
          "720": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/2851a1f691204807ae8174c7f15557ed_1680747119~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=GTBqUIJGaoUTJOGBFSHMvN7qnu4%3D",
          "960": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/2851a1f691204807ae8174c7f15557ed_1680747119~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=Kql8SmtLNkfA2gaKtIvFtOHNX1U%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "7224218912691438638",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Discovering Artists",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAA79sDcMCbl7QatFptF3JKobDAz67Uc0yws24YRf6FRLl8aWSAgSzrw6mu-bd0maiw",
        "secret": false,
        "signature": "\ud83d\udce7: discoveringartists.irl@gmail.com\n\nFollow our Spotify playlist!\ud83d\udc47",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "discoveringartists.irl",
        "verified": false
      },
      "authorStats": {
        "diggCount": 86,
        "followerCount": 515300,
        "followingCount": 13,
        "friendCount": 0,
        "heart": 15800000,
        "heartCount": 15800000,
        "videoCount": 35
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055695396906",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "doyoumakemusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055791882286",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "discoveringartistsirl"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "325757",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "4504990",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "newmusicfriday"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "136517",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musiccurator"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "17331",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indie"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "496813",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "singersongwriter"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "50864",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musicproducer"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "35857",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indieartist"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "Do you make music?"
        },
        {
          "desc": ""
        },
        {
          "desc": "#doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #indie #singersongwriter #musicproducer #indieartist #indiemusician ",
          "textExtra": [
            {
              "awemeId": "",
              "end": 15,
              "hashtagId": "7252811055695396906",
              "hashtagName": "doyoumakemusic",
              "isCommerce": false,
              "secUid": "",
              "start": 0,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 38,
              "hashtagId": "7252811055791882286",
              "hashtagName": "discoveringartistsirl",
              "isCommerce": false,
              "secUid": "",
              "start": 16,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 56,
              "hashtagId": "325757",
              "hashtagName": "undergroundmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 39,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 72,
              "hashtagId": "4504990",
              "hashtagName": "newmusicfriday",
              "isCommerce": false,
              "secUid": "",
              "start": 57,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 86,
              "hashtagId": "136517",
              "hashtagName": "musiccurator",
              "isCommerce": false,
              "secUid": "",
              "start": 73,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 93,
              "hashtagId": "17331",
              "hashtagName": "indie",
              "isCommerce": false,
              "secUid": "",
              "start": 87,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 111,
              "hashtagId": "496813",
              "hashtagName": "singersongwriter",
              "isCommerce": false,
              "secUid": "",
              "start": 94,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 126,
              "hashtagId": "50864",
              "hashtagName": "musicproducer",
              "isCommerce": false,
              "secUid": "",
              "start": 112,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 139,
              "hashtagId": "35857",
              "hashtagName": "indieartist",
              "isCommerce": false,
              "secUid": "",
              "start": 127,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 154,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 140,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1694551668,
      "desc": "Do you make music? #doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #indie #singersongwriter #musicproducer #indieartist #indiemusician ",
      "digged": false,
      "diversificationId": 10046,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7278043933794684206",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "",
        "authorName": "Discovering Artists",
        "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "coverMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "coverThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "duration": 66,
        "id": "7278043959539206958",
        "original": true,
        "playUrl": "https://v16m.tiktokcdn.com/e101489c13af1f25be29a0290a3f73db/655399bd/video/tos/maliva/tos-maliva-ve-27dcd7c799-us/oUkD5nnqeBg1AzAaAJbpCBxU6TeIMSQbglMtsD/?a=1233&ch=0&cr=0&dr=0&er=2&cd=0%7C0%7C0%7C0&br=250&bt=125&bti=NDU3ZjAwOg%3D%3D&ft=d2A~l-Inz7TPgL-Ziyq8Z&mime_type=audio_mpeg&qs=6&rc=NWk8N2loNztkNTg7OGRlN0BpajN1bzw6ZndmbjMzZzU8NEBhXjYyYS9hXy4xNTYtMmAyYSNoMGtycjRfcC1gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00090000&cc=3&download=true",
        "title": "original sound"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 23000,
        "commentCount": 1253,
        "diggCount": 350000,
        "playCount": 2100000,
        "shareCount": 2443
      },
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 34,
          "hashtagId": "7252811055695396906",
          "hashtagName": "doyoumakemusic",
          "isCommerce": false,
          "secUid": "",
          "start": 19,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 57,
          "hashtagId": "7252811055791882286",
          "hashtagName": "discoveringartistsirl",
          "isCommerce": false,
          "secUid": "",
          "start": 35,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 75,
          "hashtagId": "325757",
          "hashtagName": "undergroundmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 58,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 91,
          "hashtagId": "4504990",
          "hashtagName": "newmusicfriday",
          "isCommerce": false,
          "secUid": "",
          "start": 76,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 105,
          "hashtagId": "136517",
          "hashtagName": "musiccurator",
          "isCommerce": false,
          "secUid": "",
          "start": 92,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 112,
          "hashtagId": "17331",
          "hashtagName": "indie",
          "isCommerce": false,
          "secUid": "",
          "start": 106,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 130,
          "hashtagId": "496813",
          "hashtagName": "singersongwriter",
          "isCommerce": false,
          "secUid": "",
          "start": 113,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 145,
          "hashtagId": "50864",
          "hashtagName": "musicproducer",
          "isCommerce": false,
          "secUid": "",
          "start": 131,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 158,
          "hashtagId": "35857",
          "hashtagName": "indieartist",
          "isCommerce": false,
          "secUid": "",
          "start": 146,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 173,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 159,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 2691150,
        "bitrateInfo": [
          {
            "Bitrate": 2691150,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 22237652,
              "FileCs": "c:0-55754-fa82",
              "FileHash": "19198cbca11476be7c7194015f96e999",
              "Uri": "v12044gd0000ck0cp3rc77udnh3n0cu0",
              "UrlKey": "v12044gd0000ck0cp3rc77udnh3n0cu0_h264_540p_2691150",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/osgn8B7eME2aZgyBQgqSDb4PSnAklIAIeDgRgY/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=5256&bt=2628&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aTc3ZjM8NmU1ZTxkM2dnNkBpM2Zxa2c6ZjZmbjMzZzczNEBjYzU2XjEzXzExLV42MzI2YSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128862&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=7bd99fe146533476d483c597df6cfe63&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/osgn8B7eME2aZgyBQgqSDb4PSnAklIAIeDgRgY/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=5256&bt=2628&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aTc3ZjM8NmU1ZTxkM2dnNkBpM2Zxa2c6ZjZmbjMzZzczNEBjYzU2XjEzXzExLV42MzI2YSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128862&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=7bd99fe146533476d483c597df6cfe63&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 2062398,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 17042117,
              "FileCs": "c:0-55997-b90a",
              "FileHash": "5eddad03eb5e66f3ce8acd7f30cc149a",
              "Uri": "v12044gd0000ck0cp3rc77udnh3n0cu0",
              "UrlKey": "v12044gd0000ck0cp3rc77udnh3n0cu0_bytevc1_1080p_2062398",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ooEzygQNPdHGCA8vibZBMwQAhIpHAMQIEwtfI0/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4028&bt=2014&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=Z2hmODg0ZzRkOzQ3ZmQ6OUBpM2Zxa2c6ZjZmbjMzZzczNEAxYy9eNS41XjIxYi82NS4uYSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128862&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c2e825e35976d53a2b596e071896d31d&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ooEzygQNPdHGCA8vibZBMwQAhIpHAMQIEwtfI0/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4028&bt=2014&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=Z2hmODg0ZzRkOzQ3ZmQ6OUBpM2Zxa2c6ZjZmbjMzZzczNEAxYy9eNS41XjIxYi82NS4uYSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128862&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c2e825e35976d53a2b596e071896d31d&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 1348563,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 11143519,
              "FileCs": "c:0-55967-bddb",
              "FileHash": "63d14438f0cc4a156835cd6252cef01d",
              "Uri": "v12044gd0000ck0cp3rc77udnh3n0cu0",
              "UrlKey": "v12044gd0000ck0cp3rc77udnh3n0cu0_bytevc1_720p_1348563",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/okAA4BDMfPnVRrgZyeQg8NBDb2SgQlIEn7QPIA/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2632&bt=1316&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=PGc4aWZnOTxmaTRpaDk4O0BpM2Zxa2c6ZjZmbjMzZzczNEBeYGAyYTA0NjUxNF5fLy0xYSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128862&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=d56986e905acb6636a90dbf34ea93dab&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/okAA4BDMfPnVRrgZyeQg8NBDb2SgQlIEn7QPIA/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2632&bt=1316&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=PGc4aWZnOTxmaTRpaDk4O0BpM2Zxa2c6ZjZmbjMzZzczNEBeYGAyYTA0NjUxNF5fLy0xYSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128862&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=d56986e905acb6636a90dbf34ea93dab&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 1254334,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 10364879,
              "FileCs": "c:0-55754-1204",
              "FileHash": "ece50de2ea55d7d413060130f682cd5f",
              "Uri": "v12044gd0000ck0cp3rc77udnh3n0cu0",
              "UrlKey": "v12044gd0000ck0cp3rc77udnh3n0cu0_h264_540p_1254334",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ooMAHvZh4EIdQEOIqAfyAzkzw8pHJBrptGgCwN/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2448&bt=1224&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=ZDU2NzMzaDs6OjM5ZTxoOUBpM2Zxa2c6ZjZmbjMzZzczNEAyM2IuXmNhNmMxX2NiYC0yYSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128862&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=8f99d45d281a543dcf30ef8b4e6a0c18&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ooMAHvZh4EIdQEOIqAfyAzkzw8pHJBrptGgCwN/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2448&bt=1224&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=ZDU2NzMzaDs6OjM5ZTxoOUBpM2Zxa2c6ZjZmbjMzZzczNEAyM2IuXmNhNmMxX2NiYC0yYSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128862&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=8f99d45d281a543dcf30ef8b4e6a0c18&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 1091926,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 9022865,
              "FileCs": "c:0-55967-3ee5",
              "FileHash": "2187972dc17f22308e616752848db377",
              "Uri": "v12044gd0000ck0cp3rc77udnh3n0cu0",
              "UrlKey": "v12044gd0000ck0cp3rc77udnh3n0cu0_bytevc1_540p_1091926",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o8CA8dwEhHzAgp4fGlAEsyzZNIvBICQmIy5Mtw/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2132&bt=1066&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=MzhmZTU0N2Q1OWc8NmloN0BpM2Zxa2c6ZjZmbjMzZzczNEBhYzZgX2JiNmIxMDZiMzQ0YSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128862&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=3ee21fd6941469132d46b82fdb829887&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o8CA8dwEhHzAgp4fGlAEsyzZNIvBICQmIy5Mtw/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2132&bt=1066&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=MzhmZTU0N2Q1OWc8NmloN0BpM2Zxa2c6ZjZmbjMzZzczNEBhYzZgX2JiNmIxMDZiMzQ0YSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128862&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=3ee21fd6941469132d46b82fdb829887&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 828697,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 6847733,
              "FileCs": "c:0-55754-64e6",
              "FileHash": "74248d6ec59a3ba426f4a6cca975cc2b",
              "Uri": "v12044gd0000ck0cp3rc77udnh3n0cu0",
              "UrlKey": "v12044gd0000ck0cp3rc77udnh3n0cu0_h264_540p_828697",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/osZgztNHChqpEozMvQywfAEAIhIGAhw88BuGdg/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1618&bt=809&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=OjM8NjVnN2hoOTk6aDllNEBpM2Zxa2c6ZjZmbjMzZzczNEAzYi4wMTMzXjMxMy5hNjMwYSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128862&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=ff00245e184d2aab8ef0ee3b44de9087&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/osZgztNHChqpEozMvQywfAEAIhIGAhw88BuGdg/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1618&bt=809&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=OjM8NjVnN2hoOTk6aDllNEBpM2Zxa2c6ZjZmbjMzZzczNEAzYi4wMTMzXjMxMy5hNjMwYSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128862&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=ff00245e184d2aab8ef0ee3b44de9087&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/c6e88de5c0574ebfa65fe0c12248a937_1694551669?x-expires=1700125200&x-signature=hjGMEuyN9ZX7URmKvTr%2FMHn9%2FN4%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oIRCwUfgMIzLHNQAZ8awtEhvyAEGIwdBpSoGzA/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=4562&bt=2281&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZDdpNWc4OjdpZmVoNGg4NUBpM2Zxa2c6ZjZmbjMzZzczNEBgXzViNWE0XzMxXmIvMC0xYSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128862&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=b0b33b9139e5805b283cbdc83fb5f3c1&tk=tt_chain_token",
        "duration": 66,
        "dynamicCover": "https://p19-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/3f212aed098944eb9759b781c67fb1c4_1694551670?x-expires=1700125200&x-signature=4jSiMzSBekgzggY8q%2FkSLIBBghI%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7278043933794684206",
        "originCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/63a250110ccb48fca3a94e5d4d74619f_1694551669?x-expires=1700125200&x-signature=FhgnUjhl3obQNgYyvu87rkCIppU%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/osgn8B7eME2aZgyBQgqSDb4PSnAklIAIeDgRgY/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=5256&bt=2628&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aTc3ZjM8NmU1ZTxkM2dnNkBpM2Zxa2c6ZjZmbjMzZzczNEBjYzU2XjEzXzExLV42MzI2YSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&btag=e00090000&expire=1700128862&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=7bd99fe146533476d483c597df6cfe63&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/c6e88de5c0574ebfa65fe0c12248a937_1694551669~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=H2Hsz7UHfeWqNRiKJItU2ullYv0%3D",
        "shareCover": [
          "",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/63a250110ccb48fca3a94e5d4d74619f_1694551669~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=gNyinZ%2F7mY%2Bi6E6WRXPZqiEHGJ8%3D",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/63a250110ccb48fca3a94e5d4d74619f_1694551669~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=JrvCX4koYRpErcEMgEQqZUBUm%2Fw%3D"
        ],
        "size": 22237652,
        "subtitleInfos": [
          {
            "Format": "webvtt",
            "LanguageCodeName": "vie-VN",
            "LanguageID": "10",
            "Size": 1592,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/d0f20656ac2afe1e1bd046ee2900ac46/6555e85e/video/tos/maliva/tos-maliva-v-0068c799-us/9ff45c0b56d941e79e9063eb5e387d04/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14796&bt=7398&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Zxa2c6ZjZmbjMzZzczNEBpM2Zxa2c6ZjZmbjMzZzczNEByLTBrcjRvbS1gLS1kMS9zYSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128862,
            "Version": "4",
            "VideoSubtitleID": -471644863
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "deu-DE",
            "LanguageID": "20",
            "Size": 1443,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/c88e6321db649a36fd7b0a4ae81040fe/6555e85e/video/tos/maliva/tos-maliva-v-0068c799-us/5faeed66383540968ec48e73fc893fc2/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14796&bt=7398&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Zxa2c6ZjZmbjMzZzczNEBpM2Zxa2c6ZjZmbjMzZzczNEByLTBrcjRvbS1gLS1kMS9zYSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128862,
            "Version": "4",
            "VideoSubtitleID": 1105832347
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "por-PT",
            "LanguageID": "8",
            "Size": 1384,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/9caa4d666167c262c38c9ad9e0f8db42/6555e85e/video/tos/maliva/tos-maliva-v-0068c799-us/bd137eb122f34c3a937fa0c698203bbf/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14796&bt=7398&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Zxa2c6ZjZmbjMzZzczNEBpM2Zxa2c6ZjZmbjMzZzczNEByLTBrcjRvbS1gLS1kMS9zYSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128862,
            "Version": "4",
            "VideoSubtitleID": 1464355234
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "eng-US",
            "LanguageID": "2",
            "Size": 985,
            "Source": "ASR",
            "Url": "https://v16-webapp.tiktok.com/8760b3164df65b151c8744d1f764f8f6/6555e85e/video/tos/maliva/tos-maliva-v-0068c799-us/c72be78efe45406fb779feb80521026b/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14796&bt=7398&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Zxa2c6ZjZmbjMzZzczNEBpM2Zxa2c6ZjZmbjMzZzczNEByLTBrcjRvbS1gLS1kMS9zYSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128862,
            "Version": "1",
            "VideoSubtitleID": -1446687010
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ara-SA",
            "LanguageID": "34",
            "Size": 1343,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/b7847bca47e9963d97b5aa6ddb4449ba/6555e85e/video/tos/maliva/tos-maliva-v-0068c799-us/2963d5ec0bbc4fc78d1a0d3e3a6ab01f/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14796&bt=7398&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Zxa2c6ZjZmbjMzZzczNEBpM2Zxa2c6ZjZmbjMzZzczNEByLTBrcjRvbS1gLS1kMS9zYSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128862,
            "Version": "4",
            "VideoSubtitleID": -467504120
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ind-ID",
            "LanguageID": "23",
            "Size": 1454,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/899115ab5453bfcf42d9a5a5c985b216/6555e85e/video/tos/maliva/tos-maliva-v-0068c799-us/ef0f86d79c0d401fbac692da54f7568f/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14796&bt=7398&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Zxa2c6ZjZmbjMzZzczNEBpM2Zxa2c6ZjZmbjMzZzczNEByLTBrcjRvbS1gLS1kMS9zYSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128862,
            "Version": "4",
            "VideoSubtitleID": -1135327113
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "jpn-JP",
            "LanguageID": "3",
            "Size": 1454,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/1a106218401f1c5591f47df0e96559ec/6555e85e/video/tos/maliva/tos-maliva-v-0068c799-us/34ccf46bd8ad40d19153c8c0ebfc47c3/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14796&bt=7398&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Zxa2c6ZjZmbjMzZzczNEBpM2Zxa2c6ZjZmbjMzZzczNEByLTBrcjRvbS1gLS1kMS9zYSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128862,
            "Version": "4",
            "VideoSubtitleID": 760770691
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "rus-RU",
            "LanguageID": "6",
            "Size": 1706,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/18c71e896c81d7ef96cded706712f11b/6555e85e/video/tos/maliva/tos-maliva-v-0068c799-us/4d69539a67b140748d4a1bcbfbd862ba/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14796&bt=7398&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Zxa2c6ZjZmbjMzZzczNEBpM2Zxa2c6ZjZmbjMzZzczNEByLTBrcjRvbS1gLS1kMS9zYSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128862,
            "Version": "4",
            "VideoSubtitleID": 1834330346
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "spa-ES",
            "LanguageID": "9",
            "Size": 1388,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/a20249cd5ca90858aad6194ee720a942/6555e85e/video/tos/maliva/tos-maliva-v-0068c799-us/995e79843de549af82c9586c3a612c99/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14796&bt=7398&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Zxa2c6ZjZmbjMzZzczNEBpM2Zxa2c6ZjZmbjMzZzczNEByLTBrcjRvbS1gLS1kMS9zYSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128862,
            "Version": "4",
            "VideoSubtitleID": 1127753225
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "cmn-Hans-CN",
            "LanguageID": "1",
            "Size": 1366,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/2b11b481e9e2bd6feb24421929d7f435/6555e85e/video/tos/maliva/tos-maliva-v-0068c799-us/e3c705c5ba2b421b8fbd4f7348899731/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14796&bt=7398&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Zxa2c6ZjZmbjMzZzczNEBpM2Zxa2c6ZjZmbjMzZzczNEByLTBrcjRvbS1gLS1kMS9zYSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128862,
            "Version": "4",
            "VideoSubtitleID": 350819768
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "kor-KR",
            "LanguageID": "4",
            "Size": 1486,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/008540226d043e535be1da9caec72b33/6555e85e/video/tos/maliva/tos-maliva-v-0068c799-us/d3334d11a7ed485b86f4a6d8cc284c99/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14796&bt=7398&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Zxa2c6ZjZmbjMzZzczNEBpM2Zxa2c6ZjZmbjMzZzczNEByLTBrcjRvbS1gLS1kMS9zYSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128862,
            "Version": "4",
            "VideoSubtitleID": -461255467
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ita-IT",
            "LanguageID": "26",
            "Size": 1385,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/832588fddd89f36dc6a24ad33c8c6b5a/6555e85e/video/tos/maliva/tos-maliva-v-0068c799-us/44dc8b09f01c4acea919c5dc9aea3bc0/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14796&bt=7398&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Zxa2c6ZjZmbjMzZzczNEBpM2Zxa2c6ZjZmbjMzZzczNEByLTBrcjRvbS1gLS1kMS9zYSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128862,
            "Version": "4",
            "VideoSubtitleID": -852605652
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "fra-FR",
            "LanguageID": "7",
            "Size": 1360,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/652bc0fcb43625bfa8354b7c59e64cb4/6555e85e/video/tos/maliva/tos-maliva-v-0068c799-us/49a8becc30954139b137cbb1ba4e2161/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14796&bt=7398&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Zxa2c6ZjZmbjMzZzczNEBpM2Zxa2c6ZjZmbjMzZzczNEByLTBrcjRvbS1gLS1kMS9zYSNyLTBrcjRvbS1gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00050000",
            "UrlExpire": 1700128862,
            "Version": "4",
            "VideoSubtitleID": 629133511
          }
        ],
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -13.7,
          "Peak": 1
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/c6e88de5c0574ebfa65fe0c12248a937_1694551669~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=A5HY5L666NbVoxtdvHRih6tcqQE%3D",
          "480": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/c6e88de5c0574ebfa65fe0c12248a937_1694551669~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=yzd6HStDf2pgB9YOlwc0%2Fc6t6cA%3D",
          "720": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/c6e88de5c0574ebfa65fe0c12248a937_1694551669~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=5M8%2FKK048E00TEM4F0i2RMO%2BjQE%3D",
          "960": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/c6e88de5c0574ebfa65fe0c12248a937_1694551669~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=P%2BovD1IqQ5WRrhYFrSY4eYAbPTg%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "7224218912691438638",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Discovering Artists",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAA79sDcMCbl7QatFptF3JKobDAz67Uc0yws24YRf6FRLl8aWSAgSzrw6mu-bd0maiw",
        "secret": false,
        "signature": "\ud83d\udce7: discoveringartists.irl@gmail.com\n\nFollow our Spotify playlist!\ud83d\udc47",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "discoveringartists.irl",
        "verified": false
      },
      "authorStats": {
        "diggCount": 86,
        "followerCount": 515300,
        "followingCount": 13,
        "friendCount": 0,
        "heart": 15800000,
        "heartCount": 15800000,
        "videoCount": 35
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055695396906",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "doyoumakemusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055791882286",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "discoveringartistsirl"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "325757",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "4504990",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "newmusicfriday"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "136517",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musiccurator"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1660249047707654",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "ericdoa"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "67296",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "bladee"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1646514385777670",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "hyperpop"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "17331",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indie"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "496813",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "singersongwriter"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "50864",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musicproducer"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "35857",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indieartist"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "211116",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "area"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "Do you make music?"
        },
        {
          "desc": ""
        },
        {
          "desc": "#doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #ericdoa #bladee #hyperpop #indie #singersongwriter #musicproducer #indieartist #indiemusician #area",
          "textExtra": [
            {
              "awemeId": "",
              "end": 15,
              "hashtagId": "7252811055695396906",
              "hashtagName": "doyoumakemusic",
              "isCommerce": false,
              "secUid": "",
              "start": 0,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 38,
              "hashtagId": "7252811055791882286",
              "hashtagName": "discoveringartistsirl",
              "isCommerce": false,
              "secUid": "",
              "start": 16,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 56,
              "hashtagId": "325757",
              "hashtagName": "undergroundmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 39,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 72,
              "hashtagId": "4504990",
              "hashtagName": "newmusicfriday",
              "isCommerce": false,
              "secUid": "",
              "start": 57,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 86,
              "hashtagId": "136517",
              "hashtagName": "musiccurator",
              "isCommerce": false,
              "secUid": "",
              "start": 73,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 95,
              "hashtagId": "1660249047707654",
              "hashtagName": "ericdoa",
              "isCommerce": false,
              "secUid": "",
              "start": 87,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 103,
              "hashtagId": "67296",
              "hashtagName": "bladee",
              "isCommerce": false,
              "secUid": "",
              "start": 96,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 113,
              "hashtagId": "1646514385777670",
              "hashtagName": "hyperpop",
              "isCommerce": false,
              "secUid": "",
              "start": 104,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 120,
              "hashtagId": "17331",
              "hashtagName": "indie",
              "isCommerce": false,
              "secUid": "",
              "start": 114,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 138,
              "hashtagId": "496813",
              "hashtagName": "singersongwriter",
              "isCommerce": false,
              "secUid": "",
              "start": 121,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 153,
              "hashtagId": "50864",
              "hashtagName": "musicproducer",
              "isCommerce": false,
              "secUid": "",
              "start": 139,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 166,
              "hashtagId": "35857",
              "hashtagName": "indieartist",
              "isCommerce": false,
              "secUid": "",
              "start": 154,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 181,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 167,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 187,
              "hashtagId": "211116",
              "hashtagName": "area",
              "isCommerce": false,
              "secUid": "",
              "start": 182,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1692134262,
      "desc": "Do you make music? #doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #ericdoa #bladee #hyperpop #indie #singersongwriter #musicproducer #indieartist #indiemusician #area",
      "digged": false,
      "diversificationId": 10046,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7267661252401368362",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "",
        "authorName": "Discovering Artists",
        "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "coverMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "coverThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "duration": 35,
        "id": "7267661275076512555",
        "original": true,
        "playUrl": "https://v77.tiktokcdn.com/102408655fc7ed017d76d5fece951aea/6553999f/video/tos/maliva/tos-maliva-ve-27dcd7c799-us/ooOWISVO7zC36GlqbffMDJkIrUugqBU2Ustibh/?a=1233&ch=0&cr=0&dr=0&er=2&cd=0%7C0%7C0%7C0&br=250&bt=125&bti=NDU3ZjAwOg%3D%3D&ft=d2A~l-Inz7TPgL-Ziyq8Z&mime_type=audio_mpeg&qs=6&rc=Ozg6aWVlM2Q4NjllaTQ5ZUBpamRsNGY6Znd4bTMzZzU8NEA0NS4xM182X14xYzBiYmIzYSNecWk0cjRvamFgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00088000&cc=13&download=true",
        "title": "original sound"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 31900,
        "commentCount": 1352,
        "diggCount": 347700,
        "playCount": 3000000,
        "shareCount": 1149
      },
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 34,
          "hashtagId": "7252811055695396906",
          "hashtagName": "doyoumakemusic",
          "isCommerce": false,
          "secUid": "",
          "start": 19,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 57,
          "hashtagId": "7252811055791882286",
          "hashtagName": "discoveringartistsirl",
          "isCommerce": false,
          "secUid": "",
          "start": 35,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 75,
          "hashtagId": "325757",
          "hashtagName": "undergroundmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 58,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 91,
          "hashtagId": "4504990",
          "hashtagName": "newmusicfriday",
          "isCommerce": false,
          "secUid": "",
          "start": 76,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 105,
          "hashtagId": "136517",
          "hashtagName": "musiccurator",
          "isCommerce": false,
          "secUid": "",
          "start": 92,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 114,
          "hashtagId": "1660249047707654",
          "hashtagName": "ericdoa",
          "isCommerce": false,
          "secUid": "",
          "start": 106,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 122,
          "hashtagId": "67296",
          "hashtagName": "bladee",
          "isCommerce": false,
          "secUid": "",
          "start": 115,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 132,
          "hashtagId": "1646514385777670",
          "hashtagName": "hyperpop",
          "isCommerce": false,
          "secUid": "",
          "start": 123,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 139,
          "hashtagId": "17331",
          "hashtagName": "indie",
          "isCommerce": false,
          "secUid": "",
          "start": 133,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 157,
          "hashtagId": "496813",
          "hashtagName": "singersongwriter",
          "isCommerce": false,
          "secUid": "",
          "start": 140,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 172,
          "hashtagId": "50864",
          "hashtagName": "musicproducer",
          "isCommerce": false,
          "secUid": "",
          "start": 158,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 185,
          "hashtagId": "35857",
          "hashtagName": "indieartist",
          "isCommerce": false,
          "secUid": "",
          "start": 173,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 200,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 186,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 206,
          "hashtagId": "211116",
          "hashtagName": "area",
          "isCommerce": false,
          "secUid": "",
          "start": 201,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 2022086,
        "bitrateInfo": [
          {
            "Bitrate": 2022086,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 8872411,
              "FileCs": "c:0-30118-d0d6",
              "FileHash": "df43f62b2ca9a8b4c7fe913df7d6bd63",
              "Uri": "v12044gd0000cjdull3c77u9ilcf7ar0",
              "UrlKey": "v12044gd0000cjdull3c77u9ilcf7ar0_h264_540p_2022086",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oohpjIREIDAN4UjbQkdgV2lzSBfVq62zfRiqrJ/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3948&bt=1974&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=OWVlN2QzZjhpZTs5OWZpZkBpM2Rpbzw6Zm94bTMzZzczNEBfYDQ1NmItNTIxYDY1XzA0YSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=61ff8858e96aeb0647e3f482d5621657&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oohpjIREIDAN4UjbQkdgV2lzSBfVq62zfRiqrJ/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3948&bt=1974&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=OWVlN2QzZjhpZTs5OWZpZkBpM2Rpbzw6Zm94bTMzZzczNEBfYDQ1NmItNTIxYDY1XzA0YSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=61ff8858e96aeb0647e3f482d5621657&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 1308824,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 5742794,
              "FileCs": "c:0-30537-8dfc",
              "FileHash": "19755b88443b1a53b78cf81289605990",
              "Uri": "v12044gd0000cjdull3c77u9ilcf7ar0",
              "UrlKey": "v12044gd0000cjdull3c77u9ilcf7ar0_bytevc1_1080p_1308824",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oYojrkJAesYKkUsbnHQnVtej0KgCHAbTD7IerL/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2556&bt=1278&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=aGdkZWc3ODU2Mzk4ZzlpNkBpM2Rpbzw6Zm94bTMzZzczNEBiXl4uNS40NjMxNF9eLmFgYSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=da22721cd160887a46f9de4ab2f17f40&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oYojrkJAesYKkUsbnHQnVtej0KgCHAbTD7IerL/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2556&bt=1278&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=aGdkZWc3ODU2Mzk4ZzlpNkBpM2Rpbzw6Zm94bTMzZzczNEBiXl4uNS40NjMxNF9eLmFgYSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=da22721cd160887a46f9de4ab2f17f40&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 965601,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 4236816,
              "FileCs": "c:0-30118-e035",
              "FileHash": "401c91b03356033971ba189f91b20c5f",
              "Uri": "v12044gd0000cjdull3c77u9ilcf7ar0",
              "UrlKey": "v12044gd0000cjdull3c77u9ilcf7ar0_h264_540p_965601",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ogSz2E4ChnbQU4fgkQqAj6fNlVJRBqE2bIDpVz/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1884&bt=942&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=NTg2ZGc7O2U3ZGU5OGlpO0BpM2Rpbzw6Zm94bTMzZzczNEAtNC00Y18wXjIxMi41YGMwYSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=4b25353e00e42262d420e69c9083b297&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/ogSz2E4ChnbQU4fgkQqAj6fNlVJRBqE2bIDpVz/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1884&bt=942&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=NTg2ZGc7O2U3ZGU5OGlpO0BpM2Rpbzw6Zm94bTMzZzczNEAtNC00Y18wXjIxMi41YGMwYSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=4b25353e00e42262d420e69c9083b297&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 903576,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 3964669,
              "FileCs": "c:0-30540-5544",
              "FileHash": "a4cb192b701d0629ca14f0c158971f3c",
              "Uri": "v12044gd0000cjdull3c77u9ilcf7ar0",
              "UrlKey": "v12044gd0000cjdull3c77u9ilcf7ar0_bytevc1_720p_903576",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQaHjrJobrVnqCH7AUkNebD0eIAjfgtTYsnKHQ/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1764&bt=882&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=OzdnZ2Q6NGk6NDxpMzY2ZkBpM2Rpbzw6Zm94bTMzZzczNEAtMGIwYDBgXjMxYGBgMF4wYSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=fa3bc3e706193578ffb9e84d1af95dcb&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQaHjrJobrVnqCH7AUkNebD0eIAjfgtTYsnKHQ/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1764&bt=882&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=OzdnZ2Q6NGk6NDxpMzY2ZkBpM2Rpbzw6Zm94bTMzZzczNEAtMGIwYDBgXjMxYGBgMF4wYSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=fa3bc3e706193578ffb9e84d1af95dcb&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 659592,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 2894129,
              "FileCs": "c:0-30118-358e",
              "FileHash": "3e20fcf70b2adffa65c2824bf00025ad",
              "Uri": "v12044gd0000cjdull3c77u9ilcf7ar0",
              "UrlKey": "v12044gd0000cjdull3c77u9ilcf7ar0_h264_540p_659592",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o4fEV2Qrlfz2ABSwjkJqRlgCVhIpNDqzUb64pJ/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1288&bt=644&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=aDk4ODY2ZWU7M2k4aTY4OEBpM2Rpbzw6Zm94bTMzZzczNEBjMi1iYzNjNjExNDJeMTQ2YSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=92f5583a462533a89cd9610a6af85589&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o4fEV2Qrlfz2ABSwjkJqRlgCVhIpNDqzUb64pJ/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1288&bt=644&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=aDk4ODY2ZWU7M2k4aTY4OEBpM2Rpbzw6Zm94bTMzZzczNEBjMi1iYzNjNjExNDJeMTQ2YSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=92f5583a462533a89cd9610a6af85589&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          },
          {
            "Bitrate": 644993,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 2830072,
              "FileCs": "c:0-30540-2dd9",
              "FileHash": "2d8cff1d49d4a7e62cdfb1bd61abf9bf",
              "Uri": "v12044gd0000cjdull3c77u9ilcf7ar0",
              "UrlKey": "v12044gd0000cjdull3c77u9ilcf7ar0_bytevc1_540p_644993",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/owUeZOACPDyCVKWJAYAeR2h3brIeVCHkrnmjt8/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1258&bt=629&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=PDZkZWVmaTNoZDM3ODM8ZUBpM2Rpbzw6Zm94bTMzZzczNEBiYF9iNjNhNi8xM18zY14tYSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=64a8dbeb5edc8053cd433ae8b9002536&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/owUeZOACPDyCVKWJAYAeR2h3brIeVCHkrnmjt8/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1258&bt=629&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=PDZkZWVmaTNoZDM3ODM8ZUBpM2Rpbzw6Zm94bTMzZzczNEBiYF9iNjNhNi8xM18zY14tYSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=64a8dbeb5edc8053cd433ae8b9002536&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          }
        ],
        "codecType": "h264",
        "cover": "https://p19-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/b5d2eb640f404ee9898708bcca7dec79_1692134263?x-expires=1700125200&x-signature=ASAG4nRz4uAz1OkjVGXnU%2Bnk3yI%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oA2A2Bhf5DzHRzgqVl9zfjbDNJSQ3kUE6jIqVF/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=3702&bt=1851&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=OzdlOTs7NTk6NTo5M2c8NEBpM2Rpbzw6Zm94bTMzZzczNEAwYS0wYzI1Xy4xNTQwMDU1YSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=946d7cb3ef9783189998bdcc9c754f86&tk=tt_chain_token",
        "duration": 35,
        "dynamicCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/48c868653f644b64b6d926a0ce31b786_1692134264?x-expires=1700125200&x-signature=SPdrgbv3BDQtHS2Ez2lm1mNRVTE%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7267661252401368362",
        "originCover": "https://p19-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/80cf345558994a9196a37ad742d882e4_1692134263?x-expires=1700125200&x-signature=po6oBBdu9T3lz9Rmtq3eJKQm1XA%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oohpjIREIDAN4UjbQkdgV2lzSBfVq62zfRiqrJ/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=3948&bt=1974&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=OWVlN2QzZjhpZTs5OWZpZkBpM2Rpbzw6Zm94bTMzZzczNEBfYDQ1NmItNTIxYDY1XzA0YSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128831&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=61ff8858e96aeb0647e3f482d5621657&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/b5d2eb640f404ee9898708bcca7dec79_1692134263~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=VewJWDQ6DgjhomE3PaSLfazioaM%3D",
        "shareCover": [
          "",
          "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/80cf345558994a9196a37ad742d882e4_1692134263~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=4qLeq%2FYOGjbtwB90slnsAgsCQ7g%3D",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/80cf345558994a9196a37ad742d882e4_1692134263~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=g7vLvDaLPGvc1AV%2FnfkKJff%2FzJE%3D"
        ],
        "size": 8872411,
        "subtitleInfos": [
          {
            "Format": "webvtt",
            "LanguageCodeName": "rus-RU",
            "LanguageID": "6",
            "Size": 970,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/b93b8f8b15c8c5103545eb2ff5fe22f6/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/887c4f5df34c4b1ba9abc513f993be06/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14048&bt=7024&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Rpbzw6Zm94bTMzZzczNEBpM2Rpbzw6Zm94bTMzZzczNEBvNGBmcjQwaWFgLS1kMS9zYSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": 1777739812
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "por-PT",
            "LanguageID": "8",
            "Size": 722,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/591d910488ead0a1539baa86d51fb561/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/a45e48fd62b34e519e92b53bf91e1ed2/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14048&bt=7024&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Rpbzw6Zm94bTMzZzczNEBpM2Rpbzw6Zm94bTMzZzczNEBvNGBmcjQwaWFgLS1kMS9zYSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": 1354819640
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "cmn-Hans-CN",
            "LanguageID": "1",
            "Size": 729,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/816332b43563bd51c45e5bc268128544/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/d5a2c5f8b43940e892109e75e6feb8c1/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14048&bt=7024&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Rpbzw6Zm94bTMzZzczNEBpM2Rpbzw6Zm94bTMzZzczNEBvNGBmcjQwaWFgLS1kMS9zYSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": 1566744839
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ara-SA",
            "LanguageID": "34",
            "Size": 726,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/6cf63f71ab47119f29a28c1271de4413/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/efb018acd401430e9264fce7559f1e76/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14048&bt=7024&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Rpbzw6Zm94bTMzZzczNEBpM2Rpbzw6Zm94bTMzZzczNEBvNGBmcjQwaWFgLS1kMS9zYSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": -767591032
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "jpn-JP",
            "LanguageID": "3",
            "Size": 945,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/48446e02bedd26b0b9f90217ee396433/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/b8a61625457b4f2d903a07c6166ceb29/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14048&bt=7024&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Rpbzw6Zm94bTMzZzczNEBpM2Rpbzw6Zm94bTMzZzczNEBvNGBmcjQwaWFgLS1kMS9zYSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": -49625101
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "vie-VN",
            "LanguageID": "10",
            "Size": 887,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/4598e9dc0c73c1e26713396e12f2feee/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/881aa2dc1fb449118343997e3ebe039d/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14048&bt=7024&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Rpbzw6Zm94bTMzZzczNEBpM2Rpbzw6Zm94bTMzZzczNEBvNGBmcjQwaWFgLS1kMS9zYSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": -257286275
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ind-ID",
            "LanguageID": "23",
            "Size": 742,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/f1aea8a55fbf23a33e7e81b220fb2721/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/874294cf502442f69865ec7403117460/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14048&bt=7024&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Rpbzw6Zm94bTMzZzczNEBpM2Rpbzw6Zm94bTMzZzczNEBvNGBmcjQwaWFgLS1kMS9zYSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": 1734388153
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "spa-ES",
            "LanguageID": "9",
            "Size": 751,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/4da56737c77cda7a6a4c10979f6693d9/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/57cae4afb0164eae93fa8e4d0808410d/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14048&bt=7024&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Rpbzw6Zm94bTMzZzczNEBpM2Rpbzw6Zm94bTMzZzczNEBvNGBmcjQwaWFgLS1kMS9zYSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": -1272056303
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "kor-KR",
            "LanguageID": "4",
            "Size": 858,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/2058b637ffbde388746949a560e058d0/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/9a48fcc33eec4caca6eeeee1cf02f68c/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14048&bt=7024&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Rpbzw6Zm94bTMzZzczNEBpM2Rpbzw6Zm94bTMzZzczNEBvNGBmcjQwaWFgLS1kMS9zYSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": -575229560
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "eng-US",
            "LanguageID": "2",
            "Size": 529,
            "Source": "ASR",
            "Url": "https://v16-webapp.tiktok.com/b90770aaa55a3914e9f9f6c0fdf0267a/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/038cf63897264bbd96fa4e1196553a85/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14048&bt=7024&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Rpbzw6Zm94bTMzZzczNEBpM2Rpbzw6Zm94bTMzZzczNEBvNGBmcjQwaWFgLS1kMS9zYSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "1",
            "VideoSubtitleID": 1276133410
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ita-IT",
            "LanguageID": "26",
            "Size": 687,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/4810167be92df44f8b4e3d73c5e96f26/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/e2ec33e2ccee45a782a2d7a8638b87ad/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14048&bt=7024&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Rpbzw6Zm94bTMzZzczNEBpM2Rpbzw6Zm94bTMzZzczNEBvNGBmcjQwaWFgLS1kMS9zYSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": 1591861580
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "deu-DE",
            "LanguageID": "20",
            "Size": 756,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/2c7a28636739f23d1445be428cfbbca5/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/245eaaf88bf544b894cab45b09ed4056/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14048&bt=7024&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Rpbzw6Zm94bTMzZzczNEBpM2Rpbzw6Zm94bTMzZzczNEBvNGBmcjQwaWFgLS1kMS9zYSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": -819828963
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "fra-FR",
            "LanguageID": "7",
            "Size": 776,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/001819be7a126f43f391ac7eda7ece9d/6555e83f/video/tos/maliva/tos-maliva-v-0068c799-us/3f41df845089429c9348337456df4cbf/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=14048&bt=7024&bti=NDU3ZjAwOg%3D%3D&ds=4&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M2Rpbzw6Zm94bTMzZzczNEBpM2Rpbzw6Zm94bTMzZzczNEBvNGBmcjQwaWFgLS1kMS9zYSNvNGBmcjQwaWFgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128831,
            "Version": "4",
            "VideoSubtitleID": 993987854
          }
        ],
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -13.9,
          "Peak": 1
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/b5d2eb640f404ee9898708bcca7dec79_1692134263~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=45NFouW0TSTq5z12pG11BYXmShc%3D",
          "480": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/b5d2eb640f404ee9898708bcca7dec79_1692134263~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=o3b4hIADeq0AHcnwTb1FmiszyHQ%3D",
          "720": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/b5d2eb640f404ee9898708bcca7dec79_1692134263~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=g5xFguIwbUF8E0aUElv2x8UBk3E%3D",
          "960": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/b5d2eb640f404ee9898708bcca7dec79_1692134263~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=fmUfAQ1wHbqkt7pTKm3Cx3evk74%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/7c903ffd7492d0b2c45c1ab00e2f3740~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=aJ0x9MwhSuCPttKKayH3WRDIJco%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/7c903ffd7492d0b2c45c1ab00e2f3740~c5_720x720.jpeg?x-expires=1700125200&x-signature=mlmIl%2FY8oWEvqw2zsFky0yiyykI%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/7c903ffd7492d0b2c45c1ab00e2f3740~c5_100x100.jpeg?x-expires=1700125200&x-signature=A7zAD%2BgR1pyiozrBzSi3FzaWAj4%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "6750099502227375110",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Project Atlantic",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAA8h6OBuL5VLHKYYTzeV1sDd0Hkm0PCvxpXhNZpYpB2mMIxgJYOqYNwwXuVAJLJwlw",
        "secret": false,
        "signature": "Solo project of Josh Zimmer\n99% Sarcasm, 1% Indie rock\n\ud83d\udc47Get a Synth-a-Sette\ud83d\udc47",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "projectatlanticmusic",
        "verified": false
      },
      "authorStats": {
        "diggCount": 2195,
        "followerCount": 52600,
        "followingCount": 421,
        "friendCount": 0,
        "heart": 4700000,
        "heartCount": 4700000,
        "videoCount": 914
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "5636",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "music"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "16103",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "\ud83e\ude97What's your favourite instrument?",
          "id": "1609427218899973",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musiciansoftiktok"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "323291",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indierock"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "37634",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "independentartist"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "19244024",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musichumor"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "14249478",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "eggharbortownship"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "26766",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "nj"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "65048",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "newjersey"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1645809370293254",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "independentmusician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "Love #IndieMusic? Start here.",
          "id": "247262",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/164263ca9942461c79d1ac0ee9fa0b21.jpeg",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/164263ca9942461c79d1ac0ee9fa0b21.jpeg",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/164263ca9942461c79d1ac0ee9fa0b21.jpeg",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "35857",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indieartist"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "39733345",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "projectatlantic"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "Most prolific songwriter of all time? "
        },
        {
          "desc": ""
        },
        {
          "desc": "To support me so I can continue to recommend cutting edge artists, listen to Project Atlantic on Spotify, Apple Music, or anywhere else music can be streamed."
        },
        {
          "desc": ""
        },
        {
          "desc": "JOIN THE PROJECT ATLANTIC DISCORD SERVER. Link in bio. "
        },
        {
          "desc": ""
        },
        {
          "desc": "I\u2019ve got merch! Click the link in my bio for apparel, stickers, and buttons."
        },
        {
          "desc": ""
        },
        {
          "desc": "#music #musician #musiciansoftiktok  #indierock #independentartist #musichumor #eggharbortownship #nj #newjersey #independentmusician #indiemusician #indiemusic #indieartist #projectatlantic",
          "textExtra": [
            {
              "awemeId": "",
              "end": 6,
              "hashtagId": "5636",
              "hashtagName": "music",
              "isCommerce": false,
              "secUid": "",
              "start": 0,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 16,
              "hashtagId": "16103",
              "hashtagName": "musician",
              "isCommerce": false,
              "secUid": "",
              "start": 7,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 35,
              "hashtagId": "1609427218899973",
              "hashtagName": "musiciansoftiktok",
              "isCommerce": false,
              "secUid": "",
              "start": 17,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 47,
              "hashtagId": "323291",
              "hashtagName": "indierock",
              "isCommerce": false,
              "secUid": "",
              "start": 37,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 66,
              "hashtagId": "37634",
              "hashtagName": "independentartist",
              "isCommerce": false,
              "secUid": "",
              "start": 48,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 78,
              "hashtagId": "19244024",
              "hashtagName": "musichumor",
              "isCommerce": false,
              "secUid": "",
              "start": 67,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 97,
              "hashtagId": "14249478",
              "hashtagName": "eggharbortownship",
              "isCommerce": false,
              "secUid": "",
              "start": 79,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 101,
              "hashtagId": "26766",
              "hashtagName": "nj",
              "isCommerce": false,
              "secUid": "",
              "start": 98,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 112,
              "hashtagId": "65048",
              "hashtagName": "newjersey",
              "isCommerce": false,
              "secUid": "",
              "start": 102,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 133,
              "hashtagId": "1645809370293254",
              "hashtagName": "independentmusician",
              "isCommerce": false,
              "secUid": "",
              "start": 113,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 148,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 134,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 160,
              "hashtagId": "247262",
              "hashtagName": "indiemusic",
              "isCommerce": false,
              "secUid": "",
              "start": 149,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 173,
              "hashtagId": "35857",
              "hashtagName": "indieartist",
              "isCommerce": false,
              "secUid": "",
              "start": 161,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 190,
              "hashtagId": "39733345",
              "hashtagName": "projectatlantic",
              "isCommerce": false,
              "secUid": "",
              "start": 174,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1683112494,
      "desc": "Most prolific songwriter of all time?  To support me so I can continue to recommend cutting edge artists, listen to Project Atlantic on Spotify, Apple Music, or anywhere else music can be streamed. JOIN THE PROJECT ATLANTIC DISCORD SERVER. Link in bio.  I\u2019ve got merch! Click the link in my bio for apparel, stickers, and buttons. #music #musician #musiciansoftiktok  #indierock #independentartist #musichumor #eggharbortownship #nj #newjersey #independentmusician #indiemusician #indiemusic #indieartist #projectatlantic",
      "digged": false,
      "diversificationId": 10046,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7228912922787925291",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "New Jersey Has Townships and Boroughs Too, Nj",
        "authorName": "The Guy Who Sings Songs About Cities & Towns",
        "coverLarge": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/88c8c320bee2426c8f6c684f299917a0~c5_720x720.jpeg",
        "coverMedium": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/88c8c320bee2426c8f6c684f299917a0~c5_200x200.jpeg",
        "coverThumb": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/88c8c320bee2426c8f6c684f299917a0~c5_100x100.jpeg",
        "duration": 53,
        "id": "6887872114058086402",
        "original": false,
        "playUrl": "https://sf16-ies-music-va.tiktokcdn.com/obj/tos-useast2a-ve-2774/00f89517e34a45dbbd834424fb1777ee",
        "title": "A Song About Asbury Park"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 32800,
        "commentCount": 4894,
        "diggCount": 314100,
        "playCount": 4100000,
        "shareCount": 3394
      },
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 337,
          "hashtagId": "5636",
          "hashtagName": "music",
          "isCommerce": false,
          "secUid": "",
          "start": 331,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 347,
          "hashtagId": "16103",
          "hashtagName": "musician",
          "isCommerce": false,
          "secUid": "",
          "start": 338,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 366,
          "hashtagId": "1609427218899973",
          "hashtagName": "musiciansoftiktok",
          "isCommerce": false,
          "secUid": "",
          "start": 348,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 378,
          "hashtagId": "323291",
          "hashtagName": "indierock",
          "isCommerce": false,
          "secUid": "",
          "start": 368,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 397,
          "hashtagId": "37634",
          "hashtagName": "independentartist",
          "isCommerce": false,
          "secUid": "",
          "start": 379,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 409,
          "hashtagId": "19244024",
          "hashtagName": "musichumor",
          "isCommerce": false,
          "secUid": "",
          "start": 398,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 428,
          "hashtagId": "14249478",
          "hashtagName": "eggharbortownship",
          "isCommerce": false,
          "secUid": "",
          "start": 410,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 432,
          "hashtagId": "26766",
          "hashtagName": "nj",
          "isCommerce": false,
          "secUid": "",
          "start": 429,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 443,
          "hashtagId": "65048",
          "hashtagName": "newjersey",
          "isCommerce": false,
          "secUid": "",
          "start": 433,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 464,
          "hashtagId": "1645809370293254",
          "hashtagName": "independentmusician",
          "isCommerce": false,
          "secUid": "",
          "start": 444,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 479,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 465,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 491,
          "hashtagId": "247262",
          "hashtagName": "indiemusic",
          "isCommerce": false,
          "secUid": "",
          "start": 480,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 504,
          "hashtagId": "35857",
          "hashtagName": "indieartist",
          "isCommerce": false,
          "secUid": "",
          "start": 492,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 521,
          "hashtagId": "39733345",
          "hashtagName": "projectatlantic",
          "isCommerce": false,
          "secUid": "",
          "start": 505,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 1109359,
        "bitrateInfo": [
          {
            "Bitrate": 1109359,
            "CodecType": "h264",
            "GearName": "normal_720_0",
            "PlayAddr": {
              "DataSize": 1101039,
              "FileCs": "c:0-8048-002e",
              "FileHash": "43beeb6fb8e96470a57082277f9faec6",
              "Uri": "v12044gd0000ch9448jc77u39cc4dak0",
              "UrlKey": "v12044gd0000ch9448jc77u39cc4dak0_h264_720p_1109359",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQNICD7ohfASJKQwUILzpRhGSqRBEDySTBxNAJ/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2166&bt=1083&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NDY7NDRlPDs8aGhnOjxpNEBpM2Q3ZjY6Zjs3azMzZzczNEAwYTA1My4tXzExYF9gLi8yYSNoYWA2cjRnMTZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=9227f4a2fbcfa0524193605f24997625&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQNICD7ohfASJKQwUILzpRhGSqRBEDySTBxNAJ/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2166&bt=1083&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NDY7NDRlPDs8aGhnOjxpNEBpM2Q3ZjY6Zjs3azMzZzczNEAwYTA1My4tXzExYF9gLi8yYSNoYWA2cjRnMTZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=9227f4a2fbcfa0524193605f24997625&tk=tt_chain_token"
              ]
            },
            "QualityType": 10
          },
          {
            "Bitrate": 877012,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 888085,
              "FileCs": "c:0-7989-5da6",
              "FileHash": "479aa994e43c9717f250f87361f6744f",
              "Uri": "v12044gd0000ch9448jc77u39cc4dak0",
              "UrlKey": "v12044gd0000ch9448jc77u39cc4dak0_h264_540p_877012",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oANy6GIVCczKhSLhPQfERIRjBojxUwADSqNpSA/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1712&bt=856&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aWhoZzRoZGkzZ2dkZWc0NUBpM2Q3ZjY6Zjs3azMzZzczNEAyYGIzYDIzNTIxYWAtYjFhYSNoYWA2cjRnMTZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=a18897b72101b4e78f79e88e22f166e5&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oANy6GIVCczKhSLhPQfERIRjBojxUwADSqNpSA/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1712&bt=856&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aWhoZzRoZGkzZ2dkZWc0NUBpM2Q3ZjY6Zjs3azMzZzczNEAyYGIzYDIzNTIxYWAtYjFhYSNoYWA2cjRnMTZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=a18897b72101b4e78f79e88e22f166e5&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 752185,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 746544,
              "FileCs": "c:0-8153-8bd5",
              "FileHash": "6dd8f1a9f07e170f483d1694900980df",
              "Uri": "v12044gd0000ch9448jc77u39cc4dak0",
              "UrlKey": "v12044gd0000ch9448jc77u39cc4dak0_bytevc1_1080p_752185",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/okxRTLZoyBBaTqhSADfhwEzRCKAICgDUISNGpS/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1468&bt=734&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=ZTc5ZmY2OWQ2OjZnZ2VpZEBpM2Q3ZjY6Zjs3azMzZzczNEAuYjRfLTJeNTIxNC0zNGEyYSNoYWA2cjRnMTZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=6a5c29cdfc138e689a50aca3839eeda2&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/okxRTLZoyBBaTqhSADfhwEzRCKAICgDUISNGpS/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1468&bt=734&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=ZTc5ZmY2OWQ2OjZnZ2VpZEBpM2Q3ZjY6Zjs3azMzZzczNEAuYjRfLTJeNTIxNC0zNGEyYSNoYWA2cjRnMTZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=6a5c29cdfc138e689a50aca3839eeda2&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 467350,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 463845,
              "FileCs": "c:0-8154-af7e",
              "FileHash": "4d7b8d1a245ad4092357a8e82be122de",
              "Uri": "v12044gd0000ch9448jc77u39cc4dak0",
              "UrlKey": "v12044gd0000ch9448jc77u39cc4dak0_bytevc1_720p_467350",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oU1IQSQlinAkQjJPekeGDRkOVENgVBdUoCbTSD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=912&bt=456&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=ZjVoNzRnOGU1NzUzNmY5NUBpM2Q3ZjY6Zjs3azMzZzczNEBjYmBeNjA0NWAxMTEzNTVhYSNoYWA2cjRnMTZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=4957e56f47eb2fd97ce5a49d2e220dce&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oU1IQSQlinAkQjJPekeGDRkOVENgVBdUoCbTSD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=912&bt=456&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=ZjVoNzRnOGU1NzUzNmY5NUBpM2Q3ZjY6Zjs3azMzZzczNEBjYmBeNjA0NWAxMTEzNTVhYSNoYWA2cjRnMTZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=4957e56f47eb2fd97ce5a49d2e220dce&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 427132,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 423929,
              "FileCs": "c:0-8154-831e",
              "FileHash": "c52c4de0a9809444b6b5fb90e6cd6d17",
              "Uri": "v12044gd0000ch9448jc77u39cc4dak0",
              "UrlKey": "v12044gd0000ch9448jc77u39cc4dak0_bytevc1_540p_427132",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/okQIcMSnkBQGVJNeD7fkWAQlEDCSbUKgjRokli/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=834&bt=417&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=Ozc1ZmY0ZjxoZjs2Nzs5O0BpM2Q3ZjY6Zjs3azMzZzczNEAtXmNjNC40NTMxYTNhMi1gYSNoYWA2cjRnMTZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=0d784afe32835b48b0174bd746eb0a11&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/okQIcMSnkBQGVJNeD7fkWAQlEDCSbUKgjRokli/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=834&bt=417&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=Ozc1ZmY0ZjxoZjs2Nzs5O0BpM2Q3ZjY6Zjs3azMzZzczNEAtXmNjNC40NTMxYTNhMi1gYSNoYWA2cjRnMTZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=0d784afe32835b48b0174bd746eb0a11&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 331456,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 335641,
              "FileCs": "c:0-7989-57a8",
              "FileHash": "806eb93288c6da73ce702d9755cab92c",
              "Uri": "v12044gd0000ch9448jc77u39cc4dak0",
              "UrlKey": "v12044gd0000ch9448jc77u39cc4dak0_h264_540p_331456",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oExjSAGOSpFBhhUylKfNBAw5qQZxEoISIHzDCR/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=646&bt=323&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=Zzc5NDw5NDY2OjNoZjlkZUBpM2Q3ZjY6Zjs3azMzZzczNEBjLTZiYGIuXmAxMGBgY19hYSNoYWA2cjRnMTZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=075d182d14dcef54dd61461300308f34&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oExjSAGOSpFBhhUylKfNBAw5qQZxEoISIHzDCR/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=646&bt=323&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=Zzc5NDw5NDY2OjNoZjlkZUBpM2Q3ZjY6Zjs3azMzZzczNEBjLTZiYGIuXmAxMGBgY19hYSNoYWA2cjRnMTZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=075d182d14dcef54dd61461300308f34&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 212389,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 215071,
              "FileCs": "c:0-7989-26d2",
              "FileHash": "0942f91cd4c8894a3819036015d0195b",
              "Uri": "v12044gd0000ch9448jc77u39cc4dak0",
              "UrlKey": "v12044gd0000ch9448jc77u39cc4dak0_h264_540p_212389",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o8qSRSEhBxIhDUIIVAfyAQ9zwopASB0jNSKCGD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=414&bt=207&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=OThpaDc5NmQ2ZDgzMzhnO0BpM2Q3ZjY6Zjs3azMzZzczNEAvLmI1LjM1NTIxXjFiNmAvYSNoYWA2cjRnMTZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=d1acb471dc27dfe907650c192fe4a398&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o8qSRSEhBxIhDUIIVAfyAQ9zwopASB0jNSKCGD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=414&bt=207&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=OThpaDc5NmQ2ZDgzMzhnO0BpM2Q3ZjY6Zjs3azMzZzczNEAvLmI1LjM1NTIxXjFiNmAvYSNoYWA2cjRnMTZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=d1acb471dc27dfe907650c192fe4a398&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/98a8f8f88afe47c593c64b83a6340f26~tplv-dmt-logom:tos-useast5-i-0068-tx/fdfea8e87f4f49b188027d0c2d7b19a3.image?x-expires=1700125200&x-signature=kGVMzmh%2BUX6022hh6Iwfu16sOuY%3D",
        "definition": "720p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQkneBClkEGnVqIkQcBGDbQiShUtkoAjeDNRgQ/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=1770&bt=885&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=Nzw3ZDw2PGhoOmhlM2Y7aEBpM2Q3ZjY6Zjs3azMzZzczNEBiYWAwLy42X18xNDReMDZhYSNoYWA2cjRnMTZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=7c7bc03d9e51fbb3be783ef1e82baf2a&tk=tt_chain_token",
        "duration": 7,
        "dynamicCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/1c2f62598b0644e8a8bab1a598a036df_1683112495?x-expires=1700125200&x-signature=2Vz2lFbOxmf%2BzFQUFYtaIaBgm9Q%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1280,
        "id": "7228912922787925291",
        "originCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/7cb803ccc2f44637a9c0a6eb3ff85df3_1683112495?x-expires=1700125200&x-signature=LgcvaEx8EK8Q86u%2FLThGjk3Xc9M%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oQNICD7ohfASJKQwUILzpRhGSqRBEDySTBxNAJ/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2166&bt=1083&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NDY7NDRlPDs8aGhnOjxpNEBpM2Q3ZjY6Zjs3azMzZzczNEAwYTA1My4tXzExYF9gLi8yYSNoYWA2cjRnMTZgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=9227f4a2fbcfa0524193605f24997625&tk=tt_chain_token",
        "ratio": "720p",
        "reflowCover": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/98a8f8f88afe47c593c64b83a6340f26~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=vtGyP1NYvox5B4N4ob%2B8zj3ke6k%3D",
        "shareCover": [
          "",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/7cb803ccc2f44637a9c0a6eb3ff85df3_1683112495~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=6Wl4YOodZDo099noBQfBd0LgCfk%3D",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/7cb803ccc2f44637a9c0a6eb3ff85df3_1683112495~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=z7gfknpztMtrq1IAeYR5ZbUV9bY%3D"
        ],
        "size": 1101039,
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -9.4,
          "Peak": 1
        },
        "width": 720,
        "zoomCover": {
          "240": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/98a8f8f88afe47c593c64b83a6340f26~tplv-dmt-logom:tos-useast5-i-0068-tx/fdfea8e87f4f49b188027d0c2d7b19a3.image?x-expires=1700125200&x-signature=kGVMzmh%2BUX6022hh6Iwfu16sOuY%3D",
          "480": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/98a8f8f88afe47c593c64b83a6340f26~tplv-dmt-logom:tos-useast5-i-0068-tx/fdfea8e87f4f49b188027d0c2d7b19a3.image?x-expires=1700125200&x-signature=qRXu2Cu%2F2Qo%2BuPt9lGl%2Bj8c%2B2Jw%3D",
          "720": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/98a8f8f88afe47c593c64b83a6340f26~tplv-dmt-logom:tos-useast5-i-0068-tx/fdfea8e87f4f49b188027d0c2d7b19a3.image?x-expires=1700125200&x-signature=qRXu2Cu%2F2Qo%2BuPt9lGl%2Bj8c%2B2Jw%3D",
          "960": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/98a8f8f88afe47c593c64b83a6340f26~tplv-dmt-logom:tos-useast5-i-0068-tx/fdfea8e87f4f49b188027d0c2d7b19a3.image?x-expires=1700125200&x-signature=qRXu2Cu%2F2Qo%2BuPt9lGl%2Bj8c%2B2Jw%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/cbaf602b28e3afaca92d954bc9be9e6a~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=YRxkcH6SsGZtGkW4Jp5EcH5X4aQ%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/cbaf602b28e3afaca92d954bc9be9e6a~c5_720x720.jpeg?x-expires=1700125200&x-signature=nTDNDUki768HNCPdRiBCiYbzCSg%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/cbaf602b28e3afaca92d954bc9be9e6a~c5_100x100.jpeg?x-expires=1700125200&x-signature=SMetBLxbTJm0o1chxws51WFXYbQ%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "6922224332093998086",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "cloudkissed",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAAKxrkVyhFRb7zahuYtgpy-SbFIPQY8XJOJ7cOB-vAe7XIw-DbnNfaHJd7WK3Tgq3i",
        "secret": false,
        "signature": "soft songs for sad ppl\nflight ukulele partner\n\u203c\ufe0fOBLIVION SONG OUT NOW\u203c\ufe0f",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "cloudkissedmusic",
        "verified": false
      },
      "authorStats": {
        "diggCount": 52500,
        "followerCount": 407800,
        "followingCount": 672,
        "friendCount": 0,
        "heart": 5200000,
        "heartCount": 5200000,
        "videoCount": 316
      },
      "challenges": [
        {
          "coverLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/95dce3164582d8f6f960128e0fa2acb5",
          "coverMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/95dce3164582d8f6f960128e0fa2acb5",
          "coverThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/95dce3164582d8f6f960128e0fa2acb5",
          "desc": "TikTok has new #VoiceEffects, made just for you! Record your video, hit the checkmark and change your voice with new presets, whether you want to sound like a chipmunk or love the autotune life.",
          "id": "1361074",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/c58edc827be2fd0ebe5cb011764b3f35",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/c58edc827be2fd0ebe5cb011764b3f35",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/c58edc827be2fd0ebe5cb011764b3f35",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "voiceeffects"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1651108891927557",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "flightukulele"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "42164",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "foryou"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "229207",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "fyp"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1609191156173829",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "soldierpoetking"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        },
        {
          "coverLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/172dc8f1a7667e25a2f31c06d8f5c5d9",
          "coverMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/172dc8f1a7667e25a2f31c06d8f5c5d9",
          "coverThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/172dc8f1a7667e25a2f31c06d8f5c5d9",
          "desc": "Flower prints, knitting, plants and mushrooms. Show us your #Cottagecore life.",
          "id": "1615695775731718",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/815513cf65a2df18a00c5bd19d3fc58e",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/815513cf65a2df18a00c5bd19d3fc58e",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/815513cf65a2df18a00c5bd19d3fc58e",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "cottagecore"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1638530602820614",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "fairycore"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "31999944",
          "isCommerce": true,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "DoTheJuJu"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "21057422",
          "isCommerce": true,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "ShareTheMagic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7031628204649971713",
          "isCommerce": true,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "PawlidayPics"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "highly requested and back once again >:) #voiceeffects #flightukulele #foryou #fyp #soldierpoetking #indiemusician #cottagecore #fairycore #DoTheJuJu #ShareTheMagic #PawlidayPics",
          "textExtra": [
            {
              "awemeId": "",
              "end": 54,
              "hashtagId": "1361074",
              "hashtagName": "voiceeffects",
              "isCommerce": false,
              "secUid": "",
              "start": 41,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 69,
              "hashtagId": "1651108891927557",
              "hashtagName": "flightukulele",
              "isCommerce": false,
              "secUid": "",
              "start": 55,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 77,
              "hashtagId": "42164",
              "hashtagName": "foryou",
              "isCommerce": false,
              "secUid": "",
              "start": 70,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 82,
              "hashtagId": "229207",
              "hashtagName": "fyp",
              "isCommerce": false,
              "secUid": "",
              "start": 78,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 99,
              "hashtagId": "1609191156173829",
              "hashtagName": "soldierpoetking",
              "isCommerce": false,
              "secUid": "",
              "start": 83,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 114,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 100,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 127,
              "hashtagId": "1615695775731718",
              "hashtagName": "cottagecore",
              "isCommerce": false,
              "secUid": "",
              "start": 115,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 138,
              "hashtagId": "1638530602820614",
              "hashtagName": "fairycore",
              "isCommerce": false,
              "secUid": "",
              "start": 128,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 149,
              "hashtagId": "",
              "hashtagName": "dothejuju",
              "isCommerce": false,
              "secUid": "",
              "start": 139,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 164,
              "hashtagId": "",
              "hashtagName": "sharethemagic",
              "isCommerce": false,
              "secUid": "",
              "start": 150,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 178,
              "hashtagId": "",
              "hashtagName": "pawlidaypics",
              "isCommerce": false,
              "secUid": "",
              "start": 165,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1639091889,
      "desc": "highly requested and back once again >:) #voiceeffects #flightukulele #foryou #fyp #soldierpoetking #indiemusician #cottagecore #fairycore #DoTheJuJu #ShareTheMagic #PawlidayPics",
      "digged": false,
      "diversificationId": 10009,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7039846056057654533",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "",
        "authorName": "cloudkissed",
        "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/cbaf602b28e3afaca92d954bc9be9e6a~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=YRxkcH6SsGZtGkW4Jp5EcH5X4aQ%3D",
        "coverMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/cbaf602b28e3afaca92d954bc9be9e6a~c5_720x720.jpeg?x-expires=1700125200&x-signature=nTDNDUki768HNCPdRiBCiYbzCSg%3D",
        "coverThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/cbaf602b28e3afaca92d954bc9be9e6a~c5_100x100.jpeg?x-expires=1700125200&x-signature=SMetBLxbTJm0o1chxws51WFXYbQ%3D",
        "duration": 56,
        "id": "7039846019382659846",
        "original": true,
        "playUrl": "https://v16m.tiktokcdn.com/682f24c2685af1fd62f4e4b52f834937/655399b3/video/tos/useast2a/tos-useast2a-v-27dcd7/e9d668f294b3407d8b9898a821ed76a0/?a=1233&ch=0&cr=0&dr=0&er=2&cd=0%7C0%7C0%7C0&br=250&bt=125&bti=NDU3ZjAwOg%3D%3D&ft=d2A~l-Inz7TPgL-Ziyq8Z&mime_type=audio_mpeg&qs=6&rc=ZmdlO2RmNmY1aTVpMzxkM0BpM2Q5NDY6ZmY7OTMzNzU8M0BhYWNgNS80NTUxMjAyXjEzYSNeNjRjcjRncW1gLS1kMTZzcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00088000&cc=3&download=true",
        "title": "original sound"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 34100,
        "commentCount": 3918,
        "diggCount": 295600,
        "playCount": 1200000,
        "shareCount": 4563
      },
      "stickersOnItem": [
        {
          "stickerText": [
            "there will come a soldier who carries a mighty sword",
            "he will tear your city down, oh lei, oh lai, oh lord ",
            "oh lei, oh lai, oh lei, oh lord",
            "he will tear your city down, oh lei, oh lai, oh lord",
            "there will come a poet whose weapon is his word ",
            "he will slay you with his tongue, oh lei, oh lai, oh lord ",
            "oh lei, oh lai, oh lei, oh lord ",
            "he will slay you with his tongue, oh lei, oh lai, oh lord ",
            "there will come a ruler whose brow is laid in thorn",
            "smeared with oil like David\u2019s boy, oh lei, oh lai, oh lord "
          ],
          "stickerType": 4
        }
      ],
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 54,
          "hashtagId": "1361074",
          "hashtagName": "voiceeffects",
          "isCommerce": false,
          "secUid": "",
          "start": 41,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 69,
          "hashtagId": "1651108891927557",
          "hashtagName": "flightukulele",
          "isCommerce": false,
          "secUid": "",
          "start": 55,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 77,
          "hashtagId": "42164",
          "hashtagName": "foryou",
          "isCommerce": false,
          "secUid": "",
          "start": 70,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 82,
          "hashtagId": "229207",
          "hashtagName": "fyp",
          "isCommerce": false,
          "secUid": "",
          "start": 78,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 99,
          "hashtagId": "1609191156173829",
          "hashtagName": "soldierpoetking",
          "isCommerce": false,
          "secUid": "",
          "start": 83,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 114,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 100,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 127,
          "hashtagId": "1615695775731718",
          "hashtagName": "cottagecore",
          "isCommerce": false,
          "secUid": "",
          "start": 115,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 138,
          "hashtagId": "1638530602820614",
          "hashtagName": "fairycore",
          "isCommerce": false,
          "secUid": "",
          "start": 128,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 149,
          "hashtagId": "",
          "hashtagName": "dothejuju",
          "isCommerce": false,
          "secUid": "",
          "start": 139,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 164,
          "hashtagId": "",
          "hashtagName": "sharethemagic",
          "isCommerce": false,
          "secUid": "",
          "start": 150,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 178,
          "hashtagId": "",
          "hashtagName": "pawlidaypics",
          "isCommerce": false,
          "secUid": "",
          "start": 165,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 1460482,
        "bitrateInfo": [
          {
            "Bitrate": 1460482,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 10279240,
              "FileCs": "c:0-47413-de53",
              "FileHash": "87064199ea0d2e5d9d00feceee2a2de0",
              "Uri": "v09044g40000c6p8lnrc77u20uuk8350",
              "UrlKey": "v09044g40000c6p8lnrc77u20uuk8350_h264_540p_1460482",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-pve-0068/21138cab962245ad974b83758ef83483/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2852&bt=1426&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZDM7N2Q8OGY3aTs4NDtpNEBpMzZueDU6ZnE7OTMzNzczM0BeMjA2YDNgNi8xNGAyMF4vYSMyNXItcjRvaW1gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128852&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=565af7be2fab11605b2e8e0323a91e69&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-pve-0068/21138cab962245ad974b83758ef83483/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2852&bt=1426&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZDM7N2Q8OGY3aTs4NDtpNEBpMzZueDU6ZnE7OTMzNzczM0BeMjA2YDNgNi8xNGAyMF4vYSMyNXItcjRvaW1gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128852&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=565af7be2fab11605b2e8e0323a91e69&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 1064399,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 7491513,
              "FileCs": "c:0-47648-2ab6",
              "FileHash": "b0ba0570822a81b179fca0a4d2d6b6d5",
              "Uri": "v09044g40000c6p8lnrc77u20uuk8350",
              "UrlKey": "v09044g40000c6p8lnrc77u20uuk8350_bytevc1_720p_1064399",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c004/95f53a27db2c47b1bcb30738a4e0cb64/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2078&bt=1039&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=ZjY6ZzNpNWQ8PDVnaTs5ZUBpMzZueDU6ZnE7OTMzNzczM0BhYzIwLmNhNjExNGExMzE0YSMyNXItcjRvaW1gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128852&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=02fbd7f3e876926bd45252e247892ecb&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c004/95f53a27db2c47b1bcb30738a4e0cb64/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2078&bt=1039&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=ZjY6ZzNpNWQ8PDVnaTs5ZUBpMzZueDU6ZnE7OTMzNzczM0BhYzIwLmNhNjExNGExMzE0YSMyNXItcjRvaW1gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128852&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=02fbd7f3e876926bd45252e247892ecb&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 796549,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 5606312,
              "FileCs": "c:0-47648-0575",
              "FileHash": "ddc58f5fda0c3caf1eb5abd152192da1",
              "Uri": "v09044g40000c6p8lnrc77u20uuk8350",
              "UrlKey": "v09044g40000c6p8lnrc77u20uuk8350_bytevc1_540p_796549",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c004/d58246d214fb45a4967c5088725dfcfe/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1554&bt=777&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=OzhnOzhoZTU4PGg0OWU5NUBpMzZueDU6ZnE7OTMzNzczM0AyMC8yL2A1NmAxYS4uMmJfYSMyNXItcjRvaW1gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128852&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=cb41609dc7ce529a6129aec82efdb97f&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c004/d58246d214fb45a4967c5088725dfcfe/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1554&bt=777&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=OzhnOzhoZTU4PGg0OWU5NUBpMzZueDU6ZnE7OTMzNzczM0AyMC8yL2A1NmAxYS4uMmJfYSMyNXItcjRvaW1gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128852&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=cb41609dc7ce529a6129aec82efdb97f&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 631337,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 4443511,
              "FileCs": "c:0-47413-69a1",
              "FileHash": "05fa178c71ef30903c6288271c601e77",
              "Uri": "v09044g40000c6p8lnrc77u20uuk8350",
              "UrlKey": "v09044g40000c6p8lnrc77u20uuk8350_h264_540p_631337",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c003/cd3c4cf73bfd410eb5a0b46e14414570/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1232&bt=616&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=OWQ8aTQ3Zmk1PDw1ZTw5NkBpMzZueDU6ZnE7OTMzNzczM0AxMS00NjZiXjUxLV41MDMvYSMyNXItcjRvaW1gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128852&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=881d450ab22a97f85d3be820a53e7757&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c003/cd3c4cf73bfd410eb5a0b46e14414570/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1232&bt=616&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=OWQ8aTQ3Zmk1PDw1ZTw5NkBpMzZueDU6ZnE7OTMzNzczM0AxMS00NjZiXjUxLV41MDMvYSMyNXItcjRvaW1gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128852&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=881d450ab22a97f85d3be820a53e7757&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 396934,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 2793725,
              "FileCs": "c:0-47413-9184",
              "FileHash": "bb1fc58ec85c36684c7456a874692e09",
              "Uri": "v09044g40000c6p8lnrc77u20uuk8350",
              "UrlKey": "v09044g40000c6p8lnrc77u20uuk8350_h264_540p_396934",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c003/2b7a228cd4c449c7bfcd70b2c000c0a6/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=774&bt=387&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=N2U0OztoPDY4aTpmaDhpN0BpMzZueDU6ZnE7OTMzNzczM0AtY2AzXzM1XjIxYC4wNTZeYSMyNXItcjRvaW1gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128852&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=15636bdb3b48dbec97debff15cdb3283&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c003/2b7a228cd4c449c7bfcd70b2c000c0a6/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=774&bt=387&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=N2U0OztoPDY4aTpmaDhpN0BpMzZueDU6ZnE7OTMzNzczM0AtY2AzXzM1XjIxYC4wNTZeYSMyNXItcjRvaW1gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128852&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=15636bdb3b48dbec97debff15cdb3283&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/dda7e87211374f0d87c938fd3ac40d2e_1639091891?x-expires=1700125200&x-signature=FXtLzjM6gL5uthXIUPOo1VT%2BOUM%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c003/565fa2d345e945a5bfad43e6d05ac6f8/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=2772&bt=1386&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NGdlNzVkOmg7aDw1ZWVlZkBpMzZueDU6ZnE7OTMzNzczM0BhYS4xLi5gNWMxYl4tYGFiYSMyNXItcjRvaW1gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128852&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=afd7c93a21dcf4306364013318c2e365&tk=tt_chain_token",
        "duration": 56,
        "dynamicCover": "https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/2f709ba9e07c4dda9e653c1f9ec06336_1639091891?x-expires=1700125200&x-signature=mixipNrIe6jwA59lDtBf9TptpKU%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7039846056057654533",
        "originCover": "https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/5da18065e5fc4851a524e38bc46493db_1639091891?x-expires=1700125200&x-signature=lEwp8Qrwr88INK3LuGFCcRPntI4%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-pve-0068/21138cab962245ad974b83758ef83483/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2852&bt=1426&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZDM7N2Q8OGY3aTs4NDtpNEBpMzZueDU6ZnE7OTMzNzczM0BeMjA2YDNgNi8xNGAyMF4vYSMyNXItcjRvaW1gLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128852&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=565af7be2fab11605b2e8e0323a91e69&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/dda7e87211374f0d87c938fd3ac40d2e_1639091891~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=aUEYUw6%2BghMaVi%2Bl9KrVRhoyg%2FM%3D",
        "shareCover": [
          "",
          "https://p77-sign-va.tiktokcdn.com/tos-maliva-p-0068/5da18065e5fc4851a524e38bc46493db_1639091891~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=TvbAj22EpCDtAlyAP3vfOLQFcQY%3D",
          "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/5da18065e5fc4851a524e38bc46493db_1639091891~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=81Uuz6WUmv2Cp%2BiHIYKWlV%2BTfRw%3D"
        ],
        "size": 10279240,
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -26.2,
          "Peak": 0.35892
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/dda7e87211374f0d87c938fd3ac40d2e_1639091891~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=dAzHEAQS5IDr6h2%2Bo75zI9i01Ks%3D",
          "480": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/dda7e87211374f0d87c938fd3ac40d2e_1639091891~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=TNoOgzmwc0pwVSlhuzFEJHzaoGc%3D",
          "720": "https://p77-sign-va.tiktokcdn.com/tos-maliva-p-0068/dda7e87211374f0d87c938fd3ac40d2e_1639091891~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=NAu2%2BPYQu4jXyPE596ctjeYFVKA%3D",
          "960": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/dda7e87211374f0d87c938fd3ac40d2e_1639091891~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=59o4akUeDWYV4AeXreBRYFaRo6o%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "7224218912691438638",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Discovering Artists",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAA79sDcMCbl7QatFptF3JKobDAz67Uc0yws24YRf6FRLl8aWSAgSzrw6mu-bd0maiw",
        "secret": false,
        "signature": "\ud83d\udce7: discoveringartists.irl@gmail.com\n\nFollow our Spotify playlist!\ud83d\udc47",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "discoveringartists.irl",
        "verified": false
      },
      "authorStats": {
        "diggCount": 86,
        "followerCount": 515300,
        "followingCount": 13,
        "friendCount": 0,
        "heart": 15800000,
        "heartCount": 15800000,
        "videoCount": 35
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055695396906",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "doyoumakemusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7252811055791882286",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "discoveringartistsirl"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "325757",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "undergroundmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "4504990",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "newmusicfriday"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "136517",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musiccurator"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "2709",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "fionaapple"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "227987",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "pjharvey"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "568",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "leonardcohen"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "17331",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indie"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "496813",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "singersongwriter"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "50864",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musicproducer"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "35857",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indieartist"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "211116",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "area"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "Do you make music?"
        },
        {
          "desc": ""
        },
        {
          "desc": "#doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #fionaapple #pjharvey #leonardcohen #indie #singersongwriter #musicproducer #indieartist #indiemusician #area ",
          "textExtra": [
            {
              "awemeId": "",
              "end": 15,
              "hashtagId": "7252811055695396906",
              "hashtagName": "doyoumakemusic",
              "isCommerce": false,
              "secUid": "",
              "start": 0,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 38,
              "hashtagId": "7252811055791882286",
              "hashtagName": "discoveringartistsirl",
              "isCommerce": false,
              "secUid": "",
              "start": 16,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 56,
              "hashtagId": "325757",
              "hashtagName": "undergroundmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 39,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 72,
              "hashtagId": "4504990",
              "hashtagName": "newmusicfriday",
              "isCommerce": false,
              "secUid": "",
              "start": 57,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 86,
              "hashtagId": "136517",
              "hashtagName": "musiccurator",
              "isCommerce": false,
              "secUid": "",
              "start": 73,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 98,
              "hashtagId": "2709",
              "hashtagName": "fionaapple",
              "isCommerce": false,
              "secUid": "",
              "start": 87,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 108,
              "hashtagId": "227987",
              "hashtagName": "pjharvey",
              "isCommerce": false,
              "secUid": "",
              "start": 99,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 122,
              "hashtagId": "568",
              "hashtagName": "leonardcohen",
              "isCommerce": false,
              "secUid": "",
              "start": 109,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 129,
              "hashtagId": "17331",
              "hashtagName": "indie",
              "isCommerce": false,
              "secUid": "",
              "start": 123,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 147,
              "hashtagId": "496813",
              "hashtagName": "singersongwriter",
              "isCommerce": false,
              "secUid": "",
              "start": 130,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 162,
              "hashtagId": "50864",
              "hashtagName": "musicproducer",
              "isCommerce": false,
              "secUid": "",
              "start": 148,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 175,
              "hashtagId": "35857",
              "hashtagName": "indieartist",
              "isCommerce": false,
              "secUid": "",
              "start": 163,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 190,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 176,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 196,
              "hashtagId": "211116",
              "hashtagName": "area",
              "isCommerce": false,
              "secUid": "",
              "start": 191,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1689547111,
      "desc": "Do you make music? #doyoumakemusic #discoveringartistsirl #Undergroundmusic #newmusicfriday #musiccurator #fionaapple #pjharvey #leonardcohen #indie #singersongwriter #musicproducer #indieartist #indiemusician #area ",
      "digged": false,
      "diversificationId": 10046,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7256549528185179435",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "",
        "authorName": "Discovering Artists",
        "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=hov3SX06H8ugcqv%2B1yelbQ6Pc9I%3D",
        "coverMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_720x720.jpeg?x-expires=1700125200&x-signature=1yfeZMSBS1KZZcWixNgpa%2Fphfkk%3D",
        "coverThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/3b7a5053afd370df9c06d46aabec609b~c5_100x100.jpeg?x-expires=1700125200&x-signature=%2BFqqcnYJQ62xvC1e0oriYE%2F%2B0p4%3D",
        "duration": 34,
        "id": "7256549549799328554",
        "original": true,
        "playUrl": "https://v16m.tiktokcdn.com/42fe0ea7e0dd83353f9e0d43ef71f5ae/6553999d/video/tos/maliva/tos-maliva-v-27dcd7c799-us/osnf8KADIKEpWiT1aNAP0UjryW4cizwkWIhwBB/?a=1233&ch=0&cr=0&dr=0&er=2&cd=0%7C0%7C0%7C0&br=250&bt=125&bti=NDU3ZjAwOg%3D%3D&ft=d2A~l-Inz7TPgL-Ziyq8Z&mime_type=audio_mpeg&qs=6&rc=Ozc7aGZnaDtnMzQ3O2RmO0BpMzpmcjM6ZnQ6bDMzZzU8NEAyMDJfMWBeXjExMTYwMzY2YSNyNGk0cjRnL25gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00088000&cc=3&download=true",
        "title": "original sound"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 29900,
        "commentCount": 728,
        "diggCount": 277200,
        "playCount": 1800000,
        "shareCount": 964
      },
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 34,
          "hashtagId": "7252811055695396906",
          "hashtagName": "doyoumakemusic",
          "isCommerce": false,
          "secUid": "",
          "start": 19,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 57,
          "hashtagId": "7252811055791882286",
          "hashtagName": "discoveringartistsirl",
          "isCommerce": false,
          "secUid": "",
          "start": 35,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 75,
          "hashtagId": "325757",
          "hashtagName": "undergroundmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 58,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 91,
          "hashtagId": "4504990",
          "hashtagName": "newmusicfriday",
          "isCommerce": false,
          "secUid": "",
          "start": 76,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 105,
          "hashtagId": "136517",
          "hashtagName": "musiccurator",
          "isCommerce": false,
          "secUid": "",
          "start": 92,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 117,
          "hashtagId": "2709",
          "hashtagName": "fionaapple",
          "isCommerce": false,
          "secUid": "",
          "start": 106,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 127,
          "hashtagId": "227987",
          "hashtagName": "pjharvey",
          "isCommerce": false,
          "secUid": "",
          "start": 118,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 141,
          "hashtagId": "568",
          "hashtagName": "leonardcohen",
          "isCommerce": false,
          "secUid": "",
          "start": 128,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 148,
          "hashtagId": "17331",
          "hashtagName": "indie",
          "isCommerce": false,
          "secUid": "",
          "start": 142,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 166,
          "hashtagId": "496813",
          "hashtagName": "singersongwriter",
          "isCommerce": false,
          "secUid": "",
          "start": 149,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 181,
          "hashtagId": "50864",
          "hashtagName": "musicproducer",
          "isCommerce": false,
          "secUid": "",
          "start": 167,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 194,
          "hashtagId": "35857",
          "hashtagName": "indieartist",
          "isCommerce": false,
          "secUid": "",
          "start": 182,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 209,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 195,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 215,
          "hashtagId": "211116",
          "hashtagName": "area",
          "isCommerce": false,
          "secUid": "",
          "start": 210,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 2227353,
        "bitrateInfo": [
          {
            "Bitrate": 2227353,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 9466531,
              "FileCs": "c:0-29215-296b",
              "FileHash": "51d2d03a61e3d7b1b7d0583c0d48dfa8",
              "Uri": "v12044gd0000ciq72abc77u8kqdk6tig",
              "UrlKey": "v12044gd0000ciq72abc77u8kqdk6tig_h264_540p_2227353",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oodflCADIGIpyiJUrtIp0UjtyXBcpz0NWQhwAB/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4350&bt=2175&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZjxkM2U8OTk7NDgzOGU5ZEBpandudDs6ZmQ6bDMzZzczNEBfYi8xLy40X18xXjFfMDVgYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=8a427007201e2c97091a97556a31dbde&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oodflCADIGIpyiJUrtIp0UjtyXBcpz0NWQhwAB/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4350&bt=2175&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZjxkM2U8OTk7NDgzOGU5ZEBpandudDs6ZmQ6bDMzZzczNEBfYi8xLy40X18xXjFfMDVgYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=8a427007201e2c97091a97556a31dbde&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 1527758,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 6493165,
              "FileCs": "c:0-29582-ae88",
              "FileHash": "20393b3ad2628d1882cae79d8f76811c",
              "Uri": "v12044gd0000ciq72abc77u8kqdk6tig",
              "UrlKey": "v12044gd0000ciq72abc77u8kqdk6tig_bytevc1_1080p_1527758",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oYnTYDRgEo5tpefE7SnblaDQMGMKBRgTokQHAD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2982&bt=1491&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=NmRlZzo6NTY1OjM1ZjllaEBpandudDs6ZmQ6bDMzZzczNEAvLjZgL2BiXzMxL2NeXi5eYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=4fb348d9a841650c8aab88d1dffc3918&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oYnTYDRgEo5tpefE7SnblaDQMGMKBRgTokQHAD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2982&bt=1491&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=NmRlZzo6NTY1OjM1ZjllaEBpandudDs6ZmQ6bDMzZzczNEAvLjZgL2BiXzMxL2NeXi5eYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=4fb348d9a841650c8aab88d1dffc3918&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 992810,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 4219570,
              "FileCs": "c:0-29215-c0cd",
              "FileHash": "8b6cc0abb0cc76d1056e549667c828f4",
              "Uri": "v12044gd0000ciq72abc77u8kqdk6tig",
              "UrlKey": "v12044gd0000ciq72abc77u8kqdk6tig_h264_540p_992810",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oUm1tAzWlciBXfItypdUiQpCwIn0AphUEBIjhD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1938&bt=969&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=ZWQ6aGc0ODk3OmY4Ojw3Z0BpandudDs6ZmQ6bDMzZzczNEBhNV5hNWFfX14xNDFfMmFhYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=b229c05880f45aa78d566ef91c3634d0&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oUm1tAzWlciBXfItypdUiQpCwIn0AphUEBIjhD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1938&bt=969&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=ZWQ6aGc0ODk3OmY4Ojw3Z0BpandudDs6ZmQ6bDMzZzczNEBhNV5hNWFfX14xNDFfMmFhYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=b229c05880f45aa78d566ef91c3634d0&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 897852,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 3815984,
              "FileCs": "c:0-29569-0162",
              "FileHash": "71a90730adc3435263518d0c2be47ee8",
              "Uri": "v12044gd0000ciq72abc77u8kqdk6tig",
              "UrlKey": "v12044gd0000ciq72abc77u8kqdk6tig_bytevc1_720p_897852",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oEAQ0fDUiWprzcaDItpAjbCIwphUB6oUdyjtIl/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1752&bt=876&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=ODo8MzVmOmllNWc6aWk1M0BpandudDs6ZmQ6bDMzZzczNEAtLzA2XjA1NWIxXy8vNjAzYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=7dd553314e5b7eaa52787805d0f97aa3&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oEAQ0fDUiWprzcaDItpAjbCIwphUB6oUdyjtIl/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1752&bt=876&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=ODo8MzVmOmllNWc6aWk1M0BpandudDs6ZmQ6bDMzZzczNEAtLzA2XjA1NWIxXy8vNjAzYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=7dd553314e5b7eaa52787805d0f97aa3&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 649751,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 2761526,
              "FileCs": "c:0-29215-47af",
              "FileHash": "b919af7f5f15d5f6d7ebd707bc827912",
              "Uri": "v12044gd0000ciq72abc77u8kqdk6tig",
              "UrlKey": "v12044gd0000ciq72abc77u8kqdk6tig_h264_540p_649751",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oMdflCADI7IpiiCUOtIp0UjtyXocpzcFWQhwAB/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1268&bt=634&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=ZTc5N2hlOmk0OTpmZDo1O0BpandudDs6ZmQ6bDMzZzczNEBgNjJeMDU0Xy8xNTQyNDMyYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=eae04522a18602a82170242578b89845&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oMdflCADI7IpiiCUOtIp0UjtyXocpzcFWQhwAB/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1268&bt=634&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=ZTc5N2hlOmk0OTpmZDo1O0BpandudDs6ZmQ6bDMzZzczNEBgNjJeMDU0Xy8xNTQyNDMyYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=eae04522a18602a82170242578b89845&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          },
          {
            "Bitrate": 636406,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 2704806,
              "FileCs": "c:0-29569-bb36",
              "FileHash": "1ea9e52bb7f1cd36a0a797e3762a47ee",
              "Uri": "v12044gd0000ciq72abc77u8kqdk6tig",
              "UrlKey": "v12044gd0000ciq72abc77u8kqdk6tig_bytevc1_540p_636406",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oMUjsDwuthlLpCpyAlQiUcI0tiCGzABpdzIIWf/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1242&bt=621&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=aTk3PGQ4Zzw4NWk0NTdoZEBpandudDs6ZmQ6bDMzZzczNEA2LWA1Ni1gXjUxYS01MTQ2YSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=59f136b94b3740f7826ae09ee3045dad&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oMUjsDwuthlLpCpyAlQiUcI0tiCGzABpdzIIWf/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1242&bt=621&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=aTk3PGQ4Zzw4NWk0NTdoZEBpandudDs6ZmQ6bDMzZzczNEA2LWA1Ni1gXjUxYS01MTQ2YSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=59f136b94b3740f7826ae09ee3045dad&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/39f902c9a8604dddb11a7b8b02de4a78_1689547113?x-expires=1700125200&x-signature=cEQ9EPxUmL4IT90TZROWvCnKhP0%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oUzp0XtiQtdjyfHCYnIAAUplhWUBcIpwID2pDz/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=3672&bt=1836&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=Zjc8NjQ4Zmk6aTY7ODQ5aUBpandudDs6ZmQ6bDMzZzczNEAyMC5eMC00X2IxYmEwLTJhYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=3f6c9f5dc6bde3134b92c29889c3322d&tk=tt_chain_token",
        "duration": 34,
        "dynamicCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/0ce9ab448f314d60b5238d2a9560d424_1689547113?x-expires=1700125200&x-signature=KHL%2FdNS90CXv47CxwHZaD3U8NJ4%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7256549528185179435",
        "originCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/ec1d3f8ce8574db99381aab11b28d1c0_1689547113?x-expires=1700125200&x-signature=57SRbLJtc0Qk56fKSzYoyC3le80%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/oodflCADIGIpyiJUrtIp0UjtyXBcpz0NWQhwAB/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4350&bt=2175&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZjxkM2U8OTk7NDgzOGU5ZEBpandudDs6ZmQ6bDMzZzczNEBfYi8xLy40X18xXjFfMDVgYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128830&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=8a427007201e2c97091a97556a31dbde&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/39f902c9a8604dddb11a7b8b02de4a78_1689547113~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=dEV9kgHBHyhZ1Jo17k7amKmwslI%3D",
        "shareCover": [
          "",
          "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/ec1d3f8ce8574db99381aab11b28d1c0_1689547113~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=7wnkG3Zq1KLjhaiVfpzQ2LXBBdM%3D",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/ec1d3f8ce8574db99381aab11b28d1c0_1689547113~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=FDB8TRTyD3lyEbZzMub4OGPu7oI%3D"
        ],
        "size": 9466531,
        "subtitleInfos": [
          {
            "Format": "webvtt",
            "LanguageCodeName": "kor-KR",
            "LanguageID": "4",
            "Size": 1100,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/97c5e50de748ebb2b70b2a99fc1a1e57/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/f191a18daa884d70acab42a995b73070/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4350&bt=2175&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=andudDs6ZmQ6bDMzZzczNEBpandudDs6ZmQ6bDMzZzczNEBmM2FocjRfL25gLS1kMS9zYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": -2136837634
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ara-SA",
            "LanguageID": "34",
            "Size": 988,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/e33c880aeb16714b060b214ad667aeb1/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/87e2bd839d724433a58ea7dd2313f120/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4350&bt=2175&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=andudDs6ZmQ6bDMzZzczNEBpandudDs6ZmQ6bDMzZzczNEBmM2FocjRfL25gLS1kMS9zYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": -1642450281
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "cmn-Hans-CN",
            "LanguageID": "1",
            "Size": 958,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/2fff6493ce37a553d0d41cfd7a433f77/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/7ed44cd11b87405ba7cd2bc0bc5b4a9c/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4350&bt=2175&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=andudDs6ZmQ6bDMzZzczNEBpandudDs6ZmQ6bDMzZzczNEBmM2FocjRfL25gLS1kMS9zYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": -1769959558
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "fra-FR",
            "LanguageID": "7",
            "Size": 1059,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/af661460fa82bfc246f69ea1c7c6a597/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/1abd67447fa04c03a23228ecac699aff/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4350&bt=2175&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=andudDs6ZmQ6bDMzZzczNEBpandudDs6ZmQ6bDMzZzczNEBmM2FocjRfL25gLS1kMS9zYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": 1793269689
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "eng-US",
            "LanguageID": "2",
            "Size": 721,
            "Source": "ASR",
            "Url": "https://v16-webapp.tiktok.com/2234c6fe6f831a366762e4e8a94984eb/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/c9fc533bb7a54ce2930c15eec8a40208/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4350&bt=2175&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=andudDs6ZmQ6bDMzZzczNEBpandudDs6ZmQ6bDMzZzczNEBmM2FocjRfL25gLS1kMS9zYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "1",
            "VideoSubtitleID": 1424343791
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "rus-RU",
            "LanguageID": "6",
            "Size": 1408,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/0151f6e6e76b11b051ed0238986eaa21/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/f22315b50774400da03720f9e99175d8/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4350&bt=2175&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=andudDs6ZmQ6bDMzZzczNEBpandudDs6ZmQ6bDMzZzczNEBmM2FocjRfL25gLS1kMS9zYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": -1050277393
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "deu-DE",
            "LanguageID": "20",
            "Size": 993,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/ee62573e47a00263f295d7213d8928d1/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/7325f4e8cdcc4916bcec2d2a8ee8bc69/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4350&bt=2175&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=andudDs6ZmQ6bDMzZzczNEBpandudDs6ZmQ6bDMzZzczNEBmM2FocjRfL25gLS1kMS9zYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": 1657774722
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ita-IT",
            "LanguageID": "26",
            "Size": 964,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/6e700714c3f22554afad31a99b69c125/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/79e36b99e319456caa270d3d13e301e0/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4350&bt=2175&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=andudDs6ZmQ6bDMzZzczNEBpandudDs6ZmQ6bDMzZzczNEBmM2FocjRfL25gLS1kMS9zYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": 96470706
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "por-PT",
            "LanguageID": "8",
            "Size": 970,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/ff321eee63c2f7ad731141e9d7cfa377/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/12356623e35443fb8be582a30e8dd5f9/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4350&bt=2175&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=andudDs6ZmQ6bDMzZzczNEBpandudDs6ZmQ6bDMzZzczNEBmM2FocjRfL25gLS1kMS9zYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": 406633837
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "jpn-JP",
            "LanguageID": "3",
            "Size": 1221,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/c9ee1d68e1d431160b7eb28942afe135/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/cd1e59e3635a4602af11d692a62893fd/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4350&bt=2175&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=andudDs6ZmQ6bDMzZzczNEBpandudDs6ZmQ6bDMzZzczNEBmM2FocjRfL25gLS1kMS9zYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": -731861344
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ind-ID",
            "LanguageID": "23",
            "Size": 1001,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/56e8f7ad7a1faf16d1dad894eb26948e/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/804a27d70a4640f6bb047fc0bd8c63cc/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4350&bt=2175&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=andudDs6ZmQ6bDMzZzczNEBpandudDs6ZmQ6bDMzZzczNEBmM2FocjRfL25gLS1kMS9zYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": -1544786912
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "vie-VN",
            "LanguageID": "10",
            "Size": 1160,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/592e9a49f1b9bdc06ed1784c1dd3dd04/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/3ac0ff132c244968a55bf94eb24d130d/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4350&bt=2175&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=andudDs6ZmQ6bDMzZzczNEBpandudDs6ZmQ6bDMzZzczNEBmM2FocjRfL25gLS1kMS9zYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": 303901328
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "spa-ES",
            "LanguageID": "9",
            "Size": 1000,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/cd5b681231c903721ca771e7e319dfbc/6555e83e/video/tos/maliva/tos-maliva-v-0068c799-us/5e31f46ee947460e9172d7db8078fa72/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=4350&bt=2175&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=andudDs6ZmQ6bDMzZzczNEBpandudDs6ZmQ6bDMzZzczNEBmM2FocjRfL25gLS1kMS9zYSNmM2FocjRfL25gLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128830,
            "Version": "4",
            "VideoSubtitleID": -505897310
          }
        ],
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -14.7,
          "Peak": 1
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/39f902c9a8604dddb11a7b8b02de4a78_1689547113~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=8amNCLNMg3Yzk3YD9BZhzdd0m1s%3D",
          "480": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/39f902c9a8604dddb11a7b8b02de4a78_1689547113~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=2dwLYrMI2eOEwQFRnE8LSTNA7oA%3D",
          "720": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/39f902c9a8604dddb11a7b8b02de4a78_1689547113~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=YmmBmumjDIpci4HWKnRTeD4SKm8%3D",
          "960": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/39f902c9a8604dddb11a7b8b02de4a78_1689547113~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=%2B%2F3D3%2FZp7p1q4JWMJBA9PbYlBEs%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/7c903ffd7492d0b2c45c1ab00e2f3740~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=aJ0x9MwhSuCPttKKayH3WRDIJco%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/7c903ffd7492d0b2c45c1ab00e2f3740~c5_720x720.jpeg?x-expires=1700125200&x-signature=mlmIl%2FY8oWEvqw2zsFky0yiyykI%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/7c903ffd7492d0b2c45c1ab00e2f3740~c5_100x100.jpeg?x-expires=1700125200&x-signature=A7zAD%2BgR1pyiozrBzSi3FzaWAj4%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "6750099502227375110",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Project Atlantic",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAA8h6OBuL5VLHKYYTzeV1sDd0Hkm0PCvxpXhNZpYpB2mMIxgJYOqYNwwXuVAJLJwlw",
        "secret": false,
        "signature": "Solo project of Josh Zimmer\n99% Sarcasm, 1% Indie rock\n\ud83d\udc47Get a Synth-a-Sette\ud83d\udc47",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "projectatlanticmusic",
        "verified": false
      },
      "authorStats": {
        "diggCount": 2195,
        "followerCount": 52600,
        "followingCount": 421,
        "friendCount": 0,
        "heart": 4700000,
        "heartCount": 4700000,
        "videoCount": 914
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "5636",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "music"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "16103",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "\ud83e\ude97What's your favourite instrument?",
          "id": "1609427218899973",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musiciansoftiktok"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "323291",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indierock"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "37634",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "independentartist"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "19244024",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musichumor"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "641",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "imaginedragons"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1645809370293254",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "independentmusician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "Love #IndieMusic? Start here.",
          "id": "247262",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/164263ca9942461c79d1ac0ee9fa0b21.jpeg",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/164263ca9942461c79d1ac0ee9fa0b21.jpeg",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/164263ca9942461c79d1ac0ee9fa0b21.jpeg",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "35857",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indieartist"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "39733345",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "projectatlantic"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "Choose wisely"
        },
        {
          "desc": ""
        },
        {
          "desc": "#music #musician #musiciansoftiktok #indierock #independentartist #musichumor #imaginedragons #independentmusician #indiemusician #indiemusic #indieartist #projectatlantic",
          "textExtra": [
            {
              "awemeId": "",
              "end": 6,
              "hashtagId": "5636",
              "hashtagName": "music",
              "isCommerce": false,
              "secUid": "",
              "start": 0,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 16,
              "hashtagId": "16103",
              "hashtagName": "musician",
              "isCommerce": false,
              "secUid": "",
              "start": 7,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 35,
              "hashtagId": "1609427218899973",
              "hashtagName": "musiciansoftiktok",
              "isCommerce": false,
              "secUid": "",
              "start": 17,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 46,
              "hashtagId": "323291",
              "hashtagName": "indierock",
              "isCommerce": false,
              "secUid": "",
              "start": 36,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 65,
              "hashtagId": "37634",
              "hashtagName": "independentartist",
              "isCommerce": false,
              "secUid": "",
              "start": 47,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 77,
              "hashtagId": "19244024",
              "hashtagName": "musichumor",
              "isCommerce": false,
              "secUid": "",
              "start": 66,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 93,
              "hashtagId": "641",
              "hashtagName": "imaginedragons",
              "isCommerce": false,
              "secUid": "",
              "start": 78,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 114,
              "hashtagId": "1645809370293254",
              "hashtagName": "independentmusician",
              "isCommerce": false,
              "secUid": "",
              "start": 94,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 129,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 115,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 141,
              "hashtagId": "247262",
              "hashtagName": "indiemusic",
              "isCommerce": false,
              "secUid": "",
              "start": 130,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 154,
              "hashtagId": "35857",
              "hashtagName": "indieartist",
              "isCommerce": false,
              "secUid": "",
              "start": 142,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 171,
              "hashtagId": "39733345",
              "hashtagName": "projectatlantic",
              "isCommerce": false,
              "secUid": "",
              "start": 155,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1676038031,
      "desc": "Choose wisely #music #musician #musiciansoftiktok #indierock #independentartist #musichumor #imaginedragons #independentmusician #indiemusician #indiemusic #indieartist #projectatlantic",
      "digged": false,
      "diversificationId": 10003,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7198528392407518510",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "Dead Leaves (Can You Hear My Voice?)",
        "authorName": "Project Atlantic",
        "coverLarge": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/90f45fee45084f45ae5345825a88b953~c5_720x720.jpeg",
        "coverMedium": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/90f45fee45084f45ae5345825a88b953~c5_200x200.jpeg",
        "coverThumb": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/90f45fee45084f45ae5345825a88b953~c5_100x100.jpeg",
        "duration": 60,
        "id": "6920033054601775106",
        "original": false,
        "playUrl": "https://sf16-ies-music-va.tiktokcdn.com/obj/tos-useast2a-ve-2774/3d639d8c256d4e309a8d4f223b18be27",
        "title": "Dead Leaves (Can You Hear My Voice?)"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "7200054230433024811",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 7389,
        "commentCount": 4866,
        "diggCount": 272400,
        "playCount": 1900000,
        "shareCount": 1659
      },
      "stickersOnItem": [
        {
          "stickerText": [
            "Would you rather\u2026\n\nNot be able to listen to music ever again\n\nOR\n\nEvery time you want \nto listen to a song \nyou would first have \nto listen to Thunder \nby Imagine Dragons"
          ],
          "stickerType": 4
        },
        {
          "stickerText": [
            "Would You\nRather"
          ],
          "stickerType": 9
        }
      ],
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 20,
          "hashtagId": "5636",
          "hashtagName": "music",
          "isCommerce": false,
          "secUid": "",
          "start": 14,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 30,
          "hashtagId": "16103",
          "hashtagName": "musician",
          "isCommerce": false,
          "secUid": "",
          "start": 21,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 49,
          "hashtagId": "1609427218899973",
          "hashtagName": "musiciansoftiktok",
          "isCommerce": false,
          "secUid": "",
          "start": 31,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 60,
          "hashtagId": "323291",
          "hashtagName": "indierock",
          "isCommerce": false,
          "secUid": "",
          "start": 50,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 79,
          "hashtagId": "37634",
          "hashtagName": "independentartist",
          "isCommerce": false,
          "secUid": "",
          "start": 61,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 91,
          "hashtagId": "19244024",
          "hashtagName": "musichumor",
          "isCommerce": false,
          "secUid": "",
          "start": 80,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 107,
          "hashtagId": "641",
          "hashtagName": "imaginedragons",
          "isCommerce": false,
          "secUid": "",
          "start": 92,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 128,
          "hashtagId": "1645809370293254",
          "hashtagName": "independentmusician",
          "isCommerce": false,
          "secUid": "",
          "start": 108,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 143,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 129,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 155,
          "hashtagId": "247262",
          "hashtagName": "indiemusic",
          "isCommerce": false,
          "secUid": "",
          "start": 144,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 168,
          "hashtagId": "35857",
          "hashtagName": "indieartist",
          "isCommerce": false,
          "secUid": "",
          "start": 156,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 185,
          "hashtagId": "39733345",
          "hashtagName": "projectatlantic",
          "isCommerce": false,
          "secUid": "",
          "start": 169,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 658526,
        "bitrateInfo": [
          {
            "Bitrate": 856908,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lowest_1080_1",
            "PlayAddr": {
              "DataSize": 781929,
              "FileCs": "c:0-7629-5c50",
              "FileHash": "725f441345d98b37201d129b1448e034",
              "Uri": "v12044gd0000cfj4uv3c77udd392f460",
              "UrlKey": "v12044gd0000cfj4uv3c77udd392f460_bytevc1_1080p_856908",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/88b794110fb84915a1ab1467c5c0126a/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1672&bt=836&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=PDY3ZmQzaWkzaGc4PDY8aUBpMzc1Nmc6Znk3aTMzZzczNEAwLV8uMjNjNi0xXjQyYjZgYSMzYzZhcjQwcmdgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=4ba4b742a1a3019f7dc1b6b573b79fde&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/88b794110fb84915a1ab1467c5c0126a/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1672&bt=836&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=4&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=15&rc=PDY3ZmQzaWkzaGc4PDY8aUBpMzc1Nmc6Znk3aTMzZzczNEAwLV8uMjNjNi0xXjQyYjZgYSMzYzZhcjQwcmdgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=4ba4b742a1a3019f7dc1b6b573b79fde&tk=tt_chain_token"
              ]
            },
            "QualityType": 2
          },
          {
            "Bitrate": 658526,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 613335,
              "FileCs": "c:0-7398-e035",
              "FileHash": "b63b1016064b8282111f3f5da4585509",
              "Uri": "v12044gd0000cfj4uv3c77udd392f460",
              "UrlKey": "v12044gd0000cfj4uv3c77udd392f460_h264_540p_658526",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/383c0c7ac5af4ed7b782940a3d3a3511/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1286&bt=643&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ODc4NTkzNWc1ODpoPDpmOUBpMzc1Nmc6Znk3aTMzZzczNEA2LzZgNl8wXjUxYy02Xl9jYSMzYzZhcjQwcmdgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=48257b4cbd397e58778d91058c56acf4&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/383c0c7ac5af4ed7b782940a3d3a3511/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1286&bt=643&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ODc4NTkzNWc1ODpoPDpmOUBpMzc1Nmc6Znk3aTMzZzczNEA2LzZgNl8wXjUxYy02Xl9jYSMzYzZhcjQwcmdgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=48257b4cbd397e58778d91058c56acf4&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 392069,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 357763,
              "FileCs": "c:0-7630-0f00",
              "FileHash": "505f25b2fd0af90b8108d36248e95107",
              "Uri": "v12044gd0000cfj4uv3c77udd392f460",
              "UrlKey": "v12044gd0000cfj4uv3c77udd392f460_bytevc1_720p_392069",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/5357b84d5186409e99bd1199f930c9dd/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=764&bt=382&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=NjxnPDk7ZGloNjc1O2ZpNEBpMzc1Nmc6Znk3aTMzZzczNEA2YTU2YmMyXmMxYmIuMDVjYSMzYzZhcjQwcmdgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=7da81ef3104ebe145ed77828a31e70a6&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/5357b84d5186409e99bd1199f930c9dd/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=764&bt=382&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=NjxnPDk7ZGloNjc1O2ZpNEBpMzc1Nmc6Znk3aTMzZzczNEA2YTU2YmMyXmMxYmIuMDVjYSMzYzZhcjQwcmdgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=7da81ef3104ebe145ed77828a31e70a6&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 339595,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 309881,
              "FileCs": "c:0-7630-fc43",
              "FileHash": "215702da269fff42b3002175056a6f3c",
              "Uri": "v12044gd0000cfj4uv3c77udd392f460",
              "UrlKey": "v12044gd0000cfj4uv3c77udd392f460_bytevc1_540p_339595",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/9f176823d8544f1bac2baf17dfd78818/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=662&bt=331&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=Nzo7NTM1NTRnM2c4ZWdlZ0BpMzc1Nmc6Znk3aTMzZzczNEA2LjBeXy4xXjIxMDAuXjQ1YSMzYzZhcjQwcmdgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=9990fbc24dc4cf91953bdef590ebf3c0&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/9f176823d8544f1bac2baf17dfd78818/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=662&bt=331&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=Nzo7NTM1NTRnM2c4ZWdlZ0BpMzc1Nmc6Znk3aTMzZzczNEA2LjBeXy4xXjIxMDAuXjQ1YSMzYzZhcjQwcmdgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=9990fbc24dc4cf91953bdef590ebf3c0&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 233870,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 217821,
              "FileCs": "c:0-7398-e91e",
              "FileHash": "e30c9fb1680029c3381662417525aa44",
              "Uri": "v12044gd0000cfj4uv3c77udd392f460",
              "UrlKey": "v12044gd0000cfj4uv3c77udd392f460_h264_540p_233870",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/60eb5da03f69418b9cc6f92d8e78e280/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=456&bt=228&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=Ojk3Nmk4OWc0Ojc3OTw7O0BpMzc1Nmc6Znk3aTMzZzczNEBhLi02Nl80XmMxMzViLjYtYSMzYzZhcjQwcmdgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=ca76bfb1cbf106e6bb920b4c416c0c46&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/60eb5da03f69418b9cc6f92d8e78e280/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=456&bt=228&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=Ojk3Nmk4OWc0Ojc3OTw7O0BpMzc1Nmc6Znk3aTMzZzczNEBhLi02Nl80XmMxMzViLjYtYSMzYzZhcjQwcmdgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=ca76bfb1cbf106e6bb920b4c416c0c46&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 158616,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 147731,
              "FileCs": "c:0-7398-89e1",
              "FileHash": "166c91eb3c14ef5273d1256a28f2ce7a",
              "Uri": "v12044gd0000cfj4uv3c77udd392f460",
              "UrlKey": "v12044gd0000cfj4uv3c77udd392f460_h264_540p_158616",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/b98fa11ebd7a49788df7b337b6b3368c/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=308&bt=154&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=OmY5Njw5aWU4NjU3OWk7Z0BpMzc1Nmc6Znk3aTMzZzczNEBhYl8xYTU0X2IxY18tLjU2YSMzYzZhcjQwcmdgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=811953a4406ca363d0bb8686952ad5f9&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/b98fa11ebd7a49788df7b337b6b3368c/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=308&bt=154&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=OmY5Njw5aWU4NjU3OWk7Z0BpMzc1Nmc6Znk3aTMzZzczNEBhYl8xYTU0X2IxY18tLjU2YSMzYzZhcjQwcmdgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=811953a4406ca363d0bb8686952ad5f9&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/1ad93e4af2524aacba21ed6c96705ae9~tplv-dmt-logom:tos-useast5-i-0068-tx/12475032768d4579a4c5e392b22e3192.image?x-expires=1700125200&x-signature=ulLzac8%2B9kiaaIUVQ1cduCc6oGU%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/f1982f2e88c243f6ab0bda7988aff6f7/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=1342&bt=671&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZjRkNGZkM2k2ZWU7N2QzOkBpMzc1Nmc6Znk3aTMzZzczNEAwLi5gLS4xNi4xMF9hYjRhYSMzYzZhcjQwcmdgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=d32db807a663734cd93bb32b235dcc41&tk=tt_chain_token",
        "duration": 7,
        "dynamicCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/6d3ad5628c324f14aefc28e9157082e8_1676038032?x-expires=1700125200&x-signature=qZWO6Pz31Mk7ZeQSoaezJDIdyM8%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7198528392407518510",
        "originCover": "https://p19-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/b82e9d4538914877876b7746d305194c_1676038032?x-expires=1700125200&x-signature=kfut42xCDZeA3%2FsIjrxZHL6wFQE%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/383c0c7ac5af4ed7b782940a3d3a3511/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1286&bt=643&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ODc4NTkzNWc1ODpoPDpmOUBpMzc1Nmc6Znk3aTMzZzczNEA2LzZgNl8wXjUxYy02Xl9jYSMzYzZhcjQwcmdgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128803&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=48257b4cbd397e58778d91058c56acf4&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/1ad93e4af2524aacba21ed6c96705ae9~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=rWv%2BQtM2CFVsD4KZoq5P8gMIqmI%3D",
        "shareCover": [
          "",
          "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/b82e9d4538914877876b7746d305194c_1676038032~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=LTY8IlPlOM8z%2FCpRv7E%2BvlDeKts%3D",
          "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/b82e9d4538914877876b7746d305194c_1676038032~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=dguHw1gnsUMy%2FlQyX8ThvA4TuCI%3D"
        ],
        "size": 613335,
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -9.2,
          "Peak": 1
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/1ad93e4af2524aacba21ed6c96705ae9~tplv-dmt-logom:tos-useast5-i-0068-tx/12475032768d4579a4c5e392b22e3192.image?x-expires=1700125200&x-signature=ikS3cL8SY9843%2FrW%2BChUJw4ITB0%3D",
          "480": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/1ad93e4af2524aacba21ed6c96705ae9~tplv-dmt-logom:tos-useast5-i-0068-tx/12475032768d4579a4c5e392b22e3192.image?x-expires=1700125200&x-signature=ulLzac8%2B9kiaaIUVQ1cduCc6oGU%3D",
          "720": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/1ad93e4af2524aacba21ed6c96705ae9~tplv-dmt-logom:tos-useast5-i-0068-tx/12475032768d4579a4c5e392b22e3192.image?x-expires=1700125200&x-signature=ulLzac8%2B9kiaaIUVQ1cduCc6oGU%3D",
          "960": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/1ad93e4af2524aacba21ed6c96705ae9~tplv-dmt-logom:tos-useast5-i-0068-tx/12475032768d4579a4c5e392b22e3192.image?x-expires=1700125200&x-signature=ulLzac8%2B9kiaaIUVQ1cduCc6oGU%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/86cabf0d03ad6a87c21b700b49be2067~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=2Sj4sP5j5zyxaLaySSyhq9vHY8M%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/86cabf0d03ad6a87c21b700b49be2067~c5_720x720.jpeg?x-expires=1700125200&x-signature=CeSM5QZoDY%2FcaTU8RnJVkuMc6AY%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/86cabf0d03ad6a87c21b700b49be2067~c5_100x100.jpeg?x-expires=1700125200&x-signature=b4v%2FxgwHv21%2Boj8TmCtDgKovT4g%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "6660228456670887941",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Aridonis",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAAiciNTfTFBMptGV3R9kqwVH8_w--IEWbfY1L5CcqtjuwqpXX4aa1FMOUhSZx6q1W7",
        "secret": false,
        "signature": "Alt-Pop for Zillenials. Billionaires need not apply.",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "aridonis",
        "verified": false
      },
      "authorStats": {
        "diggCount": 12600,
        "followerCount": 8725,
        "followingCount": 446,
        "friendCount": 0,
        "heart": 1200000,
        "heartCount": 1200000,
        "videoCount": 621
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "13158956",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "guitarists"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "Whether you're rocking out or keeping it acoustic, show us what you can do \ud83c\udfb8",
          "id": "11329",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "guitar"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "446335",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "electricguitar"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1620302809777158",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "guitaristsoftiktok"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "If it bends, I\u2019ll just use my fingers thanks. #guitarists #guitar #electricguitar #guitaristsoftiktok #indiemusician",
          "textExtra": [
            {
              "awemeId": "",
              "end": 57,
              "hashtagId": "13158956",
              "hashtagName": "guitarists",
              "isCommerce": false,
              "secUid": "",
              "start": 46,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 65,
              "hashtagId": "11329",
              "hashtagName": "guitar",
              "isCommerce": false,
              "secUid": "",
              "start": 58,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 81,
              "hashtagId": "446335",
              "hashtagName": "electricguitar",
              "isCommerce": false,
              "secUid": "",
              "start": 66,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 101,
              "hashtagId": "1620302809777158",
              "hashtagName": "guitaristsoftiktok",
              "isCommerce": false,
              "secUid": "",
              "start": 82,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 116,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 102,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1623769209,
      "desc": "If it bends, I\u2019ll just use my fingers thanks. #guitarists #guitar #electricguitar #guitaristsoftiktok #indiemusician",
      "digged": false,
      "diversificationId": 10003,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "6974035631546649862",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "",
        "authorName": "Aridonis",
        "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/86cabf0d03ad6a87c21b700b49be2067~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=2Sj4sP5j5zyxaLaySSyhq9vHY8M%3D",
        "coverMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/86cabf0d03ad6a87c21b700b49be2067~c5_720x720.jpeg?x-expires=1700125200&x-signature=CeSM5QZoDY%2FcaTU8RnJVkuMc6AY%3D",
        "coverThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/86cabf0d03ad6a87c21b700b49be2067~c5_100x100.jpeg?x-expires=1700125200&x-signature=b4v%2FxgwHv21%2Boj8TmCtDgKovT4g%3D",
        "duration": 9,
        "id": "6974035592497695493",
        "original": true,
        "playUrl": "https://v16m.tiktokcdn.com/4bdfe01af682090c272693d9e4a93ad5/65539984/video/tos/useast2a/tos-useast2a-v-27dcd7/0dd62be1624848cca1174990fedf7a2c/?a=1233&ch=0&cr=0&dr=0&er=2&cd=0%7C0%7C0%7C0&br=250&bt=125&bti=NDU3ZjAwOg%3D%3D&ft=d2A~l-Inz7TPgL-Ziyq8Z&mime_type=audio_mpeg&qs=6&rc=NTk6Z2hoNDlkaDQ5Mzs2M0Bpamdqd205ZnhmNjMzNzU8M0AuYmFfLWMyNi8xMl40XjRiYSNrbWxxZ25yLTFgLS1kMTZzcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00088000&cc=3&download=true",
        "title": "original sound"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 1848,
        "commentCount": 448,
        "diggCount": 248900,
        "playCount": 3200000,
        "shareCount": 236
      },
      "stickersOnItem": [
        {
          "stickerText": [
            "My guitarist privilege when I accidentally grab a pick thinner than 1 mm\ufffc"
          ],
          "stickerType": 4
        }
      ],
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 57,
          "hashtagId": "13158956",
          "hashtagName": "guitarists",
          "isCommerce": false,
          "secUid": "",
          "start": 46,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 65,
          "hashtagId": "11329",
          "hashtagName": "guitar",
          "isCommerce": false,
          "secUid": "",
          "start": 58,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 81,
          "hashtagId": "446335",
          "hashtagName": "electricguitar",
          "isCommerce": false,
          "secUid": "",
          "start": 66,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 101,
          "hashtagId": "1620302809777158",
          "hashtagName": "guitaristsoftiktok",
          "isCommerce": false,
          "secUid": "",
          "start": 82,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 116,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 102,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 1455653,
        "bitrateInfo": [
          {
            "Bitrate": 1455653,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 1778263,
              "FileCs": "c:0-9270-058f",
              "FileHash": "b119e5a189b8b2326d6b3dce21edf720",
              "Uri": "v09044g40000c34c0gbcrhm59gg9tteg",
              "UrlKey": "v09044g40000c34c0gbcrhm59gg9tteg_h264_540p_1455653",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-pve-0068/ab31011d59f6448788f5beaed4c0b095/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2842&bt=1421&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NjVlOzU0Omk0NWY3aDs0OEBpanc8ajhrZmpmNjMzNzczM0BgYTM2LmEwXjMxNDAuNjRiYSNicWQ2am9fLTFgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=25e85ea8771c29b4aecca962f94b4dcf&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-pve-0068/ab31011d59f6448788f5beaed4c0b095/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2842&bt=1421&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NjVlOzU0Omk0NWY3aDs0OEBpanc8ajhrZmpmNjMzNzczM0BgYTM2LmEwXjMxNDAuNjRiYSNicWQ2am9fLTFgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=25e85ea8771c29b4aecca962f94b4dcf&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 865822,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 1057710,
              "FileCs": "c:0-9266-340e",
              "FileHash": "a93f8fc67dcaa677cc05f1aa82fad9f2",
              "Uri": "v09044g40000c34c0gbcrhm59gg9tteg",
              "UrlKey": "v09044g40000c34c0gbcrhm59gg9tteg_h264_540p_865822",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c003/8a7d4de578624fd5b7727abf5fb39b80/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1690&bt=845&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=O2Q6ODk0PDYzOTNoNjNoOEBpanc8ajhrZmpmNjMzNzczM0AvYi4wYjFgXzExMjM0NGI2YSNicWQ2am9fLTFgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=4c3a524526ede8e72b8bc93e01c33881&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c003/8a7d4de578624fd5b7727abf5fb39b80/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1690&bt=845&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=O2Q6ODk0PDYzOTNoNjNoOEBpanc8ajhrZmpmNjMzNzczM0AvYi4wYjFgXzExMjM0NGI2YSNicWQ2am9fLTFgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=4c3a524526ede8e72b8bc93e01c33881&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 659315,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 805436,
              "FileCs": "c:0-9408-1f26",
              "FileHash": "9cb8d1679bbccdeb9d50779c2b997e5d",
              "Uri": "v09044g40000c34c0gbcrhm59gg9tteg",
              "UrlKey": "v09044g40000c34c0gbcrhm59gg9tteg_bytevc1_720p_659315",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c004/51cac54be5b546009f1b9cd5e00564ad/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1286&bt=643&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=MzdpaDxmMzpmNDllOGRkNUBpanc8ajhrZmpmNjMzNzczM0BgMy80LTAvXy4xM19eLzBhYSNicWQ2am9fLTFgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=5af9525043fcbee6092fbf1642cf4f23&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c004/51cac54be5b546009f1b9cd5e00564ad/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1286&bt=643&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=MzdpaDxmMzpmNDllOGRkNUBpanc8ajhrZmpmNjMzNzczM0BgMy80LTAvXy4xM19eLzBhYSNicWQ2am9fLTFgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=5af9525043fcbee6092fbf1642cf4f23&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 653488,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 798318,
              "FileCs": "c:0-9266-d6d6",
              "FileHash": "c64d983b7307b2f9c4100d84db7c5d97",
              "Uri": "v09044g40000c34c0gbcrhm59gg9tteg",
              "UrlKey": "v09044g40000c34c0gbcrhm59gg9tteg_h264_540p_653488",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c002/c070922037784a4a877134171a6c64d7/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1276&bt=638&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=MzM4OGg4OjRnODlmZDM8OEBpanc8ajhrZmpmNjMzNzczM0AwYF41MGAvXjExLmIwMDFfYSNicWQ2am9fLTFgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=84821dd9cb7b564a1ec435ee1b923aae&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c002/c070922037784a4a877134171a6c64d7/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1276&bt=638&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=MzM4OGg4OjRnODlmZDM8OEBpanc8ajhrZmpmNjMzNzczM0AwYF41MGAvXjExLmIwMDFfYSNicWQ2am9fLTFgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=84821dd9cb7b564a1ec435ee1b923aae&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          },
          {
            "Bitrate": 574695,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 702063,
              "FileCs": "c:0-9408-bc9b",
              "FileHash": "252ad8a6442fdec9ad22899bd26d3bd7",
              "Uri": "v09044g40000c34c0gbcrhm59gg9tteg",
              "UrlKey": "v09044g40000c34c0gbcrhm59gg9tteg_bytevc1_540p_574695",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c004/39ef0fa64f7f4412abefa53162afbaf7/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1122&bt=561&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=Zmg7ZDM5OGZpMzM3aGRmaEBpanc8ajhrZmpmNjMzNzczM0AwY2EtYy0yXzMxM2NjMC5iYSNicWQ2am9fLTFgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=82e30f139a9169b1b9464891b514f22d&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c004/39ef0fa64f7f4412abefa53162afbaf7/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1122&bt=561&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=Zmg7ZDM5OGZpMzM3aGRmaEBpanc8ajhrZmpmNjMzNzczM0AwY2EtYy0yXzMxM2NjMC5iYSNicWQ2am9fLTFgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=82e30f139a9169b1b9464891b514f22d&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/77b9f265ef3449d8a3a5415d22157851?x-expires=1700125200&x-signature=XEj5Gx8VlItTJhDAurc6nIfJgsY%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-pve-0068/oYUntA4ZdESgo2IEwm9gAQkCoI3VAEREBvKGme/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=2962&bt=1481&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=ZmU4aDpmNDs3NGlnOWg6aEBpanc8ajhrZmpmNjMzNzczM0BeYWNfM18uNi4xNmFjLzUuYSNicWQ2am9fLTFgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=7b181d29659e9e309e8a65c69ac90969&tk=tt_chain_token",
        "duration": 9,
        "dynamicCover": "https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/0b0aa82355d74029acf774927519e76b_1623769210?x-expires=1700125200&x-signature=xbGB70dxhxiqzoF2IyDTzBSorVw%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "6974035631546649862",
        "originCover": "https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/1c79415133a646aeae3265bdde4b85b5_1623769211?x-expires=1700125200&x-signature=1s1MVT4XEL89wOtU9etQysO5DV4%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-pve-0068/ab31011d59f6448788f5beaed4c0b095/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=2842&bt=1421&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=NjVlOzU0Omk0NWY3aDs0OEBpanc8ajhrZmpmNjMzNzczM0BgYTM2LmEwXjMxNDAuNjRiYSNicWQ2am9fLTFgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128805&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=25e85ea8771c29b4aecca962f94b4dcf&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/d0ad225aef093f1766000560e4345f51~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=zd%2FP3%2B354Z9gSaJLETdiLEKfevE%3D",
        "shareCover": [
          "",
          "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/1c79415133a646aeae3265bdde4b85b5_1623769211~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=bTsRRXQYYKG7TwltFhU3JWyo7CI%3D",
          "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/1c79415133a646aeae3265bdde4b85b5_1623769211~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=XOn9djBsItRXMxJ%2Fb2oVdiivJn4%3D"
        ],
        "size": 1778263,
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -7.4,
          "Peak": 1
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/77b9f265ef3449d8a3a5415d22157851~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=6m9oEFH6aFSC43L6A8FDB%2FobD2k%3D",
          "480": "https://p77-sign-va.tiktokcdn.com/tos-maliva-p-0068/77b9f265ef3449d8a3a5415d22157851~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=CWVeZcJ12NEMoKucufK8GPZtU9A%3D",
          "720": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/77b9f265ef3449d8a3a5415d22157851~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=KdLN2yilWM%2FMubYhRER6eol4m0w%3D",
          "960": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/77b9f265ef3449d8a3a5415d22157851~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=E5GM210nOk6RwZLEq4ymSYLWrMY%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/f3a16421272a68ef640044db03eab5e0~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=FhGrumwnZUHMNYQCuSefTj5yySg%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/f3a16421272a68ef640044db03eab5e0~c5_720x720.jpeg?x-expires=1700125200&x-signature=lk25eanlozZNSRlqgMWnwjFSs84%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/f3a16421272a68ef640044db03eab5e0~c5_100x100.jpeg?x-expires=1700125200&x-signature=Ze7CGYgemLYSNVycrLnV2WmbAVQ%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 1,
        "ftc": false,
        "id": "56756354010324992",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "babimanatee",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAAjOw55nYQxdxIoiQOK5Y0qbQmA0Cr_k-u-FaClTQKOsOSNZOwPPgdc9GzWf27x5LS",
        "secret": false,
        "signature": "my names mia and i make songs",
        "stitchSetting": 1,
        "ttSeller": false,
        "uniqueId": "babimanatee",
        "verified": false
      },
      "authorStats": {
        "diggCount": 108000,
        "followerCount": 5885,
        "followingCount": 122,
        "friendCount": 0,
        "heart": 806500,
        "heartCount": 806500,
        "videoCount": 124
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        },
        {
          "coverLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/1632d26ee31d854f5e0f8f475cd3d87a.jpeg",
          "coverMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/1632d26ee31d854f5e0f8f475cd3d87a.jpeg",
          "coverThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/1632d26ee31d854f5e0f8f475cd3d87a.jpeg",
          "desc": "Share your #cover of your favorite songs",
          "id": "18146",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/9aac9667525deaf53fd139b6af3a9ad4.jpeg",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/9aac9667525deaf53fd139b6af3a9ad4.jpeg",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/9aac9667525deaf53fd139b6af3a9ad4.jpeg",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "cover"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "35857",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indieartist"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "229207",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "fyp"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "5674",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "wheatus"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "Show us when you were a #TeenageDirtbag.",
          "id": "91871",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/fd51c81efde31f2983c5305284a26450.png",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/fd51c81efde31f2983c5305284a26450.png",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/fd51c81efde31f2983c5305284a26450.png",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "teenagedirtbag"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7256636161530019886",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "babimanatee"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "wheatus live circa 2002 (colorized) #indiemusician #cover #indieartist #fyp #wheatus #teenagedirtbag #babimanatee ",
          "textExtra": [
            {
              "awemeId": "",
              "end": 50,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 36,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 57,
              "hashtagId": "18146",
              "hashtagName": "cover",
              "isCommerce": false,
              "secUid": "",
              "start": 51,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 70,
              "hashtagId": "35857",
              "hashtagName": "indieartist",
              "isCommerce": false,
              "secUid": "",
              "start": 58,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 75,
              "hashtagId": "229207",
              "hashtagName": "fyp",
              "isCommerce": false,
              "secUid": "",
              "start": 71,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 84,
              "hashtagId": "5674",
              "hashtagName": "wheatus",
              "isCommerce": false,
              "secUid": "",
              "start": 76,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 100,
              "hashtagId": "91871",
              "hashtagName": "teenagedirtbag",
              "isCommerce": false,
              "secUid": "",
              "start": 85,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 113,
              "hashtagId": "7256636161530019886",
              "hashtagName": "babimanatee",
              "isCommerce": false,
              "secUid": "",
              "start": 101,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1697316214,
      "desc": "wheatus live circa 2002 (colorized) #indiemusician #cover #indieartist #fyp #wheatus #teenagedirtbag #babimanatee ",
      "digged": false,
      "diversificationId": 10046,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7289917559976152366",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "",
        "authorName": "babimanatee",
        "coverLarge": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/f3a16421272a68ef640044db03eab5e0~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=FhGrumwnZUHMNYQCuSefTj5yySg%3D",
        "coverMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/f3a16421272a68ef640044db03eab5e0~c5_720x720.jpeg?x-expires=1700125200&x-signature=lk25eanlozZNSRlqgMWnwjFSs84%3D",
        "coverThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/f3a16421272a68ef640044db03eab5e0~c5_100x100.jpeg?x-expires=1700125200&x-signature=Ze7CGYgemLYSNVycrLnV2WmbAVQ%3D",
        "duration": 18,
        "id": "7289917611964402474",
        "original": true,
        "playUrl": "https://v16m.tiktokcdn.com/9272bb4800ef69c44edf7b520be44bf1/6553998d/video/tos/maliva/tos-maliva-ve-27dcd7c799-us/owHM9RmQBOJ2vgirkUfA4psQIF5raeqPfeIuTz/?a=1233&ch=0&cr=0&dr=0&er=2&cd=0%7C0%7C0%7C0&br=250&bt=125&bti=NDU3ZjAwOg%3D%3D&ft=d2A~l-Inz7TPgL-Ziyq8Z&mime_type=audio_mpeg&qs=6&rc=ZTZpZjY2NTU8ZjdpOmRnOEBpajZ4c2g6ZndpbjMzZzU8NEA2LjEuYF4xXy4xLzU2LzMwYSNeaGxicjRvbmlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00088000&cc=3&download=true",
        "title": "original sound"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 15500,
        "commentCount": 816,
        "diggCount": 236400,
        "playCount": 1700000,
        "shareCount": 245
      },
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 50,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 36,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 57,
          "hashtagId": "18146",
          "hashtagName": "cover",
          "isCommerce": false,
          "secUid": "",
          "start": 51,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 70,
          "hashtagId": "35857",
          "hashtagName": "indieartist",
          "isCommerce": false,
          "secUid": "",
          "start": 58,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 75,
          "hashtagId": "229207",
          "hashtagName": "fyp",
          "isCommerce": false,
          "secUid": "",
          "start": 71,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 84,
          "hashtagId": "5674",
          "hashtagName": "wheatus",
          "isCommerce": false,
          "secUid": "",
          "start": 76,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 100,
          "hashtagId": "91871",
          "hashtagName": "teenagedirtbag",
          "isCommerce": false,
          "secUid": "",
          "start": 85,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 113,
          "hashtagId": "7256636161530019886",
          "hashtagName": "babimanatee",
          "isCommerce": false,
          "secUid": "",
          "start": 101,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 774843,
        "bitrateInfo": [
          {
            "Bitrate": 774843,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 1792311,
              "FileCs": "c:0-16775-e2dc",
              "FileHash": "7b4139d6e6cf8de2d578408a2b7859a5",
              "Uri": "v12044gd0000cklfpujc77u5032sbps0",
              "UrlKey": "v12044gd0000cklfpujc77u5032sbps0_h264_540p_774843",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o8vASenIRED84Ia6LVDQYzBAfgLgXKDuKlbwzx/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1512&bt=756&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=OTVkaDU8NTtnNGUzNjs1OkBpM3N2Njg6ZnhpbjMzZzczNEBgNWE1YjZgXzAxMTI1LzFjYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128814&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c67542cd9f9f96eb69a1de30e1af8e2f&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o8vASenIRED84Ia6LVDQYzBAfgLgXKDuKlbwzx/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1512&bt=756&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=OTVkaDU8NTtnNGUzNjs1OkBpM3N2Njg6ZnhpbjMzZzczNEBgNWE1YjZgXzAxMTI1LzFjYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128814&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c67542cd9f9f96eb69a1de30e1af8e2f&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 584354,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 1351685,
              "FileCs": "c:0-16938-72ce",
              "FileHash": "b2086c61aebfe09f1fb46a680f9a42ee",
              "Uri": "v12044gd0000cklfpujc77u5032sbps0",
              "UrlKey": "v12044gd0000cklfpujc77u5032sbps0_bytevc1_720p_584354",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/osnArfbzAcnvl8FE67UBA6SgDKRDegxgRXCKhP/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1140&bt=570&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=ZTM2OjhmZ2Y8Zjc8N2c5PEBpM3N2Njg6ZnhpbjMzZzczNEA1YjEvLWMvNV4xNDUvNmBiYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128814&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=cf25b230cee5f28a2b4bdfe794932870&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/osnArfbzAcnvl8FE67UBA6SgDKRDegxgRXCKhP/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1140&bt=570&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=ZTM2OjhmZ2Y8Zjc8N2c5PEBpM3N2Njg6ZnhpbjMzZzczNEA1YjEvLWMvNV4xNDUvNmBiYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128814&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=cf25b230cee5f28a2b4bdfe794932870&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 455462,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 1053541,
              "FileCs": "c:0-16938-4bb9",
              "FileHash": "2273eb7b3b277cc1dd7e9fa7b0c037de",
              "Uri": "v12044gd0000cklfpujc77u5032sbps0",
              "UrlKey": "v12044gd0000cklfpujc77u5032sbps0_bytevc1_540p_455462",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o8zgzDbDZgKdl6wSAIBzRLAPCYQpeuEAnKhvfV/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=888&bt=444&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=O2k2PGllMzRmZzU7Ozk5N0BpM3N2Njg6ZnhpbjMzZzczNEAvY2EuNWA1NTMxLWM0MGExYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128814&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=082b8cfc331854a66d49e606baa1b983&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o8zgzDbDZgKdl6wSAIBzRLAPCYQpeuEAnKhvfV/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=888&bt=444&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=O2k2PGllMzRmZzU7Ozk5N0BpM3N2Njg6ZnhpbjMzZzczNEAvY2EuNWA1NTMxLWM0MGExYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128814&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=082b8cfc331854a66d49e606baa1b983&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 420143,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 971845,
              "FileCs": "c:0-16775-da29",
              "FileHash": "cfad5051fc6f3c8450fd48af3deadbe2",
              "Uri": "v12044gd0000cklfpujc77u5032sbps0",
              "UrlKey": "v12044gd0000cklfpujc77u5032sbps0_h264_540p_420143",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o0GneBuAvEKIzpzVQdqYDbw6SfKglIAYTDLRgc/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=820&bt=410&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=NTxlPDc7Zjk5Zmg2OWdoO0BpM3N2Njg6ZnhpbjMzZzczNEAuYzNfLTIvXzUxMS41Nl9hYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128814&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=d9ddcb74d84bab3072ffd0c6ff033975&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o0GneBuAvEKIzpzVQdqYDbw6SfKglIAYTDLRgc/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=820&bt=410&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=NTxlPDc7Zjk5Zmg2OWdoO0BpM3N2Njg6ZnhpbjMzZzczNEAuYzNfLTIvXzUxMS41Nl9hYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128814&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=d9ddcb74d84bab3072ffd0c6ff033975&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 314226,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 726845,
              "FileCs": "c:0-16775-23f8",
              "FileHash": "b7737824b5cd22e35948a2ee4e931a7c",
              "Uri": "v12044gd0000cklfpujc77u5032sbps0",
              "UrlKey": "v12044gd0000cklfpujc77u5032sbps0_h264_540p_314226",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o85EXS8Kngne6UsAzgPgbxDAlrQ9RocDSXvveB/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=612&bt=306&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=NzM8NzgzO2VoNDZkaDw2OkBpM3N2Njg6ZnhpbjMzZzczNEBiMGIyL14xNWIxNWE1MTAwYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128814&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c3469927fbe502a889476737e7a05ac4&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o85EXS8Kngne6UsAzgPgbxDAlrQ9RocDSXvveB/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=612&bt=306&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=NzM8NzgzO2VoNDZkaDw2OkBpM3N2Njg6ZnhpbjMzZzczNEBiMGIyL14xNWIxNWE1MTAwYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128814&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c3469927fbe502a889476737e7a05ac4&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/b73858631c75426090875380d24c35b1_1697316215?x-expires=1700125200&x-signature=FFOk60NKwFJqpwvm4Wp%2BIes0mMI%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o8AQYVzYDnfKuSLVDweBgzIDRAKugx6bEEBlJv/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=1832&bt=916&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=Nzo8PDg4ZDw2ODQ8ZDk0NkBpM3N2Njg6ZnhpbjMzZzczNEBeYDY0MmIzXjMxYC4tYTYyYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128814&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=8d54afcd461e992854848278097826b9&tk=tt_chain_token",
        "duration": 18,
        "dynamicCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/ec920f2a4ba748bdb44800acb7637543_1697316216?x-expires=1700125200&x-signature=MifejGb2omEVTEkwEpgyBUHjvKo%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7289917559976152366",
        "originCover": "https://p16-sign.tiktokcdn-us.com/obj/tos-useast5-p-0068-tx/b2aba8b4f5ae48f2859f002f8fb570f7_1697316215?x-expires=1700125200&x-signature=YqehThHNMF09tUWfhgI27bUoNqI%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/maliva/tos-maliva-ve-0068c799-us/o8vASenIRED84Ia6LVDQYzBAfgLgXKDuKlbwzx/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1512&bt=756&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=OTVkaDU8NTtnNGUzNjs1OkBpM3N2Njg6ZnhpbjMzZzczNEBgNWE1YjZgXzAxMTI1LzFjYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&btag=e00088000&expire=1700128814&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=c67542cd9f9f96eb69a1de30e1af8e2f&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/b73858631c75426090875380d24c35b1_1697316215~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=PI6SmKBjNaVjangbyygf29iOxq8%3D",
        "shareCover": [
          "",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/b2aba8b4f5ae48f2859f002f8fb570f7_1697316215~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=jR2AGYdkzjUh49K6MsXl0PPx%2BV4%3D",
          "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/b2aba8b4f5ae48f2859f002f8fb570f7_1697316215~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=VUbmOlKg4dCHsUnzCwUEgZOl23M%3D"
        ],
        "size": 1792311,
        "subtitleInfos": [
          {
            "Format": "webvtt",
            "LanguageCodeName": "fra-FR",
            "LanguageID": "7",
            "Size": 356,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/53e9a26d33c73c90c47bb5581c853fb2/6555e82e/video/tos/maliva/tos-maliva-v-0068c799-us/c8c461424614497baa15fff4ae14495d/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=9020&bt=4510&bti=NDU3ZjAwOg%3D%3D&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3N2Njg6ZnhpbjMzZzczNEBpM3N2Njg6ZnhpbjMzZzczNEBwXy8tcjRnbWlgLS1kMS9zYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128814,
            "Version": "4",
            "VideoSubtitleID": 424235598
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "cmn-Hans-CN",
            "LanguageID": "1",
            "Size": 338,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/94a455ef40964bf3fe7ad0863fdac79d/6555e82e/video/tos/maliva/tos-maliva-v-0068c799-us/6b38f14b7cc849afa94572d515a347c6/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=9020&bt=4510&bti=NDU3ZjAwOg%3D%3D&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3N2Njg6ZnhpbjMzZzczNEBpM3N2Njg6ZnhpbjMzZzczNEBwXy8tcjRnbWlgLS1kMS9zYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128814,
            "Version": "4",
            "VideoSubtitleID": 952404315
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ita-IT",
            "LanguageID": "26",
            "Size": 342,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/b713e3af63fc3dcd1ba067505004e1ad/6555e82e/video/tos/maliva/tos-maliva-v-0068c799-us/93711b445db74f08b598331383400c8e/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=9020&bt=4510&bti=NDU3ZjAwOg%3D%3D&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3N2Njg6ZnhpbjMzZzczNEBpM3N2Njg6ZnhpbjMzZzczNEBwXy8tcjRnbWlgLS1kMS9zYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128814,
            "Version": "4",
            "VideoSubtitleID": -957341938
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "vie-VN",
            "LanguageID": "10",
            "Size": 353,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/753861adb93b4cea92152d0f83f5876e/6555e82e/video/tos/maliva/tos-maliva-v-0068c799-us/f620d9ebae3d40d58dc85fa78971ae14/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=9020&bt=4510&bti=NDU3ZjAwOg%3D%3D&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3N2Njg6ZnhpbjMzZzczNEBpM3N2Njg6ZnhpbjMzZzczNEBwXy8tcjRnbWlgLS1kMS9zYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128814,
            "Version": "4",
            "VideoSubtitleID": -1622274136
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ind-ID",
            "LanguageID": "23",
            "Size": 331,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/605746efd249a3574ae7ea21bbb4e6c1/6555e82e/video/tos/maliva/tos-maliva-v-0068c799-us/6e24fe1bb45e43f7bd16017a8f0c2fc9/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=9020&bt=4510&bti=NDU3ZjAwOg%3D%3D&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3N2Njg6ZnhpbjMzZzczNEBpM3N2Njg6ZnhpbjMzZzczNEBwXy8tcjRnbWlgLS1kMS9zYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128814,
            "Version": "4",
            "VideoSubtitleID": -906638999
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "por-PT",
            "LanguageID": "8",
            "Size": 348,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/93bfd1b99bbd79675052bd0302cc4f6d/6555e82e/video/tos/maliva/tos-maliva-v-0068c799-us/dde81554a43c4cd19a9a5fb2f153d73a/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=9020&bt=4510&bti=NDU3ZjAwOg%3D%3D&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3N2Njg6ZnhpbjMzZzczNEBpM3N2Njg6ZnhpbjMzZzczNEBwXy8tcjRnbWlgLS1kMS9zYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128814,
            "Version": "4",
            "VideoSubtitleID": 383248080
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "jpn-JP",
            "LanguageID": "3",
            "Size": 440,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/acc2200fb82ceb8b0b50310c6d3cf6c7/6555e82e/video/tos/maliva/tos-maliva-v-0068c799-us/28eca4db2f7146349c026f9cb4cc121b/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=9020&bt=4510&bti=NDU3ZjAwOg%3D%3D&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3N2Njg6ZnhpbjMzZzczNEBpM3N2Njg6ZnhpbjMzZzczNEBwXy8tcjRnbWlgLS1kMS9zYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128814,
            "Version": "4",
            "VideoSubtitleID": -220432390
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "ara-SA",
            "LanguageID": "34",
            "Size": 298,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/fcd86122477e2507f5261c35dbd116bd/6555e82e/video/tos/maliva/tos-maliva-v-0068c799-us/88c7325436df4dff9ba26713de914fb7/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=9020&bt=4510&bti=NDU3ZjAwOg%3D%3D&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3N2Njg6ZnhpbjMzZzczNEBpM3N2Njg6ZnhpbjMzZzczNEBwXy8tcjRnbWlgLS1kMS9zYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128814,
            "Version": "4",
            "VideoSubtitleID": -1556297045
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "spa-ES",
            "LanguageID": "9",
            "Size": 330,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/36ff04996914420a6f183f7d39b1de25/6555e82e/video/tos/maliva/tos-maliva-v-0068c799-us/424d16ffd2e0411293c7ed0c04eb269f/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=9020&bt=4510&bti=NDU3ZjAwOg%3D%3D&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3N2Njg6ZnhpbjMzZzczNEBpM3N2Njg6ZnhpbjMzZzczNEBwXy8tcjRnbWlgLS1kMS9zYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128814,
            "Version": "4",
            "VideoSubtitleID": -462012579
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "rus-RU",
            "LanguageID": "6",
            "Size": 487,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/d5b7e0168de20ddd5c3fe3ef58943a0c/6555e82e/video/tos/maliva/tos-maliva-v-0068c799-us/d5828ce418514fc7971b20fc7135a5e0/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=9020&bt=4510&bti=NDU3ZjAwOg%3D%3D&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3N2Njg6ZnhpbjMzZzczNEBpM3N2Njg6ZnhpbjMzZzczNEBwXy8tcjRnbWlgLS1kMS9zYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128814,
            "Version": "4",
            "VideoSubtitleID": -2010102801
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "deu-DE",
            "LanguageID": "20",
            "Size": 342,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/b4b6c5121e822b71c77aea1c2a30ee68/6555e82e/video/tos/maliva/tos-maliva-v-0068c799-us/30264637a7e1448999698d170a68adb6/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=9020&bt=4510&bti=NDU3ZjAwOg%3D%3D&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3N2Njg6ZnhpbjMzZzczNEBpM3N2Njg6ZnhpbjMzZzczNEBwXy8tcjRnbWlgLS1kMS9zYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128814,
            "Version": "4",
            "VideoSubtitleID": -771343132
          },
          {
            "Format": "creator_caption",
            "LanguageCodeName": "eng-US",
            "LanguageID": "2",
            "Size": 925,
            "Source": "LC",
            "Url": "https://v16-webapp.tiktok.com/bc4c578995a25291958e0c2579078b63/6555e82e/video/tos/maliva/tos-maliva-v-0068c799-us/fb95f9fa06da4f55919af4e0c0dada23/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=9020&bt=4510&bti=NDU3ZjAwOg%3D%3D&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3N2Njg6ZnhpbjMzZzczNEBpM3N2Njg6ZnhpbjMzZzczNEBwXy8tcjRnbWlgLS1kMS9zYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128814,
            "Version": "8",
            "VideoSubtitleID": -1810518108
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "eng-US",
            "LanguageID": "2",
            "Size": 254,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/17b75e451d84b1d95ee593ad6c95d512/6555e82e/video/tos/maliva/tos-maliva-v-0068c799-us/63a8f1c08da048559675cbb473f24fb9/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=9020&bt=4510&bti=NDU3ZjAwOg%3D%3D&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3N2Njg6ZnhpbjMzZzczNEBpM3N2Njg6ZnhpbjMzZzczNEBwXy8tcjRnbWlgLS1kMS9zYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128814,
            "Version": "8",
            "VideoSubtitleID": 1650241234
          },
          {
            "Format": "webvtt",
            "LanguageCodeName": "kor-KR",
            "LanguageID": "4",
            "Size": 393,
            "Source": "MT",
            "Url": "https://v16-webapp.tiktok.com/6ecabf012f8853792cf0b4ca23a61c28/6555e82e/video/tos/maliva/tos-maliva-v-0068c799-us/60ef085d490446a8ac45050db9c52c67/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=9020&bt=4510&bti=NDU3ZjAwOg%3D%3D&ds=3&ft=_GC~MB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=13&rc=M3N2Njg6ZnhpbjMzZzczNEBpM3N2Njg6ZnhpbjMzZzczNEBwXy8tcjRnbWlgLS1kMS9zYSNwXy8tcjRnbWlgLS1kMS9zcw%3D%3D&l=20231114095954E1219E7683CB910E9B2D&btag=e00048000",
            "UrlExpire": 1700128814,
            "Version": "4",
            "VideoSubtitleID": 1812608318
          }
        ],
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -12.1,
          "Peak": 0.93325
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/b73858631c75426090875380d24c35b1_1697316215~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=uxHnSOTmAeREra32tZ1ts9NUCMM%3D",
          "480": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/b73858631c75426090875380d24c35b1_1697316215~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=aWxUqWLUxG85jnsEfmMTqLWpLq0%3D",
          "720": "https://p19-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/b73858631c75426090875380d24c35b1_1697316215~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=0iHIobidAFWeYI%2FxXCiUKZOPtbU%3D",
          "960": "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/b73858631c75426090875380d24c35b1_1697316215~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=AKNLdBt4d8w3dQjKXFfQBJsi%2Fmo%3D"
        }
      },
      "vl1": false
    },
    {
      "BAInfo": "",
      "adAuthorization": false,
      "adLabelVersion": 0,
      "author": {
        "avatarLarger": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/54c0ba2d5353c8c4ed003d20841f1cf6~c5_1080x1080.jpeg?x-expires=1700125200&x-signature=4%2FnxGTOBE4MnEPeF3g1UeeDlL9I%3D",
        "avatarMedium": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/54c0ba2d5353c8c4ed003d20841f1cf6~c5_720x720.jpeg?x-expires=1700125200&x-signature=KWZLHS6RbCtnFSVtML9KKMzssrQ%3D",
        "avatarThumb": "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/54c0ba2d5353c8c4ed003d20841f1cf6~c5_100x100.jpeg?x-expires=1700125200&x-signature=hsJAwbeOoqLukB0X%2FRPJIJ97Xks%3D",
        "commentSetting": 0,
        "downloadSetting": 0,
        "duetSetting": 0,
        "ftc": false,
        "id": "7173768054202745861",
        "isADVirtual": false,
        "isEmbedBanned": false,
        "nickname": "Goya.Music",
        "openFavorite": false,
        "privateAccount": false,
        "relation": 0,
        "secUid": "MS4wLjABAAAAdsIZ0F5NDBeR_9FCqpFnJP__oBoi2on8YypEMMs5oPAtXq71hUann4R3gJz6CnPO",
        "secret": false,
        "signature": "KW \ud83c\udde8\ud83c\udde6 musician",
        "stitchSetting": 0,
        "ttSeller": false,
        "uniqueId": "goya.music",
        "verified": false
      },
      "authorStats": {
        "diggCount": 21700,
        "followerCount": 1623,
        "followingCount": 360,
        "friendCount": 0,
        "heart": 808700,
        "heartCount": 808700,
        "videoCount": 215
      },
      "challenges": [
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1054928",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "britishmusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "105147",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/373c5be489cde63945f928db7dd941d3",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/373c5be489cde63945f928db7dd941d3",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/373c5be489cde63945f928db7dd941d3",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "british"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "42801",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "uk"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "7073548874861772805",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "britishmusicinvasion"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "765",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "thesmiths"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1694375819352069",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "thesmithstok"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1628527714836482",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "bcnr"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1659364206799877",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "blackcountrynewroad"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "6074585",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "blackmidi"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1671797130097669",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "malemanipulator"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "239893",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "alternativemusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1661099109807110",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "alternativemusician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "Love #IndieMusic? Start here.",
          "id": "247262",
          "isCommerce": false,
          "profileLarger": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/164263ca9942461c79d1ac0ee9fa0b21.jpeg",
          "profileMedium": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/164263ca9942461c79d1ac0ee9fa0b21.jpeg",
          "profileThumb": "https://p16-amd-va.tiktokcdn.com/obj/musically-maliva-obj/164263ca9942461c79d1ac0ee9fa0b21.jpeg",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusic"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "23558100",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "indiemusician"
        },
        {
          "coverLarger": "",
          "coverMedium": "",
          "coverThumb": "",
          "desc": "",
          "id": "1651968380762117",
          "isCommerce": false,
          "profileLarger": "",
          "profileMedium": "",
          "profileThumb": "",
          "stats": {
            "videoCount": 0,
            "viewCount": 0
          },
          "title": "musicfyp"
        }
      ],
      "collected": false,
      "contents": [
        {
          "desc": "fuck Morrissey #britishmusic #british #uk #britishmusicinvasion #thesmiths #thesmithstok #bcnr #blackcountrynewroad #blackmidi #malemanipulator #alternativemusic #alternativemusician #indiemusic #indiemusician #musicfyp ",
          "textExtra": [
            {
              "awemeId": "",
              "end": 28,
              "hashtagId": "1054928",
              "hashtagName": "britishmusic",
              "isCommerce": false,
              "secUid": "",
              "start": 15,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 37,
              "hashtagId": "105147",
              "hashtagName": "british",
              "isCommerce": false,
              "secUid": "",
              "start": 29,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 41,
              "hashtagId": "42801",
              "hashtagName": "uk",
              "isCommerce": false,
              "secUid": "",
              "start": 38,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 63,
              "hashtagId": "7073548874861772805",
              "hashtagName": "britishmusicinvasion",
              "isCommerce": false,
              "secUid": "",
              "start": 42,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 74,
              "hashtagId": "765",
              "hashtagName": "thesmiths",
              "isCommerce": false,
              "secUid": "",
              "start": 64,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 88,
              "hashtagId": "1694375819352069",
              "hashtagName": "thesmithstok",
              "isCommerce": false,
              "secUid": "",
              "start": 75,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 94,
              "hashtagId": "1628527714836482",
              "hashtagName": "bcnr",
              "isCommerce": false,
              "secUid": "",
              "start": 89,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 115,
              "hashtagId": "1659364206799877",
              "hashtagName": "blackcountrynewroad",
              "isCommerce": false,
              "secUid": "",
              "start": 95,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 126,
              "hashtagId": "6074585",
              "hashtagName": "blackmidi",
              "isCommerce": false,
              "secUid": "",
              "start": 116,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 143,
              "hashtagId": "1671797130097669",
              "hashtagName": "malemanipulator",
              "isCommerce": false,
              "secUid": "",
              "start": 127,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 161,
              "hashtagId": "239893",
              "hashtagName": "alternativemusic",
              "isCommerce": false,
              "secUid": "",
              "start": 144,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 182,
              "hashtagId": "1661099109807110",
              "hashtagName": "alternativemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 162,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 194,
              "hashtagId": "247262",
              "hashtagName": "indiemusic",
              "isCommerce": false,
              "secUid": "",
              "start": 183,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 209,
              "hashtagId": "23558100",
              "hashtagName": "indiemusician",
              "isCommerce": false,
              "secUid": "",
              "start": 195,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            },
            {
              "awemeId": "",
              "end": 219,
              "hashtagId": "1651968380762117",
              "hashtagName": "musicfyp",
              "isCommerce": false,
              "secUid": "",
              "start": 210,
              "subType": 0,
              "type": 1,
              "userId": "",
              "userUniqueId": ""
            }
          ]
        }
      ],
      "createTime": 1682990528,
      "desc": "fuck Morrissey #britishmusic #british #uk #britishmusicinvasion #thesmiths #thesmithstok #bcnr #blackcountrynewroad #blackmidi #malemanipulator #alternativemusic #alternativemusician #indiemusic #indiemusician #musicfyp ",
      "digged": false,
      "diversificationId": 10046,
      "duetDisplay": 0,
      "duetEnabled": true,
      "duetInfo": {
        "duetFromId": "0"
      },
      "forFriend": false,
      "id": "7228389255821724934",
      "isAd": false,
      "itemCommentStatus": 0,
      "itemMute": false,
      "music": {
        "album": "The Smiths",
        "authorName": "The Smiths",
        "coverLarge": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/d948c7a25dca46a38072e935e0f537d3~c5_720x720.jpeg",
        "coverMedium": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/d948c7a25dca46a38072e935e0f537d3~c5_200x200.jpeg",
        "coverThumb": "https://p16-amd-va.tiktokcdn.com/img/tos-useast2a-v-2774/d948c7a25dca46a38072e935e0f537d3~c5_100x100.jpeg",
        "duration": 60,
        "id": "6705044883058460673",
        "original": false,
        "playUrl": "https://sf16-ies-music-va.tiktokcdn.com/obj/tos-useast2a-ve-2774/3309b12e2a544cc288124f927d8b8fa0",
        "title": "This Charming Man (2011 Remaster)"
      },
      "officalItem": false,
      "originalItem": false,
      "playlistId": "",
      "privateItem": false,
      "secret": false,
      "shareEnabled": true,
      "showNotPass": false,
      "stats": {
        "collectCount": 14100,
        "commentCount": 1608,
        "diggCount": 226100,
        "playCount": 814900,
        "shareCount": 2944
      },
      "stickersOnItem": [
        {
          "stickerText": [
            "There are two wolves inside me. One of them hates British people. The other loves their music."
          ],
          "stickerType": 4
        }
      ],
      "stitchDisplay": 0,
      "stitchEnabled": true,
      "textExtra": [
        {
          "awemeId": "",
          "end": 28,
          "hashtagId": "1054928",
          "hashtagName": "britishmusic",
          "isCommerce": false,
          "secUid": "",
          "start": 15,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 37,
          "hashtagId": "105147",
          "hashtagName": "british",
          "isCommerce": false,
          "secUid": "",
          "start": 29,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 41,
          "hashtagId": "42801",
          "hashtagName": "uk",
          "isCommerce": false,
          "secUid": "",
          "start": 38,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 63,
          "hashtagId": "7073548874861772805",
          "hashtagName": "britishmusicinvasion",
          "isCommerce": false,
          "secUid": "",
          "start": 42,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 74,
          "hashtagId": "765",
          "hashtagName": "thesmiths",
          "isCommerce": false,
          "secUid": "",
          "start": 64,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 88,
          "hashtagId": "1694375819352069",
          "hashtagName": "thesmithstok",
          "isCommerce": false,
          "secUid": "",
          "start": 75,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 94,
          "hashtagId": "1628527714836482",
          "hashtagName": "bcnr",
          "isCommerce": false,
          "secUid": "",
          "start": 89,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 115,
          "hashtagId": "1659364206799877",
          "hashtagName": "blackcountrynewroad",
          "isCommerce": false,
          "secUid": "",
          "start": 95,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 126,
          "hashtagId": "6074585",
          "hashtagName": "blackmidi",
          "isCommerce": false,
          "secUid": "",
          "start": 116,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 143,
          "hashtagId": "1671797130097669",
          "hashtagName": "malemanipulator",
          "isCommerce": false,
          "secUid": "",
          "start": 127,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 161,
          "hashtagId": "239893",
          "hashtagName": "alternativemusic",
          "isCommerce": false,
          "secUid": "",
          "start": 144,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 182,
          "hashtagId": "1661099109807110",
          "hashtagName": "alternativemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 162,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 194,
          "hashtagId": "247262",
          "hashtagName": "indiemusic",
          "isCommerce": false,
          "secUid": "",
          "start": 183,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 209,
          "hashtagId": "23558100",
          "hashtagName": "indiemusician",
          "isCommerce": false,
          "secUid": "",
          "start": 195,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        },
        {
          "awemeId": "",
          "end": 219,
          "hashtagId": "1651968380762117",
          "hashtagName": "musicfyp",
          "isCommerce": false,
          "secUid": "",
          "start": 210,
          "subType": 0,
          "type": 1,
          "userId": "",
          "userUniqueId": ""
        }
      ],
      "video": {
        "bitrate": 571414,
        "bitrateInfo": [
          {
            "Bitrate": 571414,
            "CodecType": "h264",
            "GearName": "normal_540_0",
            "PlayAddr": {
              "DataSize": 363277,
              "FileCs": "c:0-5687-06a8",
              "FileHash": "849bc81d799c94f2c047d2d9d47d2a2a",
              "Uri": "v09044g40000ch868lbc77uff1p9hmt0",
              "UrlKey": "v09044g40000ch868lbc77uff1p9hmt0_h264_540p_571414",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-pve-0068/oMneQcbRFK2Gk8iE5JeBAVJ6DUUBZIHQRY1wR5/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1116&bt=558&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aGZlaGQ0ZjY5Nzw3aTQ8OEBpM3A8NGk6Zm85azMzNzczM0BfMDIwYWEvXzExX2JjLWFiYSNxZW1jcjRfNTVgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128801&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=f5998f6c8baccd0a4310c6dbf6477cd9&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-pve-0068/oMneQcbRFK2Gk8iE5JeBAVJ6DUUBZIHQRY1wR5/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1116&bt=558&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aGZlaGQ0ZjY5Nzw3aTQ8OEBpM3A8NGk6Zm85azMzNzczM0BfMDIwYWEvXzExX2JjLWFiYSNxZW1jcjRfNTVgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128801&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=f5998f6c8baccd0a4310c6dbf6477cd9&tk=tt_chain_token"
              ]
            },
            "QualityType": 20
          },
          {
            "Bitrate": 301849,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_540_1",
            "PlayAddr": {
              "DataSize": 191901,
              "FileCs": "c:0-5827-4386",
              "FileHash": "be1cbda7fffc225397846bfa58091b33",
              "Uri": "v09044g40000ch868lbc77uff1p9hmt0",
              "UrlKey": "v09044g40000ch868lbc77uff1p9hmt0_bytevc1_540p_301849",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c001/oA2QRG6jkIJ7EZBeZBU8P1RDUKnCetAYVQbrdH/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=588&bt=294&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=ODo7M2k6Z2RmNjgzPDs0NEBpM3A8NGk6Zm85azMzNzczM0BgMy1fNWNiX14xLmAtLTNeYSNxZW1jcjRfNTVgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128801&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=6fb9178b254e02e6eaba64f169838faa&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c001/oA2QRG6jkIJ7EZBeZBU8P1RDUKnCetAYVQbrdH/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=588&bt=294&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=11&rc=ODo7M2k6Z2RmNjgzPDs0NEBpM3A8NGk6Zm85azMzNzczM0BgMy1fNWNiX14xLmAtLTNeYSNxZW1jcjRfNTVgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128801&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=6fb9178b254e02e6eaba64f169838faa&tk=tt_chain_token"
              ]
            },
            "QualityType": 28
          },
          {
            "Bitrate": 282016,
            "CodecType": "h265_hvc1",
            "GearName": "adapt_lower_720_1",
            "PlayAddr": {
              "DataSize": 179292,
              "FileCs": "c:0-5827-6de3",
              "FileHash": "8d83c8b7ea168afd8c17104164cb79a6",
              "Uri": "v09044g40000ch868lbc77uff1p9hmt0",
              "UrlKey": "v09044g40000ch868lbc77uff1p9hmt0_bytevc1_720p_282016",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c002/oQAFBEdkJDVCvRrER6QbB8ZUxn0weGIe27YKHQ/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=550&bt=275&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=ZTpnaTtnNTQ1OTg4ZDk6OUBpM3A8NGk6Zm85azMzNzczM0A1NDM0NV8uXmExMC40LTUyYSNxZW1jcjRfNTVgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128801&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=e965aae2309b7836de75f5c0f7977ed3&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c002/oQAFBEdkJDVCvRrER6QbB8ZUxn0weGIe27YKHQ/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=550&bt=275&bti=NDU3ZjAwOg%3D%3D&cs=2&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=14&rc=ZTpnaTtnNTQ1OTg4ZDk6OUBpM3A8NGk6Zm85azMzNzczM0A1NDM0NV8uXmExMC40LTUyYSNxZW1jcjRfNTVgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128801&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=e965aae2309b7836de75f5c0f7977ed3&tk=tt_chain_token"
              ]
            },
            "QualityType": 14
          },
          {
            "Bitrate": 171817,
            "CodecType": "h264",
            "GearName": "lower_540_0",
            "PlayAddr": {
              "DataSize": 109233,
              "FileCs": "c:0-5687-6051",
              "FileHash": "1e9e0442015ea3b6ebfcbe8ea403b39e",
              "Uri": "v09044g40000ch868lbc77uff1p9hmt0",
              "UrlKey": "v09044g40000ch868lbc77uff1p9hmt0_h264_540p_171817",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c004/o4KBR4np8VtVUygRWRf0ABxE8eUQoJDbQkGOVD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=334&bt=167&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=MzhnNDtpOmdnNzNoODZlZUBpM3A8NGk6Zm85azMzNzczM0BeYS8xLTVhNWExLmBiXzYyYSNxZW1jcjRfNTVgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128801&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=ef9c099d36995ec4b8c2eb860d98ac12&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c004/o4KBR4np8VtVUygRWRf0ABxE8eUQoJDbQkGOVD/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=334&bt=167&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=4&rc=MzhnNDtpOmdnNzNoODZlZUBpM3A8NGk6Zm85azMzNzczM0BeYS8xLTVhNWExLmBiXzYyYSNxZW1jcjRfNTVgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128801&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=ef9c099d36995ec4b8c2eb860d98ac12&tk=tt_chain_token"
              ]
            },
            "QualityType": 24
          },
          {
            "Bitrate": 111249,
            "CodecType": "h264",
            "GearName": "lowest_540_0",
            "PlayAddr": {
              "DataSize": 70727,
              "FileCs": "c:0-5687-7b98",
              "FileHash": "7873962849ea09159dc2db461474f661",
              "Uri": "v09044g40000ch868lbc77uff1p9hmt0",
              "UrlKey": "v09044g40000ch868lbc77uff1p9hmt0_h264_540p_111249",
              "UrlList": [
                "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c004/oAABQ8YVRUecnZFFRbHBGVEeQJDaBdEU2k1KI6/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=216&bt=108&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=aTxoPGk0NmU5aTg0ODpoNEBpM3A8NGk6Zm85azMzNzczM0A2YDE0X2NfX2ExYy4xMTBfYSNxZW1jcjRfNTVgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128801&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=d3390aae427f3e467417cb82c52b45b2&tk=tt_chain_token",
                "https://v19-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c004/oAABQ8YVRUecnZFFRbHBGVEeQJDaBdEU2k1KI6/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=216&bt=108&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=5&rc=aTxoPGk0NmU5aTg0ODpoNEBpM3A8NGk6Zm85azMzNzczM0A2YDE0X2NfX2ExYy4xMTBfYSNxZW1jcjRfNTVgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128801&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=d3390aae427f3e467417cb82c52b45b2&tk=tt_chain_token"
              ]
            },
            "QualityType": 25
          }
        ],
        "codecType": "h264",
        "cover": "https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/os8kfZRURA0eBRo1QI80UyJnVbOtx3GDBEBCgQ?x-expires=1700125200&x-signature=wVUfdT2%2Fc5r3zX958x31aWHQHFI%3D",
        "definition": "540p",
        "downloadAddr": "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-ve-0068c002/oMItQkYEVBG1FQRHeKUAJ8DBRZnF2UFTeb6VcJ/?a=1988&ch=0&cr=3&dr=0&lr=tiktok_m&cd=0%7C0%7C1%7C3&cv=1&br=1174&bt=587&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=3&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=OjM4aGY8Z2dlOzQ2PGRlNkBpM3A8NGk6Zm85azMzNzczM0AtYWJjNi8vXi8xNWBjL2FjYSNxZW1jcjRfNTVgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128801&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=1c2311f9e1e1c4cfd67205c684f15014&tk=tt_chain_token",
        "duration": 5,
        "dynamicCover": "https://p77-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/40ea66edbf974c4ba0a3c3f07b0aac81_1682990530?x-expires=1700125200&x-signature=R6b0ZxfXKWes3qMCo3INR8I3pYA%3D",
        "encodeUserTag": "",
        "encodedType": "normal",
        "format": "mp4",
        "height": 1024,
        "id": "7228389255821724934",
        "originCover": "https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/c578d9c3e46144158de9e248e971eae8_1682990529?x-expires=1700125200&x-signature=atzKAidj2898ipm0KC6XICUu3aM%3D",
        "playAddr": "https://v16-webapp-prime.tiktok.com/video/tos/useast2a/tos-useast2a-pve-0068/oMneQcbRFK2Gk8iE5JeBAVJ6DUUBZIHQRY1wR5/?a=1988&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C3&cv=1&br=1116&bt=558&bti=NDU3ZjAwOg%3D%3D&cs=0&ds=6&ft=_RwJrB~lq8ZmovB3yQ_vjoxZMAhLrus&mime_type=video_mp4&qs=0&rc=aGZlaGQ0ZjY5Nzw3aTQ8OEBpM3A8NGk6Zm85azMzNzczM0BfMDIwYWEvXzExX2JjLWFiYSNxZW1jcjRfNTVgLS1kMTZzcw%3D%3D&btag=e00088000&expire=1700128801&l=20231114095954E1219E7683CB910E9B2D&ply_type=2&policy=2&signature=f5998f6c8baccd0a4310c6dbf6477cd9&tk=tt_chain_token",
        "ratio": "540p",
        "reflowCover": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/os8kfZRURA0eBRo1QI80UyJnVbOtx3GDBEBCgQ~tplv-photomode-video-cover:480:480.jpeg?x-expires=1700125200&x-signature=2cUDskzEH82ze8RYC%2BsDUOBmm7w%3D",
        "shareCover": [
          "",
          "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/c578d9c3e46144158de9e248e971eae8_1682990529~tplv-photomode-tiktok-play.jpeg?x-expires=1700557200&x-signature=9O5LGyIlRFiDTYCVQfSNJ%2Beq0tY%3D",
          "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/c578d9c3e46144158de9e248e971eae8_1682990529~tplv-tiktokx-share-play.jpeg?x-expires=1700557200&x-signature=GrRkS7G%2FEHuzqIxqMxqbGUuscpw%3D"
        ],
        "size": 363277,
        "videoQuality": "normal",
        "volumeInfo": {
          "Loudness": -10.3,
          "Peak": 0.91201
        },
        "width": 576,
        "zoomCover": {
          "240": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/os8kfZRURA0eBRo1QI80UyJnVbOtx3GDBEBCgQ~tplv-photomode-zoomcover:240:240.jpeg?x-expires=1700125200&x-signature=5yk3Uoz4zB8pzB%2FzF2PFjLKrXk8%3D",
          "480": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/os8kfZRURA0eBRo1QI80UyJnVbOtx3GDBEBCgQ~tplv-photomode-zoomcover:480:480.jpeg?x-expires=1700125200&x-signature=MVtu8cU8kYgfg77Yu49nAXt68As%3D",
          "720": "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/os8kfZRURA0eBRo1QI80UyJnVbOtx3GDBEBCgQ~tplv-photomode-zoomcover:720:720.jpeg?x-expires=1700125200&x-signature=ZuduVEKGqxbppc%2F%2B%2Bz90bPtnZ24%3D",
          "960": "https://p77-sign-va.tiktokcdn.com/tos-maliva-p-0068/os8kfZRURA0eBRo1QI80UyJnVbOtx3GDBEBCgQ~tplv-photomode-zoomcover:960:960.jpeg?x-expires=1700125200&x-signature=AyfdhWRvpecJ6CmZXJ8WpL95tms%3D"
        }
      },
      "vl1": false
    }
  ],
  "log_pb": {
    "impr_id": "20231114095954E1219E7683CB910E9B2D"
  },
  "message": "",
  "status": "success",
  "statusCode": 0,
  "status_code": 0
}

export default data;
