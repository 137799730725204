import React from 'react';
import './Search.scss';
import '../Button/Button.scss';
import Loading from "../Loading/Loading";

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.searchTerm,
      isLoading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({isLoading: true});
    let query = this.state.value;

    // Get info from TikTok api (http://localhost:3000/search/${this.state.value})
    if (query[0] === '#') {
      // Remove the # from the query
      query = query.substring(1);
    }

    // use fetch to get data from server
    fetch(`${process.env.GOFAMER_TIKTOK_API}/search/${query}`)
      .then(response => response.json())
      .then(data => {
        this.setState({isLoading: false});
        this.props.callback(this.state.value, data);
      });
  }

  showLoader = function () {
    if (this.state.isLoading) {
      return <Loading />;
    } else {
      return 'Search';
    }
  }

  render() {
    return (
      <div className="Search">
        <form className="Search-form" onSubmit={this.handleSubmit}>
          <span className="Search-prefix">#</span>
          <input className="Search-input" value={this.state.value} onChange={this.handleChange}/>
          <button className="Button" onClick={this.handleSubmit}>
            {this.showLoader()}
          </button>
        </form>
      </div>
    );
  }
};

export default Search;
