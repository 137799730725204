import React from 'react';
// import './Tracker.scss';
import '../Button/Button.scss';
import '../../utilities.scss';
import Loading from "../Loading/Loading";

class Tracker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      hasRun: false
    }

    this.authorName = props.authorName;
    this.authorId = props.authorId;
    this.heartCount = props.heartCount;
    this.followerCount = props.followerCount;
    this.followingCount = props.followingCount;
    this.diggCount = props.diggCount;
    this.friendCount = props.friendCount;
    this.videoCount = props.videoCount;

    this.submitTracking = this.submitTracking.bind(this);
  }

  submitTracking(event) {
    event.preventDefault();
    this.setState({ isLoading: true });

    // use fetch to get data from server
    fetch(`http://localhost:3000/track`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        authorId: this.props.authorId,
        authorName: this.props.authorName,
        heartCount: this.props.heartCount,
        followerCount: this.props.followerCount,
        followingCount: this.props.followingCount,
        diggCount: this.props.diggCount,
        friendCount: this.props.friendCount,
        videoCount: this.props.videoCount
      })
    })
      .then(response =>{
        this.setState({ isLoading: false, hasRun: true });
        response.json()
      })
      .then(data => {
      });
  }

  render() {
    return (
      <button className="Button Button--full u-marginTop" onClick={this.submitTracking}>
        {this.showLoading()}
      </button>
    );
  }

  showLoading() {
    if (this.state.isLoading) {
      return (
        <Loading />
      );
    } else if (this.state.hasRun) {
      return (
        <span>Tracking started...</span>
      );
    } else {
      return (
        <span>Track</span>
      );
    }
  }
};

export default Tracker;
